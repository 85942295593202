import React, {useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {inject, MobXProviderContext, observer} from "mobx-react";
import ServiceCenterDialog from "./ServiceCenterDialog";
import {FormattedMessage, useIntl} from "react-intl";
import {COLOR_UTIL} from "../../common/util/color.util";

const useStyles = makeStyles((theme) => ({
    root:{
        padding:'90px 0 0',
        width: '100%',
        "& .MuiAccordionSummary-expandIcon":{
            padding:5
        }
    },
    titleText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.5rem',
        fontWeight: 800,
        color: '#0d0d0d',
        padding:'0 20px 10px'
    },
    moreIcon:{
        color:'#c0c2c3'
    },
    accrdionText1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        fontWeight:600,
    },
    accrdionText2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.0rem',
        color:'#4f5660'
    },
    buttonStyle:{
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        borderRadius:4,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1.063rem',
        fontWeight:600,
        // color:'#fff',
        padding:'9px 14px',
        marginRight:10,
        // width:113,
        margin:'1rem',
        "&:hover":{
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    textBox:{
        background:'#f3f3f3',
        textAlign:'center',
        marginTop:45,
        padding:'36px 20px',
    },
    textStyle1:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1.438rem',
        fontWeight:700,
    },
    textStyle2:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:500,
        margin:'10px 0'
    }
}));

const Accordion = withStyles({
    root: {
        width:'100%',
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        background:'#fbfbfb',
        margin:'0 auto',
        '&:not(:last-child)': {
            // borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        background:'#fff',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        '&$expanded': {
        },
    },
    content: {
        '&$expanded': {
            margin:0,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

function MobileServiceCenter(props) {
    const classes = useStyles();
    const intl = useIntl();
    const [expanded, setExpanded] = React.useState('panel0');
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { topBarStore } = React.useContext(MobXProviderContext);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleDownloadClick = () => setDialogOpen(prevState => !prevState);

    useEffect(() => {
        if (window.location.pathname === '/servicecenter')
            topBarStore.setIsSearchBarRender(false);
    });


    return (
        <div className={classes.root}>
            <Typography className={classes.titleText}>
                <FormattedMessage id={"service_center"} />
            </Typography>
            <Box style={{padding:'0 20px'}}>
                <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{borderTop: '3px solid #000'}}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>} style={{display:'flex'}}>
                        <Typography className={classes.accrdionText1}>Q.&nbsp; </Typography>
                        <Typography className={classes.accrdionText1}>
                            <FormattedMessage id={"msg.how_do_sign_up"} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.accrdionText2}>
                            <FormattedMessage id={"msg.answer_example_one"} /><br />
                            <FormattedMessage id={"msg.answer_example_two"} /><br />
                            <FormattedMessage id={"msg.answer_example_three"} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon} />} style={{display:'flex'}}>
                        <Typography className={classes.accrdionText1}>Q.&nbsp; </Typography>
                        <Typography className={classes.accrdionText1}>
                            <FormattedMessage id={"msg.how_do_free_trial"} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.accrdionText2}>
                            <FormattedMessage id={"msg.answer_example_one"} /><br />
                            <FormattedMessage id={"msg.answer_example_two"} /><br />
                            <FormattedMessage id={"msg.answer_example_three"} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>} style={{display:'flex'}}>
                        <Typography className={classes.accrdionText1}>Q.&nbsp; </Typography>
                        <Typography className={classes.accrdionText1}>
                            <FormattedMessage id={"msg.how_much_enterprise"} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.accrdionText2}>
                            <FormattedMessage id={"msg.answer_example_one"} /><br />
                            <FormattedMessage id={"msg.answer_example_two"} /><br />
                            <FormattedMessage id={"msg.answer_example_three"} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>} style={{display:'flex'}}>
                        <Typography className={classes.accrdionText1}>Q.&nbsp; </Typography>
                        <Typography className={classes.accrdionText1}>
                            <FormattedMessage id={"msg.how_do_subscription"} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.accrdionText2}>
                            <FormattedMessage id={"msg.answer_example_one"} /><br />
                            <FormattedMessage id={"msg.answer_example_two"} /><br />
                            <FormattedMessage id={"msg.answer_example_three"} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>} style={{display:'flex'}}>
                        <Typography className={classes.accrdionText1}>Q.&nbsp; </Typography>
                        <Typography className={classes.accrdionText1}>
                            <FormattedMessage id={"msg.what_is_difference_service_grade"} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.accrdionText2}>
                            <FormattedMessage id={"msg.answer_example_one"} /><br />
                            <FormattedMessage id={"msg.answer_example_two"} /><br />
                            <FormattedMessage id={"msg.answer_example_three"} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                    <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon} />} style={{display:'flex'}}>
                        <Typography className={classes.accrdionText1}>Q.&nbsp; </Typography>
                        <Typography className={classes.accrdionText1}>
                            <FormattedMessage id={"msg.how_do_change_my_plan"} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.accrdionText2}>
                            <FormattedMessage id={"msg.answer_example_one"} /><br />
                            <FormattedMessage id={"msg.answer_example_two"} /><br />
                            <FormattedMessage id={"msg.answer_example_three"} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                    <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>} style={{display:'flex'}}>
                        <Typography className={classes.accrdionText1}>Q.&nbsp; </Typography>
                        <Typography className={classes.accrdionText1}>
                            <FormattedMessage id={"msg.how_do_cancel_subscription"} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.accrdionText2}>
                            <FormattedMessage id={"msg.answer_example_one"} /><br />
                            <FormattedMessage id={"msg.answer_example_two"} /><br />
                            <FormattedMessage id={"msg.answer_example_three"} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion square expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                    <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>} style={{display:'flex'}}>
                        <Typography className={classes.accrdionText1}>Q.&nbsp; </Typography>
                        <Typography className={classes.accrdionText1}>
                            <FormattedMessage id={"msg.how_do_cancel_membership"} />
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.accrdionText2}>
                            <FormattedMessage id={"msg.answer_example_one"} /><br />
                            <FormattedMessage id={"msg.answer_example_two"} /><br />
                            <FormattedMessage id={"msg.answer_example_three"} />
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>

            <Box className={classes.textBox}>
                <Typography className={classes.textStyle1}>
                    <FormattedMessage id={"msg.have_more_question"} />
                </Typography>
                <Typography className={classes.textStyle2}>
                    <FormattedMessage id={"msg.contact_us"} />
                </Typography>
                <Box display='flex' justifyContent='center'>
                    <Button
                        className={classes.buttonStyle}
                    >
                        <FormattedMessage id={"get_support"} />
                    </Button>
                    <Button
                        className={classes.buttonStyle}
                        onClick={handleDownloadClick}
                    >
                        <FormattedMessage id={"download_manual"} />
                    </Button>
                </Box>
            </Box>
            <ServiceCenterDialog
                open={dialogOpen}
                title={intl.formatMessage({id: 'notification'})}
                msg={intl.formatMessage({id: 'msg.coming_up_service'})}
                changeDialogOpen={setDialogOpen}
            />
        </div>
    );
}

export default inject('orgStore')(observer(MobileServiceCenter));