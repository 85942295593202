import React, {useCallback, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    MenuList,
    MenuItem,
    Grid
} from "@material-ui/core";
import OrganizationInformation from "./OrganizationInformation/OrganizationInformation";
import TeamManagement from "./TeamManagement/TeamManagement";
import TeamMemberManagement from "./TeamMemberManagement/TeamMemberManagement";
import {UI, UserType} from "../../stores/UserStore";
import {ManagementUrlCategoryType} from "../../stores/OrgStore";

import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import TeamManagementByExcel from "./TeamManagementByExcel/TeamManagementByExcel";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root:{
        width:'100%',
        background:'#fff',
        paddingTop:100,
        paddingLeft:100,
        "& .MuiListItem-root.Mui-selected":{
            background:'#d0d0d0',
        },
        "& :focus":{
            outline:'none',
        },
    },
    paper:{
        padding:0,
        borderRadius:0,
        boxShadow:'none',
    },
    menuItemBox:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:600,
        color:'#0d0d0d',
        "&:hover":{
            background:'#d0d0d0'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));
// const logPrefix = ' [ OrganizationManagement ] ' ;

function OrganizationManagement(props) {
    const intl = useIntl();
    const classes = useStyles();

    const handleListItemClick = useCallback((category) => {
        props.progressStore.setIsLoading(false);
        props.history.push(`/management/${category}`);
    },[props.progressStore, props.history]);

    const userType = props.authStore.loginUser.type;
    const selectTeamByLeader = props.teamStore.selectTeamByLeader;

    useEffect(() => {
        if ((userType !== UserType.Admin) && selectTeamByLeader &&selectTeamByLeader.id) {
            handleListItemClick(ManagementUrlCategoryType.Team);
            props.teamStore.getParentTeam(intl, selectTeamByLeader.parentTeamId);
            props.teamStore.getTeamDetail(intl, selectTeamByLeader.id);
            props.teamStore.changeUiState(UI.Information);
        } else if ((userType !== UserType.Admin) && !selectTeamByLeader.id) {
            props.history.push('/');
        }
    },[intl, props.teamStore, handleListItemClick, props.history, selectTeamByLeader, userType]);

    useEffect(()=> {
        if((userType !== UserType.Admin) && (props.match.params.category === ManagementUrlCategoryType.Organization)) props.history.push('/');
    }, [userType, props.match.params.category, props.history]);

    const handleTeamManagement = () => {
        handleListItemClick(ManagementUrlCategoryType.Team);
        if(props.authStore.loginUser.type === UserType.Admin){
            props.teamStore.changeUiState(UI.TeamTable);
            props.teamStore.teamTreeSelectedTeamId = "";
            props.teamStore.initTeamTablePage();
            props.teamStore.getTeamTreeList();
            props.teamStore.getTeamTableList();
            props.teamStore.initTeamTreeExpandedList();
        }else{
            props.teamStore.changeUiState(UI.Information);
            props.teamStore.teamTreeSelectedTeamId = "";
            props.teamStore.getTeamDetail(intl, props.teamStore.selectTeamByLeader.id);
            props.teamStore.getParentTeam(intl, props.teamStore.selectTeamByLeader.parentTeamId);
        }
    };

    const handleTeamMemberManagement = () => {
        handleListItemClick(ManagementUrlCategoryType.Member);
        if(props.authStore.loginUser.type === UserType.Admin){
            props.userStore.changeUiState(UI.MemberTable, 0);
            props.userStore.memberTreeSelectedTeamId = "";
            props.userStore.initUserTablePage();
            props.teamStore.getTeamTreeList();
            props.userStore.getUserTableListByAll();
            props.userStore.initMemberTreeExpandedList();
        }else{
            props.userStore.changeUiState(UI.MemberTable, 0);
            props.userStore.memberTreeSelectedTeamId = "";
            props.userStore.initUserTablePage();
            props.userStore.getUserTableListByTeam(intl, props.teamStore.selectTeamByLeader.id);
            props.teamStore.getTeamTreeList();
        }
    };

    const handleExcelManagement = () => {
        handleListItemClick(ManagementUrlCategoryType.Excel);
    }

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={2} className={classes.gridBox}>
                    <Paper className={classes.paper}>
                        <MenuList>
                            {props.authStore.loginUser.type === UserType.Admin &&
                            <MenuItem
                                selected={props.match.params.category === ManagementUrlCategoryType.Organization}
                                onClick={() => handleListItemClick(ManagementUrlCategoryType.Organization)}
                                className={classes.menuItemBox}
                            >
                                <FormattedMessage id="organization_setting"/>
                                {/*{props.intl.messages.OrganizationSetting}*/}
                                {/*{UTIL.getIntlFormatMessageString("OrganizationSetting")}*/}
                            </MenuItem>
                            }
                            <MenuItem
                                selected={props.match.params.category === ManagementUrlCategoryType.Team}
                                onClick={() => handleTeamManagement()}
                                className={classes.menuItemBox}
                            >
                                <FormattedMessage id="team_management"/>
                                {/*팀 관리*/}
                            </MenuItem>

                            <MenuItem
                                selected={props.match.params.category === ManagementUrlCategoryType.Member}
                                onClick={() => handleTeamMemberManagement()}
                                className={classes.menuItemBox}
                            >
                                {props.authStore.loginUser.type === UserType.Admin ?
                                                                <FormattedMessage id="team_member_and_role_management"/> :
                                                                <FormattedMessage id="member_management"/>}
                            </MenuItem>
                            {/*{props.authStore.loginUser.type === UserType.Admin &&*/}
                            {/*<MenuItem*/}
                            {/*    selected={props.match.params.category === ManagementUrlCategoryType.Excel}*/}
                            {/*    onClick={() => handleExcelManagement()}*/}
                            {/*    className={classes.menuItemBox}*/}
                            {/*>*/}
                            {/*    <FormattedMessage id={"excel_create_team_and_member"}/>*/}
                            {/*</MenuItem>*/}
                            {/*}*/}
                        </MenuList>
                    </Paper>
                </Grid>
                <Grid item xs={10} >
                    {(props.authStore.loginUser.type === UserType.Admin) && (props.match.params.category === ManagementUrlCategoryType.Organization) &&
                        <OrganizationInformation {...props}/>
                    }
                    {props.match.params.category === ManagementUrlCategoryType.Team &&
                        <TeamManagement {...props}/>
                    }
                    {props.match.params.category === ManagementUrlCategoryType.Member &&
                        <TeamMemberManagement {...props}/>
                    }
                    {/*{(props.authStore.loginUser.type === UserType.Admin) && props.match.params.category === ManagementUrlCategoryType.Excel &&*/}
                    {/*    <TeamManagementByExcel {...props}/>*/}
                    {/*}*/}
                </Grid>
            </Grid>
        </div>
    );
}
export default withRouter(inject('teamStore', 'userStore', 'authStore', 'orgStore', 'progressStore')(observer(OrganizationManagement)));