import React, {Component} from 'react';
import {
    Box,
    Button
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import CalendarShape from "./CalendarShape";
import StartTime from "./StartTime";
import MeetingTime from "./MeetingTime";
import {inject, observer} from "mobx-react";
import * as dayjs from "dayjs";
import {DATE_UTIL} from "../../common/util/date.util";
import {FormattedMessage} from "react-intl";


const style = theme => ({
    root: {
        width: 500,
        padding: 15,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
        "& .MuiButton-root": {
            minWidth: 0
        },
        "& .MuiButton-root.Mui-disabled":{
            background:'#fff',
            color:'#88889D',
            border:'1px solid #A3A8AF'
        }
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: '1.125rem',
        fontWeight: 800,
        textAlign: 'center'
    },
    buttonStyle: {
        padding: '7px 23px',
        borderRadius: 6,
        background: '#18a0fb',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#fff',
        fontSize: '1rem',
        "&:hover": {
            background: '#18a0fb',
        }
    },
    buttonStyle1: {
        padding: '7px 23px',
        borderRadius: 6,
        background: '#a3a8af',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#fff',
        fontSize: '1rem',
        marginRight: 10,
        "&:hover": {
            background: '#a3a8af',
        }
    }
});

@inject('roomStore')
@observer
class CalendarBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDatetime: {
                date:dayjs(new Date(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT)).setHours(0,0,0)).tz(DATE_UTIL.getTimeZone(), true), hour: '', min: '', am:"AM"
            },
            endDatetime:{
                hour: '',
                min: ''
            },
            checkNull : false,
        }
    }

    onChangeDate = (props) => {
        this.setState({startDatetime: {...this.state.startDatetime, date: props}})
    }

    onChangeStartTime = (time, minute, radio) => {
        this.setState({startDatetime: {...this.state.startDatetime, hour: time, min : minute, am : radio}}, this.InvalidationDate)
    }

    onChangeStartMin = (minute) => {
        this.setState({startDatetime: {...this.state.startDatetime, min: minute}},this.InvalidationDate)
    }

    onChangeAm = (radio) => {
        this.setState({startDatetime: {...this.state.startDatetime, am: radio}})
    }

    onChangeEndTime = (time, minute) => {
        this.setState({endDatetime: {hour: time, min : minute}},this.InvalidationDate)
    }

    onChangeEndMin = (props) => {
        this.setState({endDatetime: {...this.state.endDatetime, min: props}},this.InvalidationDate)
    }

    InvalidationDate = () => {
        let checkNull = false;
        if(this.state.startDatetime.hour === "" || this.state.startDatetime.hour === "시간"){
            checkNull = true;
        }else if(this.state.startDatetime.min === "" || this.state.startDatetime.min === "분"){
            checkNull = true;
        }else if(this.state.endDatetime.hour === "" || this.state.endDatetime.hour === "시간"){
            checkNull = true;
        }else if(this.state.endDatetime.min === "" || (this.state.endDatetime.hour === "00" && this.state.endDatetime.min === "00")){
            checkNull = true;
        }
        this.setState({checkNull : checkNull})
    }

    handleSave = (timeInfo) => {
        this.props.roomStore.onChangeDate(timeInfo);
        this.props.onClickSave();
    }

    handleCancel = () => {
        this.props.onClickSave();
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root} onChange={this.InvalidationDate}>
                <CalendarShape onChangeDate={this.onChangeDate}/>
                <Box style={{paddingLeft: 15, paddingRight: 15, width: '100%'}}>
                    <StartTime onChangeStartTime={this.onChangeStartTime} onChangeStartMin={this.onChangeStartMin} onChangeAm={this.onChangeAm}/>
                    <MeetingTime onChangeEndTime={this.onChangeEndTime} onChangeEndMin={this.onChangeEndMin}/>
                    <Box display='flex' justifyContent='flex-end' pt={2}>
                        <Button
                            className={classes.buttonStyle1}
                            onClick={()=>this.handleCancel()}
                            disabled={this.state.checkNull}
                        >
                            <FormattedMessage id="cancel"/>
                        </Button>
                        <Button
                            className={classes.buttonStyle}
                            onClick={()=>this.handleSave(this.state)}
                            disabled={this.state.checkNull}
                        >
                            <FormattedMessage id="complete"/>
                        </Button>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(CalendarBox);