import React, {Component} from 'react';
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box, IconButton, MenuItem, MenuList, Popover,
    TextareaAutosize,
    Typography
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";
import {ReactComponent as MoreIcon} from "../../../../common/images/MoreIcon.svg";

const style = theme => ({
    root:{
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width:'100%',
        marginBottom: 40
    },
    contentsBox:{
        width:'100%',
        border: '1px solid #c4c9de',
        borderRadius: 8,
        padding:'11px 20px 23px',
        "&:hover":{
            border: '1px solid #0097ff !important',
            borderTop: '4px solid #0097ff !important',
        },
    },
    textStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight: 600,
    },
    inputStyle: {
        width: '100%',
        padding: '11px 13px',
        borderRadius: 5,
        border: '1px solid #bec3da',
        background: '#fff',
        marginTop:7,
        "&::placeholder": {
            color: '#a3a8af',
            fontWeight: 300
        },
        "&:focus": {
            outline: 'none',
        },
    },
    popoverBox: {
        "& .MuiPopover-paper": {
            borderRadius: 7,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        }
    },
    actionsBox: {
        marginTop: 30,
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.125rem',
            color: '#0d0d0d',
            fontWeight: 600,
        }
    },
    menuText: {
        fontSize: '1rem',
        color: '#000',
        "&:hover": {
            background: '#d0d0d0'
        }
    },
    textareaStyle: {
        width: "100%",
        border: '1px solid #bec3da',
        padding: '11px 13px',
        borderRadius: 5,
        overflowY: 'scroll',
        overflowX: 'hidden',
        resize: 'none',
        marginTop:7,
        "&::placeholder": {
            color: '#a3a8af',
            fontWeight: 300
        },
        "&:focus": {
            outline: 'none',
        },
        "&::-webkit-scrollbar": {
            width: '5px',
            marginRight:10,
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background: 'transparent',
            marginTop: 10,
        },
    },
});
@inject('surveyStore')
@observer
class ParagraphAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }
    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(this.props.paragraphAddId !== this.props.focusId) {
    //         const el = document.getElementById(this.props.paragraphAddId);
    //         el.setAttribute("border", '1px solid #c4c9de');
    //         el.setAttribute("border-top", '1px solid #c4c9de');
    //         el.style.border = '1px solid #c4c9de';
    //         el.style.borderTop = '1px solid #c4c9de';
    //     }
    // }

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleSurveyItemTitle = (value) => {
        this.props.surveyStore.changeSurveyItemTitle(this.props.surveyQuestionId, value);
    };

    handleSurveyItemDescriptions = (value) => {
        this.props.surveyStore.changeSurveyItemDescription(this.props.surveyQuestionId, value);
    };

    handleDeleteContextItem = () => {
        this.props.surveyStore.deleteSurveyItems(this.props.surveyQuestionId);
    };

    render() {
        const { classes, surveyData, intl, surveyQuestionId } = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        return (
            <>
                <Box mt={3}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.actionsBox}>
                    <Typography className={classes.textStyle}><FormattedMessage id="subject"/> <span style={{color:'#ff0000'}}>*</span></Typography>
                    <IconButton style={{padding: 5}} aria-owns={open ? 'simple-popper' : undefined}
                                onClick={this.handleClick}>
                        <MoreIcon style={{width: 25, height: 25}}/>
                    </IconButton>
                    </Box>
                    <input
                        name="title"
                        type="text"
                        id={`title${surveyQuestionId}`}
                        maxLength="256"
                        value={surveyData.title}
                        placeholder={intl.formatMessage({id: "msg.insert_survey_subject"})}
                        className={clsx(classes.textStyle, classes.inputStyle)}
                        onChange={(e) => this.handleSurveyItemTitle(e.target.value)}
                    />
                </Box>
                <Box mt={3}>
                    <Typography className={classes.textStyle}><FormattedMessage id="descriptions"/></Typography>
                    <TextareaAutosize
                        rows={3}
                        rowsMax={3}
                        maxLength="10000"
                        aria-label="maximum height"
                        value={surveyData.contents}
                        placeholder={intl.formatMessage({id: "msg.insert_survey_description"})}
                        onChange={(e) => this.handleSurveyItemDescriptions(e.target.value)}
                        className={clsx(classes.textStyle, classes.textareaStyle)}
                    />
                </Box>
                <Popover
                    id="simple-popper"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className={classes.popoverBox}
                >
                    <MenuList>
                        <MenuItem className={clsx(classes.textStyle, classes.menuText)} onClick={this.handleDeleteContextItem}><FormattedMessage id="delete"/></MenuItem>
                    </MenuList>
                </Popover>
            </>
        );
    }
}

export default withStyles(style)(injectIntl(ParagraphAdd));