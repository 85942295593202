import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Grid,
    Button,
    Box, TextField, IconButton,
} from "@material-ui/core";
import CreateTeamMember from "./CreateTeamMember";
import TeamMemberInformation from "./TeamMemberInformation";
import TeamMemberTreeView from "./TreeView/TeamMemberTreeView";
import TeamMemberTable from "./TeamMemberTable/TeamMemberTable";
import {inject, observer} from "mobx-react";
import {UI, UserType} from "../../../stores/UserStore";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {Clear as ClearIcon, Search as SearchIcon} from "@material-ui/icons";
import DeleteDialog from "../../../common/DeleteDialog";
import {injectIntl} from "react-intl";
import MemberExcelModal from "./MemberExcelModal"
import {COLOR_UTIL} from "../../../common/util/color.util";


const style = theme => ({
    root:{
        width:'100%',
        background:'#fff',
        paddingTop:100,
        paddingLeft:30,
        marginLeft:90,
        "& .MuiListItem-root.Mui-selected":{
            background:'#d0d0d0',
        },
        "& :focus":{
            outline:'none',
        },
    },
    paper:{
        borderRadius:0,
        boxShadow:'none',
    },
    menuItemBox:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.375rem',
        fontWeight:600,
        color:'#0d0d0d',
        "&:hover":{
            background:'#d0d0d0'
        }
    },
    btnStyle:{
        // width:135,
        padding:'10px 16px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:600,
        // color:'#fff',
        marginRight:20,
        marginTop:30,
        letterSpacing: '-0.5px',
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    textStyle:{
        width:190,
        // padding:'10px 0',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.063rem',
        fontWeight:600,
        color:'#fff',
        marginRight:20,
        marginTop:40,
        "& input":{
            fontSize:'1.063rem',
            letterSpacing:-1,
        },
        "& .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottomColor:'#7d86a9'
        }
    }
});
@inject("teamStore", "authStore", "userStore", "excelStore")
@observer
class TeamMemberManagement extends Component {
    constructor(props) {
        super(...arguments, props);
        this.state = {
            selectedTeamId: 0
        }
    }

    handleSelectTeam = (teamId) => {
        this.setState({selectedTeamId : teamId});
    }

    componentDidMount() {
        const {intl} = this.props;
        const { selectTeamByLeader } = this.props.teamStore;

        if (this.props.authStore.loginUser.type === UserType.Admin) {
            this.props.userStore.memberTreeSelectedTeamId = "";
            this.props.userStore.initUserTablePage();
            this.props.teamStore.getTeamTreeList();
            this.props.userStore.getUserTableListByAll();
            this.props.userStore.initMemberTreeExpandedList();
        } else {
            if (selectTeamByLeader && selectTeamByLeader.id) {
                this.props.userStore.changeUiState(UI.MemberTable, 0);
                this.props.userStore.memberTreeSelectedTeamId = "";
                this.props.userStore.initUserTablePage();
                this.props.userStore.getUserTableListByTeam(intl, selectTeamByLeader.id);
                this.props.teamStore.getTeamTreeList(this.props.authStore.loginUser);
            }
        }
    }

    componentWillUnmount() {
        this.props.teamStore.initTeamTablePage();
    }

    keyDownSearchKeyword = (e) => {
        if (e.isComposing || e.keyCode === 229) { return; }
        if (e.keyCode  === 13) {
            this.SearchTeamTableListByKeyword();
        } else if (e.keyCode === 27) {
            this.props.userStore.initUserTableSearchKeyword();
        }
    }

    SearchTeamTableListByKeyword = () => {
        const {intl} = this.props;
        const { memberTreeSelectedTeamId } = this.props.userStore;
        const { selectTeamByLeader } = this.props.teamStore;
        const keyword = this.props.userStore.userTablePage.keyword;
        this.props.userStore.initUserTablePage();
        this.props.userStore.userTablePage.keyword = keyword;

        if ( memberTreeSelectedTeamId === "-1" || memberTreeSelectedTeamId === "" ){
            if ((this.props.authStore.loginUser.type !== UserType.Admin) && selectTeamByLeader && selectTeamByLeader.id) {
                this.props.userStore.getUserTableListByTeam(intl, selectTeamByLeader.id);
            } else {
                this.props.userStore.getUserTableListByAll();
            }
        } else if (memberTreeSelectedTeamId === "-2") {
            this.props.userStore.getUserTableListByNotTeam();
        } else {
            this.props.userStore.getUserTableListByTeam(intl, memberTreeSelectedTeamId);
        }
    }
    removeUser = () =>{
        this.props.userStore.removeUser(this.removeUserCallback);
    }

    removeUserCallback = () => {
        const {intl} = this.props;
        const teamId = this.props.userStore.memberTreeSelectedTeamId;

        if (!teamId || teamId === "-1") {
            this.props.userStore.getUserTableListByAll();
        } else if (teamId === "-2") {
            this.props.userStore.getUserTableListByNotTeam();
        } else {
            this.props.userStore.getUserTableListByTeam(intl, teamId);
        }
        this.props.userStore.changeUiState(UI.MemberTable, 0);
    }

    render() {
        const { classes, intl, excelStore } = this.props;
        const { UiState, changeUiState, changeUserConfirmDialogOpen, userConfirmDialog,
            changeUserTableSearchKeyword, userTablePage, initUserTableSearchKeyword,
            userDeleteDialog, changeUserDeleteDialogOpen
        } = this.props.userStore;
        const { loginUser } = this.props.authStore;

        return (
            <div>
                <Grid container>
                    {this.props.authStore.loginUser.type ==="Admin" &&
                        <Grid item xs={3}>
                            <TeamMemberTreeView selectTeam={this.handleSelectTeam}/>
                        </Grid>
                    }

                    <Grid item xs={this.props.authStore.loginUser.type ==="Admin" ? 9 : 12}>
                        {UiState.state===UI.MemberTable &&
                            <Box>
                                <Box style={{paddingLeft: 20}}>
                                    {/*{(loginUser.type === UserType.Admin && this.state.selectedTeamId !== 0) &&*/}
                                    {/*    <Button className={classes.btnStyle} onClick={()=> excelStore.setMemberExcelModal(true)}>*/}
                                    {/*        {"+ " + intl.formatMessage({id: "add_excel"})}*/}
                                    {/*    </Button>*/}
                                    {/*}*/}
                                    {/*{loginUser.type !== UserType.Admin &&*/}
                                    {/*    <Button className={classes.btnStyle} onClick={()=> excelStore.setMemberExcelModal(true)}>*/}
                                    {/*        {"+ " + intl.formatMessage({id: "add_member_excel"})}*/}
                                    {/*    </Button>*/}
                                    {/*}*/}
                                    <div style={{float: 'right', marginBottom:16}}>
                                        <TextField value={userTablePage.keyword}
                                                   onChange={changeUserTableSearchKeyword}
                                                   onKeyDown={this.keyDownSearchKeyword}
                                                   className={classes.textStyle}
                                                   placeholder={intl.formatMessage({id: "msg.search_nickname"})}
                                                   InputProps={{
                                                       endAdornment: (
                                                           <Box display='flex' style={{marginRight : -5}}>
                                                               <IconButton position="end"
                                                                           style={{ padding:2, width:'1.5rem',height:'1.5rem', textAlign:'center',backgroundColor:'#e5e5e5' }}
                                                                           onClick={initUserTableSearchKeyword}
                                                                   >
                                                                   <ClearIcon style={{width:18, height:18}}/>
                                                               </IconButton>
                                                               <IconButton position="end"
                                                                           style={{ padding:2 }}
                                                                           onClick={this.SearchTeamTableListByKeyword}
                                                               >
                                                                   <SearchIcon style={{width:24, height:24}}/>
                                                               </IconButton>
                                                           </Box>
                                                       ),
                                                   }}>
                                        </TextField>
                                        <Button className={classes.btnStyle} style={{marginRight: 30}} onClick={()=>changeUiState(UI.Create)}>
                                            {
                                                loginUser.type ===UserType.Admin ?
                                                    "+ " + intl.formatMessage({id: "add"})
                                                    :
                                                    "+ " + intl.formatMessage({id: "add_member"})
                                            }
                                        </Button>
                                    </div>
                                </Box>
                                <TeamMemberTable />
                            </Box>
                        }
                        {UiState.state===UI.Information && <TeamMemberInformation/>}
                        {UiState.state===UI.Create && <CreateTeamMember/>}

                        <DeleteDialog open={userDeleteDialog.open}
                                      title={userDeleteDialog.title}
                                      msg={userDeleteDialog.msg}
                                      changeDialogOpen={changeUserDeleteDialogOpen}
                                      submit={this.removeUser}
                        />
                        <ConfirmDialog open={userConfirmDialog.open}
                                       title={userConfirmDialog.title}
                                       msg={userConfirmDialog.msg}
                                       changeDialogOpen={(value)=>changeUserConfirmDialogOpen(value)}/>
                    </Grid>
                </Grid>
                <MemberExcelModal selectedTeamId={this.state.selectedTeamId}/>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(TeamMemberManagement));