import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Popper,
    Paper,
    Typography,
    Box,
    Button,
    IconButton,
    ClickAwayListener,
    Tooltip, Dialog, DialogContent, DialogActions, TextField
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";
import {UploadState} from "../../../stores/UserLoungeStore";
import DialogTitle from "@material-ui/core/DialogTitle";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';


const style = theme => ({
    dialogBox:{
        "& .MuiDialogContent-root":{
            padding:'15px 20px',
        },
        "& .MuiDialogActions-root":{
            // padding:16
        }
    },
    titleBox:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        // marginBottom:16,
    },
    dialogTitleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center'
    },
    titleStyle: {
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.25rem',
        color:'#303030',
        fontWeight:800,
    },
    textStyle:{
        borderTop:'1px solid #bfbfbf',
        borderBottom:'1px solid #bfbfbf',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#303030',
        lineHeight:2,
        padding:'16px 20px',
        wordBreak: 'keep-all',
    },
    buttonText:{
        width: 100,
        padding: '13px 0',
        marginRight:8,
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#fff',
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle:{
        background: () => theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#fff',
        padding:'8px 16px',
        "&:hover":{
            background:() => theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor,
        }
    },
    inputStyleBox:{
        display:'flex',
        alignItems:'center',
        marginBottom:10,
    },
    inputStyle: {
        display: 'none',
        width:'100%',
        padding:'10px 12px'
    },
    toastContainer:{
        top: '20px !important'
    },
    toasts:{
        fontSize: '1rem',
        color: '#fff !important',
        backgroundColor: 'rgb(0, 151, 255) !important',
        fontFamily:'NanumSquareRoundOTF',
        minHeight: 35
    }
});

@inject("timelineStore")
@observer
class VideoUploadDialog extends Component {
    state = {
        selectedFile: undefined,
        selectedFileName: ''
    };

    onFileSelect = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
            selectedFileName: event.target.files[0].name.split('.').slice(0, -1).join('.')
        });
    };

    render() {
        const { classes, apiRoomId } = this.props;

        return (
            <Dialog open={this.props.timelineStore.isVideoUploadDialogOpen}
                    fullWidth={true} maxWidth={'xs'} className={classes.dialogBox}>
                <DialogTitle>
                    <Box className={classes.dialogTitleBox}>
                        <Typography className={classes.titleStyle}>영상 업로드</Typography>
                    </Box>

                </DialogTitle>
                <DialogContent className={classes.inputStyleBox}>
                        <input
                            accept="video/mp4"
                            className={classes.inputStyle}
                            onChange={(event) => this.onFileSelect(event)}
                            id={"contained-button-file-" + apiRoomId}
                            type="file"
                        />
                        <label htmlFor={"contained-button-file-" + apiRoomId} >
                            <Box className={classes.titleBox}>
                                <Button className={classes.buttonText} component="span">영상 선택</Button>
                            </Box>
                        </label>

                    <TextField id="outlined-basic" label="영상 이름"
                               variant="outlined"
                               value={this.state.selectedFileName}
                               style={{width:'100%'}}
                               onChange={(event) => {
                                this.setState({
                                    selectedFileName: event.target.value
                                });
                    }}/>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            this.props.timelineStore.setVideoUploadDialogState(false);

                            this.setState({
                                selectedFile: undefined,
                                selectedFileName: ''
                            });
                        }}
                        className={classes.btnStyle}
                        style={{background: '#a3a8af'}}
                    >
                        <FormattedMessage id="cancel"/>
                    </Button>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                const tS = this.props.timelineStore;
                                const videoName = (this.state.selectedFileName + ".mp4").replaceAll(" ", "_");

                                const videoInfo = tS.getVideoInfo(apiRoomId);

                                if(videoInfo !== undefined) {
                                    for (const name of videoInfo.videos) {
                                        if(name === videoName) {
                                            ToastsStore.info("중복되는 영상 이름입니다.", 1500, classes.toasts);
                                            return;
                                        }
                                    }
                                }

                                if(this.state.selectedFile === undefined) {
                                    ToastsStore.info("파일을 업로드 해주세요.", 1500, classes.toasts);
                                    return;
                                }
                                else if(this.state.selectedFileName.length === 0){
                                    ToastsStore.info("파일명을 입력해주세요.", 1500, classes.toasts);
                                    return;
                                }


                                tS.setVideoUploadDialogState(false);

                                tS.addVideo(apiRoomId, {file: this.state.selectedFile, name: videoName, state: UploadState.Pending});

                                this.setState({
                                    selectedFile: undefined,
                                    selectedFileName: ''
                                });
                            }}
                            className={classes.btnStyle}
                        >
                            <FormattedMessage id="ok"/>
                        </Button>
                    </DialogActions>
                </DialogActions>

                <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground />
            </Dialog>
        );
    }
}

export default withStyles(style)(injectIntl(VideoUploadDialog));