import React, {useEffect} from 'react';
import clsx from 'clsx';
import {
    Typography,
    Box,
    Select,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    MenuItem
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {DATE_UTIL} from "../../common/util/date.util";
import dayjs from "dayjs";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles({
    root: {
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.2rem',
            color: '#1f1f1f'
        },
        "& .MuiFormControlLabel-root":{
            marginLeft:0,
            marginRight:5
        },
        "& .MuiFormGroup-root":{
            display:'flex',
            flexDirection:'row'
        }
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: '1.2rem',
        fontWeight: 600,
        marginTop:20,
        paddingBottom:7
    },
    formControlBox: {
        width:65,
        "& .MuiOutlinedInput-input": {
            padding: '8px 10px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
            border:'1px solid #c0c2c3'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            border:'1px solid #c0c2c3'
        },
        "& .MuiSelect-select:focus":{
            background:'transparent'
        },
    },
    selectBox: {
        width: "100%",
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        borderRadius: 4,
        background:'transparent',
        padding:0,
        outline:'none',
        "& :focus": {
            outline: 'none',
        },
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        color: '#303030',
        padding:5,
        outline:0,
        minHeight:0,
        "&:hover":{
            color:'#303030',
        }
    },
    icon: {
        borderRadius: '50%',
        width: 13,
        height: 13,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 13,
            height: 13,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}


export default function MobileStartTime(props) {
    const intl = useIntl();
    const classes = useStyles();
    const localMin = () => {
        let min = parseInt(DATE_UTIL.getTimeZoneDate(dayjs(), 'mm'));
        if (min % 5 > 0) {
            min = min - min % 5
        }
        let stringToMinute = String(min);
        if (stringToMinute.length === 1) {
            stringToMinute = '0'.concat(stringToMinute)
        }
        return stringToMinute
    }

    const [time, setTime] = React.useState(DATE_UTIL.getTimeZoneDate(dayjs(), 'hh'));
    const [minute, setMinute] = React.useState('');
    const [radio, setRadio] = React.useState(DATE_UTIL.getTimeZoneDate(dayjs(), 'HH') >= 12 ? 'PM' : 'AM');

    const changeStartTime = props.onChangeStartTime;
    useEffect(() => {
        setMinute(localMin)
    }, [])
    useEffect(() => {
        changeStartTime(time, minute, radio);
    }, [time, minute, radio, changeStartTime]);

    return (
        <div className={classes.root}>
            <Typography className={classes.titleStyle}><FormattedMessage id="start_time"/></Typography>

            <Box display='flex' alignItems='center'>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        value={time}
                        onChange={(event) => {
                            setTime(event.target.value)
                        }}
                        className={classes.selectBox}
                    >
                        <MenuItem value="시간" className={classes.optionStyle}><FormattedMessage id="hour"/></MenuItem>
                        <MenuItem value="01" className={classes.optionStyle}>1</MenuItem>
                        <MenuItem value="02" className={classes.optionStyle}>2</MenuItem>
                        <MenuItem value="03" className={classes.optionStyle}>3</MenuItem>
                        <MenuItem value="04" className={classes.optionStyle}>4</MenuItem>
                        <MenuItem value="05" className={classes.optionStyle}>5</MenuItem>
                        <MenuItem value="06" className={classes.optionStyle}>6</MenuItem>
                        <MenuItem value="07" className={classes.optionStyle}>7</MenuItem>
                        <MenuItem value="08" className={classes.optionStyle}>8</MenuItem>
                        <MenuItem value="09" className={classes.optionStyle}>9</MenuItem>
                        <MenuItem value="10" className={classes.optionStyle}>10</MenuItem>
                        <MenuItem value="11" className={classes.optionStyle}>11</MenuItem>
                        <MenuItem value="12" className={classes.optionStyle}>12</MenuItem>
                    </Select>
                </FormControl>
                <Typography style={{paddingLeft: 10, paddingRight: 10}}> : </Typography>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        value={minute}
                        onChange={(event) => {
                            setMinute(event.target.value)
                        }}
                        className={classes.selectBox}
                    >
                        <MenuItem value="분" className={classes.optionStyle}><FormattedMessage id="minute"/></MenuItem>
                        <MenuItem value="00" className={classes.optionStyle}>00</MenuItem>
                        <MenuItem value="05" className={classes.optionStyle}>05</MenuItem>
                        <MenuItem value="10" className={classes.optionStyle}>10</MenuItem>
                        <MenuItem value="15" className={classes.optionStyle}>15</MenuItem>
                        <MenuItem value="20" className={classes.optionStyle}>20</MenuItem>
                        <MenuItem value="25" className={classes.optionStyle}>25</MenuItem>
                        <MenuItem value="30" className={classes.optionStyle}>30</MenuItem>
                        <MenuItem value="35" className={classes.optionStyle}>35</MenuItem>
                        <MenuItem value="40" className={classes.optionStyle}>40</MenuItem>
                        <MenuItem value="45" className={classes.optionStyle}>45</MenuItem>
                        <MenuItem value="50" className={classes.optionStyle}>50</MenuItem>
                        <MenuItem value="55" className={classes.optionStyle}>55</MenuItem>
                    </Select>
                </FormControl>

                <FormControl component="div" style={{marginLeft: 10}}>
                    <RadioGroup
                        value={radio}
                        aria-label="time"
                        name="customized-radios"
                        onChange={(event) => {
                            setRadio(event.target.value)
                        }}
                    >
                        <FormControlLabel value="AM" control={<StyledRadio style={{padding:5}}/>} label={intl.formatMessage({id: "am"})}/>
                        <FormControlLabel value="PM" control={<StyledRadio style={{padding:5}}/>} label={intl.formatMessage({id: "pm"})}/>
                    </RadioGroup>
                </FormControl>
            </Box>


        </div>
    );
}