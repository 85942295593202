import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Button,
    Typography,
    MenuItem,
    Select,
    FormControl
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import MobileSurveySingleChoice from "./MobileSurveySingleChoice";
import MobileSurveyParagraph from "./MobileSurveyParagraph";
import MobileSurveyMultipleChoice from "./MobileSurveyMultipleChoice";
import MobileSurveyShortAnswer from "./MobileSurveyShortAnswer";
import MobileSurveyRating from "./MobileSurveyRating";
import {ReactComponent as TopIcon} from "../../../common/images/TopIcon.svg";
import {FormattedMessage, injectIntl} from "react-intl";
import {DATE_UTIL} from "../../../common/util/date.util";
import {withRouter} from "react-router-dom";
import {COLOR_UTIL} from "../../../common/util/color.util";

const style = theme => ({
    root:{
        paddingTop: 60,
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        fontWeight:600,
        color:'#0d0d0d',
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:'8px 10px 8px 20px',
    },
    titleText:{
        fontWeight:600
    },
    userInfoBox:{
        background:'#f0f1f1',
        padding:'18px 20px'
    },
    textStyle1:{
        width:'80px',
        fontSize:'1.1rem',
        color:'#a3a8af',
        fontWeight:400,
        marginBottom:12
    },
    textStyle2:{
        fontSize:'1.1rem',
        fontWeight:400,
        marginBottom:12
    },
    textStyle3:{
        fontSize:'1rem',
        color:'#ff0000',
        textAlign:'right',
        fontWeight:500
    },
    formControlBox: {
        background: '#fff',
        borderRadius: 5,
        "& .MuiSelect-select": {
            background: 'transparent',
        },
        "& .MuiOutlinedInput-input": {
            padding: '8px 6px'
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border:0,
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent',
        },
    },
    selectBox: {
        width: 150,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1rem',
        color: '#000',
        fontWeight: 500,
        marginBottom:0,
        paddingRight:25,
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1rem',
        color: '#404040',
        fontWeight: 500,
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        "&:hover":{
            background:'#e6e7e8'
        }
    },
    buttonStyle:{
        width:110,
        padding: '5px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        fontWeight: 300,
        // color: '#fff',
        borderRadius: 7,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    topButtonStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        color:'#000',
        marginTop:15
    }
});

@inject('topBarStore', 'statisticsStore', 'progressStore')
@observer
class MobileUserSurveyDetail extends Component {
    componentDidMount() {
        const { topBarStore, statisticsStore, progressStore } = this.props;

        statisticsStore.getUserSurveyStatistics(this.props.match.params.roomId, this.props.match.params.userId);
        if (window.location.pathname === `/rooms/${this.props.match.params.roomId}/users/${this.props.match.params.userId}/statistics`) {
            topBarStore.setIsSearchBarRender(false);
        }
        progressStore.setIsLoading(statisticsStore.getIsLoading);
    };

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {statisticsStore, progressStore} = this.props;
        progressStore.setIsLoading(statisticsStore.getIsLoading);
    };

    getItem = (item) => {
        switch (item.type) {
            case 'SingleChoice' :
                return (
                    <MobileSurveySingleChoice item={item}/>
                )
            case 'MultipleChoice' :
                return (
                    <MobileSurveyMultipleChoice item={item}/>
                )
            case 'ShortAnswer' :
                return (
                    <MobileSurveyShortAnswer item={item}/>
                )
            case 'Rating' :
                return (
                    <MobileSurveyRating item={item} />
                )
            default :
                return (
                    <MobileSurveyParagraph item={item} />
                )
        }
    };

    render() {
        const { classes, history, intl, statisticsStore } = this.props;
        const { selectedUserSurvey } = this.props.statisticsStore;

        return (
            <div className={classes.root}>
                <Box className={classes.titleBox} id="mobilTop">
                    <Typography className={clsx(classes.textStyle, classes.titleText)}><FormattedMessage id="survey"/></Typography>
                    {(Object.keys(statisticsStore.userSurveyStatistics).length !== 0 && statisticsStore.userSurveyStatistics.userSurveyList.length > 0 && !statisticsStore.getIsLoading) &&
                        <FormControl variant={'outlined'} className={classes.formControlBox}>
                            <Select
                                value={selectedUserSurvey}
                                onChange={(event) => statisticsStore.changeSelectedUserSurvey(event.target.value)}
                                className={clsx(classes.textStyle, classes.selectBox)}
                                MenuProps={{
                                    classes: {
                                        list: classes.menuList
                                    }
                                }}
                            >
                                {
                                    statisticsStore.userSurveyStatistics.userSurveyList.map((survey, i) => (
                                        <MenuItem key={i} value={survey} style={{minHeight:0}}>
                                            <Typography className={clsx(classes.textStyle, classes.optionStyle)}>{survey.survey.title}</Typography>
                                        </MenuItem >
                                    ))
                                }
                            </Select>
                        </FormControl>
                    }
                </Box>
                {(Object.keys(statisticsStore.userSurveyStatistics).length !== 0 && statisticsStore.userSurveyStatistics.userSurveyList.length > 0 && !statisticsStore.getIsLoading) &&
                    <>
                        <Box className={classes.userInfoBox}>
                            <Box display='flex' alignItems='center'>
                                <Typography className={clsx(classes.textStyle, classes.textStyle1)}><FormattedMessage id="respondent"/></Typography>
                                <Typography className={clsx(classes.textStyle, classes.textStyle2)}>{statisticsStore.userSurveyStatistics.userName}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Typography className={clsx(classes.textStyle, classes.textStyle1)}><FormattedMessage id="submitted_time"/></Typography>
                                <Typography className={clsx(classes.textStyle, classes.textStyle2)}>{DATE_UTIL.getTimeZoneDate(selectedUserSurvey.submittedDatetime, `YYYY${intl.formatMessage({id: "calendar_year"})} MMM D${intl.formatMessage({id: "calendar_day"})} hh:mm A`)}</Typography>
                            </Box>
                        </Box>

                        <Box pl={3} pr={3} pt={2}>
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <Typography className={classes.textStyle} style={{fontSize:'1.8rem'}}>{selectedUserSurvey.survey.title}</Typography>
                                <Typography className={clsx(classes.textStyle, classes.textStyle3)}>* <FormattedMessage id="required_items"/></Typography>
                            </Box>
                            <Typography className={classes.textStyle} style={{fontSize:'1.4rem', marginTop: 50}}> {selectedUserSurvey.survey.descriptions}</Typography>
                        </Box>

                        <Box pl={3} pr={3} pt={2}>
                            {selectedUserSurvey.survey.items.length > 0 &&
                                statisticsStore.getSortedUserSurveyItems.map((item, i) => (
                                    <div key={i}>
                                        {this.getItem(item)}
                                    </div>
                                ))
                            }
                        </Box>
                    </>
                }
                <Box display="flex" justifyContent='flex-end' alignItems='center' pl={3} pr={3}>
                    <Button className={classes.btnStyle} onClick={() => history.push(`/rooms/${this.props.match.params.roomId}/statistics`)}>
                        <FormattedMessage id="ok"/>
                    </Button>
                </Box>

                <Box display="flex" justifyContent='center'  pb={3}>
                    <Button startIcon={<TopIcon/>}
                            className={classes.topButtonStyle}
                            onClick={() => {
                                const topEl = document.getElementById("mobilTop");
                                topEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                            }}
                    >
                        <FormattedMessage id="go_to_the_top"/>
                    </Button>
                </Box>


            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(MobileUserSurveyDetail)));