import React from 'react'
import reactCSS from 'reactcss'
import {withStyles} from "@material-ui/core/styles";
import { SketchPicker } from 'react-color'
import {CONFIG_SELECTIONS} from "../../../stores/OrgStore";

const style = theme => ({
    root: {
        marginBottom:20,
        // picker box
        "& .sketch-picker":{
            width:'200px !important',
            borderRadius:'18px !important',
            boxShadow:'none !important',
            border:'1px solid #CACACA'
        },
        //큰색상 box
        "& .sketch-picker > div:first-child":{
            borderRadius:'10px !important',
            marginBottom:'10px !important'
        },
        //큰색상 box 선택 원
        "& .sketch-picker > div:first-child > div:first-child > div:last-child > div:last-child > div":{
            width:'15px !important',
            height:'15px !important',
            border:'8px solid #fff',
            boxShadow:'none !important',
            transform:'translate(-14px, -14px) !important'
        },
        //슬라이드 색상 bar 선택원
        "& .hue-horizontal > div:last-child > div":{
            width:'13px !important',
            // height:'10px !important',
            borderRadius:'50% !important',
            marginTop:'0 !important',
            transform:'translateX(-7px) !important'

        },
        //슬라이드색상 bar
        "& .sketch-picker > div:nth-of-type(2) > div:nth-of-type(1) div:nth-of-type(1) ":{
            height:'13px !important',
            borderRadius:24,
        },
        //투명색상
        "& .sketch-picker > div:nth-of-type(2) > div:nth-of-type(1) div:nth-of-type(2) ":{
            display:'none !important'
        },
        //미리보기 선택색상
        "& .sketch-picker > div:nth-of-type(2) > div:nth-of-type(2)":{
            display:'none !important'
        },
        //선택 색상
        "& .sketch-picker > div:last-child":{
            display:'none !important'
        },
        "& input":{
            border:'1px solid #E5E8E8 !important',
            borderRadius:'8px !important',
            paddingTop:'6px !important',
            paddingBottom:'6px !important',
            textAlign:'center !important'
        },
        "& label":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1rem',
            color:'#111',
            fontWeight:800
        }
    },

});


class DirectSelectionColor extends React.Component {
    state = {
        displayColorPicker: false,
        // color: {
        //     r: '241',
        //     g: '112',
        //     b: '19',
        //     a: '1'
        // },
        color: "",

    };

    componentDidMount() {
        const { mainColor, subColor, type } = this.props;
        if(type === CONFIG_SELECTIONS.Main) this.setState({color : mainColor});
        else this.setState({color : subColor})
    }

    handleChange = (color) => {
        // console.log("select color", color);
        const { type, selectMainColor, selectSubColor } = this.props;
        if(type === CONFIG_SELECTIONS.Main) selectMainColor(color.hex);
        if(type === CONFIG_SELECTIONS.Sub) selectSubColor(color.hex);
        this.setState({ color: color.hex })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((prevProps.type !== this.props.type) || (prevProps.mainColor !== this.props.mainColor) || (prevProps.subColor !== this.props.subColor)) {
            (this.props.type === CONFIG_SELECTIONS.Main) ? this.setState({color : this.props.mainColor})
                : this.setState({color : this.props.subColor});
        }
    }

    render() {
        const { classes } = this.props;

        const styles = reactCSS({
            'default': {
                color: {
                    width: '36px',
                    height: '14px',
                    borderRadius: '2px',
                    // background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
                    background: `${this.state.color}`
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    display: 'inline-block',
                    cursor: 'pointer',
                },
                popover: {

                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
            },
        });

        return (
            <div className={classes.root}>
                <div style={ styles.popover }>
                    <SketchPicker color={ this.state.color } onChange={ this.handleChange } />
                </div>
            </div>
        )
    }
}

export default withStyles(style)(DirectSelectionColor);