import React, {Component} from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles";
import {ReactComponent as FileArrowDown} from "../../../common/images/FileArrowDown.svg";
import {UploadState} from "../../../stores/UserLoungeStore";
import MoreButton from "./MobileFileMoreButton";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonBox: {
        width:'100%',
        border:'1px solid #c0c2c3',
        padding:'10px 11px',
        borderRadius:5,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:10
    },
    buttonText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        marginBottom:20
    },
    titleText:{
        width:'100%',
        textAlign:'center',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    iconBtn:{
        padding:5
    },
    fileBtn:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover":{
            background:'transparent'
        }
    },
    fileBox:{
        padding:'16px 8px',
        width:'100%',
        borderBottom:'1px solid #eee',
        "&:hover":{
            background:'#f2f7fc',
            border:'1px solid #dbeaf9',
            borderRadius:4
        },
    },
    fileBox2:{
        padding:'16px 8px',
        width:'100%',
    },
    fileNameText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#4d5a69',
        fontSize:'1.4rem',
        fontWeight:400,
        paddingLeft:8,
        cursor:'default',
        width:'100%',
    },
    fileNameText1:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#cfcfcf',
        fontSize:'1.4rem',
        fontWeight:600,
        paddingLeft:8,
        cursor:'default',
        width:'100%',
    },
    downloadBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'flex-end',
        "&:hover":{
            background:'transparent'
        }
    },
    downloadText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#567fbd',
        fontSize:'1.2rem',
        marginLeft:5,
        textAlign:'right'
    },
    input: {
        display: 'none',
    },
    fileIcon:{
        "& path":{
            fill:'#6b6b6b'
        },
        marginTop:2
    },
    fileIcon1:{
        "& path":{
            fill:'#cfcfcf'
        },
        marginTop:2
    },
    uploadedText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#5fc068',
        fontSize:'1.2rem',
        marginLeft:5,
        textAlign:'right'
    },
    failedText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#f63963',
        fontSize:'1.2rem',
        textAlign:'center'
    },
    deleteIcon:{
        padding:5
    },
});

@inject('userLoungeStore', 'timelineStore', 'progressStore')
@observer
class MobileTeamRoomFileBody extends Component {
    state = {
        open: false
    };

    componentDidMount() {
        document.getElementById('fileList').addEventListener("scroll", () => this.infiniteScroll(), true);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    }

    infiniteScroll = () => {
        const { userLoungeStore,  progressStore} = this.props
        let scrollTop = document.getElementById('fileList').scrollTop;
        let clientHeight = document.getElementById('fileList').clientHeight;
        let scrollHeight = document.getElementById('fileList').scrollHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            progressStore.setIsLoading(true);
            userLoungeStore.getTeamFileList(userLoungeStore.selectedTeamId);
        }
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const {classes} = this.props;
        const { teamFileList } = this.props.userLoungeStore;

        return (
            <Box id='fileList' style={{padding:'0 20px', overflowY:'scroll', height: '80%'}}>
                {
                    teamFileList.length > 0 ?
                        teamFileList.map((file, index) => {
                            return (
                                <Box display='flex' alignItems='center' justifyContent='space-between' className={classes.fileBox} key={index}>
                                    <Grid item xs={8}>
                                        <Box display='flex' alignItems='center' style={{width:'100%'}}>
                                            <Grid item xs={1}>
                                                <FileArrowDown className={file.state !== UploadState.Progress ? classes.fileIcon1 : classes.fileIcon}/>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography noWrap className={classes.fileNameText}>{file.title}</Typography>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} style={{display:'flex', justifyContent:'flex-end'}}>
                                        <Box display='flex' justifyContent='flex-end'>
                                            <MoreButton contentClose={this.handleClose} file={file}/>
                                        </Box>
                                    </Grid>
                                </Box>
                            )
                        })
                        :
                        <Box className={classes.fileBox2}>
                            <Typography className={classes.fileNameText}><FormattedMessage id="msg.no_file"/></Typography>
                        </Box>
                }
            </Box>
        );
    };
}

export default withStyles(style)(MobileTeamRoomFileBody);