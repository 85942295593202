import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    IconButton,
    InputBase,
    Box,
} from "@material-ui/core";
import { ReactComponent as SearchIcon } from "../../common/images/SearchIcon.svg";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";

const style = theme => ({
    search: {
        width:200,
        display:'flex',
        alignItems:'center',
        background: '#fff',
        border:'1px solid #a3a8af',
        padding:'5px 10px',
        borderRadius:3,
        "& .MuiInputBase-input::placeholder":{
            opacity:1,
            fontFamily: 'Montserrat',
            fontSize:'1rem'
        }
    },
    searchIcon: {
        cursor:'pointer',
        padding:0,
        "&:hover":{
            background:'transparent'
        },
    },
    inputRoot: {
        color: '#a3a8af',
        fontFamily: 'Montserrat',
        fontSize:'1rem',
        fontWeight:500,

    },
});

@inject('authStore', 'userLoungeStore', 'timelineStore')
@observer
class TopSearch extends Component {
    handleSearchButton = () => {
        const {authStore, userLoungeStore, timelineStore} = this.props;

        userLoungeStore.resetData();
        timelineStore.resetData();

        if (timelineStore.timelineType === 0) {
            timelineStore.getRoomListGroupByStartTime(userLoungeStore.selectedTeamId, authStore.loginUser.id, 'now', 1);
        } else {
            timelineStore.onChangeCalendarDate(userLoungeStore.selectedTeamId, authStore.loginUser.id);
        }
    };

    handleChangeSearchKeyword = (value) => {
        const {userLoungeStore, timelineStore} = this.props;
        userLoungeStore.changeSearchKeyword(value);
        timelineStore.changeSearchKeyword(value);
    };

    handleKeyUpEnter = (e) => {
        if(e.key === 'Enter') {
            this.handleSearchButton();
        }
    };

    render() {
        const { classes, intl, timelineStore } = this.props;

        return (
            <Box className={classes.search}>
                <InputBase
                    value={timelineStore.searchKeyword}
                    placeholder={intl.formatMessage({id: "search"})}
                    className={classes.inputRoot}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(event) => this.handleChangeSearchKeyword(event.target.value)}
                    onKeyPress={this.handleKeyUpEnter}
                />
                <IconButton className={classes.searchIcon} onClick={this.handleSearchButton}>
                    <SearchIcon />
                </IconButton>
            </Box>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(TopSearch)));