import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Avatar,
    Badge,
    Box,
    Drawer,
    IconButton,
    Divider,
    Typography,
    Button,
    // Select, MenuItem
} from "@material-ui/core";
import { ReactComponent as MobileUserCircle } from "../../common/images/MobileUserCircle.svg";
import {ReactComponent as MobileClose} from "../../common/images/MobileClose.svg";
import {Link, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {UTIL} from "../../common/util/common.util";
import {UserType} from "../../stores/AuthStore";
import {FormattedMessage} from "react-intl";
import {COLOR_UTIL} from "../../common/util/color.util";

const style = theme => ({
    root:{
        "& .MuiBadge-colorSecondary":{
            border:'1px solid #fff',
            background:'#00c880',
        },
        "& .MuiBadge-anchorOriginTopRightCircle":{
            top:'21%',
            right:'21%'
        }
    },
    list: {
        width: 250,
        "& .MuiBadge-colorPrimary":{
            border:'1px solid #fff',
            background:'#00c880',
        },
        "& .MuiInput-underline:after":{
            borderBottom:0
        },
        "& .MuiInput-underline:before":{
            borderBottom:0
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottom:0
        },
        "& .MuiSelect-select:focus":{
            background:'transparent'
        }
    },
    titleBox:{
        padding:'8px 10px'
    },
    listBox:{
        padding:'30px 10px 18px 17px',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
    },
    avatarStyle:{
        fontFamily: 'Montserrat',
        fontSize:'1.5rem',
        fontWeight:800,
        width:34,
        height:34,
        background:() => theme.configs.SubColor,
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.SubColor);
            return border;
        },
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.SubColor);
            return color;
        },
    },
    textStyle1:{
        width:100,
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap'

    },
    textStyle2:{
        width:100,
        color:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        marginTop:-3
    },
    menuItemBox:{

    },
    selectBox:{
        width:75,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#000',
        background:'transparent',
        padding:0,
        outline:'none',
        marginLeft:-5,

        "&:focus":{
            outline:'none',
        },
        "& .MuiMenu-paper":{
            boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
            borderRadius:0,

        },
    },
    optionStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        color:'#0d0d0d',
        padding:'5px 17px 5px 5px',
        outline:0,
        minHeight:0,
    },
    itemBox:{
        marginLeft:15,
    },
    itemText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#0d0d0d',
        padding:'8px 20px',
        fontWeight:600,
        "&:hover":{
            background:'transparent'
        },
        "&:last-child":{
            paddingBottom:0
        }
    },
    link:{
        textDecoration:'none'
    }
});
@inject('authStore','userLoungeStore','timelineStore','teamStore', 'orgStore')
@observer
class MobileUserInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            right: false,
            userState: "online"
        }
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };
    doLogout = () =>{
        const {authStore, userLoungeStore, timelineStore, teamStore} =this.props;
        authStore.doLogout(this.props.history);
        userLoungeStore.init();
        userLoungeStore.changeSimpleTeamList([]);
        timelineStore.init();
        teamStore.initSelectTeamByLeader();
    }
    render() {
        const { classes, loadAvatarImage, userInfo, isTablet, userType } = this.props;
        const sideList = (
            <div className={classes.list}>
                <Box display='flex' alignItems='center' justifyContent='flex-end' className={classes.titleBox}>
                    <IconButton onClick={this.toggleDrawer('right', false)}>
                        <MobileClose style={{width:'1.313rem',height:'1.313rem'}}/>
                    </IconButton>
                </Box>

                <Box className={classes.listBox}>
                    {/* 온라인일경우 */}

                    <Badge
                        color='primary'
                        overlap="circle"
                        badgeContent=""
                        variant="dot"
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    >
                        {
                            loadAvatarImage ?
                                <Avatar className={classes.avatarStyle}>
                                    <img
                                        id="userAvatar"
                                        style={{width: '100%', height: '100%'}}
                                        src={loadAvatarImage}
                                        alt={userInfo.name}
                                    />
                                </Avatar>
                                :
                                <Avatar className={classes.avatarStyle}>
                                    {UTIL.getFirstCapitalLetter(userInfo.name)}
                                </Avatar>
                        }
                    </Badge>
                    <Box>
                        <Typography className={classes.textStyle1}>{userInfo.name}</Typography>
                        <Typography className={classes.textStyle2}>{userInfo.email}</Typography>
                    </Box>
                    <Box className={classes.menuItemBox}>
                        <Typography className={classes.optionStyle}><FormattedMessage id="online"/></Typography>
                        {/*<Select*/}
                        {/*    name="userState"*/}
                        {/*    className={classes.selectBox}*/}
                        {/*    value={this.state.userState}*/}
                        {/*    onChange={e => this.setState({userState: e.target.value})}*/}
                        {/*>*/}
                        {/*    <MenuItem value="online" className={classes.optionStyle}>{UTIL.getIntlFormatMessageString("online")}</MenuItem>*/}
                        {/*    <MenuItem value="notHere" className={classes.optionStyle}>{UTIL.getIntlFormatMessageString("offline")}</MenuItem>*/}
                        {/*</Select>*/}
                    </Box>
                </Box>
                <Divider />

                <Box>
                    <Link to={{ pathname : "/profilesettings"}} className={classes.link}>
                        <Button className={classes.itemText} onClick={this.toggleDrawer('right', false)}><FormattedMessage id="profile_settings"/></Button>
                    </Link>
                </Box>
                {/*{isTablet && userType === UserType.Admin &&*/}
                {/*    <Box className={classes.itemBox}>*/}
                {/*        <Link to="/admin/management" className={classes.link} >*/}
                {/*            <Button className={classes.itemText} onClick={this.toggleDrawer('right', false)}><FormattedMessage id="organization_role"/></Button>*/}
                {/*        </Link>*/}
                {/*    </Box>*/}
                {/*}*/}
                <Box className={classes.itemBox}>
                    <Link to="/servicecenter" className={classes.link} >
                        <Button className={classes.itemText} onClick={this.toggleDrawer('right', false)}><FormattedMessage id="service_center"/></Button>
                    </Link>
                </Box>
                <Box className={classes.itemBox}>
                    <Button className={classes.itemText} style={{color:'#0097ff'}} onClick={()=>this.doLogout()}><FormattedMessage id="logout"/></Button>
                </Box>

            </div>
        );

        return (
            <div className={classes.root}>
                <Box style={{padding:'2px 0'}} >
                    <Badge
                        color="secondary"
                        overlap="circle"
                        badgeContent=" "
                        variant="dot"
                        onClick={this.toggleDrawer('right', true)}
                    >
                            <MobileUserCircle style={isTablet ? {width:28, height:28} : {width:24, height:24}}/>
                    </Badge>
                </Box>
                <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
                    <div>
                        {sideList}
                    </div>
                </Drawer>
            </div>
        )
    }
}

export default withRouter(withStyles(style)(MobileUserInformation));