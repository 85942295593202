import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel, Radio, RadioGroup, TextField,
    Typography,
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {QuizType} from "../../../../stores/QuizStore";
import clsx from "clsx";
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../../../common/util/color.util";


const style = theme => ({
    root: {
        "& .MuiInput-underline:after":{
            borderBottom:0
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        },
        "& .MuiFormLabel-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 15,
            color: '#000',
        },
        "& .MuiInputBase-input":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.125rem',
            color: '#0d0d0d',
        },
        "& .MuiRadio-colorSecondary.Mui-checked":{
            color:'#0097ff'
        },
        "& .MuiCheckbox-colorSecondary.Mui-checked":{
            color:'#0097ff'
        },
        "& .MuiFormControlLabel-label":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.125rem',
            color: '#0d0d0d',
        }
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.375rem',
        color: '#0d0d0d',
        fontWeight: 600,
    },
    titleImgBox: {
        // width: 450,
        // height: 288,
        // overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    inputBoxIn: {
        width: '100%',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight: 500,
        "&::placeholder": {
            color: '#a3a8af'
        }
    },
    btnStyle: {
        padding: '9px 38px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        fontWeight: 800,
        // color: '#fff',
        marginRight: 10,
        borderRadius: 7,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1: {
        padding: '9px 38px',
        background: '#a3a8af',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        fontWeight: 800,
        color: '#fff',
        marginRight: 10,
        borderRadius: 7,
        "&:hover": {
            background: '#a3a8af'
        }
    },
})

@inject('quizStore')
@observer
class QuizPreview extends Component {
    render() {
        const {classes, quizStore, intl} = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.textStyle} style={{marginBottom: 28}}>
                    {quizStore.quiz.title !== '' ? quizStore.quiz.title : intl.formatMessage({id: "msg.preview_quiz_name"})}
                </Typography>
                <Box display='flex' flexDirection='column'>
                    {quizStore.quizBankImageUrl.map((imageDataUrl, index) =>
                        <Box key={index} className={classes.titleImgBox} mb={2}>
                            {!imageDataUrl.data ?
                                <img src={imageDataUrl} alt="" style={{width: '100%'}}/>
                                :
                                <img src={`data:${quizStore.quiz.quizBankImages.type};base64,${imageDataUrl.data}`} width={"100%"} alt=""/>
                            }
                        </Box>
                    )}
                    {/* 1. 객관식단답형 */}
                    {quizStore.quiz.type === QuizType.SingleChoice &&
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="gender1">
                        {quizStore.quiz.quizBankItems.length > 0 ?
                            quizStore.quiz.quizBankItems.map((item, index) =>
                            <FormControlLabel style={{paddingTop: 20}} key={index} checked={item.correct} label={item.content} control={<Radio/>}/>
                            )
                            :
                            <FormattedMessage id="msg.preview_quiz_answer"/>
                        }
                        </RadioGroup>
                    </FormControl>
                    }

                    {/* 2. 객관식다답형 */}
                    {quizStore.quiz.type === QuizType.MultipleChoice &&
                    <FormControl component="fieldset">
                            {quizStore.quiz.quizBankItems.length > 0 ?
                                quizStore.quiz.quizBankItems.map((item, index) =>
                                <FormControlLabel style={{paddingTop: 20}} key={index} checked={item.correct} label={item.content} control={<Checkbox/>}/>
                            )
                            :
                            <FormattedMessage id="msg.preview_quiz_answer"/>
                            }
                    </FormControl>
                    }


                    {/* 3. 주관식단답형 */}
                    {quizStore.quiz.type === QuizType.ShortAnswer &&
                    <Box style={{marginTop: 28}}>
                        <TextField
                            label={intl.formatMessage({id: "msg.quiz_short_preview"})}
                            className={clsx(classes.textStyle, classes.inputBoxIn)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    }

                    {/* 4. 주관식서술형 */}
                    {quizStore.quiz.type === QuizType.Subjective &&
                    <Box style={{marginTop: 28}}>
                        <TextField
                            label={intl.formatMessage({id: "msg.quiz_subjective_preview"})}
                            className={clsx(classes.textStyle, classes.inputBoxIn)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Box>
                    }

                </Box>

                {/*<Box display="flex" alignItems='center' justifyContent='flex-end' style={{margin:'40px 0 0 0'}}>*/}
                {/*    <Box className={classes.buttonBox} >*/}
                {/*        <Box display="flex" justifyContent='flex-end' alignItems='center' >*/}
                {/*            <Button className={classes.btnStyle1}>취소</Button>*/}
                {/*            <Button className={classes.btnStyle}>전송</Button>*/}
                {/*        </Box>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(QuizPreview));