import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {TimelineItem} from "@material-ui/lab";
import TimeLineItemContent from "./TimelineItemContent";
import {inject, observer} from "mobx-react";
import TimelineItemOppositeContent from "./TimelineItemOppositeContent";
import TimeLineNoSchedule from "./TimeLineNoSchedule";
import {DATE_UTIL} from "../../../common/util/date.util";
import * as dayjs from "dayjs";
import {withRouter} from "react-router-dom";
import MaterialTable from "material-table";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import PatchedPagination from "../../../common/PatchedPagination";

const style = theme => ({
    root:{
        marginTop:50,
        "& .MuiTimeline-root":{
            paddingLeft:40,
            paddingRight:0
        },
        "& .MuiTimelineOppositeContent-root":{
            flex:0,
            padding:0,
            margin:0,
        },
        "& .MuiTimelineContent-root":{
            paddingTop:0,
            paddingLeft:0
        },
    },
    btnStyle:{
        padding:'8px 18px 8px',
        background: '#fff',
        color : '#0097ff',
        border: 'solid 0.7px #0097ff',

        borderRadius: 4.9,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:'700',
        objectFit: 'contain',
        // color:'#fff',
        "&:hover":{
            background: '#fff',
        }
    },
});

@inject('timelineStore', 'authStore', 'roomStore', 'userLoungeStore')
@observer
class TimeLineItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpand: true,
        }
    };

    handleClickExpand = event => {
        const {timelineStore} = this.props;

        timelineStore.closeAllTimelinePanel();
        event.stopPropagation();
        this.setState({isExpand: !this.state.isExpand});
    };

    componentDidMount() {
        const {timelineStore} = this.props;

        if (timelineStore.isFirstLoad && timelineStore.roomListGroupByStartTime.length > 0) {
            const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.FULL_FORMAT);

            let targetElementById = '';
            let el = undefined;

        //     if (timelineStore.roomListGroupByStartTime.length === 0) {
        //         targetElementById = "0";
        //         el = document.getElementById(targetElementById);
        //     } else if (timelineStore.roomListGroupByStartTime.length === 1) {
        //         targetElementById = timelineStore.roomListGroupByStartTime[0].roomList[0].id;
        //         el = document.getElementById(targetElementById);
        //     } else {
        //         for(let i = 0; i < timelineStore.roomListGroupByStartTime.length; i++) {
        //             if (dayjs(currentDatetime).isSameOrBefore(timelineStore.roomListGroupByStartTime[i].startDatetime)) {
        //                 if (i === 0) {
        //                     targetElementById = timelineStore.roomListGroupByStartTime[i].roomList[0].id;
        //                 } else {
        //                     targetElementById = timelineStore.roomListGroupByStartTime[i - 1].roomList[0].id;
        //                 }
        //                 el = document.getElementById(targetElementById);
        //                 break;
        //             }
        //         }
        //     }
        //
        //     if (el) {
        //         if (targetElementById === "0" || timelineStore.roomListGroupByStartTime.length === 1) {
        //             el.scrollIntoView({block: "start", behavior: 'smooth'});
        //         } else {
        //             el.scrollIntoView({block: "start", behavior: 'smooth'});
        //             const targetElement = document.getElementById('timelineList');
        //             if (targetElement) {
        //                 const scrollTop = targetElement.scrollTop;
        //                 targetElement.scrollTo({top: scrollTop + 200, behavior: 'smooth'})
        //             }
        //         }
        //         timelineStore.changeIsFirstLoad(false);
        //     }
        // } else if (timelineStore.scrollTop !== 0) {
        //     const targetElement = document.getElementById('timelineList');
        //     if (targetElement) {
        //         targetElement.scrollTo(0, timelineStore.scrollTop);
        //     }
        }
    };

    handleClickEnterance = (event, url) => {
        event.stopPropagation();
        url = url + '&tz=' + DATE_UTIL.getTimeZone() + '&local=' + DATE_UTIL.getLanguage();
        window.open(url);
    };

    render() {
        const { isFirst, history, roomData, isFirstByDate, isLast , intl, classes, authStore, roomStore, userLoungeStore, timelineStore} = this.props;

        const columns = [
            {title: '번호', field: 'number', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, align: 'left'},
            {title: '강의제목', field: 'name', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, align: 'left',},
            {title: '개설자', field: 'comment', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, align: 'left'},
            {title: '개설시각', field: 'createdDatetime', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, align: 'left',  type: 'datetime'},
            {title: '수업입장', field: 'members', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, align: 'left',
                render: rowData => (rowData.members && rowData.members.length > 0) ? <Button className={classes.btnStyle} onClick={(event) => this.handleClickEnterance(event, rowData.members.find(m => m.userId === this.props.authStore.loginUser.id).entranceUrl)}>수업입장</Button> : ''
            },
            {title: '비고', field: 'delete', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, align: 'left',
                render: rowData => (rowData.delete === authStore.loginUser.id) ? <Button className={classes.btnStyle}
                                                                                         onClick={(event) => {
                                                                                             if(window.confirm("정말 삭제 하시겠습니까?")) {
                                                                                                 roomStore.deleteRoom(intl, this.props.history, rowData.roomId, authStore.loginUser.id, timelineStore, userLoungeStore);
                                                                                             }else {

                                                                                             }}}
                >삭제</Button> : ''
            },
            {title: '', field: 'roomId', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, align: 'left', hidden: true,
            },
        ];

        const getData = (roomList) => {
            console.log("@@@@@@@@@", roomList)
            return roomList.length > 0 && roomList && roomList.map((room, index) => ({
                number : room.name ? index + 1 : '',
                name : room.name,
                comment : room.comment,
                createdDatetime : room.createdDatetime && DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(room.createdDatetime), "YYYY/MM/DD HH:mm:ss"),
                members : room.members,
                delete : room.userId,
                roomId : room.id,
            }));
        }
        return (
            <TimelineItem style={isLast ? {marginBottom:40, paddingBottom: (window.innerHeight - 350) - 200} : {marginBottom:40}}>

                <Box display='flex' flexDirection='column' justifyalign={'flex-start'} aligntext={'flex-start'} mb={2} style={{width: '100%'}}>
                    {roomData.roomList.length > 0 &&
                        // this.state.isExpand ?

                    <MaterialTable
                        style={{boxShadow: 'none'}}
                        // isLoading={activationStore.isActivationResultsLoading}
                        title=""
                        data={getData(roomData.roomList)}
                        columns={columns}
                        options={{
                            pageSize: 10,
                            // loadingType: 'linear',
                            // paginationType: 'stepped',
                            // header: false,
                            showTitle: false,
                            headerStyle: {
                                wordBreak: "keep-all",
                                fontFamily: 'NanumSquareRoundOTF',
                                fontSize: '1.063rem',
                                fontWeight: 600,
                                color: '#000',
                            },
                            cellStyle: {
                                wordBreak: "keep-all",
                                fontFamily: 'NanumSquareRoundOTF',
                                fontSize: '1.063rem',
                                fontWeight: 600,
                                color: '#000',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'
                            },
                        }}
                        localization={{
                            pagination: {
                                labelRowsSelect: intl.formatMessage({id: "table_rows_count"}),
                                labelDisplayedRows: intl.formatMessage({id: "table_bottom_text"}),
                            },
                        }}
                        components={{
                            Pagination: PatchedPagination
                        }}
                    />

                                // roomData.roomList.map((r, i) => {
                                //     return (
                                //         r.id ?
                                //             <div id={r.id} key={i}>
                                //                 <Box display='flex' flexDirection='row' justifyalign={'flex-start'}
                                //                      aligntext={'flex-start'} style={{width: '100%'}} mt={2} mb={2}>
                                //                     {/*<TimelineItemOppositeContent*/}
                                //                     {/*    room={r}*/}
                                //                     {/*    isFirst={i === 0}*/}
                                //                     {/*    isFirstByDate={isFirstByDate}*/}
                                //                     {/*/>*/}
                                //
                                //
                                //
                                //                     {/*<TimeLineItemContent*/}
                                //                     {/*    key={i}*/}
                                //                     {/*    room={r}*/}
                                //                     {/*    isFirst={i === 0}*/}
                                //                     {/*    isExpand={this.state.isExpand}*/}
                                //                     {/*    isMulti={true}*/}
                                //                     {/*    handleClickExpand={this.handleClickExpand}*/}
                                //                     {/*/>*/}
                                //                 </Box>
                                //             </div>
                                //             :
                                //             <TimeLineNoSchedule room={roomData.roomList[0]}/>
                                //     )}
                                // )
                        //     :
                        //     roomData.roomList[0].id !== 0 ?
                        //         <div id={roomData.roomList[0].id}>
                        //             <Box display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} style={{width: '100%'}} mt={2} mb={2}>
                        //                 <TimelineItemOppositeContent
                        //                     history={history}
                        //                     room={roomData.roomList[0]}
                        //                     isFirst={isFirst}
                        //                     isFirstByDate={isFirstByDate}
                        //                 />
                        //                 <TimeLineItemContent
                        //                     room={roomData.roomList[0]}
                        //                     isFirst={isFirst}
                        //                     isExpand={this.state.isExpand}
                        //                     isMulti={roomData.roomList.length > 1}
                        //                     roomCount={roomData.roomList.length}
                        //                     handleClickExpand={this.handleClickExpand}
                        //                 />
                        //             </Box>
                        //         </div>
                        //         :
                        //         <TimeLineNoSchedule room={roomData.roomList[0]}/>
                        // :
                        // null
                    }
                </Box>
            </TimelineItem>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(TimeLineItem)));
