import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography, Box} from "@material-ui/core";
import MobileDataTimeLineItem from "./MobileDataTimeLineItem";
import {inject, observer} from "mobx-react";
import {DATE_UTIL} from "../../../common/util/date.util";
import Swiper from "react-id-swiper";
import 'swiper/swiper-bundle.css';
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        "& .swiper-slide":{
            width:275
        },
    },
    dataBox:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px solid #c2c2c2',
        padding:'0 20px 8px',
        marginTop:30,
        marginBottom:17,
    },
    dataText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:600,
        color:'#a7acb2',
    },
});

@inject('userLoungeStore', 'timelineStore', 'progressStore')
@observer
class MobileDataTimeLine extends Component {
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    }

    render() {
        const { classes, intl, timelineStore } = this.props;

        const params = {
            slidesPerView: 'auto',
            initialSlide: 0,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
        }

        return (
            <div className={classes.root} >
                {
                    timelineStore.calendarData.length > 0 &&
                    timelineStore.calendarData.map((data, index) => {
                        return (
                            <Box style={{marginBottom:45}} key={index}>
                                <Box className={classes.dataBox}>
                                    <Typography className={classes.dataText} style={{color:'#212525'}}>{DATE_UTIL.getTimeZoneLanguage(data.date).format(`MMM D${intl.formatMessage({id: "calendar_day"})} dddd`)}</Typography>
                                    <Typography className={classes.dataText} ><FormattedMessage id="total"/> {data.count}<FormattedMessage id="case"/></Typography>
                                </Box>
                                {data.count > 0 ?
                                    <Box style={{padding:'0 0 0 20px',marginBottom:10}}>
                                        <Swiper {...params} >
                                            {
                                                data.roomList.map((room, index) => {
                                                    return (
                                                        <div key={room.id}>
                                                            <MobileDataTimeLineItem
                                                                room={room}
                                                                history={this.props.history}
                                                            />
                                                        </div>
                                                    )})
                                            }
                                        </Swiper>
                                    </Box>
                                    :
                                    <Typography className={classes.dataText} style={{padding:'0 20px'}}><FormattedMessage id="msg.no_schedule"/></Typography>
                                }
                            </Box>
                        )
                    })
                }
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(MobileDataTimeLine)));