import React, {Component} from 'react';
import {ReactComponent as VectorIcon} from "../../../common/images/VectorIcon.svg";
import {Typography, Box} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {ReactComponent as MobileArrowLineNoSchedule} from "../../../common/images/MobileArrowLineNoSchedule.svg";
import MobileTimelineItemOppositeContent from "./MobileTimelineItemOppositeContent";
import {observer} from "mobx-react";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        paddingLeft:5
    },
    ArrowLineBox:{
        position:'relative',
        // marginTop:-9,
        zIndex:20,
        color:'#a3a8af',
    },
    timeLineBox:{
        position:'relative',
        padding:'15px 14px',
        background:'#f5f5f5',
        width:window.innerWidth > 350 ? 250 : 200,
        height:window.innerWidth > 350 ? 160 : 128,
        borderRadius:10,
        marginLeft:20,
        marginTop:-13,
        boxSizing:'border-box',
        display:'flex',
        '@media all and (min-width:1181px) and (max-width:1919px)':{
            width:'90%',
        },
        '@media all and (min-width:480px) and (max-width:1180px)':{
            width:'100%',
        },
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:300,
        color:'#3b3b3b',
        marginBottom:95,
        marginLeft:5
    },
    lineContentBox:{
        position:'relative',
    },
});

@observer
class MobileTimeLineNoSchedule extends Component {
    componentDidMount() {
        const el = document.getElementById('0');
        if (el) {
            el.scrollIntoView({block: "start", behavior: 'smooth'});
        }
    }

    render() {
        const { classes, room } = this.props;

        return (
            <Box className={classes.root} display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} mb={2} style={{width: '100%', height: '100%'}}>
                <div style={window.innerWidth > 350 ? {width:60, paddingLeft:20} : {width:60, paddingLeft:10}}>
                    <MobileTimelineItemOppositeContent room={room}/>
                </div>
                <Box className={classes.lineContentBox}>
                    <Box className={classes.ArrowLineBox}>
                        <MobileArrowLineNoSchedule style={window.innerWidth > 350 ? {width:260} : {width:210}}/>
                    </Box>
                    <div className={classes.timeLineBox}>
                        <VectorIcon/>
                        <Typography className={classes.textStyle}><FormattedMessage id="msg.no_schedule"/></Typography>
                    </div>
                </Box>
            </Box>
        );
    }
}

export default withStyles(style)(MobileTimeLineNoSchedule);