import React, {Component} from 'react';
import {
    Box,
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import CalendarShape from "./CalendarShape";
import MobileStartTime from "./MobileStartTime";
import MobileMeetingTime from "./MobileMeetingTime";
import {inject, observer} from "mobx-react";

const style = theme => ({
    root: {
        padding: '0 20px 50px',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        alignContent:'center'
    },
    buttonStyle: {
        padding: '7px 23px',
        borderRadius: 6,
        background: '#18a0fb',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#fff',
        fontSize: '1.4rem',
        "&:hover": {
            background: '#18a0fb',
        }
    }
});

@inject('roomStore')
@observer
class MobileCalendarBox extends Component {
    onChangeDate = (props) => {
        this.props.onChangeDate(props);
    }

    onChangeStartTime = (time, minute, radio) => {
        this.props.onChangeStartTime(time, minute, radio);
    }

    onChangeStartMin = (minute) => {
        this.props.onChangeStartMin(minute);
    }

    onChangeAm = (radio) => {
        this.props.onChangeAm(radio);
    }

    onChangeEndTime = (time, minute) => {
        this.props.onChangeEndTime(time, minute);
    }

    onChangeEndMin = (props) => {
        this.props.onChangeEndMin(props);
    }

    handleSave = () => {
        this.props.onClickSave();
    }

    render() {
        const {classes} = this.props;

        return (
            <div className={classes.root} onChange={this.InvalidationDate}>
                <CalendarShape onChangeDate={this.onChangeDate}/>
                <Box style={{width: '100%'}}>
                    <MobileStartTime onChangeStartTime={this.onChangeStartTime} onChangeStartMin={this.onChangeStartMin} onChangeAm={this.onChangeAm}/>
                    <MobileMeetingTime onChangeEndTime={this.onChangeEndTime} onChangeEndMin={this.onChangeEndMin}/>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(MobileCalendarBox);

