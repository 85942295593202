import React from "react";
import Box from '@material-ui/core/Box';
import {withStyles} from "@material-ui/core/styles";
import PcMainBg from "../../../common/images/main_bg_samsung.png";
import SignIn from "./SignIn";
// import {Switch} from "react-router-dom";



const styles = theme => ({
    root:{

    },
    mainBox:{
        // backgroundImage: `url(${PcMainBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center center',
        width:'100%',
        height:'100vh',
        position:'relative'
    },

});

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Box className={classes.mainBox} style={{ backgroundImage: `url(${PcMainBg})` }}>
                </Box>
                <SignIn/>
            </div>
        );
    }
}

export default withStyles(styles)(Home);