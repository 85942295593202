import {makeAutoObservable} from "mobx";
import dayjs from "dayjs";
import axios from "axios";
import {DATE_UTIL} from "../common/util/date.util";
import {validateEmail, validateName, validateNickName} from "../common/Validation";
import timezone from "dayjs/plugin/timezone";

const logPrefix = ' [RoomStore] ';

const searchingInterval = 1000;

const ErrorCode = {
    TooManyParticipants: "msg.error_too_many_attendees",
    CanNotInsertRoom: "msg.error_create_room",
    CanNotInsertRoomMembers: "msg.error_create_room",
    CanNotRequestCreateRoom: "msg.error_create_room",
    CanNotFoundUser: "msg.error_update_room",
    CanNotRequestUpdateMembers: "msg.error_update_room",
    CanNotRequestUpdateRoom: "msg.error_update_room",
    CanNotUpdateRoomMembers: "msg.error_update_room",
    BadRequest: "msg.error_input_missing",
    NotAllowedDate: "msg.error_not_allow_date",
    CanNotSendEmail: "msg.cannot_send_email",
    Unknown: "msg.error_unknown"
}

export const State = {
    Init: 'Init',
    Pending: 'Pending',
    Failed: 'Failed',
    Done: 'Done',
};

export const TeamType = {
    Root: 'Root',
    Group: 'Group',
    Team: 'Team',
    Member: 'Member'
};

const EmptyInfo = {
    id: '',
    userName: '',
    apiRoomId: '',
    userId: '',
    teamId: '',
    type: 'Education',
    startDatetime: '',
    endDatetime: '',
    meetingTime: '',
    guestNum: '',
    name: '',
    comment: '',
    members: [],
    maxUsers: '',
    isSendEmail: false,
};

const EmptyGuestInfo = {
    nickName:'',
    email:''
}

export default class RoomStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    STATE = State.Init;
    roomInfo = Object.assign({}, EmptyInfo);

    isGetUserListLoading = false;
    isTeamListLoading = false;
    isCreateRoomLoading = false;
    isGetRoomInfoLoading = false;
    isRemoveRoomLoading = false;
    isModifyRoomLoading = false;
    isSearchLoading = false;

    teamDropDownButtonOpen = false;
    openConfirmDialog = false;

    count = 0;
    errMsg = '';
    searchKeyword = '';
    lastSearchKeyword = '';
    isMaxUsersLimit = '';
    cancelToken = undefined;
    isSendEmail = true;

    userList = [];
    teamList = [];
    myTeamList = [];
    guestList = [];

    initRoomInfo = (loginUser, teamId) => {
        this.count = 0;
        this.teamDropDownButtonOpen = false;
        this.isSendEmail = false;
        this.getMyTeamList(loginUser);
        this.setRoomUserId(loginUser.id);
        this.setRoomUserName(loginUser.name);
        this.setTeamId(teamId);
        this.getMaxUsersConfig(teamId);

        this.roomInfo.startDatetime = String(dayjs(new Date().setHours(0, 0, 0)).format(DATE_UTIL.FORMAT.FULL_FORMAT));
        this.roomInfo.endDatetime = String(dayjs(new Date().setHours(23, 59, 59)).format(DATE_UTIL.FORMAT.FULL_FORMAT));

        this.guestList = [];
    };

    initRoom = () => {
        this.roomInfo = Object.assign({}, EmptyInfo);
    }

    initTeamList = () => {
        this.teamList = [];
    }

    setRoomInfo = (loginUser, roomId, teamId, callback) => {
        this.guestList = [];
        this.getRoomInfo(loginUser, roomId, teamId, callback)
        this.getMyTeamList(loginUser)
    }

    setRoomUserId = (value) => {
        this.roomInfo.userId = value;
    }

    setRoomUserName = (value) => {
        this.roomInfo.userName = value;
    }

    setTeamId = (value) => {
        this.roomInfo.teamId = value;
    }

    changeRoomName = (value) => {
        this.roomInfo.name = value;
    }

    changeRoomType = (value) => {
        this.roomInfo.type = value;
    }

    changeComment = (value) => {
        this.roomInfo.comment = value;
    }

    onChangeDate = (value) => {
        let startDateTime = dayjs(value.startDatetime.date).add((value.startDatetime.am === 'AM' ? (value.startDatetime.hour === '12' ? parseInt(value.startDatetime.hour) - 12 : parseInt(value.startDatetime.hour)) : (value.startDatetime.hour === "12" ? parseInt(value.startDatetime.hour) : parseInt(value.startDatetime.hour) + 12)), "hour");
        startDateTime = dayjs(startDateTime).add(value.startDatetime.min, 'minute');
        this.roomInfo.startDatetime = startDateTime;
        let endDateTime = dayjs(startDateTime).add(value.endDatetime.hour, 'hour');
        let endDateMin = value.endDatetime.hour !== '10' ? value.endDatetime.min : '00';
        endDateTime = dayjs(endDateTime).add(endDateMin, 'minute');
        this.roomInfo.endDatetime = endDateTime;
        this.roomInfo.meetingTime = value.endDatetime.hour + ':' + endDateMin;
    }

    changeMembers = (value) => {
        this.roomInfo.members = value;
    }

    changeMembersInMyTeams = (value) => {
        this.roomInfo.members = this.roomInfo.members.concat(value);
    }

    changeGuestNum = (value) => {
        this.roomInfo.guestNum = value;
    }

    createGuestList = (count) => {
        if(!count){
            this.guestList.push(EmptyGuestInfo)
        }
        this.roomInfo.guestNum = this.guestList.length.toString();
    }

    changeGuestNickName = (value, index) => {
        this.guestList[index].nickName = value;
    }

    changeGuestEmail = (value, index) => {
        // console.log(logPrefix,"changeGuestEmail Start... value={}, index={}",value, index);
        this.guestList[index].email = value;
        // console.log(logPrefix,"changeGuestEmail End... this.guestList={}",this.guestList);
    }

    deleteGuest = (index) => {
        // console.log(logPrefix,"deleteGuest Start... index={}", index);
        this.roomInfo.guestNum = this.roomInfo.guestNum -1 ;
        this.guestList.splice(index,1);
        if(this.guestList.length === 0){
            this.roomInfo.guestNum = '';
        }
        // console.log(logPrefix,"deleteGuest End... guestList={}", this.guestList);
    }

    clearGuestList = () => {
        this.guestList = [];
        this.roomInfo.guestNum = '';
    }

    changeMaxUsers = (value) => {
        this.count = value;
    }

    changeSendEmail = (value) => {
        this.isSendEmail = value;
    }

    calMeetingTime = () => {
        const time = dayjs(this.roomInfo.endDatetime).diff(dayjs(this.roomInfo.startDatetime), "minute");
        this.roomInfo.meetingTime = this.MakeDateForm(time);
    }

    calLocalDate = () => {
        let convertTimeZoneToUTC = DATE_UTIL.convertTimeZoneToUTC(this.roomInfo.startDatetime);
        const startDate = DATE_UTIL.getTimeZoneDate(convertTimeZoneToUTC, "YYYY-MM-DD HH:mm:ss");
        this.roomInfo.startDatetime = startDate;
        convertTimeZoneToUTC = DATE_UTIL.convertTimeZoneToUTC(this.roomInfo.endDatetime);
        const endDate = DATE_UTIL.getTimeZoneDate(convertTimeZoneToUTC, "YYYY-MM-DD HH:mm:ss");
        this.roomInfo.endDatetime = endDate;
    }

    MakeDateForm = (min) => {
        const days = Math.floor(min / 60 / 24);
        const hours = Math.floor((min - (days * 60 * 24)) / 60);
        const mins = min - (days * 60 * 24) - (hours * 60);
        const hourStr = (hours > 9) ? hours : '0' + hours;
        const minStr = (mins > 9) ? mins : '0' + mins;
        return hourStr + ':' + minStr;
    }

    changeConfirmDialogOpen = () => {
        this.openConfirmDialog = !this.openConfirmDialog;
    }
    isInputValidation = (intl) => {
        let checkInput = true;
        if (this.roomInfo.name === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_topic"});
        } else if (this.roomInfo.comment === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_comment"});
        } else if (this.roomInfo.startDatetime === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_time"});
        } else if ((this.guestList.length !== parseInt(this.roomInfo.guestNum)) && this.roomInfo.guestNum !== "" ) {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_attendees"});
        }
        this.openConfirmDialog = !checkInput;
        return checkInput;
    }

    isGuestInputValidation = (intl) => {
        let checkInput = true;
        for(let i = 0; i < this.guestList.length; i++) {
            const currElem = this.guestList[i].nickName;

            for(let j = i+1; j < this.guestList.length; j++) {
                if(currElem === this.guestList[j].nickName && this.guestList[j].nickName !== "") {
                    this.errMsg = intl.formatMessage({id: "msg.error_duplicate_nickname"}) + `< ${i+1} >, < ${j+1} >`;
                    checkInput = false;
                    break;
                }
            }

            if(!checkInput)  {
                break;
            }
        }
        this.guestList.forEach((guestInfo, index) => {
            if(guestInfo.email !== "" && guestInfo.email !== null){
                if(!validateEmail(guestInfo.email)){
                    checkInput = false;
                    this.errMsg = `< ${index+1} > ` + intl.formatMessage({id: "guest"})+ " " + intl.formatMessage({id: "msg.email_incorrect"});
                }
                if(guestInfo.nickName === ""){
                    checkInput = false;
                    this.errMsg = `< ${index+1} > ` + intl.formatMessage({id: "guest"}) + " " + intl.formatMessage({id: "msg.insert_nickname"});
                }
            }
            if(guestInfo.nickName !== ""){
                if(!validateName(guestInfo.nickName)){
                    checkInput = false;
                    this.errMsg = `< ${index+1} > ` + intl.formatMessage({id: "guest"}) + " " + intl.formatMessage({id: "msg.re_enter_nickname"});
                }
                if(validateNickName(guestInfo.nickName, this.guestList.length)){
                    checkInput = false;
                    this.errMsg = `< ${index+1} > ` + guestInfo.nickName + " " +intl.formatMessage({id: "msg.error_unavailable_nickname"});
                }
            }
        })
        this.openConfirmDialog = !checkInput;
        return checkInput;
    }

    handleChangeDropDownButton = () => {
        this.teamDropDownButtonOpen = !this.teamDropDownButtonOpen;
    }

    handleClickAway = () => {
        this.teamDropDownButtonOpen = false;
    }

    get getIsLoading() {
        return this.isCreateRoomLoading || this.isGetRoomInfoLoading || this.isRemoveRoomLoading || this.isModifyRoomLoading || this.isTeamListLoading || this.isGetUserListLoading;
    }

    setGuestList = (guestInfo) => {
        guestInfo.forEach((guest) => {
            if(validateNickName(guest.name)){
                this.guestList.push({nickName: "", email: ""})
            } else {
                this.guestList.push({nickName: guest.name, email: guest.email})
            }
        })
    }

    searchTeamMembers = (keyword) => {
        this.isSearchLoading = true;
        this.teamList = [];
        this.searchKeyword = keyword;
        if ((this.searchIntervalState === undefined) || (this.searchIntervalState === null)) {
            // console.log(logPrefix, "Starting SearchInterval ...");
            this.searchIntervalState = setInterval(() => this.getTeamMembers(), searchingInterval);
        } else {
            // console.log(logPrefix, "SearchInterval already started ...");
        }
    }

    getTeamMembers = function* getTeamMembers() {
        // console.log(logPrefix, "SearchMembers Start keyword={}", this.searchKeyword);

        if (this.searchKeyword === '') {
            this.lastSearchKeyword = '';
            // console.log(logPrefix, "Clear SearchInterval ...");
            clearInterval(this.searchIntervalState);
            this.searchIntervalState = undefined;
            this.isSearchLoading = false;
        } else {

            if (this.searchKeyword !== this.lastSearchKeyword) {
                try {
                    this.lastSearchKeyword = this.searchKeyword;
                    const teamResults = yield axios.get(
                        this.serverContextPath + `/api/v1/teams/list/${this.lastSearchKeyword}`);
                    // console.log(logPrefix, "SearchMembers teamResults keyword : ", this.lastSearchKeyword, " >> ", teamResults.data);

                    const userResults = yield axios.get(
                        this.serverContextPath + `/api/v1/users/list/${this.roomInfo.teamId}/${this.lastSearchKeyword}`);
                    // console.log(logPrefix, "SearchMembers userResults keyword : ", this.lastSearchKeyword, " >> ", userResults.data);
                    this.teamList = teamResults.data.concat(userResults.data.filter(user => user.id !== this.roomInfo.userId));
                    this.isSearchLoading = false;
                } catch (error) {
                    console.log(logPrefix, "Cannot Search Teams or Users ...", error);
                }
            } else {
                // console.log(logPrefix, "Clear SearchInterval ...");
                clearInterval(this.searchIntervalState);
                this.searchIntervalState = undefined;
                this.lastSearchKeyword = '';
                this.isSearchLoading = false;
            }
        }
    }

    getMaxUsersConfig = function* getMaxUsersConfig(teamId) {
        // console.log(logPrefix, "getMaxUsersConfig Start teamId={}", teamId);
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/teams/maxusers/${teamId}`);
            // console.log(logPrefix, "getMaxUsersConfig response >> ", response.data);
            this.roomInfo.maxUsers = response.data;
        } catch (e) {
            console.log(logPrefix, "getMaxUsersConfig Error : >> ", e);
        }
    }

    getMyTeamList = function* getMyTeamList(loginUser) {
        // console.log(logPrefix, "GetTeamListWithMembers Start loginUserId={}", loginUser);
        this.isTeamListLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/teams/detail/${loginUser.id}`);
            // console.log(logPrefix, "GetMyTeamList response >> ", response.data);
            this.myTeamList = response.data;
        } catch (e) {
            console.log(logPrefix, "GetMyTeamList Error : >> ", e);
        } finally {
            this.isTeamListLoading = false;
        }
    };

    getRoomInfo = function* getRoomInfo(loginUser, roomId, teamId, callback) {
        // console.log(logPrefix, "GetRoomInfo Start roomId={}", roomId);
        this.isGetRoomInfoLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/rooms/${roomId}`);
            // console.log(logPrefix, "GetRoomInfo response >>", response.data);
            const {teamName, userName, userTitle, guests, maxUsers, ...others} = {...response.data};
            this.roomInfo = others;
            this.roomInfo.guestNum = response.data.guests.length;
            this.roomInfo.members = response.data.members.filter(user => user.userId !== loginUser.id).map(member => {
                const convertMember = {id: member.userId, name: member.userName, type: TeamType.Member};
                return {...convertMember};
            })
            this.getMaxUsersConfig(teamId);
            this.calMeetingTime();
            this.calLocalDate();
            this.setGuestList(guests);
        } catch (e) {
            console.log(logPrefix, "GetRoomInfo Result : >> ", e);
        } finally {
            callback && callback();
            this.isGetRoomInfoLoading = false;
        }
    };

    createRoom = function* createRoom(intl, history, timelineStore, userLoungeStore) {
        // console.log(logPrefix, "CreateRoom Start ... ");
        this.isCreateRoomLoading = true;
        this.STATE = State.Pending;
        try {
            if (!this.isInputValidation(intl)) {
                // console.log(logPrefix, "CreateRoom InputValidation Fail ... ");
                return false;
            }
            const param = Object.assign({}, this.roomInfo);
            param.startDatetime = DATE_UTIL.getUTCDate(this.roomInfo.startDatetime).format('YYYY-MM-DD HH:mm:ss');
            param.endDatetime = DATE_UTIL.getUTCDate(this.roomInfo.endDatetime).format('YYYY-MM-DD HH:mm:ss');
            param.sendEmail = this.isSendEmail;
            param.timeZone = "UTC";

            if(!this.isGuestInputValidation(intl)){
                return false;
            } else {
                param.guestInfo = this.guestList.filter((info) => info.nickName !== "");
            }

            yield axios.post(this.serverContextPath + '/api/v1/rooms', param);

            if (userLoungeStore.selectedTeamId !== 0) {
                history.push(`/teams/${userLoungeStore.selectedTeamId}`);
            } else {
                history.push(`/mytimeline`);
            }

        } catch (e) {
            console.log(logPrefix, "CreateRoom Fail : >> ", e);
            this.STATE = State.Failed;
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;
        } finally {
            this.isCreateRoomLoading = false;
        }
    }

    modifyRoom = function* modifyRoom(intl, props, timelineStore, userLoungeStore) {
        // console.log(logPrefix, "ModifyRoom Start ...");
        this.isModifyRoomLoading = true;

        if (!this.isInputValidation(intl)) {
            // console.log(logPrefix, "ModifyRoom InputValidation Fail...");
            this.isModifyRoomLoading = false;
            return false;
        }

        try {
            if (typeof this.roomInfo.startDatetime === "string") {
                this.roomInfo.startDatetime = dayjs(this.roomInfo.startDatetime).tz(DATE_UTIL.getTimeZone(), true);
                this.roomInfo.endDatetime = dayjs(this.roomInfo.endDatetime).tz(DATE_UTIL.getTimeZone(), true);
            }
            const param = Object.assign({}, this.roomInfo);
            param.startDatetime = DATE_UTIL.getUTCDate(this.roomInfo.startDatetime).format('YYYY-MM-DD HH:mm:ss');
            param.endDatetime = DATE_UTIL.getUTCDate(this.roomInfo.endDatetime).format('YYYY-MM-DD HH:mm:ss');
            param.sendEmail = this.isSendEmail;

            if(!this.isGuestInputValidation(intl)){
                return false;
            } else {
                param.guestInfo = this.guestList.filter((info) => info.nickName !== "");
            }

            yield axios.put(this.serverContextPath + '/api/v1/rooms', param);
            // console.log(logPrefix, "ModifyRoom response >>", response.data);

            if (userLoungeStore.selectedTeamId !== 0) {
                props.history.push(`/teams/${userLoungeStore.selectedTeamId}`);
            } else {
                props.history.push(`/mytimeline`);
            }
        } catch (e) {
            console.log(logPrefix, "ModifyRoom Fail : >> ", e);
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]})
            this.openConfirmDialog = true;
        } finally {
            this.isModifyRoomLoading = false;
        }
    };

    deleteRoom = function* deleteRoom(intl, history, roomId, id, timelineStore, userLoungeStore) {
        // console.log(logPrefix, "DeleteRoom Start ... roomId={}, id={}", roomId, id);
        this.isRemoveRoomLoading = true;
        try {
            yield axios.delete(this.serverContextPath + `/api/v1/rooms/${roomId}`);
            // console.log(logPrefix, "DeleteRoom response >> ", response.data);
            userLoungeStore.changeSelectedTeam(id, userLoungeStore.selectedTeamId, timelineStore, history);
        } catch (e) {
            console.log(logPrefix, "DeleteRoom Fail  >>> ", e);
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;
        } finally {
            this.isRemoveRoomLoading = false;
        }
    };
}