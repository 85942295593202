class ColorUtil {
    constructor() {
        this.hsl = {
            h: 0,
            s: 0,
            l: 0,
            a: 0,
        }
        this.THRESHOLD = 60;
        this.BORDER_THRESHOLD = 80;
    }

    getSwitchFontColor = (H) => {
        const hsl = this.hexToHSL(H);
        // console.log("hsl : >> ", hsl);
        const switchValue = (hsl.light - this.THRESHOLD) * -100;
        // const switchValue = ((hsl.light - this.THRESHOLD) * -1).toFixed(1);
        return `hsl(0, 0%, ${switchValue}%)`;
    }

    getSwitchStrokeColor = (H) => {
        const hsl = this.hexToHSL(H);
        // console.log("hsl : >> ", hsl);
        const stroke = hsl.light * 0.7;
        // const borderAlpha = `calc(${hsl.light - this.BORDER_THRESHOLD} * 10)`;
        const alpha = (hsl.light - this.BORDER_THRESHOLD) * 10;
        return `hsla(${hsl.hue}, ${hsl.sat * 1}%, ${stroke}%, ${alpha})`;
    }

    getSwitchBorderColor = (H) => {
        const hsl = this.hexToHSL(H);
        // const borderLight = `calc(${hsl.light} * 0.7%)`;
        const borderLight = hsl.light * 0.7;
        // const borderAlpha = `calc(${hsl.light - this.BORDER_THRESHOLD} * 10)`;
        const borderAlpha = (hsl.light - this.BORDER_THRESHOLD) * 10;
        // return `1em solid hsla(${hsl.hue}, ${hsl.sat * 1}%, ${borderLight}%, ${borderAlpha})`;
        return `.1em solid hsla(${hsl.hue}, ${hsl.sat * 1}%, ${borderLight}%, ${borderAlpha})`;
    }

    hexToHSL(H) {
        // Convert hex to RGB first
        let r = 0, g = 0, b = 0;
        if (H.length === 4) {
            r = "0x" + H[1] + H[1];
            g = "0x" + H[2] + H[2];
            b = "0x" + H[3] + H[3];
        } else if (H.length === 7) {
            r = "0x" + H[1] + H[2];
            g = "0x" + H[3] + H[4];
            b = "0x" + H[5] + H[6];
        } else if (H.length === 5) {
            r = "0x" + H[1] + H[1];
            g = "0x" + H[2] + H[2];
            b = "0x" + H[3] + H[3];
        } else if (H.length === 9) {
            r = "0x" + H[1] + H[2];
            g = "0x" + H[3] + H[4];
            b = "0x" + H[5] + H[6];
        }
        // Then to HSL
        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r,g,b),
            cmax = Math.max(r,g,b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        if (delta === 0)
            h = 0;
        else if (cmax === r)
            h = ((g - b) / delta) % 6;
        else if (cmax === g)
            h = (b - r) / delta + 2;
        else
            h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0)
            h += 360;

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        // return "hsl(" + h + "," + s + "%," + l + "%)";
        return {hue: h, sat: s, light: l};
    }

    hexToHSLA(H) {
        // console.log("H : >> ", H);
        let r = 0, g = 0, b = 0, a = 1;

        if (H.length === 4) {
            r = "0x" + H[1] + H[1];
            g = "0x" + H[2] + H[2];
            b = "0x" + H[3] + H[3];
        } else if (H.length === 7) {
            r = "0x" + H[1] + H[2];
            g = "0x" + H[3] + H[4];
            b = "0x" + H[5] + H[6];
        } else if (H.length === 5) {
            r = "0x" + H[1] + H[1];
            g = "0x" + H[2] + H[2];
            b = "0x" + H[3] + H[3];
            a = "0x" + H[4] + H[4];
        } else if (H.length === 9) {
            r = "0x" + H[1] + H[2];
            g = "0x" + H[3] + H[4];
            b = "0x" + H[5] + H[6];
            a = "0x" + H[7] + H[8];
        }

        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r,g,b),
            cmax = Math.max(r,g,b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        if (delta === 0)
            h = 0;
        else if (cmax === r)
            h = ((g - b) / delta) % 6;
        else if (cmax === g)
            h = (b - r) / delta + 2;
        else
            h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0)
            h += 360;

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);
        a = (a / 255).toFixed(3);

        return "hsla("+ h + "," + s + "%," + l + "%," + a + ")";
        // return {hue: h, sat: s, light: l, alpha: a};
    }
}

export const COLOR_UTIL = new ColorUtil();