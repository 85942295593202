import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Box,
    IconButton,
    Tooltip,
    ClickAwayListener,
    Popper
} from "@material-ui/core";
import {TimelineContent} from "@material-ui/lab";
import { ReactComponent as ArrowLineConference} from "../../../common/images/ArrowLineConference.svg";
import { ReactComponent as ConferenceIcon} from "../../../common/images/ConferenceIcon.svg";
import { ReactComponent as ArrowLineLearning} from "../../../common/images/ArrowLineLearning.svg";
import { ReactComponent as LearningIcon} from "../../../common/images/LearningIcon.svg";
import { ReactComponent as SmileyIcon} from "../../../common/images/SmileyIcon.svg";
import { ReactComponent as ClockIcon} from "../../../common/images/ClockIcon.svg";
import { ReactComponent as Enter} from "../../../common/images/Enter.svg";
import { ReactComponent as UploadCircleIcon} from "../../../common/images/UploadCircleIcon.svg";
import { ReactComponent as CaretCircle} from "../../../common/images/CaretCircle.svg";
import TeamMembersSideBar from "../../../components/TeamMembersSideBar";
import LinkView from "./LinkView";
import RoomInformation from "./RoomInformation";
import {DATE_UTIL} from "../../../common/util/date.util";
import dayjs from "dayjs";
import {inject, observer} from "mobx-react";
import {UTIL} from "../../../common/util/common.util";
import {FormattedMessage, injectIntl} from "react-intl";
import NewWindow from 'react-new-window'
import 'reactjs-popup/dist/index.css';
import VideoView from "./VedioView";
import Button from "@material-ui/core/Button";
import {COLOR_UTIL} from "../../../common/util/color.util";


const style = theme => ({
    root:{
        marginTop:50,
        "& .MuiTimeline-root":{
            paddingLeft:40,
            paddingRight:0
        },
        "& .MuiTimelineOppositeContent-root":{
            flex:0,
            padding:0
        },
        "& .MuiTimelineContent-root":{
            paddingTop:0,
            paddingLeft:0
        },
    },
    // 시간이 지났을경우
    lineContentBoxOpacity:{
        position:'relative',
        opacity:0.5,
        // borderTop:'1px dotted #c0c2c3',
    },

    iconButton:{
        padding:0,
        marginBottom:8,
        "&:hover":{
            background:'transparent'
        }
    },
    lineContentBox:{
        position:'relative',
        // borderTop:'1px dotted #c0c2c3',
    },
    // 회의일경우
    lineContentBoxIn:{
        position:'relative',
        display:'flex',
        justifyContent:'space-between',
        width:'90%',
        background:'#f5f5f5',
        // padding:'30px 22px 22px',
        // boxShadow:'none',
        margin: '13.8px 0 13.8px',
        padding: '15.8px 24.1px 15.8px 14.6px',
        borderRadius : 7,
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
        // borderTop:'2px solid #00bc6a',
        zIndex:10,
        // '@media all and (min-width:1920px)':{
        //     width:'50%',
        // },
        // '@media all and (min-width:768px) and (max-width:1023px)':{
        //     width:'80%',
        // },
        // '@media all and (min-width:480px) and (max-width:767px)': {
        //     width:'100%',
        // },
    },
    //회의이면서 일정이 여러개일때
    lineContentBoxIn2:{
        position:'absolute',
        top:3,
        left:22,
        width:'90%',
        height:'100%',
        content:'',
        zIndex:5,
        background:'#f5f5f5',
        // padding:'30px 22px 22px',
        // boxShadow:'none',
        padding: '15.8px 24.1px 15.8px 14.6px',
        borderRadius : 7,
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
        // '@media all and (min-width:1920px)':{
        //     width:'50%',
        // },
        // '@media all and (min-width:768px) and (max-width:1023px)':{
        //     width:'80%',
        // },
        // '@media all and (min-width:480px) and (max-width:767px)': {
        //     width:'100%',
        // },
    },
    //학습일경우
    lineContentBoxIn1:{
        position:'relative',
        display:'flex',
        justifyContent:'space-between',
        width:'90%',
        background:'#f5f5f5',
        // padding:'30px 22px 22px',
        // boxShadow:'none',
        // margin: '13.8px 0 13.8px',
        padding: '15.8px 24.1px 15.8px 14.6px',
        borderRadius : 7,
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
        // borderTop:'2px solid #664cff',
        zIndex:10,
        // '@media all and (min-width:1920px)':{
        //     width:'50%',
        // },
        // '@media all and (min-width:768px) and (max-width:1023px)':{
        //     width:'80%',
        // },
        // '@media all and (min-width:480px) and (max-width:767px)': {
        //     width:'100%',
        // },

    },
    //힉습이면서 일정이 여러개일때
    lineContentBoxIn3:{
        position:'absolute',
        top:3,
        left:22,
        width:'90%',
        height:'100%',
        content:'',
        background:'#D9D2EF',
        padding: '15.8px 24.1px 15.8px 14.6px',
        borderRadius : 7,
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
        zIndex:5,
        // '@media all and (min-width:1920px)':{
        //     width:'50%',
        // },
        // '@media all and (min-width:768px) and (max-width:1023px)':{
        //     width:'80%',
        // },
        // '@media all and (min-width:480px) and (max-width:767px)': {
        //     width:'100%',
        // },

    },
    learningIcon:{
        "& path":{
            fill:'#664cff',
        },
    },
    learningIcon1:{
        "& path":{
            stroke:'#664cff'
        },
    },
    ArrowLineBox:{
        position:'relative',
        marginTop:-15,
        zIndex:20,
        pointerEvents: "none",
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:800,
        color:'#0d0d0d',
        textAlign:'left',
        maxWidth: 200,
        whiteSpace:'nowrap',
        textOverflow: 'ellipsis',
        overflow:'hidden'
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:600,
        color:'#0d0d0d',
        margin:'6px 0 9px',
        maxWidth: 200,
        whiteSpace:'nowrap',
        textOverflow: 'ellipsis',
        overflow:'hidden'
    },
    textStyle4:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:600,
        color:'#0d0d0d',
        margin:'6px 0 9px',
        maxWidth: 500,
        whiteSpace:'nowrap',
        textOverflow: 'ellipsis',
        overflow:'hidden'
    },
    textStyle3:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#0d0d0d',
        fontWeight:400,
        marginLeft:3
    },
    countBox:{
        cursor: "pointer",
        display:'inline-block',
        position:'absolute',
        top:-20,
        right:-16,
        borderRadius:5,
        background:'#fb4a59',
        padding:'5px 10px'
    },
    countText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#fff',
        fontWeight:600
    },
    iconBtn:{
        padding:0,
        "& svg":{
            width:30,
            height:30
        }
    },
    popper: {
        zIndex: 25,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        padding:0,
        maxWidth: 500,
        overflow: 'auto',
        boxShadow:'0 4px 15px rgba(0, 0, 0, 0.25)',
        borderRadius:10,
    },
    avatarStyle:{
        display:'inline-block',
        "& .MuiAvatar-circle":{
            width:'2rem',
            height:'2rem',
            background:'#fff',
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1rem',
            color:'#0d0d0d',
            fontWeight:800,
        },
        "& .MuiAvatar-colorDefault":{
            zIndex:'999 !important',
        }
    },
    imageText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:700,
        color:'#fff',
        padding:'2px 4px'
    },
    imageTextBox:{
        position:'absolute',
        alignItems:'center',
        padding:'2px 6px',
        top:'-2%',
        left:40,
        display:'flex',
        border:'2px solid #fff',
        borderRadius:8,
    },
    btnStyle:{
        padding:'8px 18px 8px',
        background: '#fff',
        color : '#0097ff',
        border: 'solid 0.7px #0097ff',

        borderRadius: 4.9,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:'700',
        objectFit: 'contain',
        // color:'#fff',
        "&:hover":{
            background: '#fff',
        }
    },
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        padding:'4px 4px',
        border:'0.3px solid #000000',
        background:'#fffff5',
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '0.873rem',
        color:'#000',
        borderRadius:'0',
        marginLeft:5,
        marginTop:5
    },
}))(Tooltip);

@inject('authStore', 'userLoungeStore', 'timelineStore', 'orgStore')
@observer
class TimeLineItemContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            arrow: true,
            arrowRef: null,
            disablePortal: false,
            flip: true,
            placement: 'bottom',
        };
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClickEnterance = (event, url) => {
        event.stopPropagation();
        url = url + '&tz=' + DATE_UTIL.getTimeZone() + '&local=' + DATE_UTIL.getLanguage();
        window.open(url);
    };

    render() {
        const { classes, intl, userLoungeStore, timelineStore, authStore, orgStore, room, isMulti, roomCount, isExpand } = this.props;
        const { placement, disablePortal, arrow, arrowRef } = this.state;

        const roomState = UTIL.getRoomState(authStore.loginUser.id, room);

        const team = userLoungeStore.selectedTeamId !== 0 ? (userLoungeStore.selectedTeam.id !== undefined ? userLoungeStore.selectedTeam : undefined) : userLoungeStore.teamList.find(t => t.id === room.teamId);
        const member = team !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');

        // const detailOpen = timelineStore.getTimelineOpen(room.id);
        const linkViewOpen = timelineStore.getTimelineLinkViewOpen(room.id);
        const videoViewOpen = timelineStore.getTimelineVideoViewOpen(room.apiRoomId);

        const anchorEl = document.getElementById(`timeline-contents-${room.id}`);

        const videoInfo = timelineStore.getVideoInfo(room.apiRoomId);
        const isVideoAvailable = videoInfo !== undefined && videoInfo.videos.length > 0;

        // console.log("room", room.name, room.id);

        return (
            <ClickAwayListener onClickAway={() => timelineStore.closeTimelinePanel(room.id)}>
                <TimelineContent className={(dayjs(room.endDatetime).isBefore(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT)) && !isVideoAvailable) ? classes.lineContentBoxOpacity : classes.lineContentBox}>

                    {/*<Box className={classes.ArrowLineBox}>*/}
                    {/*    {*/}
                    {/*        roomState.isConference ?*/}
                    {/*            <div>*/}
                    {/*                <ArrowLineConference/>*/}
                    {/*                <div className={classes.imageTextBox} style={{background:'#00bc6a'}}>*/}
                    {/*                    <ConferenceIcon width='22' height='22'/>*/}
                    {/*                    <Typography className={classes.imageText}><FormattedMessage id="conference"/></Typography>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            :*/}
                    {/*            <div>*/}
                    {/*                <ArrowLineLearning/>*/}
                    {/*                <div className={classes.imageTextBox} style={{background:'#664cff'}}>*/}
                    {/*                    <LearningIcon width='22' height='22'/>*/}
                    {/*                    <Typography className={classes.imageText}><FormattedMessage id="education"/></Typography>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*    }*/}
                    {/*</Box>*/}

                    <Paper id={`timeline-contents-${room.id}`} className={roomState.isConference ? classes.lineContentBoxIn : classes.lineContentBoxIn1} onClick={() => timelineStore.openTimelineDetail(room.id)}>
                        {/*{isMulti &&*/}
                        {/*    <Box className={classes.countBox} onClick={this.props.handleClickExpand}>*/}
                        {/*        <Typography className={classes.countText}>{roomCount}</Typography>*/}
                        {/*    </Box>*/}
                        {/*}*/}

                        <Box display="flex" alignItems="center">
                            <Typography className={classes.textStyle4}>{room.name} ({room.comment})</Typography>
                            {/*<Box display='flex' alignItems='center'>*/}
                            {/*    <SmileyIcon className={roomState.isConference ? '' : classes.learningIcon} style={roomState.isTablet ? {width:'30', height:'30'} : {width:'1.5rem', height:'1.5rem'}} />*/}
                            {/*    <Typography className={classes.textStyle3}>{room.teamName} | {room.userName} {room.userTitle}</Typography>*/}
                            {/*</Box>*/}
                            {/*<Box display='flex' alignItems='center' pb={1} pt={1}>*/}
                            {/*    <ClockIcon className={roomState.isConference ? '' : classes.learningIcon} width='1.5rem' height='1.5rem' />*/}
                            {/*    <Typography className={classes.textStyle3} style={{color:'#4f5660',fontWeight:800}}>{DATE_UTIL.getTimeZoneLanguage(room.startDatetime).format('A')} {dayjs(room.startDatetime).format('HH:mm')} ~ {DATE_UTIL.getTimeZoneLanguage(room.endDatetime).format('A')} {dayjs(room.endDatetime).format('HH:mm')}</Typography>*/}
                            {/*</Box>*/}

                            {/*<TeamMembersSideBar members={room.members} isTeam={false} configs={orgStore.orgConfigs}/>*/}
                        </Box>

                        {/*<Box display='flex' flexDirection='column' justifyContent='space-between' alignItems='center'>*/}
                            <Box display='flex' flexDirection='column' alignItems='center'>
                                {roomState.isEnter &&
                                    <LightTooltip title={intl.formatMessage({id: "enter"})} placement="right">
                                        {/*<IconButton className={classes.iconButton}>*/}
                                        {/*    <Enter style={{width:32}}/>*/}
                                        {/*</IconButton>*/}
                                        <Button className={classes.btnStyle} onClick={event => this.handleClickEnterance(event, room.members.find(m => m.userId === authStore.loginUser.id).entranceUrl)}>
                                            <Typography>수업입장</Typography>
                                        </Button>
                                    </LightTooltip>
                                }
                                {/*{((roomState.isMade || isLeader) && room.guests.length > 0 && !roomState.isEnded)  &&*/}
                                {/*    <LinkView linkViewOpen={linkViewOpen} guests={room.guests} handleClickButton={() => timelineStore.openTimelineLinkView(room.id)} handleClose={timelineStore.closeAllTimelineLinkView}/>*/}
                                {/*}*/}
                                {/*{(isVideoAvailable || roomState.isMade) &&*/}
                                {/*    <VideoView videoViewOpen={videoViewOpen} videoInfo={videoInfo} roomState={roomState} handleClickButton={() => timelineStore.openTimelineVideoView(room.apiRoomId)} handleClose={timelineStore.closeAllTimelineVideoView}/>*/}
                                {/*}*/}
                            </Box>

                            {/*{isMulti ?*/}
                            {/*    isExpand ?*/}
                            {/*        <IconButton className={classes.iconBtn} onClick={this.props.handleClickExpand}>*/}
                            {/*            <CaretCircle className={roomState.isConference ? '' : classes.learningIcon1} />*/}
                            {/*        </IconButton>*/}
                            {/*        :*/}
                            {/*        <IconButton className={classes.iconBtn} onClick={this.props.handleClickExpand}>*/}
                            {/*            <CaretCircle className={roomState.isConference ? '' : classes.learningIcon1} style={{transform:'rotate(180deg)'}}/>*/}
                            {/*        </IconButton>*/}
                            {/*    :*/}
                            {/*    isExpand ?*/}
                            {/*        <IconButton className={classes.iconBtn} onClick={this.props.handleClickExpand}>*/}
                            {/*            <CaretCircle className={roomState.isConference ? '' : classes.learningIcon1} />*/}
                            {/*        </IconButton>*/}
                            {/*        :*/}
                            {/*        null*/}
                            {/*}*/}
                        {/*</Box>*/}
                    </Paper>

                    {/*<Popper*/}
                    {/*    open={detailOpen}*/}
                    {/*    anchorEl={anchorEl !== null ? anchorEl : document.body}*/}
                    {/*    placement={placement}*/}
                    {/*    disablePortal={disablePortal}*/}
                    {/*    className={classes.popper}*/}
                    {/*    modifiers={{*/}
                    {/*        flip: {*/}
                    {/*            enabled: true,*/}
                    {/*        },*/}
                    {/*        preventOverflow: {*/}
                    {/*            enabled: true,*/}
                    {/*            boundariesElement: 'scrollParent',*/}
                    {/*        },*/}
                    {/*        hide: {*/}
                    {/*            enabled: false*/}
                    {/*        },*/}
                    {/*        arrow: {*/}
                    {/*            enabled: arrow,*/}
                    {/*            element: arrowRef,*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}*/}
                    {/*    <Paper className={classes.paper}>*/}
                    {/*        {detailOpen ? (*/}
                    {/*            <div>*/}
                    {/*                <RoomInformation room={room}*/}
                    {/*                                 isVideoAvailable={isVideoAvailable}/>*/}
                    {/*            </div>*/}
                    {/*        ) : null }*/}
                    {/*    </Paper>*/}
                    {/*</Popper>*/}

                    {(isMulti && !isExpand) && <Paper className={roomState.isConference ? classes.lineContentBoxIn2 : classes.lineContentBoxIn3} />}
                </TimelineContent>
            </ClickAwayListener>
        );
    }
}

export default withStyles(style)(injectIntl(TimeLineItemContent));
