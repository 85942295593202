import React, {Component} from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import {withStyles} from "@material-ui/core/styles";
import { ReactComponent as DropzoneIcon } from "../../../common/images/DropzoneIcon.svg";
import {inject, observer} from "mobx-react";
import {injectIntl} from "react-intl";
import {
    DEFAULT_UPLOAD_IMAGE_ACCEPTED_FILES,
    DEFAULT_UPLOAD_IMAGE_MAX_FILE_SIZE,
} from "../../../stores/OrgStore";

const style = theme => ({
    root: {
        "& .MuiDropzoneArea-root":{
            width:155,
            minHeight:111,
            border:'2px dashed #dbdbdb',
            marginBottom:20
        },
        "& .MuiDropzoneArea-text":{
            position :  "absolute",
            width : "100%",
            top:'48%',
            textAlign :"center",
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'0.875rem',
            color:'#303030'
        },
        "& .MuiDropzoneArea-icon": {
            position :  "absolute",
            top: "13%",
            marginLeft:-25,
        },
        "& :focus":{
            outline:'none',
        },
    },
});

@inject('orgStore')
@observer
class DropzoneImage extends Component {
    constructor(props){
        super(props);
        this.state = {
            files: []
        };
    }

    handleDrop = (files) => {
        if(files.length > 1) {
            const { intl } = this.props;
            const fileLimitErrorMsg = this.props.orgStore.handleFileLimitExceedMessage(intl, 1);
            this.props.orgStore.handleError(fileLimitErrorMsg, 'error');
        }  else {
            this.setState({
                files: files
            });
            this.props.orgStore.changeMainImgData(this.state.files);
        }
    };

    handleRejectedFile = (rejectedFile) => {
        const { intl } = this.props;
        let errorMsg = '';
        if(rejectedFile.length > 1) {
            errorMsg = this.props.orgStore.handleFileLimitExceedMessage(intl, 1);
        } else {
            const acceptedFiles = DEFAULT_UPLOAD_IMAGE_ACCEPTED_FILES;
            const maxFileSize = DEFAULT_UPLOAD_IMAGE_MAX_FILE_SIZE;
            errorMsg = this.props.orgStore.handleDropRejectMessage(intl, rejectedFile[0], acceptedFiles, maxFileSize);
        }
        this.props.orgStore.handleError(errorMsg, 'error');
    }

    render() {
        const { classes, intl } = this.props;

        return (
            <div className={classes.root}>
                <DropzoneArea
                    clearOnUnmount={true}
                    onDropRejected={(file) => this.handleRejectedFile(file)}
                    onDrop={this.handleDrop}
                    acceptedFiles={['image/jpeg', 'image/png']}
                    maxFileSize={1000000}
                    showPreviewsInDropzone={false}
                    filesLimit={1}
                    dropzoneText={intl.formatMessage({id: "msg.click_upload"})}
                    Icon={DropzoneIcon}
                    showAlerts={false}
                    // onAlert={orgStore.handleError}
                    // getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => orgStore.handleDropRejectMessage(intl, rejectedFile, acceptedFiles, maxFileSize)}
                    // getFileLimitExceedMessage={(filesLimit) => orgStore.handleFileLimitExceedMessage(intl, filesLimit)}
                />
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(DropzoneImage));