import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Grid,
    Button,
    Box, IconButton, TextField,
} from "@material-ui/core";
import TeamTable from "./TeamTable/TeamTable";
import TabletCreateTeam from "./TabletCreateTeam";
import TabletTeamInformation from "./TabletTeamInformation";
import {inject, observer} from "mobx-react";
import DeleteDialog from "../../../common/DeleteDialog";
import {UI} from "../../../stores/TeamStore";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {UserType} from "../../../stores/UserStore";
import {Clear as ClearIcon, Search as SearchIcon} from "@material-ui/icons";
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../../common/util/color.util";

const style = theme => ({
    root:{
        width:'100%',
        background:'#fff',
        paddingTop:100,
        paddingLeft:30,
        marginLeft:90,
        "& .MuiListItem-root.Mui-selected":{
            background:'#d0d0d0',
        },
        "& :focus":{
            outline:'none',
        },
    },
    paper:{
        borderRadius:0,
        boxShadow:'none',
    },
    menuItemBox:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.8rem',
        fontWeight:600,
        color:'#0d0d0d',
        "&:hover":{
            background:'#d0d0d0'
        }
    },
    btnStyle:{
        background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily:'NanumSquareRoundOTF',
        fontWeight:600,
        // color:'#fff',
        marginRight:20,
        marginTop:30,
        "&:hover":{
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    textStyle:{
        width:180,
        // padding:'10px 0',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:600,
        color:'#fff',
        marginRight:20,
        marginTop:40,
        "& input":{
            fontSize:'1.25rem',
            letterSpacing:-1,
        },
        "& .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottomColor:'#7d86a9'
        }
    }
});
@inject("teamStore", "authStore")
@observer
class TabletTeamManagement extends Component {
    constructor(props) {
        super(...arguments, props);
        this.state = {
            modified : false
        };
    }
    componentDidMount() {
        if(this.props.authStore.loginUser.type === UserType.Admin){
            this.props.teamStore.teamTreeSelectedTeamId = "";
            this.props.teamStore.initTeamTablePage();
            this.props.teamStore.getTeamTreeList();
            this.props.teamStore.getTeamTableList();
            this.props.teamStore.initTeamTreeExpandedList();
        } else if ((this.props.authStore.loginUser.type !== UserType.Admin)
            && this.props.teamStore.selectTeamByLeader.id) {
            this.props.teamStore.changeUiState(UI.Information);
            // this.props.teamStore.teamTreeSelectedTeamId = "";
            // this.props.teamStore.getTeamDetail(this.props.teamStore.selectTeamByLeader.id);
            // this.props.teamStore.getParentTeam(this.props.teamStore.selectTeamByLeader.parentTeamId);
        }
        this.props.teamStore.getTeamUser();
    }
    componentWillUnmount() {
        this.props.teamStore.initTreeAction();
        this.props.teamStore.initTeamTablePage();
        this.props.teamStore.initTeamTreeSelectedTeamId();
    }
    handleClick = () => {
        this.props.teamStore.changeUiState(UI.Create);
        this.props.teamStore.addTeam();
    }
    doDeleteHandle = () =>{
        const {intl} = this.props;
        this.props.teamStore.deleteTeam(intl);
    }
    handleModifiedState = (state) => {
        this.setState({
            modified : state,
        })
    }


    keyDownSearchKeyword = (e) => {
        if (e.isComposing || e.keyCode === 229) { return; }
        if (e.keyCode  === 13) {
            this.SearchTeamTableListByKeyword();
        } else if (e.keyCode === 27) {
            this.props.teamStore.initTeamTableSearchKeyword();
        }
    }

    SearchTeamTableListByKeyword = () => {
        const { teamTreeSelectedTeamId } = this.props.teamStore;
        const keyword = this.props.teamStore.teamTablePage.keyword;
        this.props.teamStore.initTeamTablePage();
        this.props.teamStore.teamTablePage.keyword = keyword;

        if ( teamTreeSelectedTeamId === "-1" || teamTreeSelectedTeamId === "" ){
            this.props.teamStore.getTeamTableList();
            console.log("teamTree selected Organization");
        } else {
            this.props.teamStore.getTeamTableList(teamTreeSelectedTeamId);
            console.log("teamTree selected Group : groupId=", teamTreeSelectedTeamId);
        }
    }

    render() {
        const { classes, intl, teamStore, isTablet } = this.props;
        const { changeTeamConfirmDialogOpen, teamConfirmDialog, teamDeleteDialog, changeTeamDeleteDialogOpen,
            UiState, teamTablePage, changeTeamTableSearchKeyword, initSearchKeyword, } = teamStore;

        return (
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        {UiState === UI.TeamTable &&
                        <Box>
                            <Box display='flex' justifyContent='flex-end'>
                                <TextField value={teamTablePage.keyword}
                                           onChange={changeTeamTableSearchKeyword}
                                           onKeyDown={this.keyDownSearchKeyword}
                                           className={classes.textStyle}
                                           placeholder={intl.formatMessage({id: "msg.search_team_name"})}
                                           InputProps={{
                                               endAdornment: (
                                                   <Box display='flex' style={{marginRight : -5}}>
                                                       <IconButton position="end"
                                                                   style={{ padding:2 }}
                                                                   onClick={initSearchKeyword}>
                                                           <ClearIcon style={{fontSize:'1.7rem'}}/>
                                                       </IconButton>
                                                       <IconButton position="end"
                                                                   style={{ padding:2 }}
                                                                   onClick={this.SearchTeamTableListByKeyword}>
                                                           <SearchIcon style={{fontSize:'1.7rem'}}/>
                                                       </IconButton>
                                                   </Box>
                                               ),
                                           }}>

                                </TextField>
                                <Button
                                    className={classes.btnStyle}
                                    style={isTablet ? {fontSize:'1.125rem', padding:'8px 28px'}
                                                    : {fontSize:'1.4rem', padding:'10px 33px'}}
                                    onClick={this.handleClick}
                                >
                                    + <FormattedMessage id="add_team"/>
                                </Button>
                            </Box>


                            <TeamTable isMobile={this.props.isMobile} isTablet={this.props.isTablet}/>
                        </Box>
                        }
                        {UiState === UI.Information &&
                        <TabletTeamInformation />
                        }
                        {UiState === UI.Create &&
                        <TabletCreateTeam/>
                        }
                    </Grid>
                </Grid>
                <DeleteDialog open={teamDeleteDialog.open}
                              title={teamDeleteDialog.title}
                              msg={teamDeleteDialog.msg}
                              changeDialogOpen={changeTeamDeleteDialogOpen}
                              submit={this.doDeleteHandle}
                />
                <ConfirmDialog open={teamConfirmDialog.open}
                               title={teamConfirmDialog.title}
                               msg={teamConfirmDialog.msg}
                               changeDialogOpen={(value)=>changeTeamConfirmDialogOpen(value)}/>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(TabletTeamManagement));