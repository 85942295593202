import SamsungHome from "./views/Home/Samsung/Home"
import SamsungMobileHome from "./views/Home/Samsung/MobileHome"

const domain = {
    "samsung.onthe.live": {
        "home": SamsungHome,
        "mobileHome": SamsungMobileHome
    }
}

export const DOMAIN = domain;