import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Typography,
    Box,
    Avatar,
    FormControl,
    Select, IconButton, TextField
} from "@material-ui/core";
import {ReactComponent as WarningIcon} from "../../common/images/WarningIcon.svg"
import {ReactComponent as MarkQuestion} from "../../common/images/MarkQuestion.svg"
import ChangePassword from "./ChangePassword";
import {inject, observer} from "mobx-react";
import MakeProfile from "./MakeProfile";
import timeZone from "../../common/timeZone/timeZone.json"
import * as validation from "../../common/Validation";
import {Languages} from "../../common/Languages";
import ConfirmDialog from "../../common/ConfirmDialog";
import CheckIcon from "@material-ui/icons/Check";
import {UserConfigType} from "../../stores/UserStore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {PATH_UTIL} from "../../common/util/path.util";
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../common/util/color.util";

const style = theme => ({
    root: {
        background: '#fff',
        paddingTop: 100,
        paddingLeft: 70,
        marginLeft: 90,
        width: '100%',
        "& .MuiButton-root.Mui-disabled": {
            background: '#fff',
            color: '#88889D',
            border: '1px solid #A3A8AF'
        }
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.375rem',
        fontWeight: 800,
        color: '#0d0d0d',
    },
    avatarStyle: {
        width: 147,
        height: 147,
    },
    pictureBtn: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        marginLeft: 18,
        height: 30,
        "&:hover": {
            background: 'transparent'
        },
    },
    textStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight: 600,
        width: 120
    },
    textStyle2: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
    },
    btnStyle: {
        padding: '11px 53px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        fontWeight: 600,
        // color: '#fff',
        marginBottom: 50,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        },
    },
    btnStyle1: {
        padding: '11px 53px',
        background: '#a3a8af',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: '#fff',
        marginBottom: 50,
        marginRight: 27,
        "&:hover": {
            background: '#a3a8af'
        },
    },
    inputStyle: {
        width: 240,
        height: 36.25,
        padding: '6px 10px',
        borderRadius: 4,
        border: '1px solid #b7bcd6',
        background: '#fff',
        fontSize:'1rem',
        "&:focus": {
            outline: 'none',
        },
    },
    inputErrorStyle: {
        width: 240,
        height: 36.25,
        padding: '6px 10px',
        borderRadius: 4,
        border: '1px solid #ff0000',
        background: '#fff',
        fontSize:'1rem',
        "&:focus": {
            outline: 'none',
        },
    },
    inputText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.875rem',
        color: '#8d909e',
        marginTop:4,
    },
    errorText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#ff0000',
        fontWeight: 600,
    },
    autocompleteBox: {
        width: 240,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        height: 36.25,
        background: '#fff',
        border: 0,
        borderRadius: 3,
        "& .MuiAutocomplete-root": {
            padding: 0,
        },
        "& .MuiAutocomplete-input": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.125rem',
            color: '#0d0d0d',
        },
        "& .MuiChip-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1rem',
            color: '#0d0d0d'
        },
        "& .MuiAutocomplete-popper": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1rem',
            color: '#0d0d0d',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    option: {
        fontSize: '1rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#303030'
    },
    formControlBox: {
        width: 240,
        background: '#fff',
        border: 0,
        borderRadius: 3,
        "& .MuiSelect-select": {
            background: 'transparent',
        },
        "& .MuiOutlinedInput-input": {
            padding: '11px 5px',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent',
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
    },
    selectBox: {
        paddingLeft: 6,
        width: 240,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#303030',
    },
    iconBtn: {
        padding: 5,
        marginLeft: 10,
    },
});

@inject('userStore', 'authStore', 'avatarStore', 'progressStore', 'localeStore', 'orgStore', 'topBarStore')
@observer
class ProfileSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modified: false,
            timeZone: '',
            language: '',
            name: '',
            error: false,
            checkNameState: true,
        }
    }

    componentDidMount() {
        const { topBarStore } = this.props;

        if (PATH_UTIL.getPath(this.props.location.pathname) === 'profilesettings') {
            topBarStore.setIsSearchBarRender(false);
        }
        this.props.userStore.getUserInfo(this.props.authStore.loginUser.id);
        this.props.avatarStore.initialize(this.props.authStore.loginUser.id, 'standbyVideo', 'backgroundImage', 'captureCanvas', 'backgroundCanvas', 'maskedCanvas', 'avatarCanvas');
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {progressStore, userStore, avatarStore} = this.props;
        progressStore.setIsLoading(userStore.getIsLoading || avatarStore.getIsLoading)
    };

    componentWillUnmount() {
        this.props.userStore.closeDialogState(false);
    };

    handleCancelClick = () => {
        this.setState({
            modified: !this.state.modified,
            language: this.props.userStore.userInfo.userConfig.find((config => config.configKey === UserConfigType.Language)) !== undefined ? this.props.userStore.userInfo.userConfig.find((config => config.configKey === 'Language')).value : "",
            timeZone: this.props.userStore.userInfo.userConfig.find((config => config.configKey === UserConfigType.Zone)) !== undefined ? this.props.userStore.userInfo.userConfig.find((config => config.configKey === 'Zone')).value : "",
            name: this.props.userStore.userInfo.name,
            error: false,
            checkNameState: true,
        });
        this.props.userStore.errMsg = '';
        this.props.userStore.closeDialogState(false);
    };

    handleChangeNickName = e => {
        const {intl} = this.props;

        this.handleChangeCheckNameState(false);
        this.setState({
            name: e.target.value
        }, () => this.setState({error: !validation.validateName(this.state.name)}, () => {
            this.state.error ? this.props.userStore.errMsg = `${intl.formatMessage({id: "msg.re_enter_nickname"})}` : this.props.userStore.errMsg = `${intl.formatMessage({id: "msg.required_duplicate_button"})}`;
        }))
    };

    handleTimeZoneSelect = value => {
        if (timeZone.includes(value) || value === null) {
            this.setState({timeZone: value})
        }
    };

    handleLanguageSelect = e => {
        this.setState({language: e.target.value})
    };

    handleLanguageSet = newLanguage => {
        if (!newLanguage || newLanguage === "") {
            return;
        } else {
            this.props.localeStore.changeLocale(newLanguage);
        }
    };

    handleCheckName = () => {
        const {intl} = this.props;

        if (this.state.name !== this.props.userStore.userInfo.name) {
            this.props.userStore.checkName(intl, this.state.name, (value) => {
                this.handleChangeCheckNameState(value)
            });
        } else {
            this.props.userStore.errMsg = `${intl.formatMessage({id: "msg.available_nickname"})}`;
            this.handleChangeCheckNameState(true);
        }
    };

    handleChangeCheckNameState = (value) => {
        this.setState({checkNameState: value})
    };

    handleSave = (selectLanguage) => {
        const {intl} = this.props;
        this.props.userStore.changeProfile(intl, this.state, this.props.authStore.loginUser.id,() => {
            this.handleCancelClick();
            this.handleLanguageSet(selectLanguage);
        });
    };

    render() {
        const {classes, intl, userStore, orgStore} = this.props;
        const {locale} = this.props.localeStore;
        const {userInfo} = this.props.userStore;
        const {loadAvatarImage, avatarImage} = this.props.avatarStore;
        return (
            <div className={classes.root}>
                <Typography className={classes.titleStyle}><FormattedMessage id="profile_settings"/></Typography>
                <Box style={{width: '80%', margin: '0 auto'}}>
                    <Box display='flex' alignItems='center' pt={4} pb={5}>
                        <Avatar
                                alt="userImg"
                                // src={loadAvatarImage}
                                className={classes.avatarStyle}
                        >
                            {
                                loadAvatarImage ?
                                    <img id={'userProfileAvatar'} alt={userInfo.name} src={loadAvatarImage}/>
                                    : <img id={'userProfileAvatar'} alt={userInfo.name} src={avatarImage}/>
                            }
                        </Avatar>
                        {
                            this.state.modified === true &&
                                <MakeProfile/>
                        }
                    </Box>
                    <Box display="flex" alignItems="center">
                        {this.state.modified === false ?
                            <Typography className={classes.textStyle1}
                                        style={{marginBottom: 38}}><FormattedMessage id="nickname"/></Typography>
                            :
                            <Typography className={classes.textStyle1}
                                        style={{marginBottom: 38}}><FormattedMessage id="nickname"/><span
                                style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                        }
                        {this.state.modified === false ?
                            <Typography className={classes.textStyle2} style={{marginBottom: 40}}>
                                {userInfo.name}
                            </Typography>
                            :
                            <Box display='flex' flexDirection='column'>
                                <Box>
                                    <input
                                        variant="outlined"
                                        name="name"
                                        type="text"
                                        id="name"
                                        placeholder={intl.formatMessage({id: "msg.validation_nickname"})}
                                        value={this.state.name}
                                        className={this.state.error ? classes.inputErrorStyle : classes.inputStyle}
                                        onChange={e => this.handleChangeNickName(e)}
                                    />
                                    <IconButton onClick={this.handleCheckName} disabled={this.state.error}
                                                className={classes.iconBtn}>
                                        <CheckIcon/>
                                    </IconButton>
                                </Box>
                                <Box display="flex" alignItems='center'>
                                    {userStore.errMsg === '' ?
                                        <Box display="flex" alignItems='center'>
                                            <Typography className={classes.inputText}
                                                        style={{marginBottom: 15}}><FormattedMessage id="msg.validation_nickname"/></Typography>
                                            <MarkQuestion style={{marginLeft: 5, marginBottom: 15, width:20, height:20}}/>
                                        </Box>
                                        :
                                        userStore.errMsg === `${intl.formatMessage({id: "msg.available_nickname"})}` ?
                                            <Typography className={classes.inputText}
                                                        style={{marginBottom: 15, color: '#0097ff'}}>
                                                {userStore.errMsg}
                                            </Typography>
                                            :
                                            <Box display="flex" alignItems='center'>
                                                <WarningIcon
                                                    style={{marginRight: 5, marginBottom: 15, width: 13, height: 13}}/>
                                                <Typography className={classes.inputText}
                                                            style={{marginBottom: 15, color: '#ff0000'}}>
                                                    {userStore.errMsg}
                                                </Typography>
                                            </Box>
                                    }
                                </Box>
                            </Box>
                        }
                    </Box>
                    <Box display="flex" alignItems="center" pb={5}>
                        <Typography
                            className={classes.textStyle1}><FormattedMessage id="company"/></Typography>
                        <Typography className={classes.textStyle2}>
                            {orgStore.orgConfigs.OrganizationName}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" pb={5}>
                        <Typography
                            className={classes.textStyle1}><FormattedMessage id="email"/></Typography>
                        <Typography className={classes.textStyle2}>
                            {userInfo.email}
                        </Typography>
                    </Box>
                    {this.state.modified === false &&
                    <Box display="flex" alignItems="center" pb={5}>
                        <Typography
                            className={classes.textStyle1}><FormattedMessage id="password"/></Typography>
                        <ChangePassword locale={locale}/>
                    </Box>
                    }
                    <Box display="flex" alignItems="center" pb={5}>
                        <Typography
                            className={classes.textStyle1}><FormattedMessage id="timezone"/></Typography>
                        {this.state.modified === false ?
                            <Typography
                                className={classes.textStyle2}>{userInfo.userConfig.filter((config => config.configKey === UserConfigType.Zone)).map((data => data.value))}</Typography>
                            :
                            <Box className={classes.autocompleteBox}>
                                <Autocomplete
                                    id="tags-outlined"
                                    classes={{
                                        option: classes.option,
                                    }}
                                    value={this.state.timeZone}
                                    options={timeZone}
                                    noOptionsText={intl.formatMessage({id: "no_search_result"})}
                                    loadingText={intl.formatMessage({id: "searching"})}
                                    getOptionLabel={(option) => {
                                        return option.substring(option.indexOf('/') + 1)
                                    }}
                                    groupBy={(option) => option.substring(0, option.indexOf('/'))}
                                    size='small'
                                    disablePortal={true}
                                    freeSolo
                                    onChange={(e, value) => {
                                            this.handleTimeZoneSelect(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label=""
                                            placeholder={`${intl.formatMessage({id: "msg.insert_search_city"})}`}
                                        />
                                    )}
                                />
                            </Box>
                        }
                    </Box>
                    <Box display="flex" alignItems="center" pb={4}>
                        <Typography
                            className={classes.textStyle1}><FormattedMessage id="language"/></Typography>
                        {this.state.modified === false ?
                            <Typography className={classes.textStyle2}>
                                {userInfo.userConfig.find(config => config.configKey === UserConfigType.Language) &&
                                Languages[userInfo.userConfig.find(config => config.configKey === UserConfigType.Language).value]}
                            </Typography>
                            :
                            <FormControl variant={'outlined'} className={classes.formControlBox}>
                                <Select
                                    native
                                    value={this.state.language}
                                    name="Language"
                                    onChange={(e) => this.handleLanguageSelect(e)}
                                    className={classes.selectBox}
                                >
                                    <option value=""
                                            className={classes.optionStyle}>{intl.formatMessage({id: "msg.required_language"})}</option>
                                    {
                                        Object.entries(Languages).map(([key, value]) =>
                                            <option key={key} value={key}
                                                    className={classes.optionStyle}>{value}</option>)
                                    }
                                </Select>
                            </FormControl>
                        }
                    </Box>

                    <Box display="flex" justifyContent='flex-end' pt={8}>
                        {this.state.modified === false ?
                            <Button className={classes.btnStyle}
                                    onClick={this.handleCancelClick}><FormattedMessage id="edit"/></Button>
                            :
                            <Box display="flex">
                                <Button className={classes.btnStyle1}
                                        onClick={this.handleCancelClick}><FormattedMessage id="cancel"/></Button>
                                <Button className={classes.btnStyle}
                                        disabled={this.state.error || this.props.userStore.getIsLoading || !this.state.checkNameState}
                                        onClick={() => this.handleSave(this.state.language)}><FormattedMessage id="save"/></Button>
                            </Box>
                        }
                    </Box>
                </Box>
                <ConfirmDialog open={userStore.openConfirmDialog}
                               title={userStore.alertMsg}
                               msg={userStore.errMsg}
                               changeDialogOpen={userStore.changeConfirmDialogOpen}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(ProfileSettings)));