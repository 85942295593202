import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Avatar,
    Drawer,
    IconButton, List, ListItem,
} from "@material-ui/core";

import {ReactComponent as MobileListPlus} from "../common/images/MobileListPlus.svg";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {UserType} from "../stores/AuthStore";
import {UTIL} from "../common/util/common.util";
import {PATH_UTIL} from "../common/util/path.util";
import {ReactComponent as Home} from "../common/images/House.svg";
import {COLOR_UTIL} from "../common/util/color.util";


const style = theme => ({
    root:{
        "& .MuiListItem-root.Mui-selected":{
            '& .MuiAvatar-circular':{
                marginLeft:'-4px'
            },
        },
    },
    list: {
        width: 90,
        "& .MuiListItem-root.Mui-selected":{
            background: () => theme.configs.MainColor ? theme.configs.MainColor : '#f2f2f2',
            borderLeft: () => theme.configs.SubColor
                ? `4px solid ${theme.configs.SubColor}`
                : '4px solid #0097ff',
            '& .MuiAvatar-circular':{
                marginLeft:'-4px'
            },
        },
    },
    avatarStyle:{
        fontFamily: 'Montserrat',
        fontSize:'2.2rem',
        fontWeight:800,
        width:48,
        height:48,
        background: () => theme.configs.SubColor
            ? theme.configs.SubColor
            : '#0097ff',
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.SubColor);
            return border;
        },
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.SubColor);
            return color;
        },
        "& path":{
            // fill: () => theme.configs.SubColor,
            stroke: () => {
                let stroke = COLOR_UTIL.getSwitchStrokeColor(theme.configs.SubColor);
                return stroke;
            },
            strokeWidth : '1px'
        },
    },
});

@inject('authStore', 'userStore', 'userLoungeStore', 'avatarStore', 'timelineStore', 'surveyStore', 'quizStore')
@observer
class MobileSideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            left: false
        }
    };

    componentDidMount() {
        const {authStore, userLoungeStore} = this.props;
        userLoungeStore.getSimpleTeamList(authStore.loginUser.id);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {history, authStore, userLoungeStore, timelineStore, surveyStore, quizStore} = this.props;
        if ((this.props.location.pathname !== prevProps.location.pathname) && (prevProps.location.pathname !== '/')) {
            const path = PATH_UTIL.getPath(this.props.location.pathname);
            const teamId = PATH_UTIL.getTeamId(this.props.location.pathname);

            if ((path === 'mytimeline') || (path === 'teams')) {
                if (userLoungeStore.selectedTeamId !== teamId) {
                    userLoungeStore.changeSelectedTeamId(teamId);
                }
                userLoungeStore.changeSelectedTeam(authStore.loginUser.id, teamId, timelineStore, history);
                surveyStore.getSurveyListByUserId(authStore.loginUser);
                quizStore.getQuizListByUserId(authStore.loginUser);
            }
        }
    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleTeamClick = (teamId) => {
        const {history, authStore} = this.props;
        if (teamId === 0) {
            if (authStore.loginUser.type === UserType.Admin) {
                history.push('/management/organization')
            } else {
                history.push('/mytimeline');
            }
        } else {
            history.push(`/teams/${teamId}`);
        }
    }

    render() {
        const { classes, authStore, userLoungeStore, isTablet } = this.props;
        const sideListStyle = {
            avatarStyle:{
                fontFamily: 'Montserrat',
                fontSize:'2.2rem',
                fontWeight:800,
                width:48,
                height:48,
                background: this.props.configs.SubColor,

            },
        }
        const sideList = (
            <div className={classes.root}>
                <List component="nav" aria-label="main mailbox folders" style={{paddingTop:0}}>
                    <ListItem
                        button
                        selected={authStore.loginUser.type === 'Admin' ? true : userLoungeStore.selectedTeamId === 0}
                        onClick={(event) => {this.handleTeamClick(0)}}
                        style={{
                            background : (userLoungeStore.selectedTeamId === undefined || userLoungeStore.selectedTeamId === 0) ? this.props.configs.MainColor : "",
                            borderLeft : (userLoungeStore.selectedTeamId === undefined || userLoungeStore.selectedTeamId === 0) ? `4px solid ${this.props.configs.SubColor}` : "",
                        }}
                    >
                        <Avatar className={classes.avatarStyle} title={"Home"}>
                            <Home style={{width: '70%', height: '70%'}}/>
                        </Avatar>
                        {/*{*/}
                        {/*    avatarStore.loadAvatarImage ?*/}
                        {/*        <Avatar style={sideListStyle.avatarStyle}>*/}
                        {/*            <img id="userAvatar" style={{width: '100%', height: '100%'}} src={avatarStore.loadAvatarImage} alt={authStore.loginUser.name} title={"Home"}/>*/}
                        {/*        </Avatar>*/}
                        {/*        :*/}
                        {/*        <Avatar className={classes.avatarStyle} title={"Home"}>*/}
                        {/*            {UTIL.getFirstCapitalLetter(userInfo.name)}*/}
                        {/*        </Avatar>*/}
                        {/*}*/}
                    </ListItem>
                    {
                        userLoungeStore.simpleTeamList.map((team) => {
                            return (
                                <ListItem
                                    key={team.id}
                                    button
                                    selected={userLoungeStore.selectedTeamId === team.id}
                                    onClick={(event) => {this.handleTeamClick(team.id)}}
                                    style={{
                                        background : userLoungeStore.selectedTeamId === team.id ? this.props.configs.MainColor : "",
                                        borderLeft : userLoungeStore.selectedTeamId === team.id ? `4px solid ${this.props.configs.SubColor}` : "",
                                    }}
                                >
                                    {
                                        team.image ?
                                            <Avatar style={{width: 48, height: 48}}>
                                                <img id="teamAvatar" style={{width: '100%', height: '100%'}} src={team.image} alt={team.name} title={team.name}/>
                                            </Avatar>
                                            :
                                            <Avatar style={sideListStyle.avatarStyle} title={team.name}>
                                                {UTIL.getFirstCapitalLetter(team.name)}
                                            </Avatar>
                                    }
                                </ListItem>
                            );
                        })
                    }
                </List>
            </div>
        );

        return (
            <div className={classes.root}>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.toggleDrawer('left', true)}
                >
                    <MobileListPlus style={isTablet ? {width:28, height:28 } : {width:24, height:24 } }/>
                </IconButton>

                <Drawer anchor="left" open={this.state.left} onClose={this.toggleDrawer('left', false)}>
                    <div
                        tabIndex={0}
                        role="button"
                    >
                        {sideList}
                    </div>
                </Drawer>
            </div>
        )
    }
}

export default withRouter(withStyles(style)(MobileSideBar));