import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    Input,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@material-ui/core";
import XLSX from "xlsx";
import {inject, observer} from "mobx-react";
import {DATE_UTIL} from "../../../common/util/date.util";
import {State, TeamUserType} from "../../../stores/ExcelStore";
import {FormattedMessage, injectIntl} from "react-intl";
import ErrorIcon from '@material-ui/icons/Error';
import ConfirmDialog from "../../../common/ConfirmDialog";
import {COLOR_UTIL} from "../../../common/util/color.util";

const style = theme => ({
    root: {
        width: '100%',
        background: '#fff',
        padding: '50px 15px',
        "& .MuiOutlinedInput-input": {
            padding: '8px 12px'
        },
        "& .MuiListItem-root.Mui-selected": {
            background: '#d0d0d0',
        },
        "& :focus": {
            outline: 'none',
        },
    },
    tableBox:{
        width:'100%',
        borderTop:'1px solid #dddddd',
        marginBottom: 30,
        "& th":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1.125rem',
            color:'#0d0d0d',
            fontWeight:600,
            background:'#f5f5f5',
            width:150,
            padding:20,
            textAlign:'left'
        },
        "& td":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1.125rem',
            color:'#0d0d0d',
            padding:20,
            marginLeft:14,
        },
        "& tr":{
            borderBottom:'1px solid #dddddd',
        },
    },
    paper: {
        padding: 5,
        borderRadius: 0,
        boxShadow: 'none',
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight: 600,
        marginBottom: 30,
    },
    inputStyle: {
        // padding:'10px 0px',
        borderRadius: 4,
        // border:'1px solid #b7bcd6',
        background: '#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        marginRight: 10,
    },
    btnStyle: {
        width: 135,
        padding: '10px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        fontWeight: 600,
        // color: '#fff',
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    inputFileButton: {
        border: '1px solid #bfbfbf',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#1f1f1f',
        fontSize: '1rem',
        borderRadius: 4,
        padding: '11px 12px',
        cursor: 'pointer',
    },
    inputFileLabel: {
        width: 250,
        fontSize: '1rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        margin: '0 12px',
        padding: '15px 6px',
        alignItems: 'center',
        borderBottom: '1px solid #b7bcd6',
    },

    paperBox:{
        background:'transparent',
        boxShadow: 'none',
    },
    cellText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#7d86a9',
        fontWeight:600
    },
    cellText1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#202020',
        maxWidth: 140,
        background:'#f8f8f8',
        fontWeight:600,
        textOverflow: "ellipsis",
        overflow:"hidden",
        whiteSpace: 'nowrap',
    },
    tableRowBox:{
        background:'#fff',
        borderBottom:'1px solid rgba(224, 224, 224, 1)'
    },
    table: {
        background:'transparent',
        border:'0'
    },
    gridBox:{
        marginBottom:30,
        "& .MuiButton-root.Mui-disabled":{
            background:'#fff',
            color:'#88889D',
            border:'1px solid #A3A8AF'
        }
    },

});

@inject("excelStore")
@observer
class TeamManagementByExcel extends Component {

    constructor(...props) {
        super(...arguments,props);
        this.state = {
            validatedState: false,
            fileName: ''
        }
    };

    componentWillUnmount() {
        this.props.excelStore.initExcelStore();
    };

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const { excelStore, progressStore } = this.props;
        progressStore.setIsLoading(excelStore.getIsLoading);
    };

    parsingExcelToJson = (e) => {
        console.log("parsingExcelToJson");
        this.props.excelStore.initExcelStore();
        this.setState({validatedState: false, fileName: e.target.value.split('\\').pop().toLowerCase()});

        let fileList = e.target.files; //input file 객체를 가져온다.
        for (let i = 0; i < fileList.length; ++i) {

            let file = fileList[i];
            let reader = new FileReader();
            reader.onload = async (e) => {

                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                await workbook.SheetNames.forEach((item, index, array) => {
                    if (item === "Data") {
                        const ref = workbook.Sheets[item]["!ref"];
                        if (ref) {
                            const ws = workbook.Sheets[item];

                            const activationPoint = ref.split(":");
                            const endPoint = {
                                col: activationPoint[1][0],
                                colCode: activationPoint[1][0].charCodeAt(),
                                row: parseInt(activationPoint[1].slice(1)),
                            };

                            this.props.excelStore.teamJsonByExcel = {
                                name: this.searchExcelData(ws, "C3"),
                                comment: this.searchExcelData(ws, "C4"),
                                maxUsers: this.searchExcelData(ws, "C5"),
                            };

                            const teamLeader = {
                                type: TeamUserType.Leader,
                                email: this.searchExcelData(ws, "B9"),
                                name: this.searchExcelData(ws, "C9"),
                                password: this.searchExcelData(ws, "D9"),
                                title: this.searchExcelData(ws, "E9"),
                                isExist: false
                            };

                            const memberStartPoint = {
                                col: "B",
                                colCode: "B".charCodeAt(),
                                row: 11
                            }

                            let teamMemberList = [];
                            for (let rowIndex = 2; (memberStartPoint.row + rowIndex) <= endPoint.row; rowIndex++) {
                                let teamMember = {
                                    type : TeamUserType.Member,
                                    email : this.searchExcelData(ws, "B" + (memberStartPoint.row + rowIndex)),
                                    name : this.searchExcelData(ws, "C" + (memberStartPoint.row + rowIndex)),
                                    password : this.searchExcelData(ws, "D" + (memberStartPoint.row + rowIndex)),
                                    title : this.searchExcelData(ws, "E" + (memberStartPoint.row + rowIndex)),
                                    isExist: false
                                }
                                if (Object.values(teamMember).filter(value => value).length > 2) {
                                    teamMemberList.push(teamMember);
                                }
                            }

                            this.props.excelStore.memberJsonByExcel = [teamLeader, ...teamMemberList]
                        } else {
                            //값이 없는 sheet
                        }
                    }
                });
                this.checkValidationObjectByExcel();
            };
            reader.readAsBinaryString(file);
        }
    };

    searchExcelData = (ws, key) => {
        if (ws[key] && ws[key].v) {
            return ws[key].v;
        } else {
            return ""
        }
    };

    exportExcelByDefaultForm = () => {
        const language = DATE_UTIL.getLanguage()
        this.props.excelStore.exportLmsExcelSample(language);
    };

    updateJsonData = () => {
        const intl = this.props.intl;
        this.props.excelStore.createTeamByExcel(intl);
    };

    checkValidationObjectByExcel = async () => {
        this.props.excelStore.validationState = State.Pending;
        this.setState({validatedState: true});
        const intl = this.props.intl;
        await this.props.excelStore.checkTeamValidation(intl);
        await this.props.excelStore.checkMemberValidation(intl);

        this.props.excelStore.memberForView = this.props.excelStore.memberJsonByExcel;
        this.props.excelStore.teamForView = this.props.excelStore.teamJsonByExcel;

        this.props.excelStore.validationState = State.Done;
    };

    render() {
        const {classes} = this.props;
        const {
            teamJsonByExcel, memberJsonByExcel, teamForView, memberForView,
            teamValidationState, memberValidationState, validationState, createExcelState,
            excelConfirmDialog, changeExcelConfirmDialogOpen
        } = this.props.excelStore;

        const team = teamForView;
        const leader = memberForView.find(member => member.type === TeamUserType.Leader);
        const memberList = memberForView.filter(member => (member.type !== TeamUserType.Leader) && (member.isExist === false)).concat(memberForView.filter(member => (member.type !== TeamUserType.Leader) && (member.isExist === true)));

        return (
            <div className={classes.root}>
                <Typography className={classes.textStyle}>
                    <FormattedMessage id={"excel_create_team_and_member"}/>
                </Typography>
                <Paper className={classes.paper}>
                    <Box display="flex" flexDirection="row" justifyContent='flex-start'>
                        <label htmlFor="input-file" className={classes.label}>
                            <Button className={classes.inputFileButton} component="span">
                                <FormattedMessage id="file_selection"/>
                            </Button>
                        </label>
                        <span className={classes.inputFileLabel}
                              id="fileName">{this.state.fileName === '' ? <FormattedMessage id={"msg.no_file"}/> : this.state.fileName}</span>
                        <Input type="file" id="input-file" style={{display: 'none'}}
                               className={classes.inputStyle}
                               onChange={this.parsingExcelToJson}
                               onClick={(event) => event.target.value=''}
                        />
                        <Button className={classes.btnStyle}
                                onClick={this.exportExcelByDefaultForm}>
                            <FormattedMessage id={"form_download"}/>
                        </Button>
                    </Box>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid item xs={10} className={classes.gridBox}>
                        <TableContainer component={Paper} className={classes.paperBox}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.tableRowBox}>
                                        <TableCell colSpan={2} align="center" className={classes.cellText1}>
                                            <FormattedMessage id={"team_information"}/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="left" className={classes.cellText}> <FormattedMessage id="team_name"/> </TableCell>
                                        <TableCell align="left"
                                                   className={classes.cellText}> {team && team.name ? team.name : ""} </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={classes.cellText}> <FormattedMessage id="descriptions"/>  </TableCell>
                                        <TableCell align="left"
                                                   className={classes.cellText}> {team && team.comment ? team.comment : ""} </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={classes.cellText}> <FormattedMessage id="max_user_count"/>  </TableCell>
                                        <TableCell align="left"
                                                   className={classes.cellText}> {team && team.maxUsers ? team.maxUsers : ""} </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="left" className={classes.cellText}> <FormattedMessage id="result"/>  </TableCell>
                                        <TableCell align="left" className={classes.cellText}>
                                            {
                                                team && team.msg && !team.validationState ?
                                                    <Tooltip
                                                        title={team && team.msg ? team.msg.join("/") : ""}
                                                        arrow={true}
                                                        placement={"bottom-start"}>

                                                        <ErrorIcon />
                                                    </Tooltip>
                                                    :
                                                    ""
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={10} className={classes.gridBox}>
                        <TableContainer component={Paper} className={classes.paperBox}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.tableRowBox}>
                                        <TableCell colSpan={6} align="center" className={classes.cellText1}>
                                            <FormattedMessage id={"team_leader"}/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id="email"/> </TableCell>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id="nickname"/> </TableCell>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id="password"/> </TableCell>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id="title"/> </TableCell>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id="result"/> </TableCell>
                                    </TableRow>
                                    {leader ?
                                        <TableRow  style={{ background : leader.isExist ? "#F2F7FC" : "" }}>
                                            <TableCell align="center" className={classes.cellText}> {leader && leader.email ? leader.email : ""} </TableCell>
                                            <TableCell align="center" className={classes.cellText}> {leader && leader.name ? leader.name : ""} </TableCell>
                                            <TableCell align="center" className={classes.cellText}> {leader && leader.password ? leader.password : ""} </TableCell>
                                            <TableCell align="center" className={classes.cellText}> {leader && leader.title ? leader.title : ""} </TableCell>
                                            <TableCell align="center" className={classes.cellText}>
                                                {
                                                    !leader.validationState ?
                                                        <Tooltip
                                                            title={leader && leader.msg ? leader.msg.join("/") : ""}
                                                            arrow={true}
                                                            placement={"bottom-start"}>

                                                            <ErrorIcon />
                                                        </Tooltip>
                                                        :
                                                        ""
                                                }
                                            </TableCell>
                                        </TableRow>
                                        :
                                        <TableRow>
                                            <TableCell colSpan={6} align="center" className={classes.cellText}>
                                                <FormattedMessage id="msg.no_data"/>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={10} className={classes.gridBox}>
                        <TableContainer component={Paper} className={classes.paperBox}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow className={classes.tableRowBox}>
                                        <TableCell colSpan={6} align="center" className={classes.cellText1}>
                                            <FormattedMessage id={"team_member"}/>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id={"email"}/> </TableCell>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id={"nickname"}/> </TableCell>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id={"password"}/> </TableCell>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id={"title"}/> </TableCell>
                                        <TableCell align="center" className={classes.cellText}> <FormattedMessage id={"result"}/> </TableCell>
                                    </TableRow>
                                    {memberList && memberList.length > 0 ?
                                        memberList.map( (member, index) => {
                                            return (
                                                <TableRow key={index.toString()} style={{ background : member.isExist ? "#F2F7FC" : "" }}>
                                                    <TableCell align="center" className={classes.cellText}> {member.email ? member.email : ""} </TableCell>
                                                    <TableCell align="center" className={classes.cellText}> {member.name ? member.name : ""} </TableCell>
                                                    <TableCell align="center" className={classes.cellText}> {member.password ? member.password : ""} </TableCell>
                                                    <TableCell align="center" className={classes.cellText}> {member.title ? member.title : ""} </TableCell>
                                                    <TableCell align="center" className={classes.cellText}>
                                                        {
                                                            !member.validationState ?
                                                                <Tooltip
                                                                    title={member.msg ? member.msg.join("/") : ""}
                                                                    arrow={true}
                                                                    placement={"bottom-start"}>

                                                                    <ErrorIcon />
                                                                </Tooltip>
                                                                :
                                                                ""
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={6} align="center" className={classes.cellText}>
                                                <FormattedMessage id={"msg.no_data"}/>
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Grid>
                </Paper>
                <Paper className={classes.paper}>
                    <Grid item xs={10} className={classes.gridBox}>
                        <Box display="flex" justifyContent='flex-end'>
                            <Button className={classes.btnStyle}
                                    style={{align: ""}}
                                    onClick={this.updateJsonData}
                                    disabled={
                                        ((!teamValidationState || !memberValidationState)
                                            || (teamJsonByExcel.length < 1 && memberJsonByExcel.length < 1)
                                            || (!this.state.validatedState))
                                            || validationState === State.Pending
                                            || createExcelState !== State.Init
                                    }>
                                <FormattedMessage id="save"/>
                            </Button>
                        </Box>
                    </Grid>
                </Paper>

                <ConfirmDialog open={excelConfirmDialog.open}
                               title={excelConfirmDialog.title}
                               msg={excelConfirmDialog.msg}
                               changeDialogOpen={(value)=>changeExcelConfirmDialogOpen(value)}/>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(TeamManagementByExcel));