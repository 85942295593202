import React, {Component} from 'react';
import {Typography, Grid} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import AttendanceCheck from "./attendanceCheck/AttendanceCheck";
import Questions from "./questions/Questions";
import QuizResult from "./quiz/QuizResult";
import MemberActivity from "./memberActivity/MemberActivity";
import ConnectStatus from "./ConnectStatus/ConnectStatus";
import Device from "./device/Device";
import {inject, observer} from "mobx-react";
import ImagePopupModal from "./memberActivity/ImagePopupModal";
import {FormattedMessage} from "react-intl";
import StatisticsSurveyResult from "./survey/StatisticsSurveyResult";
import {withRouter} from "react-router-dom";

const style = theme => ({
    root:{
        flexGrow: 1,
        width:'100%',
        padding:'100px 70px 0 160px',
        // marginLeft:90,
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.375rem',
        fontWeight:600,
        color:'#0d0d0d',
        marginBottom:45
    },
    borderBottomStyle:{
        borderBottom:'2px solid #e2ebf1',
        paddingBottom:50,
        marginBottom:50
    }
});

@inject('statisticsStore', 'topBarStore', 'progressStore')
@observer
class Statistics extends Component {
    componentDidMount() {
        const { topBarStore, statisticsStore, progressStore } = this.props;

        statisticsStore.getStatistics(this.props.match.params.id);
        if (window.location.pathname === `/rooms/${this.props.match.params.id}/statistics`) {
            topBarStore.setIsSearchBarRender(false);
        }
        progressStore.setIsLoading(statisticsStore.getIsLoading);
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}><FormattedMessage id="statistics_status"/></Typography>
                <Grid container className={classes.borderBottomStyle} style={{display:'flex', justifyContent:'space-between'}}>
                    <Grid item xs={6}>
                        <AttendanceCheck />
                    </Grid>
                    <Grid item xs={6}>
                       <Questions />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.borderBottomStyle}>
                    <QuizResult />
                </Grid>

                <Grid item xs={12} className={classes.borderBottomStyle}>
                    <StatisticsSurveyResult />
                </Grid>

                <Grid container className={classes.borderBottomStyle} style={{display:'flex', justifyContent:'space-between'}}>
                    <Grid item xs={6}>
                        <ConnectStatus />
                    </Grid>
                    <Grid item xs={6}>
                        <Device />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{marginBottom:65}}>
                    <MemberActivity />
                </Grid>
                <ImagePopupModal />
            </div>
        );
    };
}

export default withRouter(withStyles(style)(Statistics));