import React, {Component} from 'react';
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Typography
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";

const style = theme => ({
    root:{
        width:'100%',
        marginBottom: 25,
        marginTop: 16
    },
    textStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight: 600,
    },
    typoStyle:{
        fontSize: '1.2rem',
        fontWeight: 400,
    },
});

@inject("surveyStore")
@observer
class MobilePreviewParagraphAdd extends Component {
    render() {
        const { classes, surveyData} = this.props;
        return (
            <div className={classes.root}>
                    <Typography className={classes.textStyle}>{surveyData.title !== "" ? surveyData.title : <FormattedMessage id="msg.preview_survey_subject"/> } </Typography>
                <Box style={{marginTop: 20}}>
                    <Typography className={clsx(classes.textStyle, classes.typoStyle)}>{surveyData.contents !== "" ? surveyData.contents : <FormattedMessage id="msg.preview_survey_description"/>}</Typography>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobilePreviewParagraphAdd));