import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Popper,
    Paper,
    Typography,
    Box,
    Button,
    IconButton,
    ClickAwayListener,
    Tooltip, Dialog, DialogContent, DialogActions
} from "@material-ui/core";
import { ReactComponent as VideoCameraIcon} from "../../../common/images/VideoCameraIcon.svg";
import { ReactComponent as WatchVideoIcon} from "../../../common/images/WatchVideoIcon.svg";
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";
import {AuthorityType, UploadState} from "../../../stores/UserLoungeStore";
import VideoUpload from "./VideoUpload";
import {ReactComponent as DeleteIcon} from "../../../common/images/QuizDeleteIcon.svg";
import DialogTitle from "@material-ui/core/DialogTitle";
import {ReactComponent as Close} from "../../../common/images/Close.svg";
import VideoUploadDialog from "./VideoUploadDialog";


const style = theme => ({
    root: {
        flexGrow: 1,
    },
    iconButton:{
        padding:0,
        // marginBottom:8,
        marginRight:0,
        "&:hover":{
            background:'transparent'
        }
    },
    popper: {
        zIndex: 30,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        padding:'10px 10px 18px 0',
        maxWidth: 500,
        // overflow: 'auto',
        boxShadow:'0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        // border:'1px solid #cdced0',
        borderRadius:10,
    },
    textareaBox:{
        padding:'10px 28px',
        height:150,
        overflowY:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    titleBox:{
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        marginBottom:16,
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#000000',
        fontSize:'1.125rem',
        fontWeight:800,
        marginRight:'1rem',
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#303030',
        fontSize:'1.063rem',
        width:220,
    },
    linkUnderline:{
        width:'100%',
        fontFamily:'NanumSquareRoundOTF',
        color:'#303030',
        fontSize:'1.063rem',
        textDecoration:'underline',
        wordBreak:'break-all',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        wordWrap:'normal',
        overflow:'hidden',
        cursor:'pointer',
    },
    toastContainer:{
        top: '20px !important'
    },
    toasts:{
        fontSize:'1rem',
        color: '#fff !important',
        backgroundColor: 'rgba(97, 97, 97, 0.9) !important',
        fontFamily:'NanumSquareRoundOTF',
        minHeight: 35
    },
    buttonText: {
        border: '1px solid #bfbfbf',
        color: '#1f1f1f',
        fontWeight: 500,
        borderRadius: 4,
        padding: '5px 12px',
        "&:hover": {
            background: 'transparent'
        }
    },
    input: {
        display: 'none',
    },
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        padding:'4px 4px',
        border:'0.3px solid #000000',
        background:'#fffff5',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.813rem',
        color:'#000',
        borderRadius:'0',
        marginLeft:5,
        marginTop:5
    },
}))(Tooltip);

@inject("timelineStore")
@observer
class VideoView extends Component {
    state = {
        arrow: true,
        arrowRef: null,
        disablePortal: false,
        flip: true,
        placement: 'right',
    };

    handleClickButton = (event) => {
        event.stopPropagation();

        if(this.props.handleClickButton) {
            this.props.handleClickButton();
        }
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClickUrl() {
        const { classes, intl } = this.props;
        ToastsStore.info(intl.formatMessage({id: "msg.success_url_copy"}), 1500, classes.toasts);
    };

    render() {
        const { classes, intl, videoViewOpen, videoInfo, roomState } = this.props;
        const { placement, disablePortal, flip, arrow, arrowRef } = this.state;

        return (
            <div className={classes.root} onClick={(event) => event.stopPropagation()}>
                <LightTooltip title={intl.formatMessage({id: "watching_class_video"})} placement="bottom">
                    <IconButton
                        className={classes.iconButton}
                        buttonRef={node => {this.anchorEl = node;}}
                        onClick={this.handleClickButton}
                    >
                        <WatchVideoIcon style={{width:32}}/>
                    </IconButton>
                </LightTooltip>
                <Popper
                    open={videoViewOpen}
                    anchorEl={this.anchorEl}
                    placement={placement}
                    disablePortal={disablePortal}
                    className={classes.popper}
                    modifiers={{
                        flip: {
                            enabled: flip,
                        },
                        preventOverflow: {
                            enabled: false,
                            boundariesElement:'scrollParent',
                        },
                        hide: {
                            enabled: false
                        },
                        arrow: {
                            enabled: arrow,
                            element: arrowRef,
                        },
                    }}
                >
                    {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={this.props.handleClose}>
                            <Box>
                                {videoViewOpen ? (
                                    <Box className={classes.textareaBox}>
                                        <Box flexDirection={"row"} justifyContent={"space-between"} display={"flex"}>

                                        <Typography className={classes.textStyle1}><FormattedMessage id="watching_class"/></Typography>
                                        {roomState.isMade &&
                                            <Box onClick={() => this.props.timelineStore.setVideoUploadDialogState(true)}>
                                                {/*<input*/}
                                                {/*    accept="video/mp4"*/}
                                                {/*    className={classes.input}*/}
                                                {/*    onChange={(event) => this.onFileSelect(event, videoInfo.id)}*/}
                                                {/*    id={"contained-button-file-" + videoInfo.id}*/}
                                                {/*    type="file"*/}
                                                {/*/>*/}
                                                {/*<label htmlFor={"contained-button-file-" + videoInfo.id} >*/}
                                                    <Box className={classes.titleBox}>
                                                        <Button className={classes.buttonText} component="span">업로드</Button>
                                                    </Box>
                                                {/*</label>*/}
                                            </Box>
                                        }
                                        </Box>

                                        {videoInfo.videos.map((name, index) => {
                                            return (
                                                <Box display='flex'>
                                                    <LightTooltip title={name.split('.').slice(0, -1).join('.')} placement="bottom" key={index}>
                                                        <Box display='flex' alignItems='center' style={{
                                                            marginBottom:6,
                                                            // "&:hover": {
                                                                cursor: 'pointer'
                                                            // }
                                                        }} onClick={() => {
                                                            this.props.timelineStore.selectVideoInfo(videoInfo);
                                                            this.props.timelineStore.setSelectedVideoName(name);
                                                            this.props.timelineStore.setVideoOpened(true);
                                                        }}>
                                                            <VideoCameraIcon style={{width:24, height: 24, marginRight:8}}/>
                                                            <Typography className={classes.textStyle2} noWrap>{name.split('.').slice(0, -1).join('.')}</Typography>
                                                        </Box>
                                                    </LightTooltip>

                                                    {roomState.isMade &&
                                                        <LightTooltip title={"영상 삭제"} placement="bottom"
                                                                      key={"video-del-" + index}>
                                                            <DeleteIcon onClick={() => {
                                                                this.props.timelineStore.deleteVideo(videoInfo.id, name);
                                                            }}/>
                                                        </LightTooltip>
                                                    }
                                                </Box>
                                            )})
                                        }

                                        <VideoUploadDialog apiRoomId={videoInfo.id}/>

                                        {this.props.timelineStore.uploadVideoList.length > 0 &&
                                            <VideoUpload/>
                                        }
                                    </Box>
                                ) : null}
                            </Box>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(VideoView));