import AuthStore from "./stores/AuthStore";
import LocaleStore from "./stores/LocaleStore";
import TeamStore from "./stores/TeamStore";
import UserStore from "./stores/UserStore";
import AvatarStore from "./stores/AvatarStore";
import OrgStore from "./stores/OrgStore";
import UserLoungeStore from "./stores/UserLoungeStore";
import NoticeStore from "./stores/NoticeStore";
import TimelineStore from "./stores/TimelineStore";
import RoomStore from "./stores/RoomStore";
import ProgressStore from "./stores/ProgressStore";
import TopBarStore from "./stores/TopBarStore";
import StatisticsStore from "./stores/StatisticsStore"
import QRCodeStore from "./stores/QRCodeStore";
import SurveyStore from "./stores/SurveyStore";
import ExcelStore from "./stores/ExcelStore";
import QuizStore from "./stores/QuizStore";

export default function configureStore(serverContextPath) {
    return {
        authStore: new AuthStore(serverContextPath),
        localeStore: new LocaleStore(serverContextPath),
        teamStore: new TeamStore(serverContextPath),
        userStore: new UserStore(serverContextPath),
        avatarStore: new AvatarStore(serverContextPath),
        roomStore: new RoomStore(serverContextPath),
        orgStore: new OrgStore(serverContextPath),
        userLoungeStore: new UserLoungeStore(serverContextPath),
        timelineStore: new TimelineStore(serverContextPath),
        noticeStore: new NoticeStore(serverContextPath),
        progressStore: new ProgressStore(),
        topBarStore: new TopBarStore(),
        statisticsStore: new StatisticsStore(serverContextPath),
        qrCodeStore: new QRCodeStore(serverContextPath),
        surveyStore: new SurveyStore(serverContextPath),
        excelStore : new ExcelStore(serverContextPath),
        quizStore : new QuizStore(serverContextPath),
    }
}