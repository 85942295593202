import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {configure} from "mobx";
import {Provider as MobxProvider} from "mobx-react";
import {ThemeProvider} from "@material-ui/core/styles";
import configureStore from "./configureStore";
import configureTheme from "./configureTheme";
import configureServerContextPath from "./configureServerContextPath"
import {BrowserRouter} from "react-router-dom";

configure({enforceActions: "never"});

const serverContextPath = configureServerContextPath(false);

const stores = configureStore(serverContextPath);
stores.orgStore.requestGetOrgConfig();

const themeConfigs = stores.orgStore.orgConfigs;
const themeImages = stores.orgStore.orgImages;
const theme = configureTheme(themeConfigs, themeImages);

ReactDOM.render(
    <BrowserRouter>
        <MobxProvider {...stores}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </MobxProvider>
    </BrowserRouter>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
