import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Typography,
    FormControlLabel,
    RadioGroup,
    Radio,
    TextField,
    Button,
    IconButton,
    Switch
} from "@material-ui/core";
import {ReactComponent as SurveyCloseIcon} from "../../../../common/images/SurveyCloseIcon.svg";
import {ReactComponent as PlusIcon} from '../../../../common/images/plusIcon.svg';
import {ReactComponent as QuizDeleteIcon} from "../../../../common/images/QuizDeleteIcon.svg";
import clsx from "clsx";
import {QuizType} from "../../../../stores/QuizStore";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {
        "& .MuiInput-underline:after": {
            borderBottom: 0
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
        },
        "& .MuiFormLabel-root": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.5rem',
            color: '#000',
        },
        "& .MuiInputBase-input": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.4rem',
            color: '#0d0d0d',
        }
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
    },
    radioBox: {
        marginBottom: 8,
        "& .MuiFormControlLabel-root": {
            margin: '0 20px 20px 0px'
        },
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.4rem',
            color: '#000',
            margin: '0 20px 0 5px'
        },
        "& .MuiRadio-colorSecondary.Mui-checked": {
            color: '#0097ff'
        }
    },
    typo: {
        fontSize: '1.2rem',
        color: '#4f5660',
    },
    input: {
        display: 'none',
    },
    titleImgBox: {
        // width: 450,
        // height: 288,
        // overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    imgButton: {
        width: 110,
        fontSize: '1.2rem',
        color: '#fff',
        background: '#1b4a6a',
        padding: '10px 0',
        marginLeft: 18,
        "&:hover": {
            background: '#1b4a6a',
        }
    },
    iconButtonStyle: {
        padding: 0,
        "&:hover": {
            background: 'transparent'
        }
    },
    addButtonStyle: {
        width: 100,
        background: '#a3a8af',
        color: '#fff',
        fontSize: '1.2rem',
        padding: '10px 0',
        boxShadow: 'none',
        "&:hover": {
            background: '#a3a8af',
            boxShadow: 'none',
        },
        "& svg .cls-1": {
            stroke: '#fff'
        }
    },
    switchBox: {
        // switch circle on color
        "& .MuiSwitch-switchBase.Mui-checked > .MuiIconButton-label > .MuiSwitch-thumb": {
            background: '#009AEC',
            boxShadow: '0px 1px 2px rgba(32, 145, 205, 0.5)'
        },
        "& .MuiSwitch-track": {
            opacity: 1,
            background: '#BFBFBF'
        },
        // switch bar on color
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            opacity: 1,
            background: '#BEE5FA'
        },
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.2rem',
            color: '#0d0d0d',
        }
    }
});

@inject('quizStore')
@observer
class MobileQuizAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            answerTitle: '',
            switch: false,
        };
    }

    handleQuizTypeChange = event => {
        this.props.quizStore.changeQuizType(event.target.value);
    };

    handleChangeAnswerTitle = (event) => {
        const strLimit = 500;

        const fixedTitle = event.target.value.length <= strLimit ? event.target.value : event.target.value.substring(0, strLimit - 1);

        this.setState({answerTitle: fixedTitle})
    };

    handleChangeQuizImage = (file) => {
        if (file.files && file.files[0]) {
            const reader = new FileReader();
            reader.onload = event => {
                this.props.quizStore.changeQuizImage(event.target.result, file.files[0]);
            }
            reader.readAsDataURL(file.files[0]);
        }
    };

    handleDeleteQuizImage = (index) => {
        const previewImage = document.getElementById("icon-button-file");
        previewImage.value = "";
        this.props.quizStore.deleteQuizImage(index);
    }

    handleSwitchChange = event => {
        this.setState({switch: event.target.checked});
    };

    handleAddAnswerOptions = () => {
        this.props.quizStore.addAnswerOptions(this.state)
        this.setState({answerTitle: '', switch: false})
    };

    handleDeleteAnswerOption = (index) => {
        this.props.quizStore.deleteAnswerOption(index);
    };

    render() {
        const {classes, quizStore, intl} = this.props;
        const isExistCorrectInSingleAnswer = quizStore.quiz.type === QuizType.SingleChoice && quizStore.quiz.quizBankItems.some(item => item.correct);

        return (
            <div className={classes.root}>
                <RadioGroup name={"type"} value={quizStore.quiz.type} onChange={this.handleQuizTypeChange} row
                            className={classes.radioBox}>
                    <FormControlLabel value={QuizType.SingleChoice}
                                      control={<Radio style={{padding: 0}}/>} label={intl.formatMessage({id: "single_choice"})}/>
                    <FormControlLabel value={QuizType.MultipleChoice}
                                      control={<Radio style={{padding: 0}}/>} label={intl.formatMessage({id: "multiple_choice"})}/>
                    <FormControlLabel value={QuizType.ShortAnswer}
                                      control={<Radio style={{padding: 0}}/>} label={intl.formatMessage({id: "short_answer"})}/>
                    <FormControlLabel value={QuizType.Subjective}
                                      control={<Radio style={{padding: 0}}/>} label={intl.formatMessage({id: "subjective"})}/>
                </RadioGroup>
                <Typography className={clsx(classes.textStyle, classes.typo)}>
                    {quizStore.quiz.type === QuizType.SingleChoice ? intl.formatMessage({id: "msg.quiz_single_guide"})
                        : quizStore.quiz.type === QuizType.MultipleChoice ? intl.formatMessage({id: "msg.quiz_multi_guide"})
                            : quizStore.quiz.type === QuizType.ShortAnswer ? intl.formatMessage({id: "msg.quiz_short_guide"})
                                : quizStore.quiz.type === QuizType.Subjective ? intl.formatMessage({id: "msg.quiz_subjective_guide"})
                                    : ''
                    }
                </Typography>

                <Box display="flex" flexDirection="column" mt={5}>
                    <TextField
                        id="title"
                        label={intl.formatMessage({id: "quiz_name"})}
                        placeholder={intl.formatMessage({id: "msg.input_length_1000"})}
                        fullWidth
                        inputProps={{ maxLength: 1000 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={quizStore.quiz.title}
                        onChange={(e) => quizStore.changeQuizTitle(e.target.value, 1000)}
                    />
                    <Box display='flex' justifyContent='flex-end' mt={1}>
                    <input accept="image/*" className={classes.input} id="icon-button-file" type="file"
                           onChange={(e) => this.handleChangeQuizImage(e.target)}/>
                    <label htmlFor="icon-button-file" >
                        <Button className={clsx(classes.textStyle, classes.imgButton)} component="span"><FormattedMessage id="image"/></Button>
                    </label>
                    </Box>
                </Box>
                <Box display='flex' justifyContent='flex-end' mt={1} mb={3}>
                    <Typography className={clsx(classes.textStyle, classes.typo)}>* <FormattedMessage id="image_max_size"/></Typography>
                </Box>

                {quizStore.quizBankImageUrl.map((imageDataUrl, index) =>
                <Box key={index} display='flex' justifyContent='center' alignItems='flex-start' mb={4}>
                    <Box className={classes.titleImgBox}>
                        {!imageDataUrl.data ?
                            <img src={imageDataUrl} alt="" style={{width: '100%'}}/>
                            :
                            <img src={`data:${quizStore.quiz.quizBankImages.type};base64,${imageDataUrl.data}`} width={"100%"} alt=""/>
                        }
                    </Box>
                    <IconButton className={clsx(classes.textStyle, classes.iconButtonStyle)}
                                onClick={() => this.handleDeleteQuizImage(index)}>
                        <SurveyCloseIcon/>
                    </IconButton>
                </Box>
                )}

                {(quizStore.quiz.type === QuizType.SingleChoice || quizStore.quiz.type === QuizType.MultipleChoice) &&
                <>
                    <Box display="flex" flexDirection="column" mb={4}>
                        <TextField
                            id="title-answer"
                            label={intl.formatMessage({id: "answer_items"})}
                            placeholder={intl.formatMessage({id: "msg.input_length_500"})}
                            fullWidth
                            inputProps={{ maxLength: 500 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={this.state.answerTitle}
                            onChange={this.handleChangeAnswerTitle}
                        />
                        <Box display="flex" justifyContent="flex-end" alignItems='center' mt={1}>
                        {!isExistCorrectInSingleAnswer &&
                        <Box className={classes.switchBox}>
                            <FormControlLabel control={<Switch checked={this.state.switch} size="small"
                                                               onChange={this.handleSwitchChange}/>}
                                              label={this.state.switch ? intl.formatMessage({id: "correct_answer"}) : intl.formatMessage({id: "incorrect_answer"})}
                            />
                        </Box>
                        }
                        <Button variant="contained" className={classes.addButtonStyle}
                                onClick={this.handleAddAnswerOptions} disabled={this.state.answerTitle.trim() === ''}>
                            <PlusIcon style={{marginRight: 5}}/><FormattedMessage id="add"/>
                        </Button>
                        </Box>
                    </Box>

                    <Typography className={clsx(classes.textStyle, classes.typo)} style={{color: '#000'}}><FormattedMessage id="answer_list"/></Typography>

                    {quizStore.quiz.quizBankItems.length > 0 &&
                    quizStore.quiz.quizBankItems.map((item, index) =>
                        <Box key={index} display='flex' justifyContent='space-between' alignItems='center' mt={3}>
                            <Box display='flex' flexDirection='column'>
                                <Typography
                                    className={classes.textStyle}>{intl.formatMessage({id: "survey_option_number"},{number: item.itemId})}-{item.content}</Typography>
                                <Typography className={clsx(classes.textStyle, classes.typo)}
                                            style={item.correct ? {color: '#ff0000'} : {color: '#4f5660'}}>{item.correct ? intl.formatMessage({id: "correct_answer"}) : intl.formatMessage({id: "incorrect_answer"})}</Typography>
                            </Box>
                            <IconButton onClick={() => this.handleDeleteAnswerOption(index)}>
                                <QuizDeleteIcon/>
                            </IconButton>
                        </Box>
                    )
                    }
                </>

                }
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobileQuizAdd));