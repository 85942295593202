import {createMuiTheme} from "@material-ui/core";

export default function configureTheme(configs, images) {
    return createMuiTheme({
        drawerWidth: 240,
        configs: configs,
        images: images,
    });
};

