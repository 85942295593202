import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
} from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import {inject, observer} from "mobx-react";
import * as Store from "../../../../stores/TeamStore";
import dayjs from "dayjs";
import {UI} from "../../../../stores/TeamStore";
import {DATE_UTIL} from "../../../../common/util/date.util";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        padding:'20px 20px',
        background:'#fff',
        height:'100%',
        "& .MuiPaper-elevation1":{
            boxShadow:'none'
        },
        "& .MuiPaper-rounded":{
            borderRadius:0
        },
        "& .MuiTableCell-root":{
            borderBottom:0
        }
    },
    tabletRoot:{
        [theme.breakpoints.down('md')]: {
            "& .MuiTablePagination-caption":{
                fontSize:12
            },
            "& .MuiIconButton-root":{
                padding:0
            }
        },
        padding:'20px 0',
        background:'#fff',
        height:'100%',
        "& .MuiPaper-elevation1":{
            boxShadow:'none'
        },
        "& .MuiPaper-rounded":{
            borderRadius:0
        },
        "& .MuiTableCell-root":{
            borderBottom:0
        }
    },
    paperBox:{
        background:'transparent',
    },
    cellText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#7d86a9',
        fontWeight:600
    },
    tabletCellText:{
        [theme.breakpoints.up('md')]: {
            fontSize:'1.063rem',
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.938rem',
        color:'#7d86a9',
        fontWeight:600
    },
    cellText1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#202020',
        maxWidth: 140,
        background:'#f8f8f8',
        fontWeight:600,
        textOverflow: "ellipsis",
        overflow:"hidden",
        whiteSpace: 'nowrap',
    },
    tabletCellText1:{
        [theme.breakpoints.up('md')]: {
            fontSize:'1.063rem',
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.938rem',
        color:'#202020',
        maxWidth: 90,
        background:'#f8f8f8',
        fontWeight:600,
        textOverflow: "ellipsis",
        overflow:"hidden",
        whiteSpace: 'nowrap',
    },
    tableRowBox:{
        background:'#fff',
        borderBottom:'1px solid rgba(224, 224, 224, 1)'
    },
    table: {
        background:'transparent',
        border:'0'
    },
    tablePageination:{
        '& *': {
            fontFamily: 'NanumSquareRoundOTF!important',
        },
        '& p':{
            fontSize:'1rem',
        },
        fontSize:'0.938rem',
    },
});


@inject('teamStore', 'progressStore')
@observer
class TeamTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teams : [],
        }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {teamStore, progressStore} = this.props;
        progressStore.setIsLoading(teamStore.getIsLoading);
    }
    handleChangePage = (event, newPage) => {
        let selectGroupId = 0;
        if(this.props.teamStore.teamTreeSelectedTeamId && this.props.teamStore.teamTreeSelectedTeamId !== "-1"){
            selectGroupId = this.props.teamStore.teamTreeSelectedTeamId;
        }
        this.props.teamStore.teamTablePage.page = newPage;
        this.props.teamStore.getTeamTableList(selectGroupId);
    }

    handleChangeRowsPerPage = (event) => {
        let selectGroupId = 0;
        if(this.props.teamStore.teamTreeSelectedTeamId && this.props.teamStore.teamTreeSelectedTeamId !== "-1"){
            selectGroupId = this.props.teamStore.teamTreeSelectedTeamId;
        }
        this.props.teamStore.teamTablePage.page = 0;
        this.props.teamStore.teamTablePage.rowsPerPage = parseInt(event.target.value, 10);
        this.props.teamStore.getTeamTableList(selectGroupId);
    }

    handleSetDetailTeam = (teamUser) =>{
        const {intl} = this.props;

        this.props.teamStore.getTeamDetail(intl, teamUser.id);
        this.props.teamStore.changeUiState(UI.Information);
    }

    render() {
        const { classes, intl, isTablet } = this.props;
        const { teamTablePage, teamTableList, teamLoadingState } = this.props.teamStore
        return (
            <div className={isTablet ? classes.tabletRoot : classes.root}>
                <TableContainer component={Paper} className={classes.paperBox}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="team_name"/></TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="member_count"/></TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="team_leader"/></TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="registration_date"/></TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="state"/></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {teamTableList.length > 0 ? (
                                teamTableList.map((teamUser, index) => (
                                <TableRow key={teamUser.id} className={classes.tableRowBox}>

                                    <TableCell component="th"
                                               scope="row"
                                               align="center"
                                               className={isTablet ? classes.tabletCellText1 : classes.cellText1}
                                               style={{color:'#57a4ff',textDecoration:'underline',cursor:'pointer'}}
                                               onClick={() => this.handleSetDetailTeam(teamUser)}>
                                        {teamUser.name}
                                    </TableCell>
                                    <TableCell align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>{teamUser.memeberCount}</TableCell>
                                    <TableCell align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>
                                        {teamUser.teamLeader ? teamUser.teamLeader : "-"}
                                    </TableCell>
                                    <TableCell align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>{dayjs(teamUser.createdDatetime).format(DATE_UTIL.FORMAT.DEFAULT_FORMAT)}</TableCell>
                                    <TableCell align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>{teamUser.enabled === true ? <FormattedMessage id="enabled"/> : <FormattedMessage id="disabled"/>}</TableCell>
                                </TableRow>
                            ))
                                ) :(
                                teamLoadingState === Store.State.Pending ? (
                                        <TableRow className={classes.tableRowBox}>
                                            <TableCell colSpan={5} align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>
                                                <FormattedMessage id="msg.wait"/>
                                            </TableCell>
                                        </TableRow>
                                    ):(
                                    <TableRow className={classes.tableRowBox}>
                                        <TableCell colSpan={5} align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>
                                            <FormattedMessage id="msg.no_data"/>
                                        </TableCell>
                                    </TableRow>
                                    )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    labelRowsPerPage={intl.formatMessage({id: "rows_per_page"})}
                    labelDisplayedRows={({ from, to, count }) => intl.formatMessage({id: "table_bottom_text"}, {"count": count, "from": from, "to": to})}
                    component="div"
                    count={teamTablePage.total}
                    rowsPerPage={teamTablePage.rowsPerPage}
                    page={teamTablePage.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    className={classes.tablePageination}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(TeamTable)));

