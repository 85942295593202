import React from "react";
import {withStyles} from "@material-ui/core/styles";

import {Box, Button, Typography} from "@material-ui/core";
import MainImage from '../../common/images/main_bg.png';
import {inject, observer} from "mobx-react";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import {PATH_UTIL} from "../../common/util/path.util";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import {FormattedMessage, injectIntl} from "react-intl";

const styles = theme => ({
    root:{

    },
    mainBox:{
        backgroundImage: () =>
            theme.images.Main !== undefined
            ? `url(${theme.images.Main})`
                : `url(${MainImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',
        backgroundPosition:'center center',
        width:'100%',
        height:'100vh',
        position:'relative'
    },
    titleBox:{
        display : 'flex',
        paddingTop:'12%',
        paddingLeft:'10%',
        paddingRight:'10%',
        justifyContent : 'space-between',
    },
    tabletTitleBox:{
        [theme.breakpoints.down('sm')]: {
            width : '100%',
            paddingLeft:'5%',
            paddingRight: '5%',
            display: 'flex',
            justifyContent : 'space-between',

        },
        width : '100%',
        position:'absolute',
        bottom:'10%',
        paddingLeft:'10%',
        paddingRight: '10%',
        display : 'flex',
        justifyContent : 'space-between',
    },
    qrBox:{
        width: 'min-content',
    },
    tabletQrBox:{
        [theme.breakpoints.down('sm')]: {
            paddingLeft:'74%',
            paddingRight :'10%',
            paddingTop:'15%',
            bottom:'25%',
        },
        width :'100%',
        position:'absolute',
        bottom:'29%',
        paddingTop:'10%',
        paddingLeft:'80%',
    },
    titleText:{
        [theme.breakpoints.down('sm')]: {
            fontSize:41,
        },
        fontFamily:'Noto Sans KR',
        fontSize:'3.563rem',
        fontWeight:700,
        color: () =>
            theme.configs.MainTextColor ? theme.configs.MainTextColor : '#fff',
        lineHeight:1.32
    },
    subText:{
        [theme.breakpoints.down('sm')]: {
            fontSize:'1.25rem',
        },
        fontFamily:'Noto Sans KR',
        fontSize:'1.313rem',
        color: () =>
            theme.configs.SubTextColor ? theme.configs.SubTextColor : '#fff',
        marginTop:21
    },
    inputBtnStyle: {
        [theme.breakpoints.down('sm')]: {
            // width: 100,
            // height: 24,
            // fontSize : '1rem',
            // color: '#333',
            // background: '#fff',
            // "&:hover": {
            //     color: '#333',
            //     background: '#fff',
            // },
        },
        minWidth: 130,
        // height: 34,
        padding:'9px 22px',
        flexGrow : 0,
        borderRadius: 21,
        color: '#333',
        background: '#fff',
        marginTop : 10,
        "&:hover": {
            background: '#fff',
            color : '#333',
        },
    },
    qrImage : {
        [theme.breakpoints.down('sm')]: {
            width : 100,
            height : 100,
            background : '#000000',
        },
        width : 150,
        height : 150,
        background : '#000000',
    },
    typoBox : {
        // paddingRight: '39%',
    },
    btnInnerText: {
        fontFamily: 'Noto Sans KR',
        fontSize: '1.063rem',
        fontWeight:500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.14px',
        textAlign: 'left',
    },
});

@inject('orgStore', 'qrCodeStore')
@observer
class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isQRCode : true,
        }
    }

    handleInputBtn = () => {
        const { classes, intl } = this.props;
        ToastsStore.info(intl.formatMessage({id: "msg.success_url_copy"}), 1500, classes.toasts);
    }

    handleQRCodeError = (e) => {
        console.log("Failed Get QRCode : >> ", e.target.src);
        this.setState({isQRCode : false});
    }

    render() {
        const { classes, orgStore, qrCodeStore, isTablet } = this.props;
        const { MainText, SubText } = orgStore.orgConfigs
        // const originURL = PATH_UTIL.getOriginURL();
        // const qrURL = `${originURL}${qrCodeStore.serverContextPath}${qrCodeStore.orgQRCodeURL}`;

        return (
            <div className={classes.root}>
                <Box className={classes.mainBox} >
                    <Box className={isTablet ? classes.tabletTitleBox : classes.titleBox}>
                        <Box className={classes.typoBox}>
                            <Typography className={classes.titleText} component={"p"}>
                                {
                                    MainText
                                        ? MainText.split('\n').map((line, index) => {
                                            return (
                                                <span key={index}>
                                                {line}
                                                    <br/>
                                            </span>
                                            )
                                        })
                                        : ""
                                }
                            </Typography>
                            <Typography className={classes.subText}>
                                {
                                    SubText
                                        ? SubText.split('\n').map((line, index) => {
                                            return (
                                                <span key={index}>
                                                {line}
                                                    <br/>
                                            </span>
                                            )
                                        })
                                        : ""
                                }
                            </Typography>
                        </Box>
                        <Box className={classes.qrBox}>
                            {/*{*/}
                            {/*    this.state.isQRCode &&*/}
                            {/*        <img className={classes.qrImage} src={qrURL} alt={"QRCODE"} onError={(e)=>this.handleQRCodeError(e)}/>*/}
                            {/*}*/}
                            <CopyToClipboard text={PATH_UTIL.getOriginURL()} onCopy={() => this.handleInputBtn()}>
                                <Button className={classes.inputBtnStyle}>
                                    <Typography className={classes.btnInnerText}>
                                        <FormattedMessage id="copied_url"/>
                                    </Typography>
                                </Button>
                            </CopyToClipboard>
                            <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground />
                        </Box>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withStyles(styles)(injectIntl(Home));
