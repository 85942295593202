import React, {Component} from 'react';
import {Typography, Grid} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import MobileAttendanceCheck from "./attendanceCheck/MobileAttendanceCheck";
import MobileQuestions from "./questions/MobileQuestions";
import MobileQuizResult from "./quiz/MobileQuizResult";
import MobileMemberActivity from "./memberActivity/MobileMemberActivity";
import MobileConnectStatus from "./ConnectStatus/MobileConnectStatus";
import MobileDevice from "./device/MobileDevice";
import ImagePopupModal from "./memberActivity/ImagePopupModal";
import {inject, observer} from "mobx-react";
import {FormattedMessage} from "react-intl";
import MobileStatisticsSurveyResult from "./survey/MobileStatisticsSurveyResult";
import {withRouter} from "react-router-dom";

const style = theme => ({
    root:{
        padding:'70px 0 20px',
        width: '100%',
        '& > div':{
            padding:'20px 20px 40px 20px',
            '& > div > p':{
                marginBottom:20
            }
        },
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:800,
        color:'#0d0d0d',
        marginBottom:25,
        padding:'0 20px',
    },
    borderBottomStyle:{
        borderBottom:'2px solid #e2ebf1',
        paddingBottom:30,
        marginBottom:30
    }
});

@inject('statisticsStore', 'topBarStore', 'progressStore')
@observer
class MobileStatistics extends Component {
    componentDidMount() {
        const { topBarStore, statisticsStore, progressStore } = this.props;

        statisticsStore.getStatistics(this.props.match.params.id);
        if (window.location.pathname === `/rooms/${this.props.match.params.id}/statistics`) {
            topBarStore.setIsSearchBarRender(false);
        }
        progressStore.setIsLoading(statisticsStore.getIsLoading);
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}><FormattedMessage id="statistics_status"/></Typography>
                <Grid item xs={12} className={classes.borderBottomStyle}>
                    <MobileAttendanceCheck />
                </Grid>
                <Grid item xs={12} className={classes.borderBottomStyle}>
                    <MobileQuestions />
                </Grid>

                <Grid item xs={12} className={classes.borderBottomStyle}>
                    <MobileQuizResult />
                </Grid>
                <Grid item xs={12} className={classes.borderBottomStyle}>
                    <MobileStatisticsSurveyResult />
                </Grid>
                <Grid item xs={12} className={classes.borderBottomStyle}>
                    <MobileConnectStatus />
                </Grid>
                <Grid item xs={12} className={classes.borderBottomStyle}>
                    <MobileDevice />
                </Grid>
                <Grid item xs={12} style={{marginBottom:40}}>
                    <MobileMemberActivity />
                </Grid>
                <ImagePopupModal />
            </div>
        );
    };
}

export default withRouter(withStyles(style)(MobileStatistics));