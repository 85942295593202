import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {
    Button,
    Typography,
    Box,
    Dialog, Slide, MenuList, MenuItem, Popover, LinearProgress
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";
import MobileParagraph from "./paragraph/MobileParagraph";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {ReactComponent as SurveyDragIcon} from "../../../../common/images/SurveyDragIcon.svg";
import MobileSurveyQuestion from "./surveyQuestion/MobileSurveyQuestion";
import MobileParagraphAdd from "./paragraphAdd/MobileParagraphAdd";
import {ReactComponent as PollCreatePlus} from "../../../../common/images/PollCreatePlus.svg";
import {ReactComponent as TopIcon} from "../../../../common/images/TopIcon.svg";
import MobileSurveyPreview from "./MobileSurveyPreview";
import {SurveyItemType} from "../../../../stores/SurveyStore";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import MobileDeleteDialog from "../../../../common/MobileDeleteDialog";


const style = theme => ({
    root: {
    },
    saveBtn:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover":{
            background:'transparent'
        }
    },
    textStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        "& .MuiMenuItem-root":{
            minHeight:0
        }
    },
    typoStyle1:{
        fontSize: '1rem',
        color: '#a3a8af',
        fontWeight:500
    },
    btnStyle:{
        color:'#0097ff',
        fontSize: '1rem',
        textDecoration:'underline',
        fontWeight:500,
        minWidth:0,
        "&:hover":{
            background:'transparent',
            textDecoration:'underline',
        }
    },
    popoverBox:{
        "& .MuiPopover-paper":{
            borderRadius:7,
            boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        },
        "& .MuiMenuItem-root":{
            minHeight:0
        }
    },
    menuText:{
        fontSize: '1.2rem',
        color:'#000',
        fontWeight:500,
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        "&:hover":{
            background:'#d0d0d0'
        }
    },
    surveyQuestion:{
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width: '100%',
        marginBottom: 40
    },
    surveyQuestionContentsBox:{
        width:'100%',
        border: '1px solid #c4c9de',
        borderRadius: 5,
        padding:'11px 14px 23px',
        "&:hover":{
            border: '1px solid #0097ff !important',
            borderTop: '4px solid #0097ff !important',
        },
    },
    questionBtn:{
        background:'rgba(20, 0, 254, 0.5)',
        borderRadius: 5,
        padding:'5px 16px',
        color:'#fff',
        fontSize: '1.15rem',
        fontWeight:300,
        marginRight:16,
        "&:hover":{
            background:'rgba(20, 0, 254, 0.5)',
        }
    },
    paragraphBtn:{
        background:'transparent',
        border:'1px solid #8a80fe',
        borderRadius: 5,
        padding:'5px 16px',
        fontSize: '1.15rem',
        color:'#1400fe',
        fontWeight:300,
        marginRight:16,
        "&:hover":{
            background:'transparent',
        }
    },
    plusIcon:{
        "& path":{
            fill:'#1400FE'
        }
    },
    cancelBtn:{
        width:110,
        padding: '5px 0',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        fontWeight: 300,
        color: '#fff',
        borderRadius: 7,
        background: '#a3a8af',
        marginRight: 13,
        "&:hover": {
            background: '#a3a8af'
        }
    },
    topButtonStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        color:'#000',
        marginTop:15
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref}/>
));

@inject('authStore', 'surveyStore')
@observer
class MobileSurveyCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            selectedSurveyQuestionIndex: -1,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedSurveyQuestionIndex !== this.state.selectedSurveyQuestionIndex) {
            this.props.surveyStore.surveyItemDataList.forEach((v, i) => {
                let el = document.getElementById(`surveyQuestionBox-${i}`);
                if (i !== this.state.selectedSurveyQuestionIndex) {
                    el.setAttribute("border", '1px solid #c4c9de');
                    el.setAttribute("border-top", '1px solid #c4c9de');
                    el.style.border = '1px solid #c4c9de';
                    el.style.borderTop = '1px solid #c4c9de';
                }
            });
        }
    };

    componentWillUnmount() {
        this.props.surveyStore.mobileSurveyDialogClose();
    }

    initSelectedSurveyQuestionIndex = () => this.setState({selectedSurveyQuestionIndex: -1});

    setSelectedSurveyQuestionIndex = (e, selectedSurveyQuestionIndex) => {
        e.stopPropagation();
        this.setState({selectedSurveyQuestionIndex: selectedSurveyQuestionIndex});
    };

    handleChangeDialogOpen = () => {
        this.props.surveyStore.mobileSurveyDialogOpen();
        this.props.surveyStore.initSurvey(this.props.authStore.loginUser);
    };

    handlePopoverClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleAddSurveyItem = () => {
        this.props.surveyStore.addSurveyItems();
    };

    handleAddSurveyParagraph = () => {
        this.props.surveyStore.addSurveyItems(SurveyItemType.Context);
    };

    handleMoveHistory = (roomId) => {
        this.props.history.push(`/rooms/${roomId}/statistics`)
    };

    handleDeleteItem = () => {
        this.props.surveyStore.deleteSurveyItems(this.state.selectedSurveyQuestionIndex);
    };

    render() {
        const { classes, surveyStore, intl, history } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const { isSurveyLoading } = this.props.surveyStore;
        return (
            <div className={classes.root} >
                <Dialog
                    fullScreen
                    open={surveyStore.mobileSurveyDialog}
                    onClose={this.handlePopoverClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox} id="mobileSurveyCreateTop">
                        <Typography className={classes.textStyle}><FormattedMessage
                            id="new_survey"/></Typography>
                            {surveyStore.surveyHistory.length > 0 &&
                        <Box display='flex' alignItems='center'>
                            <Typography className={clsx(classes.textStyle, classes.typoStyle1)}><FormattedMessage
                                id="usage_history"/></Typography>
                            <Button
                                aria-owns={open ? 'simple-popper' : undefined}
                                onClick={this.handlePopoverClick}
                                className={clsx(classes.textStyle,classes.btnStyle)}
                            >
                                {surveyStore.surveyHistory.length}
                            </Button>
                            <Popover
                                id="simple-popper"
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                className={classes.popoverBox}
                            >
                                <MenuList>
                                    {surveyStore.surveyHistory.map(h => <MenuItem key={h.historyId}
                                                                                  className={clsx(classes.textStyle, classes.menuText)} onClick={() => this.handleMoveHistory(h.roomId)}>{h.roomName}</MenuItem>)}
                                </MenuList>
                            </Popover>
                        </Box>
                                }
                    </Box>
                    {isSurveyLoading && <LinearProgress />}
                    <Box pl={3} pr={3} pt={4} pb={3}>
                        <MobileParagraph surveyQuestionInit={this.initSelectedSurveyQuestionIndex}/>

                        {surveyStore.surveyItemDataList.length > 0 &&
                            <DragDropContext
                                onDragEnd={result => {
                                    if (result.type !== 'Survey') {
                                        return false;
                                    } else if (!result.destination) {
                                        return false;
                                    } else {
                                        const index = parseInt(result.draggableId.split('-')[1]);
                                        this.initSelectedSurveyQuestionIndex();
                                        surveyStore.changeItemIndex(index, result.destination.index);
                                    }
                                }}
                            >
                                <Droppable
                                    droppableId="surveyQuestionDroppable"
                                    type="Survey"
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={{backgroundColor: snapshot.isDraggingOver ? '#fff' : '#fff'}}
                                        >

                                            {surveyStore.surveyItemDataList.map((surveyQuestion, i) => (

                                                <Draggable
                                                    key={`surveyQuestionDraggableKey-${i}`}
                                                    draggableId={`surveyQuestionDraggableId-${i}`}
                                                    index={i}
                                                >
                                                    {(p) => (
                                                        <div
                                                            ref={p.innerRef}
                                                            {...p.draggableProps}
                                                            style={
                                                                p.draggableProps.style
                                                            }
                                                        >
                                                            <div className={classes.surveyQuestion}>
                                                                <Box id={`surveyQuestionBox-${i}`}
                                                                     className={classes.surveyQuestionContentsBox}
                                                                     onClick={e => {
                                                                         this.setSelectedSurveyQuestionIndex(e, i);

                                                                         const el = document.getElementById(`surveyQuestionBox-${i}`);
                                                                         el.setAttribute("border", '1px solid #0097ff');
                                                                         el.setAttribute("border-top", '4px solid #0097ff');
                                                                         el.style.border = '1px solid #0097ff';
                                                                         el.style.borderTop = '4px solid #0097ff';
                                                                     }}>
                                                                    <Box display={i === this.state.selectedSurveyQuestionIndex ? 'flex' : 'none'} justifyContent='center'>
                                                                            <span {...p.dragHandleProps}>
                                                                                <SurveyDragIcon
                                                                                    style={{cursor: 'pointer'}}/>
                                                                            </span>
                                                                    </Box>
                                                                    {surveyQuestion.type !== SurveyItemType.Context ?
                                                                        <MobileSurveyQuestion surveyData={surveyQuestion}
                                                                            surveyQuestionId={i}
                                                                        />
                                                                        :
                                                                        <MobileParagraphAdd surveyData={surveyQuestion} surveyQuestionId={i}/>
                                                                    }
                                                                </Box>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>

                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        }

                        <Box display="flex" alignItems='center' mb={1} onClick={this.initSelectedSurveyQuestionIndex}>
                            <Button className={clsx(classes.textStyle, classes.questionBtn)} onClick={this.handleAddSurveyItem}><PollCreatePlus style={{marginRight:8}}/><FormattedMessage id="add_question"/></Button>
                            <Button className={clsx(classes.textStyle, classes.paragraphBtn)} onClick={this.handleAddSurveyParagraph}><PollCreatePlus className={classes.plusIcon} style={{marginRight:8}}/><FormattedMessage id="add_paragraph"/></Button>
                        </Box>

                        <Box display="flex" justifyContent='center' alignItems='center' mt={5}>
                            <Button className={classes.cancelBtn} onClick={() => surveyStore.mobileSurveyDialogClose()}><FormattedMessage id="cancel"/></Button>
                            <MobileSurveyPreview history={history}/>
                        </Box>
                        <Box display="flex" justifyContent='center'>
                            <Button startIcon={<TopIcon/>}
                                    className={classes.topButtonStyle}
                                    onClick={() => {
                                        const topEl = document.getElementById("mobileSurveyCreateTop");
                                        topEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                                    }}
                            >
                                <FormattedMessage id="go_to_the_top"/>
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                <MobileDeleteDialog open={surveyStore.openDeleteItemDialog}
                                    title={intl.formatMessage({id: "delete"})}
                                    msg={intl.formatMessage({id: "msg.check_delete"})}
                                    onClose={surveyStore.changeOpeDeleteItemDialog}
                                    submit={this.handleDeleteItem}
                />
                <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground />
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(MobileSurveyCreate));