import React from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {Box, Typography, Button} from "@material-ui/core";
import MobileMainBg from '../../common/images/mobile_main_bg.png';
import {ReactComponent as MobileCaretRight} from "../../common/images/MobileCaretRight.svg";
import {inject, observer} from "mobx-react";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {PATH_UTIL} from "../../common/util/path.util";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../common/util/color.util";

const styles = theme => ({
    root: {},
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    mainBack: {
        position:'relative',
        backgroundImage: () => theme.images.Main ? `url(${theme.images.Main})` :`url(${MobileMainBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: '100%',
        height: '100vh',

    },
    mainBox: {
        position: 'absolute',
        bottom: '20%',
        paddingLeft: 20,
        paddingRight: 20
    },
    titleText: {
        fontFamily: 'Noto Sans KR',
        fontSize: '2.8rem',
        fontWeight: 700,
        color: '#fff',
        lineHeight: 1.32
    },
    subText: {
        fontFamily: 'Noto Sans KR',
        fontSize: '1.1rem',
        color: '#fff',
        marginTop: 21
    },
    buttonStyle:{
        fontFamily:'Noto Sans KR',
        fontSize:'1.5rem',
        // color:'#fff',
        // background: () => theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor,
        background: '#00ACE7',
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor)
            return border;
        },
        width:229,
        padding:'10px 18px',
        display:'flex',
        justifyContent:'space-between',
        marginTop:25,
        fontWeight:600,
    },
    inputBtnStyle: {
        minWidth:110,
        padding:'9px 22px',
        flexGrow : 0,
        borderRadius: 21,
        color: '#333',
        background: '#fff',
        marginTop : 40,
        marginLeft: 18,
        "&:hover": {
            background: '#ffe4e3',
            color: '#dc524f',
        },
    },
    btnInnerText: {
        fontFamily: 'Noto Sans KR',
        fontSize: '1.0rem',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: '-0.12px',
        textAlign: 'left',
    },
});

@inject('authStore', 'orgStore')
@observer
class MobileHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogoDisplay: true,
        }
    }

    timeout = null;

    componentDidMount() {
        this.timeout = setTimeout(() => this.setState({ isLogoDisplay: false}), 3000);
    }

    componentWillUnmount() {
        this.setState({isLogoDisplay : false});
        clearTimeout(this.timeout);
    }

    handleClickOpen = () => {
        this.props.authStore.setLoginModal();
    };

    handleInputBtn = () => {
        const { classes, intl } = this.props;
        ToastsStore.info(intl.formatMessage({id: "msg.success_url_copy"}), 1500, classes.toasts);
    }

    render() {
        const { classes, orgStore } = this.props;
        const { MainText, SubText } = orgStore.orgConfigs;
        const { Logo } = orgStore.orgImages;

        return (
            <div className={classes.root}>
                {this.state.isLogoDisplay ?
                    <Box display='flex' flexDirection='column' justifyContent='center' style={{height:'100vh'}}>
                        <Box style={{margin:'0 auto', width:230}}>
                            <img src={Logo} alt={"logo"} style={{margin:'0 auto', width:'100%'}}/>
                        </Box>
                    </Box>

                    :
                    <div className={classes.mainBack} >
                        <CopyToClipboard text={PATH_UTIL.getOriginURL()} onCopy={()=>this.handleInputBtn()}>
                            <Button className={classes.inputBtnStyle}>
                                <Typography className={classes.btnInnerText}>
                                    <FormattedMessage id={"copied_url"} />
                                </Typography>
                            </Button>
                        </CopyToClipboard>
                        <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground />
                        <Box className={classes.mainBox}>
                            <Typography className={classes.titleText} component={"p"}>
                                {
                                    MainText
                                    ? MainText.split(`\n`).map((line, index) => {
                                        return (
                                            <span key={index}>
                                                {line}
                                                <br/>
                                            </span>
                                        )
                                        })
                                    : ""
                                }
                                {/*온택트시대,<br/>*/}
                                {/*안전하고 편리한<br/>*/}
                                {/*화상 서비스 플랫폼<br/>*/}

                            </Typography>
                            <Typography className={classes.subText}>
                                {
                                    SubText
                                    ? SubText.split(`\n`).map((line, index) => {
                                        return (
                                            <span key={index}>
                                                {line}
                                                <br />
                                            </span>
                                        )
                                        })
                                    : ""
                                }
                                {/*쌍방향 화상회의와 관리형 교육이 가능한 탁월한 플랫폼*/}
                            </Typography>
                            <Link to="/mobilesignin" className={classes.link} >
                                <Button onClick={this.handleClickOpen} className={classes.buttonStyle} >
                                    <FormattedMessage id={"sign_in"} />
                                    <MobileCaretRight/></Button>
                            </Link>
                        </Box>

                    </div>
                }
            </div>
        );
    }
};

export default withRouter(withStyles(styles)(injectIntl(MobileHome)));
