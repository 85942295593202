import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    Badge,
    Avatar,
} from "@material-ui/core";
import {COLOR_UTIL} from "../../../../common/util/color.util";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiBadge-dot":{
            minWidth:5,
            height:5
        },
        "& .MuiBadge-colorPrimary":{
            background:'#00c880',
        },
    },
    userButton:{
        minWidth:20,
        padding:0,
        "&:hover":{
            background:'transparent'
        },
    },
    avatarStyle1:{
        width:15,
        height:15,
        // border:'1px solid #fff',
        background : (props) => props.subColor ? props.subColor : theme.configs.SubColor,
        border: (props) => {
            let border = COLOR_UTIL.getSwitchBorderColor(props.subColor ? props.subColor : theme.configs.SubColor);
            return border;
        },
        color: (props) => {
            let color = COLOR_UTIL.getSwitchFontColor(props.subColor ? props.subColor : theme.configs.SubColor);
            return color;
        },
    },
}));


export default function PreviewUserInformation(props) {
    const classes = useStyles(props);
    const anchorRef = React.useRef(null);

    return (
        <div className={classes.root}>
            <Button
                ref={anchorRef}
                aria-haspopup="true"
                className={classes.userButton}
            >
                 {/*온라인일경우*/}
                <Badge
                    color='primary'
                    overlap="circle"
                    badgeContent=" "
                    variant="dot"
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                >
                    <Avatar
                        className={classes.avatarStyle1}
                        alt="userImg"
                    />
                </Badge>
            </Button>
        </div>
    );
}