import React, {Component} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {withStyles} from "@material-ui/core/styles";
import {Typography, MobileStepper, Box, Button, Grid, Select, MenuItem, FormControl} from "@material-ui/core";
import {ReactComponent as ArrowIcon} from "../../../common/images/ArrowIcon.svg";
import StatisticsSurveyItemChart from "./StatisticsSurveyItemChart";
import {inject, observer} from "mobx-react";
import StatisticsSurveyItem from "./StatisticsSurveyItem";
import {ReactComponent as ProhibitIcon} from "../../../common/images/ProhibitIcon.svg";
import {FormattedMessage, injectIntl} from "react-intl";
import DownloadIcon from "@material-ui/icons/GetApp";

const style = theme => ({
    root:{
        marginLeft:10,
        "& .MuiButton-root.Mui-disabled":{
            "& path":{
                fill:'#a3a8af'
            },
        }
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.25rem',
        fontWeight:600,
        color:'#0d0d0d',
        marginBottom:30
    },
    textStyle1:{
        fontSize:'1.125rem',
        fontWeight:500,
    },
    textStyle2:{
        fontSize:'1.063rem',
        fontWeight:300,
        color:'#5a6069',
        marginTop:20,
        marginBottom: 40,
    },
    mobileStepper:{
        width:300,
        margin:'0 auto',
        background:'transparent'
    },
    formControlBox: {
        background: '#fff',
        border: 0,
        borderRadius: 5,
        "& .MuiSelect-select": {
            background: 'transparent',
        },
        "& .MuiOutlinedInput-input": {
            padding: '11px 6px',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #b7bcd6',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #b7bcd6',
        }
    },
    selectBox: {
        width: 185,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#000',
        fontWeight: 500,
        paddingRight:25,
        marginBottom:0
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#404040',
        fontWeight: 500,
        marginBottom:0,
        "&:hover":{
            background:'#e6e7e8'
        }
    },
    menuList: {
        padding:0,
    },
    imageText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.375rem',
        fontWeight:800,
        color:'#0d0d0d'
    },
    noDataBox:{
        textAlign:'center',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate( -50%, -50% )'
    },
    downloadBtn:{
        marginTop: 10,
        marginLeft: 16,
        cursor: 'pointer',
    }
});

@inject('statisticsStore')
@observer
class StatisticsSurveyResult extends Component {
    handleNext = () => {
        const {statisticsStore} = this.props;
        statisticsStore.nextSelectedSurveyItemIndex();
    };

    handleBack = () => {
        const {statisticsStore} = this.props;
        statisticsStore.backSelectedSurveyItemIndex();
    };

    handleDownloadExcel = () => {
        const {statisticsStore} = this.props;
        statisticsStore.downloadSurveyExcel();
    }

    render() {
        const { classes, theme, intl, statisticsStore } = this.props;
        const { selectedSurvey, selectedSurveyItemIndex } = this.props.statisticsStore;
        const maxSteps = statisticsStore.contextFilteredItemList.length;

        return (
            <div className={classes.root}>
                <Box display='flex' alignItems='flex-start' justifyContent='space-between'>
                    <Typography className={classes.textStyle}><FormattedMessage id="survey_response_status"/></Typography>
                    {(statisticsStore.surveyList.length !== 0 && !statisticsStore.getIsLoading) &&
                        <div>
                            <FormControl variant={'outlined'} className={classes.formControlBox}>
                                <Select
                                    value={selectedSurvey}
                                    onChange={(event) => statisticsStore.changeSelectedSurvey(event.target.value)}
                                    className={clsx(classes.textStyle, classes.selectBox)}
                                    MenuProps={{
                                        classes: {
                                            list: classes.menuList
                                        }
                                    }}
                                >
                                    {
                                        statisticsStore.surveyList.map((survey, i) => (
                                            <MenuItem key={i} value={survey} className={clsx(classes.textStyle, classes.optionStyle)}>{survey.title}</MenuItem >
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <DownloadIcon className={classes.downloadBtn} onClick={this.handleDownloadExcel}/>
                        </div>
                    }
                </Box>
                {(statisticsStore.surveyList.length === 0 && !statisticsStore.getIsLoading) &&
                    <div style={{position: "relative", width: "100%"}}>
                        <img style={{width: "100%", height: "100%"}} src="/images/NoData_SurveyResult.png" alt="No Data"/>
                        <div className={classes.noDataBox}>
                            <ProhibitIcon />
                            <Typography className={classes.imageText}><FormattedMessage id="msg.no_display_data"/></Typography>
                        </div>
                    </div>
                }
                {statisticsStore.surveyList.length > 0 &&
                    <>
                        <div>
                            <Box display='flex'>
                                <Grid container>
                                    <Grid item xs={6}>
                                        {statisticsStore.selectedSurveyItem &&
                                            <StatisticsSurveyItem />
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        {(statisticsStore.getSurveyItemAnswerList(intl).length > 0 || statisticsStore.getSurveyShortAnswerList.length > 0) &&
                                            <StatisticsSurveyItemChart />
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            variant="text"
                            activeStep={selectedSurveyItemIndex}
                            className={classes.mobileStepper}
                            nextButton={
                                <Button size="small" onClick={this.handleNext} disabled={selectedSurveyItemIndex === maxSteps - 1}>
                                    {theme.direction === 'rtl' ? <ArrowIcon style={{transform: 'rotate(180deg)',width:32,height:32}}/> : <ArrowIcon/>}
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={this.handleBack} disabled={selectedSurveyItemIndex === 0}>
                                    {theme.direction === 'rtl' ? <ArrowIcon/> : <ArrowIcon style={{transform: 'rotate(180deg)',width:32,height:32}}/>}
                                </Button>
                            }
                        />
                    </>
                }
            </div>
        );
    }
}

StatisticsSurveyResult.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(style, { withTheme: true })(injectIntl(StatisticsSurveyResult));