import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {
    Button,
    Typography,
    Box,
    Dialog, Slide, MenuList, MenuItem, Popover, LinearProgress
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as TopIcon} from "../../../../common/images/TopIcon.svg";
import MobileQuizPreview from "./MobileQuizPreview";
import MobileQuizAdd from "./MobileQuizAdd";
import MobileConfirmDialog from "../../../../common/MobileConfirmDialog";


const style = theme => ({
    textStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        "& .MuiMenuItem-root":{
            minHeight:0
        }
    },
    typoStyle1:{
        fontSize: '1rem',
        color: '#a3a8af',
        fontWeight:500
    },
    btnStyle:{
        color:'#0097ff',
        fontSize: '1rem',
        textDecoration:'underline',
        fontWeight:500,
        minWidth:0,
        "&:hover":{
            background:'transparent',
            textDecoration:'underline',
        }
    },
    popoverBox:{
        "& .MuiPopover-paper":{
            borderRadius:7,
            boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
        },
        "& .MuiMenuItem-root":{
            minHeight:0
        }
    },
    menuText:{
        fontSize: '1.2rem',
        color:'#000',
        fontWeight:500,
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        "&:hover":{
            background:'#d0d0d0'
        }
    },
    cancelBtn:{
        width:110,
        padding: '5px 0',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        fontWeight: 300,
        color: '#fff',
        borderRadius: 7,
        background: '#a3a8af',
        marginRight: 13,
        "&:hover": {
            background: '#a3a8af'
        }
    },
    topButtonStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        color:'#000',
        marginTop:15
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref}/>
));

@inject('authStore', 'quizStore')
@observer
class MobileQuizCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    componentWillUnmount() {
        this.props.quizStore.mobileQuizDialogClose();
    };

    handlePopoverClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleMoveHistory = (roomId) => {
        this.props.history.push(`/rooms/${roomId}/statistics`)
    };

    render() {
        const { classes, quizStore, intl, history } = this.props;
        const { isQuizLoading } = quizStore;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <div className={classes.root} >
                <Dialog
                    fullScreen
                    open={quizStore.mobileQuizDialog}
                    onClose={this.handlePopoverClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox} id="mobileQuizCreateTop">
                        <Typography className={classes.textStyle}><FormattedMessage
                            id="new_quiz"/></Typography>
                            {quizStore.quizHistory.length > 0 &&
                        <Box display='flex' alignItems='center'>
                            <Typography className={clsx(classes.textStyle, classes.typoStyle1)}><FormattedMessage
                                id="usage_history"/></Typography>
                            <Button
                                aria-owns={open ? 'simple-popper' : undefined}
                                onClick={this.handlePopoverClick}
                                className={clsx(classes.textStyle,classes.btnStyle)}
                            >
                                {quizStore.quizHistory.length}
                            </Button>
                            <Popover
                                id="simple-popper"
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handlePopoverClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                className={classes.popoverBox}
                            >
                                <MenuList>
                                    {quizStore.quizHistory.map(h => <MenuItem key={h.historyId}
                                                                                  className={clsx(classes.textStyle, classes.menuText)} onClick={() => this.handleMoveHistory(h.roomId)}>{h.roomName}</MenuItem>)}
                                </MenuList>
                            </Popover>
                        </Box>
                                }
                    </Box>
                    {isQuizLoading && <LinearProgress />}
                    <Box pl={3} pr={3} pt={4} pb={3}>
                        <MobileQuizAdd />

                        <Box display="flex" justifyContent='center' alignItems='center' mt={5}>
                            <Button className={classes.cancelBtn} onClick={() => quizStore.mobileQuizDialogClose()}><FormattedMessage id="cancel"/></Button>
                            <MobileQuizPreview history={history}/>
                        </Box>
                        <Box display="flex" justifyContent='center'>
                            <Button startIcon={<TopIcon/>}
                                    className={classes.topButtonStyle}
                                    onClick={() => {
                                        const topEl = document.getElementById("mobileQuizCreateTop");
                                        topEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                                    }}
                            >
                                <FormattedMessage id="go_to_the_top"/>
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
                <MobileConfirmDialog open={quizStore.openConfirmDialog}
                               title={intl.formatMessage({id: "msg.input_incorrect"})}
                               msg={quizStore.errMsg}
                               changeDialogOpen={quizStore.changeConfirmDialogOpen}
                />
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(MobileQuizCreate));