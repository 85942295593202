import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Popper,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Box,
    TextareaAutosize
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import { AuthorityType } from "../../../stores/UserLoungeStore"
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../../common/util/color.util";


const style = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding:0,
        maxWidth: 450,
        overflow: 'auto',
        boxShadow:'0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        borderRadius:10,
        "& .MuiTypography-h6":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1.125rem',
            color:'#000',
            fontWeight:600
        },
        "& .MuiDialogTitle-root":{
            padding:'20px 20px 10px',

        },
        "& .MuiDialogContent-root":{
            padding:'0px 20px',
        },
        "& .MuiDialogContentText-root":{
            marginBottom:0
        },
        "& .MuiDialogActions-root":{
            padding:'8px 20px'
        }
    },
    buttonText:{
        border:'1px solid #bfbfbf',
        fontFamily:'NanumSquareRoundOTF',
        color:'#1f1f1f',
        fontSize:'0.938rem',
        borderRadius:4,
        padding:'5px 12px',
        "&:hover":{
            background:'transparent'
        }
    },
    popper: {
        zIndex: 9999,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    inputTitleStyle:{
        width:'100%',
        padding:10,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#303030',
        borderRadius:5,
        fontWeight: 600,
        border:'1px solid #D3D7DB',
        "&::placeholder":{
            color:'#a3a8af',
        },
        "&:focus":{
            outline:'none',
        },
    },
    dialogContentText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#000',
        fontWeight:600,
        marginBottom:5
    },
    textareaStyle:{
        width:380,
        padding:10,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#303030',
        resize:'none',
        border:'1px solid #D3D7DB',
        borderRadius:5,
        "&::placeholder":{
            color:'#a3a8af',
            fontWeight:300
        },
        "&:focus":{
            outline:'none',
        },
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    btnStyle:{
        width:70,
        padding:'5px 0',
        background: () => theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.875rem',
        fontWeight:600,
        // color:'#fff',
        "&:hover":{
            background:'#0097ff'
        }
    },
    btnStyle1:{
        width:70,
        padding:'5px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.875rem',
        fontWeight:600,
        color:'#fff',
        marginRight:10,
        "&:hover":{
            background:'#a3a8af'
        }
    },
});

@inject('authStore', 'userLoungeStore')
@observer
class Announcement extends Component {
    state = {
        arrow: true,
        arrowRef: null,
        disablePortal: false,
        flip: true,
        open: false,
        placement: 'right-start',
    };

    handleClickButton = () => {
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = (event) => {
        const { userLoungeStore } = this.props;
        userLoungeStore.resetNotice();
        this.setState({
            open: false,
        });
    };

    handleSave = (event) => {
        const { authStore, userLoungeStore } = this.props;
        userLoungeStore.addTeamNotice(authStore.loginUser.id);
        this.setState({
            open: false,
        });
    };

    render() {
        const { classes, intl, userLoungeStore } = this.props;
        const { open, placement, disablePortal, arrow, arrowRef } = this.state;

        return (
            <div className={classes.root} >
                <Button
                    buttonRef={node => {this.anchorEl = node;}}
                    onClick={this.handleClickButton}
                    className={classes.buttonText}
                    disabled={!userLoungeStore.authorityList.find(a => a.authority === AuthorityType.CreateNotice) && userLoungeStore.userType !== 'Leader'}
                >
                    <FormattedMessage id="registration"/>
                </Button>
                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    placement={placement}
                    disablePortal={disablePortal}
                    className={classes.popper}
                    modifiers={{
                        flip: {
                            enabled: true,
                        },
                        arrow: {
                            enabled: arrow,
                            element: arrowRef,
                        },
                        preventOverflow: {
                            enabled: true,
                            boundariesElement:'scrollParent',
                        },
                    }}

                >
                    {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                    <Paper className={classes.paper}>
                        <DialogTitle>
                            <input
                                name="title"
                                type="text"
                                id="title"
                                maxLength="100"
                                placeholder={intl.formatMessage({id: "msg.insert_subject"})}
                                onChange={(event) => userLoungeStore.changeNoticeTitle(event.target.value)}
                                className={classes.inputTitleStyle}
                            />
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component='div'>
                                <Typography className={classes.dialogContentText}><FormattedMessage id="contents"/></Typography>
                                <TextareaAutosize
                                    rows={9}
                                    rowsMax={9}
                                    maxLength="500"
                                    aria-label="maximum height"
                                    placeholder={intl.formatMessage({id: "msg.insert_contents"})}
                                    onChange={(event) => userLoungeStore.changeNoticeContent(event.target.value)}
                                    maxLength={500}
                                    className={classes.textareaStyle}
                                />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Box display="flex" justifyContent='flex-end'>
                                <Button className={classes.btnStyle1} onClick={this.handleClose}><FormattedMessage id="cancel"/></Button>
                                <Button className={classes.btnStyle} onClick={this.handleSave}><FormattedMessage id="registration"/></Button>
                            </Box>
                        </DialogActions>
                    </Paper>
                </Popper>
            </div>
        );
    }
};

export default withStyles(style)(injectIntl(Announcement));