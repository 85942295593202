import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Box, Button, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core";
import MoreButton from "./TimelineMoreButton"
import {ReactComponent as SmileyIcon} from "../../../common/images/SmileyIcon.svg";
import {ReactComponent as ClockIcon} from "../../../common/images/ClockIcon.svg";
import TeamMembersSideBar from "../../../components/TeamMembersSideBar";
import {DATE_UTIL} from "../../../common/util/date.util";
import {inject, observer} from "mobx-react";
import * as dayjs from "dayjs";
import {UTIL} from "../../../common/util/common.util";
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../../common/util/color.util";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding:0,
        width: 450,
        overflow: 'auto',
        boxShadow:'0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        // border:'1px solid #cdced0',
        borderRadius:10,
        "& .MuiTypography-h6":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1.125rem',
            color:'#000',
            fontWeight:600
        },
        "& .MuiDialogTitle-root":{
            padding:0,
            margin:'10px 20px 0',
        },
        "& .MuiDialogContent-root":{
            padding:'10px 20px 10px',
            textAlign:'justify'
        }
    },
    dialogTitleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#000',
        fontWeight: 600,
        marginBottom: 10,
        marginTop:10
    },
    textareaBox:{
        width:'100%',
        maxHeight:200,
        paddingRight:10,
        overflow:'auto',
        marginBottom:14,
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#303030',
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#0d0d0d',
        fontWeight:300,
        marginLeft:3
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.875rem',
        color:'#0d0d0d',
        fontWeight:300,
        marginLeft:3
    },
    learningIcon:{
        width:25,
        height:25,
        "& path":{
            fill:'#664cff',
        },
    },
    buttonStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '0.875rem',
        paddingBottom:4,
        paddingLeft: 4,
        // borderBottom: '1px solid #c0c2c3',
        backgroundColor: 'rgba(0,0,0,0)',
        color: 'black',
        textDecoration:'underline',
        textDecorationColor: '#c0c2c3'
    },
    TabletDialogBox: {
        fontFamily:'NanumSquareRoundOTF',
        "& .MuiDialog-paper": {
            borderRadius: 10,
            padding: '60px 10px 14px 10px'
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialogTitle-root": {
            padding: '0 60px'
        }
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        textAlign:'center',
        whiteSpace:'pre'
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.938rem',
        fontWeight: 500,
        color: '#0d0d0d',
        textAlign: 'left',
        marginBottom: 36
    },
    btnStyle:{
        width: 68,
        height: 30,
        padding:'20px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:600,
        // color:'#fff',
        "&:hover":{
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1:{
        width: 68,
        height: 30,
        padding:'18px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:600,
        color:'#fff',
        marginRight:'2%',
        "&:hover":{
            background:'#a3a8af'
        }
    },
});

@inject('authStore', 'orgStore', 'timelineStore', 'progressStore')
@observer
class RoomInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSendEmailDialog: false,
            confirmSendEmailDialog: false,
            resultMsg: ''
        }
    };

    handleSendEmail = (roomId) => {
        const {intl, authStore, timelineStore, progressStore} = this.props;

        progressStore.setIsLoading(true);
        this.closeSendEmailDialog();
        timelineStore.sendRoomEmail(intl, roomId, authStore.loginUser.id, this.openConfirmSendEmailDialog);
    };

    handleSendVideoEmail = (roomId) => {
        const {intl, authStore, timelineStore, progressStore} = this.props;

        progressStore.setIsLoading(true);
        this.closeSendEmailDialog();
        timelineStore.sendVideoEmail(intl, roomId, authStore.loginUser.id, this.openConfirmSendEmailDialog);
    };

    openSendEmailDialog = () => {
        this.setState({openSendEmailDialog: true})
    };

    closeSendEmailDialog = () => {
        this.setState({openSendEmailDialog: false})
    };

    openConfirmSendEmailDialog = (msg) => {
        const {progressStore} = this.props;

        progressStore.setIsLoading(false);
        this.setState({confirmSendEmailDialog: true, resultMsg: msg})
    };

    closeConfirmSendEmailDialog = () => {
        this.setState({confirmSendEmailDialog: false, resultMsg: ''})
    };

    render() {
        const { classes, intl, authStore, room, orgStore, isVideoAvailable } = this.props;

        const roomState = UTIL.getRoomState(authStore.loginUser.id, room);
        return (
            <div className={classes.paper}>
                <DialogTitle>
                    {
                        ((room.userId === authStore.loginUser.id) && !roomState.isLive) &&
                        <Box display='flex' justifyContent='flex-end'>
                            <MoreButton room={room} />
                        </Box>
                    }
                    <Typography className={classes.dialogTitleText}>
                        {room.name}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText component='div'>
                        <Box className={classes.textareaBox}>
                            <Typography className={classes.textStyle}>
                                {room.comment}
                            </Typography>
                        </Box>

                        <Box>
                            <Box display='flex' alignItems='center'>
                                {room.type === 'Conference' ? <SmileyIcon style={{width:25,height:25}}/> : <SmileyIcon className={classes.learningIcon}/>}
                                <Typography className={classes.textStyle1}>{room.teamName} | {room.userName} {room.userTitle}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center' pb={1} pt={1}>
                                {room.type === 'Confer∂ence' ? <ClockIcon style={{width:25,height:25}}/> : <ClockIcon className={classes.learningIcon}/>}
                                <Typography className={classes.textStyle1} style={{color:'#4f5660',fontWeight:800}}>{DATE_UTIL.getTimeZoneLanguage(room.startDatetime).format('A')} {dayjs(room.startDatetime).format('HH:mm')} ~ {DATE_UTIL.getTimeZoneLanguage(room.endDatetime).format('A')} {dayjs(room.endDatetime).format('HH:mm')}</Typography>
                            </Box>
                            <TeamMembersSideBar members={room.members} isTeam={false} configs={orgStore.orgConfigs}/>
                        </Box>
                        {room.userId === authStore.loginUser.id && roomState.isEnded ?
                            <Button className={classes.buttonStyle} onClick={this.openSendEmailDialog}><FormattedMessage id="send_video_invitation_email"/> ></Button>
                            :
                            <Button className={classes.buttonStyle} onClick={this.openSendEmailDialog}><FormattedMessage id="send_invitation_email"/> ></Button>
                        }
                        {(room.userId === authStore.loginUser.id && room.sendMailDatetime) &&
                            <Typography className={classes.textStyle2}>(<FormattedMessage id="recently_send"/>: {DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(room.sendMailDatetime), `YYYY${intl.formatMessage({id: "calendar_year"})} MMM D${intl.formatMessage({id: "calendar_day"})} hh:mm A`)})</Typography>
                        }
                    </DialogContentText>
                </DialogContent>
                <Dialog
                    maxWidth='xs'
                    open={this.state.openSendEmailDialog}
                    onClose={this.closeSendEmailDialog}
                    aria-labelledby="form-dialog-title"
                    className={classes.TabletDialogBox}
                >
                    <DialogTitle id="form-dialog-title" className={classes.titleBox}>
                        <Typography className={classes.titleText} style={{fontSize:'1.125rem'}}>
                            {roomState.isEnded ?
                                isVideoAvailable ?
                                <FormattedMessage id={"msg.confirm_send_video_invitation_email"} />
                                    :
                                <FormattedMessage id={"msg.not_exist_video"} />
                                :
                                <FormattedMessage id={"msg.confirm_send_invitation_email"} />
                            }
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button className={classes.btnStyle1} onClick={this.closeSendEmailDialog}>
                            <FormattedMessage id={"cancel"} />
                        </Button>
                        <Button className={classes.btnStyle} disabled={!isVideoAvailable} onClick={roomState.isEnded ? () => this.handleSendVideoEmail(room.id) : () => this.handleSendEmail(room.id)}>
                            <FormattedMessage id={"send"} />
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth='xs'
                    open={this.state.confirmSendEmailDialog}
                    onClose={this.closeConfirmSendEmailDialog}
                    aria-labelledby="form-dialog-title"
                    className={classes.TabletDialogBox}
                >
                    <DialogTitle id="form-dialog-title" className={classes.titleBox}>
                        <Typography className={classes.titleText} style={{fontSize:'1.125rem'}}>
                            {this.state.resultMsg}
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button className={classes.btnStyle} onClick={this.closeConfirmSendEmailDialog}>
                            <FormattedMessage id={"ok"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(RoomInformation));