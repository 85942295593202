import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Input,
    Typography
} from "@material-ui/core";
import Sample1 from "../../../../../common/images/Sample1.jpg";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = () => ({
    root: {
        width: '100%',
        border: '1px solid #c4c9de',
        borderRadius: 5,
        marginBottom: 40,
        padding: '30px 14px 25px',
        "& .MuiInput-underline:after, .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before ": {
            borderBottom: 0
        },
        "& label + .MuiInput-formControl": {
            marginTop: 0
        }
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#a3a8af',
        fontWeight: 600,
    },
    explanationText: {
        fontSize: '1rem',
        fontWeight: 400,
        marginTop: 12
    },
    inputBoxIn: {
        width: '100%',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        fontWeight: 500,
        borderBottom: '1px solid #979797',
        paddingLeft: 13,
        "&::placeholder": {
            color: '#a3a8af'
        }
    },
    titleImgBox: {
        width: 241,
        height: 181,
        overflow: 'hidden',
        margin: 'auto',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        display: 'flex',
        margin: 'auto',
        cursor: 'pointer',
        justifyContent:'center'
    },
    imageWidth: {
        width: '100%',
        height:'auto'
    },
    imageHeight: {
        height: '100%',
        width:'auto'
    },
});

@inject('surveyStore')
@observer
class MobilePreviewShortAnswer extends Component {

    render() {
        const {classes, surveyData, intl} = this.props;
        return (
            <div className={classes.root}>

                <Typography className={classes.textStyle}
                            style={{color: '#0d0d0d'}}>{surveyData.title !== "" ? surveyData.title :
                    <FormattedMessage id="msg.preview_survey_subject"/>}
                    {surveyData.required &&
                    <span style={{color: '#FF0000'}}>*</span>
                    }
                </Typography>

                {surveyData.contents !== "" &&
                <Typography className={clsx(classes.textStyle, classes.explanationText)}
                            style={{color: '#0d0d0d'}}>{surveyData.contents}</Typography>
                }

                {surveyData.surveyItemImage.length > 0 ?
                    surveyData.surveyItemImage[0].image === "" ?
                        <Box className={classes.titleImgBox} mb={2}>
                            <img src={Sample1} alt="" style={{width: '100%'}}/>
                        </Box>
                        :
                        <Box className={classes.titleImgBox} mb={2}>
                            <img src={surveyData.surveyItemImage[0].image} alt="" className={clsx(classes.image, (surveyData.surveyItemImage[0] !== undefined && surveyData.surveyItemImage[0].width >= surveyData.surveyItemImage[0].height && surveyData.surveyItemImage[0].width > 241) && classes.imageWidth, (surveyData.surveyItemImage[0] !== undefined && surveyData.surveyItemImage[0].height >= surveyData.surveyItemImage[0].width && surveyData.surveyItemImage[0].height > 181) && classes.imageHeight)}/>
                        </Box>
                    :
                    <>
                    </>
                }

                <Box style={{marginTop: 28}}>
                    <Input
                        placeholder={intl.formatMessage({id: "msg.preview_survey_response"})}
                        className={clsx(classes.textStyle, classes.inputBoxIn)}
                        inputProps={{
                            'aria-label': 'Description',
                        }}
                    />
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobilePreviewShortAnswer));