import {makeAutoObservable} from "mobx";
import localeDataKo from '../localeDataKo.json';
import localeDataEn from '../localeDataEn.json';

const localeData = {
    'ko': localeDataKo,
    'en': localeDataEn,
};

export const LanguageKey = '_ONTHELIVE_LMS_LANG';
const DefaultLocale = 'en';
// export const DefaultLocale = 'ko';

export default class LocaleStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        this.locale = DefaultLocale;
        this.localeMessage = localeData[DefaultLocale];

        makeAutoObservable(this);

        const locale = this.getBrowserLocale();
        this.changeLocale(locale);
    }

    getBrowserLocale() {
        const language = localStorage.getItem(LanguageKey) || (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || navigator.browserLanguage || 'en';
        return language.toLowerCase().split(/[_-]+/)[0];
    };

    changeLocale(locale) {
        if(localeData[locale]) {
            this.locale = locale;
            this.localeMessage = localeData[locale];
            localStorage.setItem(LanguageKey, locale);
        } else {
            console.warn(`Locale ${locale} does not supported : set to default ${DefaultLocale}`);

            this.locale = DefaultLocale;
            this.localeMessage = localeData[DefaultLocale];
            localStorage.setItem(LanguageKey, DefaultLocale);
        }
    }
}