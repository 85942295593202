import {makeAutoObservable} from "mobx";

const QRCODE_API_URL = '/api/v1/qr';

const QRCODE_TYPE = {
    Organization: 'organization',
}
export default class QRCodeStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    orgQRCodeURL = `${QRCODE_API_URL}/${QRCODE_TYPE.Organization}`;
}