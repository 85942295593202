import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Typography
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";

const style = theme => ({
    root: {
        width: '100%',
        marginBottom: 25,
        marginTop: 16
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight: 600,
    },
    typoStyle: {
        fontSize: '1.2rem',
        fontWeight: 400,
    },
});

@inject("surveyStore")
@observer
class MobilePreviewParagraph extends Component {
    render() {
        const {classes, surveyStore} = this.props;
        return (
            <div className={classes.root}>
                <Typography className={classes.textStyle}>{surveyStore.survey.title === "" ?
                    <FormattedMessage id="msg.preview_survey_subject"/> : surveyStore.survey.title}</Typography>
                <Box style={{marginTop: 20}}>

                    <Typography
                        className={clsx(classes.textStyle, classes.typoStyle)}>{surveyStore.survey.descriptions === "" ?
                        <FormattedMessage id="msg.preview_survey_description"/> : surveyStore.survey.descriptions}</Typography>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobilePreviewParagraph));