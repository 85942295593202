import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    Box,
    Select,
    FormControl,
    TextareaAutosize,
    MenuItem,
    MenuList,
    IconButton,
    FormGroup,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio, Button, Avatar, ClickAwayListener,
} from "@material-ui/core";
import TeamDropzoneImage from "./TeamDropzoneImage";
import { ReactComponent as DeleteIcon} from "../../../common/images/DeleteIcon.svg"
import {ReactComponent as CheckedBox} from "../../../common/images/CheckedBox.svg";
import {ReactComponent as UnCheckedBox} from "../../../common/images/UnCheckedBox.svg";
import {inject, observer} from "mobx-react";
import * as Store from "../../../stores/TeamStore";
import {UI} from "../../../stores/TeamStore";
import {TeamUserType, UserType} from "../../../stores/UserStore";
import * as UStore from "../../../stores/UserStore";
import {ReactComponent as WarningIcon} from "../../../common/images/WarningIcon.svg";
import CheckIcon from "@material-ui/icons/Check";
import {FormattedMessage, injectIntl} from "react-intl";
import clsx from "clsx";
import {COLOR_UTIL} from "../../../common/util/color.util";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";

const style = theme => ({
    root:{
        display:'flex',
        width:'100%',
        background:'#fff',
        padding:'50px 30px',
        "& .MuiOutlinedInput-input":{
            padding:'8px 12px'
        },
        "& :focus":{
            outline:'none',
        },
    },
    gridBox:{
        marginBottom:30,
        "& .MuiButton-root.Mui-disabled":{
            background:'#fff',
            color:'#88889D',
            border:'1px solid #A3A8AF'
        }
    },
    avatarStyle:{
        width:120,
        height:120,
        border:'1px dashed #dbdbdb',
    },
    avatarNoImage:{
        borderStyle:'solid'
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#0d0d0d',
        fontWeight:600
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#666666'
    },
    textStyle2_err:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#ff0000'
    },
    inputStyle:{
        width:'80%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid #b7bcd6',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color:'#0d0d0d',
    },
    inputStyle1:{
        width:'70%',
        padding:10,
        borderRadius:6,
        background:'#f2f3f7',
        border:0,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color:'#b6b6b6',
    },
    formControlBox:{
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
            border:'1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus":{
            background:'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            border:'1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    selectBox: {
        width: 240,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        marginBottom:10,
        color:'#0d0d0d',
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color:'#0d0d0d',
    },
    textareaStyle:{
        width:"80%",
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#0d0d0d',
        border:'1px solid #b7bcd6',
        padding:'10px',
        borderRadius:4,
        resize:'none',
        "&::placeholder":{
            color:'#8d909e'
        },
        "&:focus":{
            outline:'none',
        },
        overflowY:'scroll',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    textareaBox:{
        width:"80%",
        padding:'10px',
    },
    textStyle3:{
        width:"80%",
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color:'#0d0d0d'
    },
    teamLeaderBox:{
        width:"50%",
        height:140,
        overflowY:'auto',
        border:'1px solid #b7bcd6',
        padding:'0 8px',
        marginBottom:14,
        marginTop:5,
        "& .MuiListItem-button":{
            background:'transparent'
        },
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    teamMembersBox:{
        width:"50%",
        height:140,
        overflowY:'auto',
        border:'1px solid #b7bcd6',
        padding:'0 8px',
        marginBottom:14,
        marginTop:5,
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    textStyle4:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color:'#8d909e',
    },
    listStyle:{
        width:'100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color:'#0d0d0d',
        padding:5
    },
    spanStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color:'#7d86a9',
        marginLeft:5
    },
    checkboxStyle:{
        "& .MuiTypography-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1rem',
            color:'#0d0d0d',
        },
        marginLeft:20,
    },
    checkboxStyle1:{
        "& .MuiTypography-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1rem',
            color:'#0d0d0d',
            marginRight:50
        },
    },
    btnStyle:{
        width:135,
        padding:'8px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:600,
        // color:'#fff',
        marginBottom:50,
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1:{
        width:135,
        padding:'8px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:600,
        color:'#fff',
        marginBottom:50,
        marginRight:27,
        "&:hover":{
            background:'#a3a8af'
        }
    },
    btnStyle2:{
        width:135,
        padding:'8px 0',
        background:'#ff0000',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:600,
        color:'#fff',
        marginBottom:50,
        marginRight:27,
        "&:hover":{
            background:'#ff0000'
        }
    },
    teamDropdown:{
        position: 'relative',
    },
    dropdown: {
        width:'70%',
        position: 'absolute',
        top: 40,
        right: 0,
        left: 0,
        zIndex: 1,
        border:'1px solid #b7bcd6',
        padding:'8px 0',
        background:'#fff'
    },
    dropdownText:{
        fontSize: '1.125rem',
        fontFamily:'NanumSquareRoundOTF',
        color:'#0d0d0d',
        cursor:'pointer',
        padding:'5px 18px',
        "&:hover":{
            background:'#f5f5f5'
        }
    },
});

@inject("teamStore", "userStore", "avatarStore", "authStore", "progressStore")
@observer
class TeamInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leaderSearchBar : false,
            memberSearchBar : false,
            CreateRoom: false,
            CreateNotice: false,
            UploadFile: false,
            userSelectIndex : 0,

            teamNameCheckState : true,
            teamNameCheckMsg : "",
            teamMaxUsersCheckState : true,
            teamMaxUsersCheckMsg : "",
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
        this.props.teamStore.changeUiState(UI.TeamTable);
        this.props.teamStore.initModifyTeam();
        this.props.userStore.initSearchUserList(TeamUserType.Leader);
        this.props.userStore.initSearchUserList(TeamUserType.Member);
    }
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {teamStore, progressStore} = this.props;
        progressStore.setIsLoading(teamStore.getIsLoading)
    }
    handleClick = () => {
        this.props.teamStore.changeModifiedState(true);
    }
    memberHandleListItemClick = (member, type) => {
        this.setState({
            CreateRoom :false,
            CreateNotice :false,
            UploadFile :false,
        })
        if(this.props.teamStore.selectedUserIdBySearchKeyword === member.userId) {
            this.props.teamStore.changeSelectedUserIdBySearchKeyword(0);
        }else{
            this.props.teamStore.changeSelectedUserIdBySearchKeyword(member.userId);
            const authority = this.props.teamStore.detailTeamUserAuthority.filter( authority => authority.userId ===  member.userId);
            if(authority)
                authority.forEach( auth => {
                    this.setState({ [auth.authority] : true});
                })
        }
    }
    handleChange = (event) => {
        if(event.target.checked){
            this.props.teamStore.addDetailTeamUserAuthority(event.target.name);
        }else this.props.teamStore.removeDetailTeamUserAuthority(event.target.name);
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };
    handleDeleteTeam = () => {
        const {intl} = this.props;

        this.props.teamStore.changeTeamDeleteDialogTitle(intl.formatMessage({id: "delete"}));
        this.props.teamStore.changeTeamDeleteDialogMsg(intl.formatMessage({id: "msg.team_delete_guide"}));
        this.props.teamStore.changeTeamDeleteDialogOpen(true);
        const teamId = this.props.teamStore.detailTeam.id;
        this.props.teamStore.changeDeleteTeamId(teamId);
    }
    handleInitDetailTeam = () => {
        this.props.teamStore.modifyTeamCancel();
        this.props.userStore.initSearchUserList(TeamUserType.Leader);
        this.props.userStore.initSearchUserList(TeamUserType.Member);
    }
    handleLeaderKey = (e) =>{
        this.props.userStore.searchUserList(e.target.value, TeamUserType.Leader);
    }
    handleMemberKey = (e) =>{
        this.props.userStore.searchUserList(e.target.value, TeamUserType.Member);
    }
    handleAddMember = (e, type, list, leaders) =>{
        if (e.isComposing || e.keyCode === 229) return;
        if(e.keyCode  === 13) {
            console.log('this.state.userSelectIndex', this.state.userSelectIndex);
            const userSelectIndex = this.state.userSelectIndex;
            const newMember = list[userSelectIndex];
            if (newMember) {
                if((this.props.authStore.loginUser.type === UStore.UserType.Normal) && leaders.find(u => u.userId === newMember.id)){
                    ToastsStore.error("이미 리더로 포함된 사용자 입니다. ( 팀 리더 수정은 관리자만 가능합니다 )")
                }else {
                    newMember.type = type;
                    newMember.userId = newMember.id;
                    newMember.teamId = this.props.teamStore.detailTeam.id;
                    this.props.teamStore.addDetailTeamUser(newMember);
                }
            }
        }else if(e.keyCode  === 38 && this.state.userSelectIndex > 0) {
            const index = this.state.userSelectIndex - 1;
            this.setState({userSelectIndex : index});
        }else if(e.keyCode  === 40 && this.state.userSelectIndex < list.length-1) {
            const index = this.state.userSelectIndex + 1;
            this.setState({userSelectIndex : index});
        }else if (e.keyCode  === 27){
            this.setState({
                leaderSearchBar : false,
                memberSearchBar : false,
            });
        }
    }
    hoverTest = (index) =>{
        this.setState({userSelectIndex : index});
    }

    handleAddMemberByMember = (member, type) =>{
        const {intl} = this.props;

        member.type = type;
        member.userId = member.id;
        member.teamId = this.props.teamStore.detailTeam.id;
        member.enabled = true;

        if(this.props.teamStore.detailTeam.maxUsers === ""){
            this.handleCheckMaxUsersState(false, intl.formatMessage({id: "msg.required_max_user_count"}));
        }else if(this.props.teamStore.detailTeamUser.length < parseInt(this.props.teamStore.detailTeam.maxUsers)){
            this.props.teamStore.addDetailTeamUser(member);
        }else{
            // this.props.teamStore.detailTeam.maxUsers = (this.props.teamStore.detailTeamUser.length + 1).toString();
            // this.handleCheckMaxUsersState(true, "");
            this.props.teamStore.addDetailTeamUser(member);
        }
    }

    handleRemoveNewMember = (member) => {
        this.props.teamStore.removeDetailTeamUser(member);
        this.setState({
            CreateRoom :false,
            CreateNotice :false,
            UploadFile :false,
        })
    }
    handleSearchBar = (event, state) =>{
        this.setState({[event.target.name] : state});
    }

    handleClickAway = () => {
        this.props.userStore.initSearchUserList(TeamUserType.Leader);
        this.setState({leaderSearchBar : false});
    };

    handleClickAwayMembers = () => {
        this.props.userStore.initSearchUserList(TeamUserType.Member);
        this.setState({memberSearchBar : false});
    };


    modifyTeam = () =>{
        const {intl} = this.props;

        const leader = this.props.teamStore.detailTeamUser.filter( leader => leader.type === Store.UserType.Leader);
        const member = this.props.teamStore.detailTeamUser.filter( member => member.type === Store.UserType.Member);
        if(leader.length === 0 && member.length > 0){
            const msg = intl.formatMessage({id: "msg.required_team_leader"});
            const title = intl.formatMessage({id: "error_insert_member"});
            this.props.teamStore.setTeamConfirmDialog(msg, title);
            return;
        }
        if (this.props.teamStore.detailTeam.maxUsers < this.props.teamStore.detailTeamUser.length) {
            const msg = intl.formatMessage({id: "msg.small_max_user_count"});
            const title = intl.formatMessage({id: "error_insert_member"});
            this.props.teamStore.setTeamConfirmDialog(msg, title);
            return;
        }
        this.props.teamStore.modifyTeam(intl);
        if(this.state.avatarState){
            const teamId = this.props.teamStore.detailTeam.id;
            this.props.avatarStore._uploadTeamAvatarImage(teamId);
        }

        this.setState({
            avatarState : false,
            CreateRoom :false,
            CreateNotice :false,
            UploadFile :false,
        });
    }

    handlTeamInUserList = (userId) =>{
        const {intl} = this.props;

        let teamUserList = this.props.teamStore.teamUserListForUsersBelongedToTeam.filter(e => e.userId === userId);
        teamUserList = teamUserList.sort((a, b) => {
            if(b.teamId === this.props.teamStore.detailTeam.id){
                return 1;
            }else{
                return -1;
            }
        })
        const nameList = teamUserList.map(e => {
            return e.teamName;
        })
        if(nameList.length > 0) return nameList.join(", ");
        else return intl.formatMessage({id: "independent"});
    }
    handleCheckNameState= (value, msg) =>{
        this.setState({
            teamNameCheckState : value,
            teamNameCheckMsg : msg,
        });
    }

    changeDetailTeamName = (e) =>{
        const {intl} = this.props;
        const { orgDetailTeam } = this.props.teamStore;

        if (e.target.value === "") {
            this.handleCheckNameState(false,"* " + intl.formatMessage({id: "msg.required_team_name"}));
        } else {
            if ( orgDetailTeam.name === e.target.value  ) {
                this.handleCheckNameState(true,"");
            } else {
                this.handleCheckNameState(false, "팀 명에 대한 중복체크가 필요합니다.");
            }
        }
        this.props.teamStore.changeDetailTeamName(e);
    }

    changeDetailTeamMaxUsers = (e) =>{
        const {intl} = this.props;

        if(e.target.value === ""){
            this.handleCheckMaxUsersState(false, intl.formatMessage({id: "msg.required_max_user_count"}));
        }else if(parseInt(e.target.value) < 1){
            this.handleCheckMaxUsersState(false, intl.formatMessage({id: "msg.required_team_user"}));
        }else if(parseInt(e.target.value) < this.props.teamStore.detailTeamUser.length){
            this.handleCheckMaxUsersState(false, intl.formatMessage({id: "msg.small_max_user_count"}));
        } else {
            this.handleCheckMaxUsersState(true, "");
        }
        this.props.teamStore.changeDetailTeamMaxUsers(e);
    }
    handleCheckMaxUsersState= (value, msg) =>{
        this.setState({
            teamMaxUsersCheckState : value,
            teamMaxUsersCheckMsg : msg,
        });
    }
    handleCheckTeamName = () => {
        const { detailTeam } = this.props.teamStore;
        if (!this.state.teamNameCheckState && detailTeam.name !=="") {
            this.props.teamStore.checkTeamName(detailTeam.name, this.handleCheckNameState);
        }
    }
    handleCheckTeamNameByKeyDown = (e) => {
        if (e.isComposing || e.keyCode === 229) { return; }
        if (e.keyCode  === 13) {
            this.handleCheckTeamName();
        }
    }
    render() {
        const { classes, intl } = this.props;
        const {detailTeamUser, selectedUserIdBySearchKeyword, actionState, detailTeam, parentTeam, parentTeamList,
             changeDetailTeamComment, changeDetailTeamParentTeam, changeDetailTeamEnabled} = this.props.teamStore;
        const { userList, searchKeyword } = this.props.userStore;
        const { loginUser } = this.props.authStore;
        const leaders = detailTeamUser.filter(user => user.type === Store.UserType.Leader);
        const members = detailTeamUser.filter(user => user.type === Store.UserType.Member);
        const notLeaderList = userList[TeamUserType.Leader].filter(user => user.type !== TeamUserType.Leader);
        const notMemberList = userList[TeamUserType.Member].filter(user => user.type !== TeamUserType.Member);
        console.log('notLeaderList',notLeaderList)
        console.log('notMemberList',notMemberList)
        console.log('userList',userList)
        console.log('leaders',leaders)
        return (
            <Grid container className={classes.root}>
                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="team_image"/></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    {actionState.modified === false ?
                        <Box>
                            {detailTeam.image ?
                                <Avatar alt="userImg" src={detailTeam.image} className={classes.avatarStyle}/>
                                :
                                <Avatar alt="userImg" src={this.props.avatarStore.avatarImage} className={clsx(classes.avatarStyle, classes.avatarNoImage)}/>
                            }
                        </Box>
                        :
                        <Box>
                            <TeamDropzoneImage flag={"modify"} handleAvatarState={(value)=>this.setState({avatarState : value})}/>
                            <Typography className={classes.textStyle2} style={{marginTop:20}}>* <FormattedMessage id="msg.team_image_guide"/></Typography>
                            <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.logo_upload_guide"/></Typography>
                        </Box>
                    }
                </Grid>

                <Grid item xs={2}>
                    {actionState.modified === false ?
                        <Typography className={classes.textStyle}><FormattedMessage id="team_name"/></Typography>
                        :
                        <Typography className={classes.textStyle}><FormattedMessage id="team_name"/><span
                            style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                    }
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    {actionState.modified === false ?
                        <Typography className={classes.textStyle3}>{detailTeam.name}</Typography>
                        :
                        <Box>
                            {loginUser.type === UStore.UserType.Normal ?
                                <Box style={{padding : 5 }}>
                                    <Typography className={classes.textStyle3}>{parentTeam.name ? parentTeam.name : "-" }</Typography>
                                </Box>
                                :
                                <FormControl variant={'outlined'} className={classes.formControlBox}>
                                    <Select
                                        native
                                        value={detailTeam.parentTeamId? detailTeam.parentTeamId : 0}
                                        onChange={changeDetailTeamParentTeam}
                                        className={classes.selectBox}
                                    >
                                        <option key={"0"} value="" className={classes.optionStyle}>{intl.formatMessage({id: "msg.select_parent_group"})}</option>
                                        {parentTeamList.length > 0 &&
                                        parentTeamList.map( group =>{
                                            return <option key={group.id.toString()} value={group.id} className={classes.optionStyle}>{group.name}</option>
                                        })
                                        }
                                    </Select>
                                </FormControl>
                            }

                            <Box >
                                <input
                                    value={detailTeam.name}
                                    name={detailTeam.name}
                                    type="text"
                                    id={detailTeam.name}
                                    placeholder={intl.formatMessage({id: "msg.insert_team_name"})}
                                    className={classes.inputStyle}
                                    onChange={this.changeDetailTeamName}
                                    onKeyDown={this.handleCheckTeamNameByKeyDown}
                                />
                                <IconButton className={classes.iconBtn} onClick={this.handleCheckTeamName}>
                                    <CheckIcon />
                                </IconButton>
                            </Box>
                            {!this.state.teamNameCheckState &&
                                <Box display="flex" alignItems="center" pt={1}>
                                    <WarningIcon style={{width:13, height:13, marginRight:5}}/>
                                    <Typography className={classes.textStyle2_err}>
                                        {this.state.teamNameCheckMsg}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    }
                </Grid>

                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="descriptions"/></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    {actionState.modified === false ?
                        <Typography className={classes.textStyle3} style={{'overflowWrap': 'break-word'}}>
                            {detailTeam.comment}
                        </Typography>
                        :
                        <TextareaAutosize
                            value={detailTeam.comment}
                            rows={8}
                            rowsMax={8}
                            aria-label="maximum height"
                            placeholder={intl.formatMessage({id: "msg.insert_team_descriptions"})}
                            className={classes.textareaStyle}
                            onChange={changeDetailTeamComment}
                        />
                    }
                </Grid>

                <Grid item xs={2}>
                    {actionState.modified === false ?
                        <Typography className={classes.textStyle}><FormattedMessage id="max_user_count"/></Typography>
                        :
                        <Typography className={classes.textStyle}><FormattedMessage id="max_user_count"/><span
                            style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                    }
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    {actionState.modified === false ?
                        <Typography className={classes.textStyle3}>{detailTeam.maxUsers} <FormattedMessage id="person"/></Typography>
                        :
                        loginUser.type === UStore.UserType.Normal ?
                                <Box style={{padding : 5 }}>
                                    <Typography className={classes.textStyle3}>{detailTeam.maxUsers}  <FormattedMessage id="person"/></Typography>
                                </Box>
                                :
                                <div>
                                    <input
                                        value={detailTeam.maxUsers}
                                        name={detailTeam.maxUsers.toString()}
                                        type="number"
                                        id={detailTeam.maxUsers.toString()}
                                        placeholder={intl.formatMessage({id: "msg.insert_max_user_count"})}
                                        className={classes.inputStyle}
                                        onChange={this.changeDetailTeamMaxUsers}
                                    />
                                    {!this.state.teamMaxUsersCheckState &&
                                        <Box display="flex" alignItems="center" pt={1}>
                                            <WarningIcon style={{width: 13, height: 13, marginRight: 5}}/>
                                            <Typography className={classes.textStyle2_err}>
                                                {this.state.teamMaxUsersCheckMsg}
                                            </Typography>
                                        </Box>
                                    }
                                </div>
                    }
                </Grid>

                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="team_leader"/></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    <Typography className={classes.textStyle3}>{leaders.length} <FormattedMessage id="person"/></Typography>
                    {actionState.modified === false ?
                        <Box className={classes.teamLeaderBox}>
                            {leaders.length > 0 &&
                                leaders.map( (leader, index) => {
                                    return (
                                        <Typography key={index.toString()}
                                                    className={classes.listStyle}>
                                            {leader.name}
                                            <span className={classes.spanStyle}>
                                                ({this.handlTeamInUserList(leader.userId)} / {leader.title? leader.title :"-"} )
                                            </span>
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                        :
                        <>
                            <Box className={classes.teamLeaderBox}>
                                <Box display='flex' >
                                    <MenuList style={{width: '100%'}}>
                                        {leaders.length > 0 &&
                                            leaders.map((leader, index) => {
                                                return (
                                                    <Box key={`${index.toString()}-${leader.userId}`} display='flex' justifyContent='space-between'>
                                                        <MenuItem key={index.toString()} className={classes.listStyle}>
                                                            {leader.name}
                                                            <span className={classes.spanStyle}>
                                                                ({this.handlTeamInUserList(leader.userId)} / {leader.title? leader.title :"-"} )
                                                            </span>
                                                        </MenuItem>
                                                        {loginUser.type === UStore.UserType.Admin &&
                                                            <IconButton onClick={()=>this.handleRemoveNewMember(leader)}>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        }
                                                    </Box>
                                                )
                                            })
                                        }
                                    </MenuList>
                                </Box>
                            </Box>
                            {loginUser.type === UStore.UserType.Admin &&
                            <ClickAwayListener onClickAway={this.handleClickAway}>
                                <Box className={classes.teamDropdown}>
                                    <input
                                        value={searchKeyword[TeamUserType.Leader]}
                                        onChange={this.handleLeaderKey}
                                        onKeyDown={(event) => this.handleAddMember(event, Store.UserType.Leader, notLeaderList)}
                                        onSelect={(event) => this.handleSearchBar(event, true)}
                                        name="leaderSearchBar"
                                        type="text"
                                        id="leaderSearchBar"
                                        placeholder={intl.formatMessage({id: "msg.insert_or_select_nickname"})}
                                        className={classes.inputStyle1}
                                    />
                                    {this.state.leaderSearchBar ? (
                                        <Box className={classes.dropdown}>
                                            {notLeaderList.length > 0 ?
                                                notLeaderList.map((leader, index) => {
                                                    if (index === this.state.userSelectIndex)
                                                        return (
                                                            <Typography key={index.toString()}
                                                                        className={classes.dropdownText}
                                                                        style={{background: '#f5f5f5'}}
                                                                        onMouseEnter={() => this.hoverTest(index)}
                                                                        onClick={() => this.handleAddMemberByMember(leader, Store.UserType.Leader)}>
                                                                {leader.name}
                                                                <span
                                                                    key={`${index.toString()}-${leader.id.toString()}`}
                                                                    className={classes.spanStyle}>
                                                                ({this.handlTeamInUserList(leader.id)} / {leader.title ? leader.title : "-"} )
                                                            </span>
                                                            </Typography>
                                                        )
                                                    else return (
                                                        <Typography key={index.toString()}
                                                                    className={classes.dropdownText}
                                                                    onMouseEnter={() => this.hoverTest(index)}
                                                                    onClick={() => this.handleAddMemberByMember(leader, Store.UserType.Leader)}>
                                                            {leader.name}
                                                            <span key={`${index.toString()}-${leader.id.toString()}`}
                                                                  className={classes.spanStyle}>
                                                            ({this.handlTeamInUserList(leader.id)} / {leader.title ? leader.title : "-"} )
                                                        </span>
                                                        </Typography>
                                                    )
                                                }) : (
                                                    <Typography key={"0"}
                                                                className={classes.dropdownText}>
                                                        <FormattedMessage id="msg.no_addable_user"/>
                                                    </Typography>
                                                )}
                                        </Box>
                                    ) : null}
                                </Box>
                            </ClickAwayListener>
                            }
                        </>
                    }

                </Grid>

                <Grid item xs={2}>
                    {actionState.modified === false ?
                        <Typography className={classes.textStyle}><FormattedMessage id="team_member"/></Typography>
                        :
                        <Typography className={classes.textStyle}><FormattedMessage id="team_member"/></Typography>
                    }
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    <Box display='flex'>
                        <Typography className={classes.textStyle3} style={{width:'50%'}}>{members.length} <FormattedMessage id="person"/></Typography>
                        {/*{actionState.modified === true &&*/}
                        {/*    <Typography  className={classes.textStyle} style={{marginLeft: 50}}><FormattedMessage id="authority"/></Typography>*/}
                        {/*}*/}
                    </Box>

                    {actionState.modified === false ?
                        <Box className={classes.teamMembersBox}>
                            {members.length >0 &&
                                members.map( (member, index) =>{
                                    return (
                                        <Typography  key={index.toString()} className={classes.listStyle}>
                                            {member.name}
                                            <span className={classes.spanStyle}>
                                                ({this.handlTeamInUserList(member.userId)} / {member.title ? member.title : "-"} )
                                            </span>
                                        </Typography>
                                    )
                                })
                            }
                        </Box>
                        :
                        <>
                            <Box display='flex'>
                                <Box className={classes.teamMembersBox}>
                                    <Box display='flex'>
                                        <MenuList style={{width: '100%'}}>
                                            {members.length >0 &&
                                                members.map( (member, index) =>{
                                                    return (
                                                        <Box key={`${member.userId.toString()}-${index.toString()}`} display='flex' justifyContent='space-between'>
                                                        <MenuItem
                                                            key={index.toString()}
                                                            value={member.userId}
                                                            selected={selectedUserIdBySearchKeyword === member.userId}
                                                            onClick={() => this.memberHandleListItemClick(member, Store.UserType.Member)}
                                                            className={classes.listStyle}
                                                        >
                                                            {member.name}
                                                            <span key={`${index.toString()}-${member.userId.toString()}`} className={classes.spanStyle}>
                                                                ({this.handlTeamInUserList(member.userId)} / {member.title?  member.title : "-"} )
                                                            </span>
                                                        </MenuItem>
                                                        <IconButton onClick={() => this.handleRemoveNewMember(member)}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Box>
                                                    )
                                                })
                                            }
                                        </MenuList>
                                    </Box>
                                </Box>
                                <Box style={{width: '20%', textAlign: 'center', marginTop: 10}}>
                                    {actionState.modified === true &&
                                    <Typography  className={classes.textStyle} style={{marginLeft: 20, marginBottom:'0.5rem', textAlign:'left'}}><FormattedMessage id="authority"/></Typography>
                                    }
                                    <FormGroup className={classes.checkboxStyle}>
                                        <FormControlLabel
                                            control={<Checkbox
                                                icon={<UnCheckedBox/>}
                                                checkedIcon={<CheckedBox/>}
                                                checked={this.state.CreateRoom}
                                                disabled={selectedUserIdBySearchKeyword === 0}
                                                onChange={this.handleChange}
                                                name="CreateRoom"
                                            />}
                                            label={intl.formatMessage({id: "create_room"})}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                icon={<UnCheckedBox/>}
                                                checkedIcon={<CheckedBox/>}
                                                checked={this.state.CreateNotice}
                                                disabled={selectedUserIdBySearchKeyword === 0}
                                                onChange={this.handleChange}
                                                name="CreateNotice"
                                            />}
                                            label={intl.formatMessage({id: "notice"})}
                                        />
                                        <FormControlLabel
                                            control={<Checkbox
                                                icon={<UnCheckedBox/>}
                                                checkedIcon={<CheckedBox/>}
                                                checked={this.state.UploadFile}
                                                disabled={selectedUserIdBySearchKeyword === 0}
                                                onChange={this.handleChange}
                                                name="UploadFile"
                                            />}
                                            label={intl.formatMessage({id: "file_management"})}
                                        />
                                    </FormGroup>
                                </Box>
                            </Box>

                            <ClickAwayListener onClickAway={this.handleClickAwayMembers}>
                                <Box className={classes.teamDropdown} pb={2}>
                                    <input
                                        value={searchKeyword[TeamUserType.Member]}
                                        onChange={this.handleMemberKey}
                                        onKeyDown={(event) => this.handleAddMember(event, Store.UserType.Member, notMemberList, leaders)}
                                        onSelect={(event) => this.handleSearchBar(event, true)}
                                        name="memberSearchBar"
                                        type="text"
                                        id="memberSearchBar"
                                        placeholder={intl.formatMessage({id: "msg.insert_or_select_nickname"})}
                                        className={classes.inputStyle1}
                                    />
                                    {this.state.memberSearchBar ? (
                                        <Box className={classes.dropdown}>
                                            {notMemberList.length > 0 ?
                                                notMemberList.map((member, index) => {
                                                    if (index === this.state.userSelectIndex)
                                                        return (
                                                            <Typography key={index.toString()}
                                                                        className={classes.dropdownText}
                                                                        style={{background: '#f5f5f5'}}
                                                                        onMouseEnter={() => this.hoverTest(index)}
                                                                        onClick={() => this.handleAddMemberByMember(member, Store.UserType.Member)}>
                                                                {member.name}
                                                                <span
                                                                    key={`${index.toString()}-${member.id.toString()}`}
                                                                    className={classes.spanStyle}>
                                                                ({this.handlTeamInUserList(member.id)} / {member.title ? member.title : "-"} )
                                                            </span>
                                                            </Typography>
                                                        )
                                                    else return (
                                                        <Typography key={index.toString()}
                                                                    className={classes.dropdownText}
                                                                    onMouseEnter={() => this.hoverTest(index)}
                                                                    onClick={() => this.handleAddMemberByMember(member, Store.UserType.Member)}>
                                                            {member.name}
                                                            <span key={`${index.toString()}-${member.id.toString()}`}
                                                                  className={classes.spanStyle}>
                                                            ({this.handlTeamInUserList(member.id)} / {member.title ? member.title : "-"} )
                                                        </span>
                                                        </Typography>
                                                    )
                                                }) : (
                                                    <Typography key={"0"}
                                                                className={classes.dropdownText}>
                                                        <FormattedMessage id="msg.no_addable_user"/>
                                                    </Typography>
                                                )}
                                        </Box>
                                    ) : null}
                                </Box>
                            </ClickAwayListener>

                            <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.team_member_authority"/> </Typography>
                        </>
                    }
                </Grid>
                <Grid item xs={2}>
                    {actionState.modified === false ?
                        <Typography className={classes.textStyle}><FormattedMessage id="state"/></Typography>
                        :
                        <Typography className={classes.textStyle}><FormattedMessage id="state"/><span
                            style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                    }
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    {actionState.modified === false || loginUser.type === UserType.Normal?
                        <Typography className={classes.textStyle3}>
                            {
                                detailTeam.enabled === true ?
                                    <FormattedMessage id="enabled"/>
                                    :
                                    <FormattedMessage id="disabled"/>
                            }
                        </Typography>
                        :
                        <RadioGroup row className={classes.checkboxStyle1}
                                    aria-label="useSelection"
                                    name="useSelection1"
                                    value={detailTeam.enabled === true ? true : false}
                                    onChange={changeDetailTeamEnabled}>
                            <FormControlLabel
                                control={<Radio
                                    icon={<UnCheckedBox/>}
                                    checkedIcon={<CheckedBox/>}
                                    disabled={!actionState.modified
                                    || loginUser.type !== UStore.UserType.Admin}
                                />}
                                value={true}
                                label={intl.formatMessage({id: "enabled"})}
                            />
                            <FormControlLabel
                                control={<Radio
                                    icon={<UnCheckedBox/>}
                                    checkedIcon={<CheckedBox/>}
                                    disabled={!actionState.modified
                                    || loginUser.type !== UStore.UserType.Admin}
                                />}
                                value={false}
                                label={intl.formatMessage({id: "disabled"})}
                            />
                        </RadioGroup>
                    }
                </Grid>
                {actionState.modified === false ?
                    <>
                        <Grid item xs={2}/>
                        <Grid item xs={10}>
                            <Box display="flex" justifyContent='flex-end' pt={8} style={{width: "80%"}}>
                                {this.props.authStore.loginUser.type === UserType.Admin &&
                                    <Button className={classes.btnStyle2}
                                            onClick={this.handleDeleteTeam}>
                                        <FormattedMessage id="delete"/>
                                    </Button>
                                }

                                <Button className={classes.btnStyle}
                                        onClick={this.handleClick}
                                        style={
                                            this.props.authStore.loginUser.type !== UserType.Admin ?
                                                {} // {margin: '10px auto'}
                                        :
                                                {}}>
                                    <FormattedMessage id="edit_team"/>
                                </Button>
                            </Box>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid item xs={2}/>
                        <Grid item xs={10}>
                            <Box style={{width: "80%", display:"flex", justifyContent:'flex-end', paddingTop:64}}>
                                <Button className={classes.btnStyle1}
                                        onClick={this.handleInitDetailTeam}><FormattedMessage id="cancel"/></Button>
                                <Button className={classes.btnStyle}
                                        onClick={this.modifyTeam}
                                        disabled={
                                            !this.state.teamNameCheckState
                                            || !this.state.teamMaxUsersCheckState
                                            || this.props.avatarStore.creating
                                        }><FormattedMessage id="save"/></Button>
                            </Box>

                        </Grid>
                    </>
                }
            <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground />
            </Grid>
        );
    }
}

export default withStyles(style)(injectIntl(TeamInformation));