import {makeAutoObservable, toJS} from "mobx";
import axios from "axios";
import {DATE_UTIL} from "../common/util/date.util";
import {LanguageKey} from "./LocaleStore";
import {validateEmail, validateName, validatePassword, validateKor} from "../common/Validation";

const logPrefix = ' [ UserStore ] ';

const searchingInterval = 1000;

export const State = {
    Init: 'Init',
    Pending: 'Pending',
    Failed: 'Failed',
    Done: 'Done',
};
export const UserType = {
    Admin: 'Admin',
    Normal: 'Normal',
};
export const TeamUserType = {
    Leader: 'Leader',
    Member: 'Member',
};
export const UserConfigType = {
    Language: 'Language',
    Zone: 'Zone',
};

const EmptyInfo = {
    id: '',
    title: '',
    email: '',
    password: '',
    newPassword: '',
    newPasswordConfirm: '',
    timeZone: '',
    language: '',
};
const EmptyUser = {
    id: '',
    name: '',
    type: '',
    title: '',
    email: '',
    isEnabled: '',
    shouldChangePassword: '',
    userConfig: [{configKey: '', userId: '', value: '', createdDatetime: '', updatedDatetime: ''}],
    createdDatetime: '',
    updatedDatetime: '',
};


const EmptyUser2 = {
    id: 0,
    name: '',
    password: '',
    type: UserType.Normal,
    title: '',
    email: '',
    enabled: true,
    shouldChangePassword: true,
    userConfig: [],
    createdDatetime: '',
    updatedDatetime: '',
};

const EmptyUserConfig = {
    configKey: '',
    userId: 0,
    value: '',
    createdDatetime: '',
    updatedDatetime: ''
};

const ConverterConfigKey = {
    language: 'Language',
    timeZone: 'Zone',
};

export const UI = {
    TeamTable: 'TeamTable',
    MemberTable: 'MemberTable',
    Information: 'Information',
    Create: 'Create',
};

export const LocalStorageTokenKey = '_BASKITOP_AUTHENTICATION_TOKEN_';
const ErrorCode = {
    PasswordNotCorrect: "msg.error_current_password",
    CanNotFoundUser: "msg.not_found_member",
    BadRequest: "msg.missing_required_value",
    CanNotInsertUserConfig: "msg.cannot_insert_member_config",
    CanNotUpdateUser: "msg.cannot_modify_member",
    CanNotUpdateUserPassword: "msg.cannot_modify_member_password",
    CanNotInsertTeamUser: "msg.cannot_insert_team_member",
    CanNotInsertUserByMaxUsers : "msg.full_team_member",
    CanNotDeleteTeamUser: "msg.cannot_delete_team_member",
    CanNotFoundTeam: "msg.cannot_found_team",
    CanNotChangeProfile: "msg.error_change_profile",
    Unknown: "msg.unknown_error"
};
const EmptyDialog = {
    open: false,
    title: "",
    msg: "",
};
const EmptyPage = {
    page: 0,
    rowsPerPage: 10,
    total: 0,
    keyword: "",
};


const EmptyTeamUser = {
    teamId : 0,
    userId : 0,
    type : TeamUserType.Member,
};

export default class UserStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    UiState = {
        state: UI.MemberTable,
        actionId: 0
    };

    isGetUserListLoading = false;
    isChangePasswordLoading = false;
    isChangeProfile = false;

    modifyState = false;
    userLoadingState = State.Init;
    userConfirmDialog = {...EmptyDialog};
    userDeleteDialog = {...EmptyDialog};

    memberTreeExpandedList = [];
    memberTreeSelectedTeamId = "";
    userTablePage = {...EmptyPage};

    memberTableList = [];
    newUser = {...EmptyUser2};
    newTeamUserList = [];

    detailUser = {...EmptyUser2};
    orgDetailUser = {...EmptyUser2};
    detailTeamUserList = [];
    deleteDetailTeamUserList = [];
    insertDetailTeamUserList = [];
    openConfirmDialog = false;
    errMsg = "";
    alertMsg = "";

    selectedActiveTeam = {...EmptyTeamUser};

    changeSelectedActiveTeam = (team) => {
        this.selectedActiveTeam = team;
    }
    initSelectedActiveTeam = () => {
        this.selectedActiveTeam = {...EmptyTeamUser};
    }

    changeMemberType = (type) => {

        let selectedTeamUser = {};
        if (type === "create") {
           selectedTeamUser = this.newTeamUserList.find(teamUser => teamUser.teamId === this.selectedActiveTeam.teamId);
            // console.log("selectedTeamUser : ", selectedTeamUser);
            // console.log("this.selectedActiveTeam : ", this.selectedActiveTeam);
        } else {
            selectedTeamUser = this.detailTeamUserList.find(teamUser => teamUser.teamId === this.selectedActiveTeam.teamId);
        }

        if (selectedTeamUser){
            if (selectedTeamUser.type === TeamUserType.Leader) {
                this.selectedActiveTeam.type = TeamUserType.Member;
                selectedTeamUser.type = TeamUserType.Member;
            } else {
                this.selectedActiveTeam.type = TeamUserType.Leader;
                selectedTeamUser.type = TeamUserType.Leader;
            }
        }
    }

    changeUiState = (state, actionId) => {
        this.UiState.state = state;
        if (actionId) {
            this.UiState.actionId = actionId;
        }
    }

    changeUserTableSearchKeyword = (e) => {
        this.userTablePage.keyword = e.target.value;
    }
    initUserTableSearchKeyword = () => {
        this.userTablePage.keyword = "";
    }
    initUserTablePage = () => {
        this.userTablePage = {...EmptyPage};
    }

    changeMemberTreeExpandedList = (event, nodes) => {
        const node = nodes.find(node => node === "-1");
        if (!node) {
            this.memberTreeExpandedList = [];
        } else {
            this.memberTreeExpandedList = nodes;
        }
    };

    changeMemberTreeExpandedListByCustom = (node) => {
        const isIt = this.memberTreeExpandedList.find(expand => expand === node);
        if (!isIt) {
            this.memberTreeExpandedList = [...this.memberTreeExpandedList, node];
        }
    }
    initMemberTreeExpandedList = () => {
        this.memberTreeExpandedList = [];
    }

    changeSelectedTeamIdByMemberTree = (node) => {
        const isIt = this.memberTreeSelectedTeamId === node;
        if (!isIt) {
            this.memberTreeSelectedTeamId = node;
        }
    }

    // initSelected = () => {
    //     this.memberTreeSelectedTeamId = {};
    // }
    // initSelectedTeamIdByMemberTree = () => {
    //     this.memberTreeSelectedTeamId = {};
    // }



    changeUserConfirmDialogOpen = (open) => {
        if (open) {
            this.userConfirmDialog.open = open;
        } else {
            this.userConfirmDialog = {...EmptyDialog};
        }
    }
    changeUserConfirmDialogTitle = (title) => {
        this.userConfirmDialog.title = title;
    }
    changeUserConfirmDialogMsg = (msg) => {
        this.userConfirmDialog.msg = msg;
    }

    setUserConfirmDialog = (msg, title) => {
        this.changeUserConfirmDialogOpen(true);
        this.changeUserConfirmDialogTitle(title);
        this.changeUserConfirmDialogMsg(msg);
    }

    setUserConfirmDialogByError = (intl, err, title) => {
        const errValidation = ErrorCode[err.code];
        let msg = "";
        if (errValidation) {
            msg = intl.formatMessage({id: errValidation});
        } else {
            msg = intl.formatMessage({id: ErrorCode.Unknown});
        }
        this.changeUserConfirmDialogOpen(true);
        this.changeUserConfirmDialogTitle(title);
        this.changeUserConfirmDialogMsg(msg);
    }

    changeUserDeleteDialogOpen = (open) => {
        this.userDeleteDialog.open = open;
    }
    changeUserDeleteDialogTitle = (title) => {
        this.userDeleteDialog.title = title;
    }
    changeUserDeleteDialogMsg = (msg) => {
        this.userDeleteDialog.msg = msg;
    }
    //////////////////////////////////////////////////////////////////////////////////////////

    randomPassword = () => {
        this.newUser.password = "";
        let pw = "";
        const str1 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        const str2 = "abcdefghijklmnopqrstuvwxyz";
        const num = "1234567890";
        const spc = "!@#$%^&*";

        for (let i = 0; i < 8; i++) {
            switch (i % 4) {
                case 0 :
                    pw += str1.charAt(Math.floor(Math.random() * str1.length));
                    break;
                case 1 :
                    pw += str2.charAt(Math.floor(Math.random() * str2.length));
                    break;
                case 2 :
                    pw += num.charAt(Math.floor(Math.random() * num.length));
                    break;
                case 3 :
                    pw += spc.charAt(Math.floor(Math.random() * spc.length));
                    break;
                default :
                    break;
            }
        }
        ;
        pw = this._shuffle(pw);
        return pw;
    }
    _shuffle = (pw) => {
        const pwToList = pw.split('');
        let copied = pwToList.slice();
        for (let i = 0; i < copied.length; i++) {
            let random = Math.floor(Math.random() * copied.length);
            let temp = copied[random];
            copied[random] = copied[i];
            copied[i] = temp;
        }
        ;
        return copied.join("");
    }

    get getIsLoading() {
        return this.isGetUserListLoading || this.isChangePasswordLoading || this.isChangeProfile;
    }

    ///////////////////////////////////////

    changeNewUserName = (intl, name, handleCheckNameState) => {
        if (name.length <= 16) {
            this.newUser.name = name;
            const msg = intl.formatMessage({id: "msg.required_duplicate_check_nickname"});
            handleCheckNameState(false, msg);
        }
        if (this.newUser.name === "") {
            const msg = intl.formatMessage({id: "msg.required_nickname"});
            handleCheckNameState(false, msg);
        }
    }
    changeNewUserEmail = (intl, e, handleCheckEmailState) => {
        // if (e.target.value.indexOf("@") !== -1) return;
        if (e.target.name === "email") {
            const email = e.target.value;
            this.newUser.email = email;
            const msg = intl.formatMessage({id: "msg.required_duplicate_check_email"});
            handleCheckEmailState(false, msg);
        }
        // else {
        //     const emailAddress = e.target.value;
        //     this.newUser.email =
        //         `${this.newUser.email.slice(0, this.newUser.email.indexOf("@"))}@${emailAddress}`;
        //     const msg = intl.formatMessage({id: "msg.required_duplicate_check_email"});
        //     handleCheckEmailState(false, msg);
        // }
        // if (this.newUser.email === "@") {
        //     this.newUser.email = "";
        //     const msg = intl.formatMessage({id: "msg.required_email"});
        //     handleCheckEmailState(false, msg);
        // }
    }
    changeNewUserPassword = (intl, e, handleCheckPasswordState) => {
        const password = e.target.value;
        if (password.length <= 16 || !validateKor(password)) this.newUser.password = password.trim();

        if (password === "") {
            handleCheckPasswordState(false, intl.formatMessage({id: "msg.required_password"}));
        }
        // else if (password.length < 8) {
        //     handleCheckPasswordState(false, intl.formatMessage({id: "msg.password_policy"}));
        // } else if (!validatePassword(password)) {
        //     handleCheckPasswordState(false, intl.formatMessage({id: "msg.password_incorrect"}));
        // } else if (validatePassword(password)) {
        //     handleCheckPasswordState(true, intl.formatMessage({id: "msg.available_password"}));
        // }
    }
    creatNewUserRandomPassword = () => {
        const pw = this.randomPassword();
        this.newUser.password = pw;
    }

    changeNewUserType = (intl, e, handleCheckTypeState) => {
        this.newUser.type = e.target.value;
        if (this.newUser.type === UserType.Admin) {
            this.newTeamUserList = [];
        }
        if (e.target.value === "") {
            handleCheckTypeState(false, intl.formatMessage({id: "msg.required_type"}));
        } else {
            handleCheckTypeState(true, "");
        }
    }

    changeNewUserTitle = (e) => {
        const title = e.target.value;
        if (title.length <= 16) {
            this.newUser.title = title;
        }
    }

    changeNewUserEnabled = (e) => {
        if (e.target.value === "true") {
            this.newUser.enabled = true;
        } else {
            this.newUser.enabled = false;
        }
    }

    changeNewTeamUserEnabled = (e) => {
        if (this.newTeamUserList && this.newTeamUserList[0]){
            if (e.target.value === "true") {
                this.newTeamUserList[0].enabled = true;
            } else {
                this.newTeamUserList[0].enabled = false;
            }
        } else {
            console.warn("Did not set newTeamUserList[0]");
        }

    }

    addNewTeamUser = (team) => {
        const teamUser = {
            userId: 0,
            teamId: team.id,
            type: TeamUserType.Member,
            enabled : true,
            name: team.name,
        };
        this.newTeamUserList.push(teamUser);
    }
    removeNewTeamUser = (teamId) => {
        const idx = this.newTeamUserList.findIndex(teamUser => teamUser.teamId === teamId);
        this.newTeamUserList.splice(idx, 1);
    }
    cancelAddNewUser = () => {
        this.changeUiState(UI.MemberTable);
        this.newUser = {...EmptyUser2};
        this.newTeamUserList = [];
    }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    changeModifyState = (value) => {
        this.modifyState = value;
    }
    cancelModyUser = () => {
        this.changeModifyState(false);
        this.detailUser = this.orgDetailUser;
        this.detailTeamUserList = this.detailUser.teamUser;
    }
    changeDetailUserName = (intl, name, handleCheckNameState) => {
        if (name.length <= 16) {
            this.detailUser.name = name;
            if (this.orgDetailUser.name === this.detailUser.name) {
                handleCheckNameState(true, "");
            } else if (this.detailUser.name === "") {
                const msg = intl.formatMessage({id: "msg.required_nickname"});
                handleCheckNameState(false, msg);
            } else {
                const msg = intl.formatMessage({id: "msg.required_duplicate_check_nickname"});
                handleCheckNameState(false, msg);
            }
        }
    }

    changeDetailUserEmail = (intl, e, handleCheckEmailState) => {
        // if (e.target.value.indexOf("@") === -1) {
            if (e.target.name === "email") {
                const email = e.target.value;
                this.detailUser.email = email;
                    // `${email}@${this.detailUser.email.slice(this.detailUser.email.indexOf("@") + 1, this.detailUser.email.length)}`;
                if (this.orgDetailUser.email === this.detailUser.email) {
                    handleCheckEmailState(true, "");
                } else {
                    const msg = intl.formatMessage({id: "msg.required_duplicate_check_email"});
                    handleCheckEmailState(false, msg);
                }
            }
            // else {
            //     const emailAddress = e.target.value;
            //     this.detailUser.email =
            //         `${this.detailUser.email.slice(0, this.detailUser.email.indexOf("@"))}@${emailAddress}`;
            //     if (this.orgDetailUser.email === this.detailUser.email) {
            //         handleCheckEmailState(true, "");
            //     } else {
            //         const msg = intl.formatMessage({id: "msg.required_duplicate_check_email"});
            //         handleCheckEmailState(false, msg);
            //     }
            // }
            // if (this.detailUser.email === "@") {
            //     this.detailUser.email = "";
            //     const msg = intl.formatMessage({id: "msg.required_email"});
            //     handleCheckEmailState(false, msg);
            // }
        // }
    }

    changeDetailUserPassword = (intl, e, handleCheckPasswordState) => {
        const password = e.target.value;
        if (password.length <= 16) {
            this.detailUser.password = password.trim();
        }

        // if (password === "") {
        //     handleCheckPasswordState(false, intl.formatMessage({id: "msg.insert_new_password"}));
        // } else if (password.length < 8) {
        //     handleCheckPasswordState(false, intl.formatMessage({id: "msg.password_policy"}));
        // } else if (!validatePassword(password)) {
        //     handleCheckPasswordState(false, intl.formatMessage({id: "msg.password_incorrect"}));
        // } else if (validatePassword(password)) {
            handleCheckPasswordState(true, intl.formatMessage({id: "msg.available_password"}));
        // }
    }
    creatDetailUserRandomPassword = () => {
        const pw = this.randomPassword();
        this.detailUser.password = pw;
    }
    changeDetailUserType = (intl, e, handleCheckTypeState) => {
        this.detailUser.type = e.target.value;
        if (this.detailUser.type === UserType.Admin) {
            this.detailTeamUserList = [];
        } else if (this.detailUser.type === UserType.Normal) {
            this.detailTeamUserList = toJS(this.orgDetailUser.teamUser);
        }
        if (e.target.value === "") {
            handleCheckTypeState(false, intl.formatMessage({id: "msg.required_type"}));
        } else {
            handleCheckTypeState(true, "");
        }
    }
    changeDetailUserTitle = (e) => {
        const title = e.target.value;
        if (title.length <= 16) {
            this.detailUser.title = title;
        }
    }
    changeDetailUserEnabled = (e) => {
        if (e.target.value === "true") {
            this.detailUser.enabled = true;
        } else {
            this.detailUser.enabled = false;
        }
    }

    changeDetailTeamUserEnable = (e, teamId) => {
        const teamUser = this.detailTeamUserList.find( dtu => dtu.teamId === teamId);
        if (teamUser) {
            if (e.target.value === "true") {
                teamUser.enabled = true;
            } else {
                teamUser.enabled = false;
            }
        } else {
            console.warn("Con not found teamId by detailTeamUserList : teamId={}", teamId);
        }

    }
    addDetailTeamUser = (team) => {
        const teamUser = {
            userId: this.UiState.actionId,
            teamId: team.id,
            type: TeamUserType.Member,
            teamName: team.name,
        };
        this.detailTeamUserList.push(teamUser);
    }
    removeDetailTeamUser = (teamId) => {
        const idx = this.detailTeamUserList.findIndex(teamUser => teamUser.teamId === teamId);
        this.detailTeamUserList.splice(idx, 1);
    }
    checkModifyUser = () => {
        this.deleteDetailTeamUserList = this.orgDetailUser.teamUser.filter(org =>
            this.detailTeamUserList.findIndex(newValue => newValue.teamId === org.teamId && newValue.enabled === org.enabled && newValue.type === org.type) === -1
        );
        this.insertDetailTeamUserList = this.detailTeamUserList.filter(org =>
            this.orgDetailUser.teamUser.findIndex(newValue => newValue.teamId === org.teamId && newValue.enabled === org.enabled && newValue.type === org.type) === -1
        );
    }
    setDetailUser = (user) => {
        this.detailUser = user;
        this.orgDetailUser = toJS(user);
        this.detailTeamUserList = toJS(user.teamUser);

    }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    userList = {
        Leader: [],
        Member: []
    };
    searchKeyword = {
        Leader: "",
        Member: ""
    };
    lastSearchKeyword = {
        Leader: "",
        Member: ""
    };
    searchIntervalState = {
        Leader: undefined,
        Member: undefined
    };

    initSearchUserList = (type) => {
        clearInterval(this.searchIntervalState[type]);
        this.searchIntervalState[type] = undefined;
        this.lastSearchKeyword[type] = '';
        this.searchKeyword[type] = '';
        this.userList[type] = [];
        this.isProgress = false;
    }

    searchUserList = (keyword, type) => {
        this.isProgress = true;
        this.userList[type] = [];
        this.searchKeyword[type] = keyword;
        if ((this.searchIntervalState[type] === undefined) || (this.searchIntervalState[type] === null)) {
            // console.log(logPrefix, "Starting SearchInterval ...");
            this.searchIntervalState[type] = setInterval(() => this.getUserList(type), searchingInterval);
        } else {
            // console.log(logPrefix, "SearchInterval already started ...");
        }
    }

    getUserList = function* getUserList(type) {
        // console.log(logPrefix, "SearchMembers Start keyword={}", this.searchKeyword);

        if (this.searchKeyword[type] === '') {
            this.lastSearchKeyword[type] = '';
            // console.log(logPrefix, "Clear SearchInterval ...");
            clearInterval(this.searchIntervalState[type]);
            this.searchIntervalState[type] = undefined;
            this.isProgress = false;
        } else {
            if (this.searchKeyword[type] !== this.lastSearchKeyword[type]) {
                try {
                    this.lastSearchKeyword[type] = this.searchKeyword[type];
                    const response = yield axios.get(
                        this.serverContextPath + `/api/v1/users/list/${this.lastSearchKeyword[type]}`);
                    // console.log(logPrefix, "SearchMembers userResults >> ", this.lastSearchKeyword[type], ": ", response.data);
                    this.userList[type] = response.data;
                    this.isProgress = false;
                } catch (error) {
                    console.log(logPrefix, "Cannot Search Users ...", error);
                }
            } else {
                // console.log(logPrefix, "Clear SearchInterval ...");
                clearInterval(this.searchIntervalState[type]);
                this.searchIntervalState[type] = undefined;
                this.lastSearchKeyword[type] = '';
                this.isProgress = false;
            }
        }
    }


    getUserTableListByTeam = function* getUserTableListByTeam(intl, teamId) {
        this.isGetUserListLoading = true;
        this.userLoadingState = State.Pending;

        // console.log(logPrefix, "getUserTableListByTeam Start");
        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/users",
                params: {
                    teamId: teamId,
                    page: this.userTablePage.page,
                    rowsPerPage: this.userTablePage.rowsPerPage,
                    keyword: this.userTablePage.keyword,
                    type: "team"
                }
            })
            // console.log(logPrefix, "getUserTableListByTeam Done");

            this.memberTableList = response.data.userTableList.sort((a, b) => {
                if (b.type === TeamUserType.Leader) {
                    return 1;
                } else {
                    return -1;
                }
            });
            // console.log(logPrefix, "getUserTableListByTeam sort Success");
            this.userTablePage.total = response.data.userTableListCount;

            this.userLoadingState = State.Done;


        } catch (err) {
            const title = intl.formatMessage({id: "loading_member"})
            this.setUserConfirmDialogByError(intl, err.response.data, title);
            this.userLoadingState = State.Failed;
            console.log(logPrefix, "getUserTableListByTeam Failed : teamId={}", teamId);
            console.log(err);
        } finally {
            this.isGetUserListLoading = false;
        }
    }

    getUserTableListByAll = function* getUserTableListByAll() {
        this.isGetUserListLoading = true;
        this.userLoadingState = State.Pending;


        // console.log(logPrefix, "getAllUserTableList Start");

        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/users",
                params: {
                    page: this.userTablePage.page,
                    rowsPerPage: this.userTablePage.rowsPerPage,
                    keyword: this.userTablePage.keyword,
                    type: "all"
                }
            });
            this.memberTableList = response.data.userTableList;
            this.userTablePage.total = response.data.userTableListCount;

            this.userLoadingState = State.Done;
            // console.log(logPrefix, "getAllUserTableList Done");
        } catch (err) {
            this.userLoadingState = State.Failed
            console.log(logPrefix, "getAllUserTableList Failed");
            console.log(err);
        } finally {
            this.isGetUserListLoading = false;
        }
    }

    getUserTableListByNotTeam = function* getUserTableListByNotTeam() {
        this.isGetUserListLoading = true;
        this.userLoadingState = State.Pending;
        // console.log(logPrefix, "getAllUserTableList Start");

        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/users",
                params: {
                    page: this.userTablePage.page,
                    rowsPerPage: this.userTablePage.rowsPerPage,
                    keyword: this.userTablePage.keyword,
                    type: "notTeam",
                }
            });
            this.memberTableList = response.data.userTableList;
            this.userTablePage.total = response.data.userTableListCount;
            this.sortingTableList();
            this.userLoadingState = State.Done;
            // console.log(logPrefix, "getAllUserTableList Done");
        } catch (err) {
            this.userLoadingState = State.Failed;
            console.log(logPrefix, "getAllUserTableList Failed");
            console.log(err);
        } finally {
            this.isGetUserListLoading = false;
        }
    }

    sortingTableList = () => {
        this.memberTableList = this.memberTableList.sort((a, b) => {
            if (a.userType < b.userType) {
                return -1;
            } else {
                return 1;
            }
        })
    }

    getUserDetail = function* getUserDetail(userId) {
        this.userLoadingState = State.Pending;
        // console.log(logPrefix, "getUserDetail Start");
        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + `/api/v1/users/${userId}`,
                params: {type: "detail"}
            });
            this.setDetailUser(response.data);
            this.userLoadingState = State.Done;
            // console.log(logPrefix, "getUserDetail Done");
        } catch (err) {
            this.userLoadingState = State.Failed;
            console.log(logPrefix, "getUserDetail Failed");
            console.log(err);
        } finally {
            this.changeUiState(UI.Information, userId);
        }
    }

    checkUserName = function* checkUserName(intl, handleCheckNewUserNameCallback, modify) {
        // console.log(logPrefix, "checkUserName Start");
        let name = "";

        if (modify) {
            name = this.detailUser.name;
            if (this.detailUser.name === "") {
                handleCheckNewUserNameCallback(false, intl.formatMessage({id: "msg.required_nickname"}));
                return;
            } else if (!validateName(name)) {
                handleCheckNewUserNameCallback(false, intl.formatMessage({id: "msg.re_enter_nickname"}));
                return;
            } else if (this.detailUser.name === this.orgDetailUser.name) {
                handleCheckNewUserNameCallback(true, "");
                return;
            }
        } else {
            name = this.newUser.name;
            if (this.newUser.name === "") {
                handleCheckNewUserNameCallback(false, intl.formatMessage({id: "msg.required_nickname"}));
                return;
            } else if (!validateName(name)) {
                handleCheckNewUserNameCallback(false, intl.formatMessage({id: "msg.re_enter_nickname"}));
                return;
            }
        }
        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/users/check/name",
                params: {
                    name: name
                }
            });
            if (response.data) {
                const msg = intl.formatMessage({id: "msg.available_nickname"});
                handleCheckNewUserNameCallback(true, msg);
            } else {
                const msg = intl.formatMessage({id: "msg.already_using_nickname"});
                handleCheckNewUserNameCallback(false, msg);
            }
            // console.log(logPrefix, "checkUserName Done");
        } catch (err) {
            console.log(logPrefix, "checkUserName Failedmsg=", err.response.data.msg);
            console.log(err);
        } finally {
        }
    }
    checkUserEmail = function* checkUserEmail(intl, handleCheckEmailCallback, modify) {
        // console.log(logPrefix, "checkUserEmail Start");
        let email = "";
        if (modify) {
            email = this.detailUser.email;
            if (this.detailUser.email === "") {
                handleCheckEmailCallback(false, intl.formatMessage({id: "msg.required_email"}));
                return;
            } else if (!validateEmail(this.detailUser.email)) {
                handleCheckEmailCallback(false, intl.formatMessage({id: "msg.email_incorrect"}));
                return;
            } else if (this.detailUser.email === this.orgDetailUser.email) {
                handleCheckEmailCallback(true, "");
                return;
            }
        } else {
            email = this.newUser.email;
            if (this.newUser.email === "") {
                handleCheckEmailCallback(false, intl.formatMessage({id: "msg.required_email"}));
                return;
            } else if (!validateEmail(this.newUser.email)) {
                handleCheckEmailCallback(false, intl.formatMessage({id: "msg.email_incorrect"}));
                return;
            }
        }
        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/users/check/email",
                params: {
                    email: email
                }
            });
            if (response.data) {
                const msg = intl.formatMessage({id: "msg.available_email"});
                handleCheckEmailCallback(true, msg);
            } else {
                const msg = intl.formatMessage({id: "msg.already_using_email"});
                handleCheckEmailCallback(false, msg);
            }
            // console.log(logPrefix, "checkUserEmail Done");
        } catch (err) {
            console.log(logPrefix, "checkUserEmail Failed msg=", err.response.data.msg);
            console.log(err);
        } finally {
        }
    }

    createUser = function* createUser(intl) {
        this.isGetUserListLoading = true;
        this.userLoadingState = State.Pending;
        // console.log(logPrefix, "createUser Start");
        try {
            const response = yield axios({
                method: 'post',
                url: this.serverContextPath + "/api/v1/users",
                data: {
                    user : this.newUser,
                    teamUserListToCreate : this.newTeamUserList
                },
            });

            const result = response.data;
            this.getUserDetail(result);
            this.newUser = {...EmptyUser2};
            this.newTeamUserList = [];
            this.changeUiState(UI.Information, result);
            this.userLoadingState = State.Done;
            // console.log(logPrefix, "createUser Done");
        } catch (err) {
            const errDate = err.response.data;
            const title = intl.formatMessage({id: "error_modify_member"});

            let msg = "";
            if(errDate && errDate.code && errDate.code === "CanNotInsertUserByMaxUsers"){
                const errPointerStart = errDate.message.indexOf("=")+1;
                if(errPointerStart > 0){
                    const errPointer = errDate.message.slice(errPointerStart);
                    msg = `${intl.formatMessage({id: ErrorCode[errDate.code]})}\n${intl.formatMessage({id: "team_name"})} : ${errPointer}`;
                    this.setUserConfirmDialog(msg, title);
                }
            } else {
                this.setUserConfirmDialogByError(intl, errDate, title);
                this.userLoadingState = State.Failed;
            }
            console.log(logPrefix, "createUser Failed msg=", err.response.data.msg);
            console.log(err);
        } finally {
            this.isGetUserListLoading = false;
        }
    };

    modifyUser = function* modifyUser(intl) {
        this.isGetUserListLoading = true;
        this.userLoadingState = State.Pending;
        // console.log(logPrefix, "modifyUser Start");
        this.checkModifyUser();
        try {
            yield axios({
                method: 'put',
                url: this.serverContextPath + "/api/v1/users",
                data: {
                    user : this.detailUser,
                    teamUserListToRemove : this.deleteDetailTeamUserList,
                    teamUserListToCreate : this.insertDetailTeamUserList,
                },
            });
            this.deleteDetailTeamUserList = [];
            this.insertDetailTeamUserList = [];
            this.getUserDetail(this.detailUser.id);
            this.changeModifyState(false);
            this.userLoadingState = State.Done;
            // console.log(logPrefix, "modifyUser Done");
        } catch (err) {
            const errDate = err.response.data;
            const title = intl.formatMessage({id: "error_modify_member"});


            let msg = "";
            console.log("errDate : ", errDate);
            if(errDate && errDate.code && errDate.code === "CanNotInsertUserByMaxUsers"){
                const errPointerStart = errDate.message.indexOf("=")+1;
                if(errPointerStart > 0){
                    const errPointer = errDate.message.slice(errPointerStart);
                    msg = `${intl.formatMessage({id: ErrorCode[errDate.code]})}\n ${intl.formatMessage({id: "team_name"})} : ${errPointer}`;
                    this.setUserConfirmDialog(msg, title);
                }
            } else {
                this.setUserConfirmDialogByError(intl, errDate, title);
                this.userLoadingState = State.Failed;
            }

            console.log(logPrefix, "modifyUser Failed msg=", errDate.msg);
            console.log(err);
        } finally {
            this.isGetUserListLoading = false;
        }
    }

    modifyUserPassword = function* modifyUserPassword(intl, handleClose) {
        this.isGetUserListLoading = true;
        this.userLoadingState = State.Pending;
        // console.log(logPrefix, "modifyUserPassword Start");
        try {
            yield axios({
                method: 'put',
                url: this.serverContextPath + `/api/v1/users/${this.detailUser.id}/pw`,
                data: this.detailUser,
            });
            handleClose();
            this.cancelModyUser();
            this.deleteDetailTeamUserList = [];
            this.insertDetailTeamUserList = [];
            const title = intl.formatMessage({id: "change_password"});
            const msg = intl.formatMessage({id: "msg.complete_change_password"});
            this.setUserConfirmDialog(msg, title);
            this.userLoadingState = State.Done;
            // console.log(logPrefix, "modifyUserPassword Done");
        } catch (err) {
            const title = intl.formatMessage({id: "error_modify_member"});
            this.setUserConfirmDialogByError(intl, err.response.data, title);
            this.userLoadingState = State.Failed;
            console.log(logPrefix, "modifyUserPassword Failed msg=", err.response.data.msg);
            console.log(err);
        } finally {
            this.isGetUserListLoading = false;
        }
    }

    removeUser = function* removeUser(removeUserCallback){
        this.userLoadingState = State.Pending;
        // console.log("removeUser Start");
        try{
            yield axios({
                method : "delete",
                url : `/api/v1/users/${this.detailUser.id}`
            })
        }catch(err) {
            console.log(err)
        }finally {
            removeUserCallback();
        }
    }

    //////////////////////////////////////////////////////////////////////////////
    userInfo = Object.assign({}, EmptyUser);
    changeInfo = Object.assign({}, EmptyInfo);
    changeState = "";
    open = false;
    checkPwd = true;

    resetInfo = () => {
        this.changeInfo = Object.assign({}, EmptyInfo)
    }

    dialogState = () => {
        this.open = !this.open
        this.resetInfo();
    }

    closeDialogState = (value) => {
        this.open = value;
        this.resetInfo();
    }

    CheckPassword(str) {
        const pw = str;
        const num = pw.search(/[0-9]/g);
        const eng = pw.search(/[a-z]/ig);
        const spe = pw.search(/[`~!@#$%^&*()_=+-]/gi);

        // if (pw.length < 8 || pw.length > 16) {
        //     return false;
        // }
        // if (pw.search(/₩s/) !== -1) {
        //     return false;
        // }
        // if ((num < 0 && eng < 0) || (eng < 0 && spe < 0) || (spe < 0 && num < 0)) {
        //     return false;
        // }
        return true;
    }

    changeLoginPassword = (password) => {
        this.changeInfo.password = password;
    };
    changeNewPassword = (newPassword) => {
        this.changeInfo.newPassword = newPassword;
    };
    changeNewPasswordConfirm = (newPasswordConfirm) => {
        this.changeInfo.newPasswordConfirm = newPasswordConfirm;
    };

    changeUserLanguage = (language) => {
        if (this.userInfo.userConfig.find((data => data.configKey === 'Language')) === undefined) {
            const data = {configKey: 'Language', value: language}
            this.userInfo.userConfig.push(data)
            this.changeInfo.language = language
        } else {
            this.userInfo.userConfig.find((data => data.configKey === 'Language')).value = language
            this.changeInfo.language = language
        }
    }
    changeUserTimeZone = (timeZone) => {
        if (this.userInfo.userConfig.find((data => data.configKey === 'Zone')) === undefined) {
            const data = {configKey: 'Zone', value: timeZone}
            this.userInfo.userConfig.push(data)
            this.changeInfo.timeZone = timeZone
        } else {
            this.userInfo.userConfig.find((data => data.configKey === 'Zone')).value = timeZone
            this.changeInfo.timeZone = timeZone
        }
    }
    changeConfirmDialogOpen = () => {
        this.openConfirmDialog = !this.openConfirmDialog;
    }

    get isNewPassword() {
        return this.CheckPassword(this.changeInfo.newPassword);
    }

    get isPasswordConfirm() {
        return this.changeInfo.newPassword === this.changeInfo.newPasswordConfirm || this.changeInfo.newPasswordConfirm === "";
    }

    get isComplete() {
        return this.isNewPassword && this.isPasswordConfirm && this.changeInfo.password !== "" && this.changeInfo.newPasswordConfirm !== "";
    }

    get getUserConfigLanguage() {
        return this.userInfo.userConfig.find(c => c.configKey === 'Language') && this.userInfo.userConfig.find(c => c.configKey === 'Language').value;
    }

    getUserInfo = function* getUserInfo(id, callback1, callback2) {
        // console.log(logPrefix, "getUserInfo Start ... id={}", id);
        this.isGetUserListLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/users/${id}`, {params: {type: "info"}});
            // console.log(logPrefix, "getUserInfo response >> ", response.data);
            this.userInfo = response.data;
            if (this.userInfo.userConfig.find(c => c.configKey === 'Zone')) {
                DATE_UTIL.setTimeZone(this.userInfo.userConfig.find(c => c.configKey === 'Zone').value);
            } else if(this.userInfo.userConfig.find(c => c.configKey === 'Zone') === undefined || this.userInfo.userConfig.find(c => c.configKey === 'Zone') === null) {
                const localTimezone = DATE_UTIL.getLocalTimeZone();
                DATE_UTIL.setTimeZone(localTimezone);
                this.insertUserConfig(UserConfigType.Zone, localTimezone);
            }
            if (this.userInfo.userConfig.find(c => c.configKey === 'Language')) {
                DATE_UTIL.setLanguage(this.userInfo.userConfig.find(c => c.configKey === 'Language').value);
            }
        } catch (e) {
            console.log(logPrefix, "getUserInfo Error ...", e);
        } finally {
            if (callback1){
                callback1(this.getUserConfigLanguage);
            }
            if (callback2) {
                callback2();
            }
            this.isGetUserListLoading = false;
        }
    };

    changePassword = function* changePassword(intl, loginUser, callback) {
        // console.log(logPrefix, "changePassword Start ... loginUser={}", loginUser);
        this.isChangePasswordLoading = true;
        this.changeState = State.Pending;
        this.errMsg = '';
        this.alertMsg = '';
        try {
            this.changeInfo.email = loginUser.email;
            const param = this.changeInfo;
            yield axios.put(this.serverContextPath + `/api/v1/users/${loginUser.id}/profile/password`, param);
            this.changeState = State.Done;
            this.alertMsg = intl.formatMessage({id: "msg.success_change_password"});
            this.errMsg = intl.formatMessage({id: "msg.complete_change_password"});
            this.dialogState();
            if (callback) {
                callback();
            };
        } catch (e) {
            console.log(logPrefix, "changePassword Failed ... ", e);
            this.changeState = State.Failed;
            this.alertMsg = intl.formatMessage({id: "msg.error_change_password"});
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
        } finally {
            this.openConfirmDialog = true;
            this.isChangePasswordLoading = false;
        }
    };

    changeProfile = function* changeProfile(intl, changeInfo, id, callback) {
        // console.log(logPrefix, "ChangeProfile Start... changeInfo={}",changeInfo);
        this.isChangeProfile = true;
        this.changeState = State.Pending;
        try {
            const param = {
                id: id,
                name: changeInfo.name,
                userConfig: [],
            };
            for (const [key, value] of Object.entries(changeInfo)) {
                if ((key === 'language' || key === 'timeZone') && value !== null && value !=="") {
                    const config = {configKey: ConverterConfigKey[key], value: value, userId: id};
                    param.userConfig.push(config);
                }
            }
            // console.log(logPrefix, "ChangeProfile param={}",param);
            yield axios.put(this.serverContextPath + `/api/v1/users/${id}/profile`, param);
            this.getUserInfo(id);
            this.changeState = State.Done;
        } catch (e) {
            // console.log(logPrefix, "changeProfile Failed ... ", e);
            this.changeState = State.Failed;
            this.alertMsg = intl.formatMessage({id: "msg.error_change_profile"});
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;
        } finally {
            this.isChangeProfile = false;
            if(callback) {
                callback();
            };
        }
    }

    /** check locale(Language) **/

    checkConfigLanguage = (userConfigLanguage) => {
        const localLanguage = localStorage.getItem(LanguageKey);
        if (!localLanguage) {
            return;
        } else if (localLanguage && (!userConfigLanguage || userConfigLanguage === "")) {
            this.insertUserConfig(UserConfigType.Language, localLanguage);
        } else if (localLanguage === userConfigLanguage) {
            return;
        } else {
            this.changeConfigLanguage(localLanguage);
        }
    }

    insertUserConfig = function* insertUserConfig(configKey, value) {
        this.isChangeProfile = true;

        const param = Object.assign({}, EmptyUserConfig);
        param.userId = this.userInfo.id;
        param.configKey = configKey;
        param.value = value;

        try {
            const response = yield axios.put(this.serverContextPath + `/api/v1/users/${this.userInfo.id}/config/${configKey}`, param);

            // console.log(logPrefix, ' Insert UserConfig result : >> ', response.data);

            this.isChangeProfile = false;
        } catch (e) {
            this.isChangeProfile = false;

            console.log(logPrefix, ' Failed insertConfigLanguage ', e.response);
        } finally {
            this.isChangeProfile = false;
            this.getUserInfo(this.userInfo.id);
        }
    }
    

    changeConfigLanguage = function* changeConfigLanguage(language) {
        this.isChangeProfile = true;

        const param = Object.assign({}, this.userInfo.userConfig.find(c => c.configKey === UserConfigType.Language));
        param.value = language;

        try {
            const response = yield axios.post(this.serverContextPath + `/api/v1/users/${this.userInfo.id}/config/language`, param);

            // console.log(logPrefix, ' Change config language result : >> ', response.data);

            this.isChangeProfile = false;
        } catch (e) {
            this.isChangeProfile = false;

            console.log(logPrefix, ' Failed ChangeConfigLanguage ', e.response);
        } finally {
            this.isChangeProfile = false;
            this.getUserInfo(this.userInfo.id);
        }
    }

    checkName = function* checkName(intl, name, callback) {
        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/users/check/name",
                params: {
                    name: name
                }
            });
            if (response.data) {
                this.errMsg = intl.formatMessage({id: "msg.available_nickname"});
                callback(true);
            } else {
                this.errMsg = intl.formatMessage({id: "msg.already_using_nickname"});
                callback(false);
            }
            // console.log("checkUserName Done");
        } catch (err) {
            console.log("checkUserName Failedmsg=", err.response.data.msg);
            console.log(err);
        } finally {
            // this.isGetUserListLoading = false;
        }
    }


}