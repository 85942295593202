import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    BottomNavigation, BottomNavigationAction,
    Box,
    Typography
} from "@material-ui/core";
import Sample1 from "../../../../common/images/Sample1.jpg";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width:'100%',
        border: '1px solid #c4c9de',
        borderRadius: 8,
        marginBottom: 40,
        padding:'30px 20px 25px',
    },
    textStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.375rem',
        color: '#a3a8af',
        fontWeight: 600,
    },
    explanationText:{
        fontSize: '1rem',
        fontWeight: 400,
        marginTop:12
    },
    titleImgBox: {
        width: 241,
        height: 181,
        overflow: 'hidden',
        margin: 'auto',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        display: 'flex',
        margin: 'auto',
        cursor: 'pointer',
        justifyContent:'center'
    },
    imageWidth: {
        width: '100%',
        height:'auto'
    },
    imageHeight: {
        height: '100%',
        width:'auto'
    },
    buttonBox:{
        "& .MuiBottomNavigation-root":{
            justifyContent:'flex-start',
            flexWrap:'wrap',
            height:'inherit'
        },
        "& .MuiBottomNavigationAction-root.Mui-selected":{
            border:'1px solid #0097ff',
            background:'#fff',
            paddingTop:0
        },
        "& .MuiBottomNavigationAction-label.Mui-selected":{
            fontFamily: 'NanumSquareRoundOTF',
            color:'#0097ff',
            fontWeight: 600,
            fontSize: '1.375rem',
        },
        "& .MuiBottomNavigationAction-label":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.375rem',
            transaction:'none',
            transitionDelay:0,
        },
        "& .MuiBottomNavigation-root button:last-child":{
            marginRight:0
        }
    },
    toggleButtonStyle:{
        maxWidth:31,
        minWidth:31,
        height:31,
        padding:0,
        background:'rgba(163, 168, 175, 0.2)',
        color:'#a3a8af',
        marginRight:6,
        marginTop:6,
        borderRadius: 4,
        border:'1px solid rgba(163, 168, 175, 0.2)',
        "&:hover":{
            border:'1px solid #0097ff',
            background:'#fff',
            color:'#0097ff',
        }
    },
    actionText:{
        fontSize: '0.875rem',
        color: '#000',
        fontWeight: 500,
    }
});

@inject('surveyStore')
@observer
class PreviewRating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ratingValue: '',
        };
    }
    handleRatingValue = (event, ratingValue) => this.setState({ ratingValue });

    render() {
        const { classes, intl, surveyQuestion} = this.props;
        const { ratingValue } = this.state;
        const leftSelect = surveyQuestion.surveyItemOption[0].number;
        const rightSelect = surveyQuestion.surveyItemOption[surveyQuestion.surveyItemOption.length-1].number;
        const labelDefaultWidth = (37 * ((rightSelect - leftSelect) + 1));
        const maxSelect = 11;
        const labelWidth = window.innerWidth >= 1535 ? labelDefaultWidth : rightSelect >= maxSelect ? 31 * maxSelect : labelDefaultWidth;
        return (
            <div className={classes.root}>

                <Typography className={classes.textStyle} style={surveyQuestion.title !== "" ? {color: '#0d0d0d'} : {}}>
                    {surveyQuestion.title !== "" ?
                        surveyQuestion.title
                        :
                        <FormattedMessage id="msg.preview_survey_subject"/>
                    }
                    {surveyQuestion.required &&
                    <span style={{color: '#FF0000'}}>*</span>
                    }
                </Typography>

                <Typography className={clsx(classes.textStyle, classes.explanationText)}
                            style={surveyQuestion.contents !== "" ? {color: '#0d0d0d'} : {}}>
                    {surveyQuestion.contents !== "" ?
                        surveyQuestion.contents
                        :
                        <FormattedMessage id="msg.preview_survey_description"/>
                    }
                </Typography>

                {surveyQuestion.surveyItemImage.length > 0 ?
                    surveyQuestion.surveyItemImage[0].image === "" ?
                        <Box className={classes.titleImgBox} mb={2}>
                            <img src={Sample1} alt="" style={{width:'100%'}} />
                        </Box>
                        :
                        <Box className={classes.titleImgBox} mb={2}>
                            <img src={surveyQuestion.surveyItemImage[0].image} alt=""
                                 className={clsx(classes.image, (surveyQuestion.surveyItemImage[0] !== undefined && surveyQuestion.surveyItemImage[0].width >= surveyQuestion.surveyItemImage[0].height && surveyQuestion.surveyItemImage[0].width > 241) && classes.imageWidth, (surveyQuestion.surveyItemImage[0] !== undefined && surveyQuestion.surveyItemImage[0].height >= surveyQuestion.surveyItemImage[0].width && surveyQuestion.surveyItemImage[0].height > 181) && classes.imageHeight)}
                            />
                        </Box>
                    :
                    <>
                    </>
                }
                <Box className={classes.buttonBox} mt={3}>
                    <BottomNavigation value={ratingValue} showLabels onChange={this.handleRatingValue}>
                        {leftSelect <= 0 && <BottomNavigationAction  value={0} label="0" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        {leftSelect <= 1 && <BottomNavigationAction  value={1} label="1" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        <BottomNavigationAction  value={2} label="2" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>
                        {rightSelect >= 3 && <BottomNavigationAction  value={3} label="3" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        {rightSelect >= 4 && <BottomNavigationAction  value={4} label="4" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        {rightSelect >= 5 && <BottomNavigationAction  value={5} label="5" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        {rightSelect >= 6 && <BottomNavigationAction  value={6} label="6" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        {rightSelect >= 7 && <BottomNavigationAction  value={7} label="7" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        {rightSelect >= 8 && <BottomNavigationAction  value={8} label="8" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        {rightSelect >= 9 && <BottomNavigationAction  value={9} label="9" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                        {rightSelect >= 10 && <BottomNavigationAction  value={10} label="10" className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>}
                    </BottomNavigation>
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{marginTop:20, minWidth: '84px', width: labelWidth}}>
                        <Typography className={clsx(classes.textStyle, classes.actionText)}>
                            {surveyQuestion.surveyItemOption[0].contents === "" ? intl.formatMessage({id: "highly_dissatisfied"}) : surveyQuestion.surveyItemOption[0].contents}
                        </Typography>
                        <Typography className={clsx(classes.textStyle, classes.actionText)}>
                            {surveyQuestion.surveyItemOption[surveyQuestion.surveyItemOption.length -1].contents === "" ? intl.formatMessage({id: "highly_satisfied"}) : surveyQuestion.surveyItemOption[surveyQuestion.surveyItemOption.length -1].contents}
                        </Typography>
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(PreviewRating));