import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    ClickAwayListener,
    Grow,
    MenuList,
    Paper,
    Popper,
    MenuItem,
    IconButton,
    Typography,
    TextField,
    Box, Tooltip
} from "@material-ui/core";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { ReactComponent as FolderPlus } from "../../../../common/images/FolderPlus.svg";
import { ReactComponent as FolderMinus } from "../../../../common/images/FolderMinus.svg";
import { ReactComponent as MoreIcon } from "../../../../common/images/MoreIcon.svg";
import {inject, MobXProviderContext, observer} from "mobx-react";
import * as Store from "../../../../stores/TeamStore";
import {toJS} from "mobx";
import {UI} from "../../../../stores/TeamStore";
import {FormattedMessage, useIntl} from "react-intl";

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        width:'100%',
        "& .MuiPaper-root":{
            background:'#fff',
            boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
            borderRadius:7,
            border:'0.5px solid #d4d4d6',
        },
        "& .MuiMenuItem-root":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1.0rem',
            color:'#000',
        }
    },
    content: {
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#0d0d0d',
        // '$expanded > &': {
        // },
    },
    group: {
        '& $content': {
            paddingLeft:0,
        },
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        width:'100%',
        display: 'flex',
        alignItems: 'center',
        paddingTop:8,
        paddingBottom:8,
    },
    labelBox:{
        display: "flex",
        alignItems: "center",
        width:'100%',
    },
    labelIcon: {
        marginRight:5,
    },
    rootLabelText: {
        width:80,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.063rem',
        fontWeight:500,
        color:'#0d0d0d',
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap'
    },
    labelText: {
        width:65,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.063rem',
        color:'#0d0d0d',
        fontWeight:500,
        marginLeft:5,
        overflow:'hidden',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap'
    },
    labelNumber:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.063rem',
        color:'#7d86a9',
    },
    iconBox:{
        padding:0,
        "&:hover":{
            background:'transparent'
        }
    }
}));


function StyledTreeItem(props) {
    const intl = useIntl();
    const classes = useTreeItemStyles();
    const { open, labelTextField ,labelText, labelIcon: LabelIcon,labelNumber,labelType, ...other} = props;
    const {teamStore} = React.useContext(MobXProviderContext);
    const anchorRef = React.useRef(null);
    const handleToggle = (e) => {
        e.stopPropagation();
        teamStore.changeTeamTreeMenuDivOpenList(props.nodeId);
    };
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        teamStore.changeTeamTreeMenuDivOpenList("null");
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            teamStore.changeMemberTreeExpandedList("null");
        }
    }

    const handleAddTeam = async (e, type) => {
        e.stopPropagation();
        await teamStore.initTreeAction();
        teamStore.addTeamSimple(props.value, type);
        teamStore.changeTeamTreeExpendListByCustom(props.nodeId);
        teamStore.changeTeamTreeMenuDivOpenList("null");
    }

    const handleDeleteTeam = (e, value) => {
        e.stopPropagation();
        teamStore.changeTeamDeleteDialogTitle(intl.formatMessage({id: "delete"}));
        teamStore.changeTeamDeleteDialogMsg(intl.formatMessage({id: "msg.team_delete_guide"}));
        teamStore.changeTeamDeleteDialogOpen(true);
        teamStore.changeDeleteTeamId(props.value.id);
        teamStore.changeTeamTreeMenuDivOpenList("null");
    }
    const handleModyTeamName = async (e) => {
        e.stopPropagation();
        await teamStore.initTreeAction();
        teamStore.changeModyTeamSimple(props.value);
        teamStore.changeTeamTreeMenuDivOpenList("null");
    }

    const getPopoverContent = (type, value) => {
        if(type === Store.TeamType.Root) {
            return (
                <React.Fragment>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={props.open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={(e) => handleAddTeam(e, Store.TeamType.Group)}><FormattedMessage id="make_group"/></MenuItem>
                            <MenuItem onClick={(e) => handleAddTeam(e, Store.TeamType.Team)}><FormattedMessage id="make_team"/></MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </React.Fragment>
            )
        } else if(type === Store.TeamType.Group) {
            return (
                <React.Fragment>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={props.open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={handleModyTeamName}><FormattedMessage id="change_group_name"/></MenuItem>
                            <MenuItem onClick={(e) => handleDeleteTeam(e, value)}><FormattedMessage id="delete_group"/></MenuItem>
                            <MenuItem onClick={(e) => handleAddTeam(e, Store.TeamType.Team)}><FormattedMessage id="make_team"/></MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </React.Fragment>
            )
        } else if(type === Store.TeamType.Team){
            if(value === 0 ) return;
            return (
                <React.Fragment>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={props.open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                            <MenuItem onClick={handleModyTeamName}><FormattedMessage id="change_team_name"/></MenuItem>
                            <MenuItem onClick={(e) => handleDeleteTeam(e, value)}><FormattedMessage id="delete_team"/></MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </React.Fragment>
            )
        }
    }

    const onLabelClick = async (event) =>{
        if(props.value ===0){
            return;
        }
        event.preventDefault();
        if (teamStore.teamTreeSelectedTeamId !== props.nodeId) {
            if (props.value === "-1") {
                teamStore.initTeamTablePage();
                teamStore.changeTeamTreeExpendListByCustom(props.nodeId);
                teamStore.getTeamTableList();
                teamStore.changeUiState(UI.TeamTable);
            } else {
                const team = props.value;
                if (team.type === Store.TeamType.Team) {
                    teamStore.initTeamTablePage();
                    teamStore.getTeamDetail(intl, team.id);
                    teamStore.changeUiState(UI.Information);
                } else if (team.type === Store.TeamType.Group) {
                    teamStore.initTeamTablePage();
                    teamStore.changeTeamTreeExpendListByCustom(props.nodeId);
                    teamStore.getTeamTableList(team.id);
                    teamStore.changeUiState(UI.TeamTable);
                }
            }
        }
    }

    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <Tooltip title={labelText}>
                        <div className={classes.labelBox}>
                            <LabelIcon color="inherit" className={classes.labelIcon} />
                            {labelText === "labelTextField" || labelText === "labelTextField" ?
                                <div  variant="body2" className={labelType === Store.TeamType.Root ? classes.rootLabelText : classes.labelText  }>
                                    {labelTextField}
                                </div>
                                :
                                <Typography noWrap variant="body2" className={labelType === Store.TeamType.Root ? classes.rootLabelText : classes.labelText  }>
                                    {labelText}
                                </Typography>
                            }
                            {labelType === Store.TeamType.Team ?
                                null
                            :
                                <Typography className={classes.labelNumber}>({labelNumber})</Typography>
                            }
                        </div>
                    </Tooltip>
                    <div>
                        <IconButton
                            ref={anchorRef}
                            aria-controls={props.open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={(e) => handleToggle(e)}
                            className={classes.iconBox}
                        >
                            <MoreIcon/>
                        </IconButton>
                        <Popper open={props.open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex:5000}}>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                >
                                    <Paper>
                                        {getPopoverContent(labelType, props.value)}
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </div>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}


            onLabelClick={onLabelClick}
            {...other}
        />
    );
}
StyledTreeItem.propTypes = {
    labelIcon: PropTypes.elementType.isRequired,
    labelNumber: PropTypes.number,
    labelText: PropTypes.string.isRequired,
    labelTextField : PropTypes.object,
    open : PropTypes.bool,
};

const useStyles = makeStyles({
    root:{
        paddingRight:5,
        minHeight:100,
        maxHeight:320,
        marginBottom:5,
        overflowY:'scroll',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    treeViewBox: {
        marginTop:45,
        maxWidth:'100%',
        width:'100%'
    },
    tabletTreeViewBox:{
        maxWidth:'100%',
        width:'100%'
    }
});

const handleLabelIcon = (team) =>{
    if(!team.childTeam) return <FolderMinus/>;
    else if(team.childTeam.length > 0) return <FolderPlus/>;
    else return <FolderMinus/>;
}


function TabletTeamTreeView(props) {
    const intl = useIntl();
    const classes = useStyles();
    const {teamTreeList, modyTeam, newTeamSimple, teamTreeMenuDivOpenList} = props.teamStore;
    const {orgConfigs} = props.orgStore;
    const { isTablet } = props;

    let sortedtTeamTreeList = toJS(teamTreeList);

    const createGroup = (e) =>{
        if (e.isComposing || e.keyCode === 229) return;
        if(e.keyCode === 13) props.teamStore.createTeamSimple(intl);
        else if(e.keyCode === 27) props.teamStore.addTeamCancelSimple();
    }
    const modyGroup = (e) =>{
        if (e.isComposing || e.keyCode === 229) return;
        if(e.keyCode  === 13) props.teamStore.modifyTeamSimple(intl);
        else if(e.keyCode === 27) props.teamStore.modifyTeamCancelSimple();
    }
    const handleClickTextField = (e) => {
        e.stopPropagation();
    }
    const handleChangeTeamTreeSelectedTeamId = (e, node) =>{
        props.teamStore.changeTeamTreeSelectedTeamId(node)
    }
    return (
        <Box className={classes.root}>
            <TreeView
                className={isTablet? classes.tabletTreeViewBox : classes.treeViewBox}
                defaultCollapseIcon={<ArrowDropDownIcon />}
                defaultExpandIcon={<ArrowRightIcon />}
                expanded={props.teamStore.teamTreeExpandedList}
                onNodeToggle={props.teamStore.changeTeamTreeExpandedList}
                selected={props.teamStore.teamTreeSelectedTeamId}
                onNodeSelect={handleChangeTeamTreeSelectedTeamId}
                // onPointerDown={handleChangeSelected}
            >
                <StyledTreeItem value="-1"
                                nodeId={"-1"}
                                key={"-1"}
                                open={teamTreeMenuDivOpenList === "-1"}
                                labelNumber={teamTreeList.length}
                                labelType={Store.TeamType.Root}
                                labelText={orgConfigs.OrganizationName ?
                                                orgConfigs.OrganizationName === ''
                                                    ? intl.formatMessage({id: "my_organization"})
                                                    : orgConfigs.OrganizationName
                                            : intl.formatMessage({id: "my_organization"})}
                                labelIcon={FolderPlus}
                >
                    {sortedtTeamTreeList &&
                    sortedtTeamTreeList.sort((a,b) => {return a.id-b.id}).map(team => {
                            return team.id !==0 && !team.isMody ? (
                                <StyledTreeItem value={team}
                                                nodeId={team.id.toString()}
                                                id={team.id.toString()}
                                                key={team.id.toString()}
                                                open={teamTreeMenuDivOpenList === team.id.toString()}
                                                labelNumber={team.childTeam.length}
                                                labelType={team.type}
                                                labelText={team.name}
                                                labelIcon={()=>handleLabelIcon(team)}
                                >
                                {team.childTeam && team.childTeam.length > 0 &&
                                    team.childTeam.sort((a,b) => {return a.id-b.id}).map(child => {
                                        return child.id !==0 && !child.isMody? (
                                            <StyledTreeItem value={child}
                                                            nodeId={child.id.toString()}
                                                            id={child.id.toString()}
                                                            open={teamTreeMenuDivOpenList === child.id.toString()}
                                                            labelNumber={0}
                                                            key={child.id.toString()}
                                                            labelType={child.type}
                                                            labelText={child.name}
                                                            labelIcon={FolderMinus}
                                            />
                                        ):(
                                            child.isMody ? (
                                                <StyledTreeItem  value={modyTeam.id}
                                                                 nodeId={modyTeam.id.toString()}
                                                                 id={modyTeam.id}
                                                                 key={modyTeam.id.toString()}
                                                                 open={false}
                                                                 labelType={modyTeam.type}
                                                                 labelText={"labelTextField"}
                                                                 labelTextField={
                                                                    <labelTextField value={modyTeam.name}
                                                                               onClick={handleClickTextField}
                                                                               onChange={props.teamStore.changeModyTeamName}
                                                                               onKeyDown={(e) =>modyGroup(e)}/>
                                                                }
                                                                labelIcon={()=>handleLabelIcon(modyTeam)}/>
                                            ):(
                                                <StyledTreeItem value={0}
                                                                nodeId={"0"}
                                                                id={"0"}
                                                                key={"0"}
                                                                open={false}
                                                                labelNumber={0}
                                                                labelType={newTeamSimple.type}
                                                                labelText={"labelTextField"}
                                                                labelTextField={
                                                                    <TextField value={newTeamSimple.name}
                                                                               onClick={handleClickTextField}
                                                                               onChange={props.teamStore.changeNewTeamSimpleName}
                                                                               onKeyDown={(e) =>createGroup(e)}/>
                                                                }
                                                                labelIcon={FolderMinus}/>
                                            )
                                        )
                                })}
                            </StyledTreeItem>
                            ) : (
                                team.isMody ? (
                                    <StyledTreeItem value={modyTeam.id}
                                                    nodeId={modyTeam.id.toString()}
                                                    id={modyTeam.id}
                                                    key={modyTeam.id.toString()}
                                                    open={false}
                                                    labelNumber={modyTeam.childTeam.length}
                                                    labelType={modyTeam.type}
                                                    labelText={"labelTextField"}
                                                    labelTextField={
                                                        <TextField value={modyTeam.name}
                                                                   onClick={handleClickTextField}
                                                                   onChange={props.teamStore.changeModyTeamName}
                                                                   onKeyDown={(e) =>modyGroup(e)}/>
                                                    }
                                                    labelIcon={()=>handleLabelIcon(modyTeam)}>

                                        {team.childTeam && team.childTeam.length > 0 &&
                                        team.childTeam.sort((a,b) => {return a.id-b.id}).map(child => {
                                            return (
                                                <StyledTreeItem value={child}
                                                                nodeId={child.id.toString()}
                                                                id={child.id.toString()}
                                                                key={child.id.toString()}
                                                                open={teamTreeMenuDivOpenList === child.id.toString()}
                                                                labelNumber={0}
                                                                labelType={child.type}
                                                                labelText={child.name}
                                                                labelIcon={FolderMinus}
                                                />
                                            )
                                        })}
                                    </StyledTreeItem>
                                    ):(
                                    <StyledTreeItem value={0}
                                                    nodeId={"0"}
                                                    id={"0"}
                                                    key={"0"}
                                                    open={false}
                                                    labelNumber={0}
                                                    labelType={newTeamSimple.type}
                                                    labelText={"labelTextField"}
                                                    labelTextField={
                                                        <TextField value={newTeamSimple.name}
                                                                   onClick={handleClickTextField}
                                                                   onChange={props.teamStore.changeNewTeamSimpleName}
                                                                   onKeyDown={(e) =>createGroup(e)}/>
                                                    }
                                                    labelIcon={FolderMinus}>

                                    </StyledTreeItem>
                            )
                            )
                    })}
                </StyledTreeItem>
            </TreeView>
        </Box>

    );
}
export default inject('teamStore', 'authStore', 'orgStore')(observer(TabletTeamTreeView))






