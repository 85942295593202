import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Typography
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";

const style = theme => ({
    root: {
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width: '100%',
        marginBottom: 70
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.375rem',
        color: '#a3a8af',
        fontWeight: 600,
    },
    typoStyle: {
        fontSize: '1.125rem',
        fontWeight: 400,
    },
});

@inject("surveyStore")
@observer
class PreviewParagraph extends Component {
    render() {
        const {classes, surveyStore} = this.props;
        return (
            <div className={classes.root}>
                <Typography className={classes.textStyle}
                            style={surveyStore.survey.title !== "" ? {color: '#0d0d0d'} : {}}>{surveyStore.survey.title === "" ?
                    <FormattedMessage id="msg.preview_survey_subject"/> : surveyStore.survey.title} </Typography>
                <Box style={{marginTop: 50}}>
                    <Typography className={clsx(classes.textStyle, classes.typoStyle)}
                                style={surveyStore.survey.descriptions !== "" ? {color: '#0d0d0d'} : {}}>{surveyStore.survey.descriptions === "" ? <FormattedMessage id="msg.preview_survey_description"/> :
                         surveyStore.survey.descriptions } </Typography>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(PreviewParagraph));