import React, {useEffect} from "react";
import {
    Box,
    Paper,
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import {deepPurple} from "@material-ui/core/colors";
import PreviewTopBar from "./Previews/PreviewTopBar";
import PreviewSideBar from "./Previews/PreviewSideBar";
import PreviewTimeline from "./Previews/PreviewTimeline";


const useStyles = makeStyles((theme) => ({
    topBox: {
        '& > span': {
            margin: theme.spacing(2),
        },
        minWidth : '100%',
        maxHeight : 500,
        display :'flex'
    },
    purple: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
}))

function TimelinePreview(props) {
    const classes = useStyles();
    const { setPreviewConfigs, previewConfigs } = props.orgStore;

    useEffect(() => {
        if(Object.values(previewConfigs).length === 0) {
            setPreviewConfigs();
        } else return;
    }, [previewConfigs, setPreviewConfigs])

    return (
        <Paper className={classes.topBox}>
            <PreviewSideBar mainColor={previewConfigs.MainColor} subColor={previewConfigs.SubColor}/>
            <Box style={{width : "-webkit-fill-available"}}>
                <PreviewTopBar mainColor={previewConfigs.MainColor} subColor={previewConfigs.SubColor}/>
                <Box>
                    <PreviewTimeline
                        mainColor={previewConfigs.MainColor}
                        subColor={previewConfigs.SubColor}
                        mainBtnColor={previewConfigs.MainBtnColor}/>
                </Box>
            </Box>
        </Paper>
    )
}

export default inject('orgStore')(observer(TimelinePreview));
