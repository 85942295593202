import React, {Component} from 'react';
import clsx from 'clsx';
import {withStyles} from "@material-ui/core/styles";
import {Typography, Box} from "@material-ui/core";
import { ResponsiveBar } from '@nivo/bar'
import {useTheme} from "@nivo/core";
import MaterialTable from "material-table";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";


const style = theme => ({
    root:{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        width: '100%',
        marginTop:20,
        marginBottom: 20
    },
    myResponsiveBarBox:{
        height: window.innerHeight/1.8,
    },
    chartBox:{
        position:'absolute',
        right:0,
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:600,
        color:'#5a6069',
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:300,
        color:'#5a6069',
    },
    circleStyle1:{
        width:12,
        height:12,
        borderRadius:'50%',
        background:'#0024ff',
        content:'',
        marginRight:5
    },
    circleStyle2:{
        width:12,
        height:12,
        borderRadius:'50%',
        background:'#4fb6fe',
        content:'',
        marginRight:5
    },
    labelStyle:{
        fontSize:'1rem',
        color:'#000',
        fontWeight:400
    }
});

@inject('statisticsStore')
@observer
class MobileStatisticsSurveyItemChart extends Component {
    render() {
        const { classes, intl, statisticsStore } = this.props;
        const { selectedSurveyItem } = this.props.statisticsStore;
        const chartData = statisticsStore.getSurveyItemAnswerList(intl);

        const CustomBottomTick = tick => {
            const theme = useTheme();

            const TEXT_MAX_LENGTH = 6;
            const getShortenedText = (length, text) => text.length > length ? text.substr(0, TEXT_MAX_LENGTH) + '...' : text;
            return (
                <g transform={`translate(${tick.x},${tick.y + 22})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: '#7b8288',
                            fontSize: '1rem',
                            fontFamily:'NanumSquareRoundOTF',
                            fontWeight:600
                        }}
                    >
                        {getShortenedText(TEXT_MAX_LENGTH, tick.value)}
                    </text>
                </g>
            )
        }

        const CustomLeftTick = tick => {
            const theme = useTheme();
            return (
                <g transform={`translate(${tick.x - 22},${tick.y})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: '#7b8288',
                            fontSize: '1rem',
                            fontFamily:'NanumSquareRoundOTF',
                            fontWeight:600
                        }}
                    >
                        {tick.value}
                    </text>
                </g>
            )
        }

        const TotalLabels = ({ bars, yScale }) => {
            const labelMargin = 20;
            return bars.map(({ data: { data, indexValue }, x, width }, i) => {
                const yScaleStandard = data.count;
                return (
                    <g
                        transform={`translate(${x}, ${yScale(yScaleStandard ? yScaleStandard : 0) - labelMargin})`}
                        key={`${indexValue}-${i}`}
                    >
                        <text
                            // add any class to the label here
                            className="bar-total-label"
                            x={width / 2}
                            y={labelMargin / 2}
                            textAnchor="middle"
                            alignmentBaseline="central"
                            // add any style to the label here
                            style={{
                                fill: '#0047ac',
                                fontSize: '1rem',
                                fontFamily:'NanumSquareRoundOTF',
                                fontWeight:600
                            }}
                        >
                            {data.count}{data.count > 0 && <FormattedMessage id="person"/>}
                        </text>
                    </g>
                );
            });
        };

        const MyResponsiveBar = ({ data /* see data tab */ }) => (
            <ResponsiveBar
                data={data}
                keys={[ 'count' ]}
                indexBy="name"
                margin={{ top: 20, right: 40, bottom: 50, left: 40 }}
                padding={0.2}
                // groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                borderRadius={5}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', '0.8' ] ] }}
                colors={({ id, data }) => data.color }
                label={false}
                isInteractive={true}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                axisBottom={{
                    renderTick: CustomBottomTick,
                }}
                axisLeft={{
                    renderTick: CustomLeftTick,
                }}
                layers={["grid", "axes", "bars", TotalLabels, "markers", "legends"]}
                tooltip={( props ) => {
                    return (
                        <Typography  style={{fontFamily:'NanumSquareRoundOTF',color:'#0047ac',fontSize:'1.2rem'}}>
                            <span style={{fontWeight:600}}>{props.data.users}</span>
                        </Typography>
                    )
                }}
            />
        )

        const columns = [
            {
                title: intl.formatMessage({id: "student"}),
                field: 'userName',
                cellStyle: {
                    position: 'sticky',
                    left: 0,
                    maxWidth:75,
                    zIndex: 999,
                    background:'#fff',
                    fontFamily: 'NanumSquareRoundOTF',
                    fontSize: '1rem',
                    fontWeight: 300,
                    color: '#000',
                },
                headerStyle: {
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    maxWidth:75,
                }
            },
            {title: intl.formatMessage({id: "answer"}), field: 'answer'}
        ];

        return (
            <div className={classes.root}>
                {selectedSurveyItem.type !== "ShortAnswer" ?
                    <Box className={classes.myResponsiveBarBox}>
                        {MyResponsiveBar({data: chartData})}
                        {/* 점수 척도형일때만 보여짐 */}
                        {(selectedSurveyItem.type === "Rating" && chartData.length > 1) &&
                            <Box display='flex' justifyContent='space-between' alignItems='center'
                                 style={{marginRight: 40, marginLeft: 40}}>
                                <Typography className={clsx(classes.textStyle, classes.labelStyle)}>{chartData[0].contents !== "" ? chartData[0].contents : intl.formatMessage({id: "highly_dissatisfied"})}</Typography>
                                <Typography className={clsx(classes.textStyle, classes.labelStyle)}>{chartData[chartData.length - 1].contents !== "" ? chartData[chartData.length - 1].contents : intl.formatMessage({id: "highly_satisfied"})}</Typography>
                            </Box>
                        }
                    </Box>
                    :
                    <MaterialTable
                        style={{
                            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
                            width: "100%",
                            height: "100%"
                        }}
                        title=""
                        columns={columns}
                        data={statisticsStore.getSurveyShortAnswerList}
                        options={{
                            pageSize: 5,
                            headerStyle: {
                                wordBreak: "keep-all",
                                fontFamily: 'NanumSquareRoundOTF',
                                fontSize: '1.3rem',
                                fontWeight: 600,
                                color: '#000',
                            },
                            cellStyle: {
                                wordBreak: "keep-all",
                                fontFamily: 'NanumSquareRoundOTF',
                                fontSize: '1.3rem',
                                fontWeight: 300,
                                color: '#000',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden'

                            },
                            pageSizeOptions: [5]
                        }}
                        localization={{
                            pagination: {
                                labelRowsSelect: intl.formatMessage({id: "table_rows_count"}),
                                labelDisplayedRows: intl.formatMessage({id: "table_bottom_text"}),
                            },
                        }}
                    />
                }
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobileStatisticsSurveyItemChart));