import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Box, FormControl, NativeSelect, InputBase,AppBar,Toolbar} from "@material-ui/core";
import SignIn from "./SignIn";
import TabletSignIn from "./TabletSignIn";
import {inject, observer} from "mobx-react";
import {Languages} from "../../common/Languages";

const styles = theme => ({
    appBar: {
        width:'100%',
        boxShadow:'none',
        background:'#fff'
    },
    topBar:{
        width:'100%',
        height:60,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    margin: {
        margin: theme.spacing(1),
    },
});

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: '1.063rem',
        padding: '7px 26px 7px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        lineHeight: '20px',
        fontFamily: [
            'Noto Sans',
            'Noto Sans KR',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

@inject('orgStore', 'localeStore')
@observer
class MainTopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLocale : '',
        }
    }

    componentDidMount() {
        this.setState({selectedLocale : this.props.localeStore.locale})
    }

    onChange = (selectLanguage) => {
        this.setState({selectedLocale : selectLanguage},  ()=> {
            this.props.localeStore.changeLocale(this.state.selectedLocale)
        });

    }

    render() {
        const { classes, isTablet } = this.props;
        const { orgImages } = this.props.orgStore;
        const orgConfigs = {...this.props.orgStore.orgConfigs};

        return (
            // <Container maxWidth="xl">
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <div className={classes.topBar}>
                        <Box>
                            {
                                orgImages.Logo !== undefined && orgImages.Logo !== "" &&
                                <img
                                    src={orgImages.Logo}
                                    //src={DEFAULT_ORG_IMAGE.Logo}
                                    alt="LogoImage"
                                    style={{width:'auto', height : 'auto', objectFit : 'contain'}}
                                />
                            }
                        </Box>
                        <Box display={"flex"} justifyContent={'flex-end'} width={250} alignItems={'center'}>
                            <Box>
                                <FormControl className={classes.margin}>
                                    {/*<InputLabel htmlFor={"MainTopBar-language-select-label"}>language</InputLabel>*/}
                                    <NativeSelect
                                        // className={classes.selectEmpty}
                                        // labelId={"MainTopBar-language-select-label"}
                                        id={"MainTopBar-language-select"}
                                        value={this.state.selectedLocale}
                                        onChange={(e)=>this.onChange(e.target.value)}
                                        input={<BootstrapInput />}
                                    >
                                        {
                                            Object.entries(Languages).map(([key, value]) =>
                                                <option key={key} value={key}>{value}</option>)
                                        }
                                    </NativeSelect>
                                </FormControl>
                            </Box>
                            <Box>
                                {isTablet ?
                                    <TabletSignIn configs={orgConfigs}/>
                                    :
                                    <SignIn configs={orgConfigs}/>
                                }
                            </Box>
                        </Box>
                    </div>
                </Toolbar>
            </AppBar>
            // </Container>
        );
    }
}

export default (withStyles(styles)(MainTopBar));