import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Button, Typography} from "@material-ui/core";
import MaterialTable from "material-table";
import {inject, observer} from "mobx-react";
import {DATE_UTIL} from "../../../common/util/date.util";
import MobileQuizDetailDialog from "./MobileQuizDetailDialog";
import {ReactComponent as ProhibitIcon} from "../../../common/images/ProhibitIcon.svg";
import {FormattedMessage, injectIntl} from "react-intl";
import {Link} from "react-router-dom";

const style = theme => ({
    root:{
        padding:'0 20px',
        "& .MuiInputBase-root":{
            width:150
        },
        "& .MuiInputBase-input":{
            "&::placeholder":{
                fontFamily:'NanumSquareRoundOTF',
                fontSize:'1rem'
            }
        },
        "& .MuiIcon-root":{
            fontSize:'1.5rem'
        },
        "& .MuiTablePagination-select > div":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1rem'
        },
        "& .MuiTypography-caption":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1rem'
        },
        "& .MuiIconButton-root":{
            padding:5
        }

    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.25rem',
        fontWeight:600,
        color:'#0d0d0d',
    },
    imageText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        fontWeight:800,
        color:'#0d0d0d'
    },
    noDataBox:{
        textAlign:'center',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate( -50%, -50% )'
    },
});

@inject('statisticsStore')
@observer
class MobileMemberActivity extends Component {
    render() {
        const {classes, intl, statisticsStore} = this.props;
        const { userSummaryList } = this.props.statisticsStore;

        const chartData = [];
        userSummaryList.forEach((us) => {
            chartData.push({
                "roomId": us.roomId,
                "externalKey": us.externalKey,
                "userName": us.userName,
                "enterDatetime": us.enterDatetime !== null ? DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(us.enterDatetime), 'YYYY. M. D A hh:mm:ss') : '',
                "exitDatetime": us.exitDatetime !== null ? DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(us.exitDatetime), 'YYYY. M. D A hh:mm:ss') : '',
                "playTime": parseInt(us.playTime / 60) + intl.formatMessage({id: "minute"}),
                "connectCount": us.historyCount,
                "attendCount": us.responseCount + '/' + us.attendanceCount + intl.formatMessage({id: "count"}),
                "presentationCount": us.presentationCount + intl.formatMessage({id: "count"}),
                "questionCount": us.questionCount + intl.formatMessage({id: "count"}),
                "quizResults": us.quizResults,
                "surveyResponse": us.surveyResponse
            });
        })

        const columns = [
            {title: "roomId", field: 'roomId', hidden: true},
            {title: "externalKey", field: 'externalKey', hidden: true},
            {
                title: intl.formatMessage({id: "student"}),
                field: 'userName',
                cellStyle: {
                    position: 'sticky',
                    left: 0,
                    maxWidth:75,
                    zIndex: 999,
                    background:'#fff',
                    fontFamily: 'NanumSquareRoundOTF',
                    fontSize: '1rem',
                    fontWeight: 300,
                    color: '#000',
                },
                headerStyle: {
                    position: 'sticky',
                    left: 0,
                    zIndex: 999,
                    maxWidth:75,
                }
            },
            {title: intl.formatMessage({id: "first_enter_time"}), field: 'enterDatetime'},
            {title: intl.formatMessage({id: "last_exit_time"}), field: 'exitDatetime'},
            {title: intl.formatMessage({id: "play_time"}), field: 'playTime'},
            {title: intl.formatMessage({id: "access_count"}), field: 'connectCount'},
            {title: intl.formatMessage({id: "attendance_check_count"}), field: 'attendCount'},
            {title: intl.formatMessage({id: "presentation_count"}), field: 'presentationCount'},
            {title: intl.formatMessage({id: "question_count"}), field: 'questionCount'},
            {title: intl.formatMessage({id: "quiz_results"}), field: 'quizResults',
                render: rowData => (rowData.quizResults.length > 0) ? <Button style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)', color: 'black', wordBreak: "keep-all", fontFamily: 'NanumSquareRoundOTF', fontSize: '0.8rem',}} onClick={() => this.props.statisticsStore.setQuizDetailDialogOpen(true, rowData.quizResults)}> <FormattedMessage id="see_more"/> </Button> : ''
            },
            {title: intl.formatMessage({id: "survey_response"}), field: 'surveyResponse',
                render: rowData => rowData.surveyResponse ? <Link to={`/rooms/${rowData.roomId}/users/${rowData.externalKey}/statistics`}>Yes</Link> : 'No'
            },
        ];

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}><FormattedMessage id="result_student_activities"/></Typography>
                {chartData.length > 0 &&
                    <MaterialTable
                        style={{
                            boxShadow:'none',
                            border:'none',
                            padding: 0
                        }}
                        title=""
                        columns={columns}
                        data={chartData}
                        options={{
                            pageSize: 5,
                            headerStyle: {
                                wordBreak: "keep-all",
                                fontFamily: 'NanumSquareRoundOTF',
                                fontSize: '1rem',
                                fontWeight: 600,
                                color: '#000',
                                paddingTop:20,
                                paddingBottom:5,
                            },
                            cellStyle: {
                                wordBreak: "keep-all",
                                fontFamily: 'NanumSquareRoundOTF',
                                fontSize: '1rem',
                                fontWeight: 300,
                                color: '#000',
                                paddingTop:5,
                                paddingBottom:5,
                            },
                        }}
                        localization={{
                            pagination: {
                                labelRowsSelect: intl.formatMessage({id: "table_rows_count"}),
                                labelDisplayedRows: intl.formatMessage({id: "table_bottom_text"}),
                            },
                        }}
                    />
                }
                {(chartData.length === 0 && !statisticsStore.getIsLoading) &&
                    <div style={{position: "relative", width: "100%"}}>
                        <img style={{width: "100%", height: "100%"}} src="/images/NoData_MemberActivity.png" alt="No Data"/>
                        <div className={classes.noDataBox}>
                            <ProhibitIcon />
                            <Typography className={classes.imageText}><FormattedMessage id="msg.no_display_data"/></Typography>
                        </div>
                    </div>
                }

                <MobileQuizDetailDialog />
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(MobileMemberActivity));