import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {
    Box, Button,
} from "@material-ui/core";
import QuizCreateContents from "./QuizCreateContents";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";
import ConfirmDialog from "../../../../common/ConfirmDialog";
import { ReactComponent as TopIcon } from "../../../../common/images/TopIcon.svg";
import {COLOR_UTIL} from "../../../../common/util/color.util";

const style = theme => ({
    root:{
        padding:'100px 20px 0 160px',
        width: '100%',
    },
    createContents:{
        overflowY:'scroll',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    buttonBox:{
        paddingRight:35
    },
    buttonBoxIn:{
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width:'100%',
    },
    btnStyle: {
        padding: '9px 38px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        fontWeight: 800,
        // color: '#fff',
        marginRight: 10,
        borderRadius: 7,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1: {
        padding: '9px 38px',
        background: '#a3a8af',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        fontWeight: 800,
        color: '#fff',
        marginRight: 10,
        borderRadius: 7,
        "&:hover": {
            background: '#a3a8af'
        }
    },
    topButtonStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        color:'#000',
        "& .MuiButton-label":{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            alignContent:'center'
        },
        "& .MuiButton-startIcon":{
            margin:'0 0 5px',
        }
    }
});

@inject('quizStore', 'authStore', 'progressStore')
@observer
class QuizCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollTop: 0,
        };
    }

    componentDidMount() {
        this.props.quizStore.initQuiz(this.props.authStore.loginUser);
        const targetEl = document.getElementById('quizCreateContents');
        targetEl.onscroll = this.handleScrollEvent;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {quizStore, progressStore} = this.props;
        progressStore.setIsLoading(quizStore.getIsLoading)
    };

    handleScrollEvent = () => {
        const targetEl = document.getElementById('quizCreateContents');
        this.setState({scrollTop: targetEl.scrollTop});
    };

    componentWillUnmount() {
        const targetEl = document.getElementById('quizCreateContents');
        targetEl.removeEventListener('scroll', this.handleScrollEvent);
    };

    handleCancelSubmit = () => {
        this.props.history.push('/mytimeline');
    };

    handleInsertQuiz = () => {
        const {quizStore, history, intl} = this.props;
        quizStore.insertQuiz(intl, history);
    };

    render() {
        const { classes, quizStore, intl } = this.props;
        return (
            <div className={classes.root}>
                <Box id='quizCreateContents' className={classes.createContents} style={{height: 'calc(100vh - 232px)'}}>
                    <QuizCreateContents/>
                </Box>
                <Box display="flex" alignItems='center' style={{margin:'40px 55px 0 20px'}}>
                    <Box className={classes.buttonBox} style={{width:'calc(50% - 45px)'}}>
                        <Box display="flex" justifyContent='flex-end' alignItems='center' className={classes.buttonBoxIn}>
                            <Button className={classes.btnStyle1} disabled={quizStore.isQuizLoading} onClick={this.handleCancelSubmit} ><FormattedMessage id="cancel"/></Button>
                            <Button className={classes.btnStyle} disabled={quizStore.isQuizLoading} onClick={this.handleInsertQuiz} ><FormattedMessage id="save"/></Button>
                        </Box>
                    </Box>
                    {this.state.scrollTop > 0 &&
                        <Button startIcon={<TopIcon/>} className={classes.topButtonStyle} onClick={() => {
                                const targetEl = document.getElementById('quizCreateContents');
                                targetEl.scroll({
                                    top: 0,
                                    behavior: 'smooth'
                                });
                                setTimeout(() => this.handleScrollEvent(), 500);
                            }
                        }>
                            <FormattedMessage id="go_to_the_top"/>
                        </Button>
                    }

                </Box>
                <ConfirmDialog open={quizStore.openConfirmDialog}
                               title={intl.formatMessage({id: "msg.input_incorrect"})}
                               msg={quizStore.errMsg}
                               changeDialogOpen={quizStore.changeConfirmDialogOpen}
                />
                {/*<DeleteDialog open={surveyStore.opeDeleteDialog}*/}
                {/*              title={intl.formatMessage({id: "survey"})}*/}
                {/*              msg={intl.formatMessage({id: "msg.check_delete"})}*/}
                {/*              changeDialogOpen={surveyStore.changeOpeDeleteDialog}*/}
                {/*              submit={this.handleCancelSubmit}*/}
                {/*/>*/}
            </div>
        );
    }
}

export default  withRouter(withStyles(style)(injectIntl(QuizCreate)));