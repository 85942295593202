import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Typography,
    Box,
    FormControlLabel,
    Checkbox, Button, Input,
} from "@material-ui/core";
import {ReactComponent as UnCheckedBox} from "../../../common/images/UnCheckedBox.svg";
import {ReactComponent as CheckedBox} from "../../../common/images/CheckedBox.svg";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {DATE_UTIL} from "../../../common/util/date.util";
import XLSX from "xlsx";
import {validateNumber} from "../../../common/Validation";

const style = theme => ({
    root: {},
    labelText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: '#0d0d0d',
        width: 180,
    },
    checkBox: {
        width: '100%',
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.125rem',
            color: '#0d0d0d',
            fontWeight: 600
        }
    },
    guestCheckText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#333',
    },
    inputStyle1: {
        width: '35%',
        marginRight: 10,
        padding: 10,
        borderRadius: 6,
        background: '#fff',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        margin: '10px 0 9px',
        "&:focus": {
            outline: 'none !important'
        }
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        color: '#666666'
    },
    inputStyle: {
        width: '25%',
        padding: '10px',
        marginRight: 10,
        marginBottom: 5,
        borderRadius: 5,
        border: '1px solid #b7bcd6',
        background: '#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        "&:focus": {
            outline: 'none',
        },
    },
    inputStyle2: {
        width: '50%',
        padding: '10px',
        marginRight: 10,
        borderRadius: 5,
        border: '1px solid #b7bcd6',
        background: '#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        "&:focus": {
            outline: 'none',
        },
    },
    guestBox: {
        width: '100%',
        maxHeight: 230,
        overflowY: 'scroll',
        marginBottom: 5,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
    },
    buttonStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        border: '1px solid #b7bcd6',
        padding: '8px 14px',
        background:'#fff'
    },
    btnStyle: {
        padding: '8px 14px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        fontWeight: 600,
        color: '#fff',
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
});

@inject('roomStore','excelStore')
@observer
class GuestParticipation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guestParticipation: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.didMountRoomInfo !== undefined) {
            if ((this.props.didMountRoomInfo !== prevProps.didMountRoomInfo) && (this.props.roomStore.roomInfo.guestNum !== 0)) {
                this.setState({guestParticipation: true})
            }
        }
    }

    componentWillUnmount() {
        this.setState({guestParticipation: false})
    }

    handleChecked = (e) => {
        this.setState({guestParticipation: e.target.checked}, () => this.state.guestParticipation ? this.props.roomStore.changeGuestNum(this.props.roomStore.guestList.length === 0 ? "" : this.props.roomStore.guestList.length) : this.props.roomStore.changeGuestNum(""));
    };

    // changeGuestNum = (value) => {
    //     if (value <= this.props.roomStore.roomInfo.maxUsers || this.props.roomStore.roomInfo.maxUsers === 0) {
    //         this.props.roomStore.changeGuestNum(value);
    //     } else {
    //         this.props.roomStore.changeGuestNum("");
    //     }
    // };

    deleteGuest = (index) => {
        this.props.roomStore.deleteGuest(index);
    };

    exportExcelByDefaultForm = () => {
        const language = DATE_UTIL.getLanguage()
        this.props.excelStore.exportLmsGuestExcelSample(language);
    };

    searchExcelData = (ws, key) => {
        if (ws[key] && ws[key].v) {
            return ws[key].v;
        } else {
            return ""
        }
    };

    parsingExcelToJson = (e) => {
        let fileList = e.target.files; //input file 객체를 가져온다.
        for (let i = 0; i < fileList.length; ++i) {

            let file = fileList[i];
            let reader = new FileReader();

            reader.onload = async (e) => {
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});

                await workbook.SheetNames.forEach((item, index, array) => {
                    if (item === "Data") {
                        const ref = workbook.Sheets[item]["!ref"];
                        if (ref) {
                            const ws = workbook.Sheets[item];

                            const activationPoint = ref.split(":");
                            const endPoint = {
                                col: activationPoint[1][0],
                                colCode: activationPoint[1][0].charCodeAt(),
                                row: parseInt(activationPoint[1].slice(1)),
                            };

                            const guestStartPoint = {
                                col: "B",
                                colCode: "B".charCodeAt(),
                                row: 2
                            }

                            let guestCount = 0;
                            for (let rowIndex = 2; (guestStartPoint.row + rowIndex) <= endPoint.row; rowIndex++) {
                                let guest = {
                                    nickName : this.searchExcelData(ws, "B" + (guestStartPoint.row + rowIndex)),
                                    email : this.searchExcelData(ws, "C" + (guestStartPoint.row + rowIndex)),
                                }

                                if (guest.nickName !== "" || guest.email !== "") {
                                    this.props.roomStore.guestList.push(guest);
                                    guestCount++
                                }
                            }
                            if(guestCount !== 0){
                                this.props.roomStore.createGuestList(guestCount);
                            }
                        } else {
                            //값이 없는 sheet
                        }
                    }
                });
            };
            reader.readAsBinaryString(file);
        }
    };

    isNotNumber = (value) => validateNumber(value);

    render() {
        const {classes, intl, roomStore} = this.props;
        return (
            <div>
                <Box display='flex' alignItems='center' pb={4}>
                    <Typography className={classes.labelText}><FormattedMessage id="guest"/></Typography>
                    <Box display='flex' flexDirection="column" className={classes.checkBox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={<UnCheckedBox/>}
                                    checkedIcon={<CheckedBox/>}
                                    checked={this.state.guestParticipation}
                                    onChange={this.handleChecked}
                                />
                            }
                            label={intl.formatMessage({id: "allow_guest"})}
                            style={{width: 200}}
                        />
                    </Box>
                </Box>
                {this.state.guestParticipation &&
                <Box dipaly='flex' flexDirection='coulmn' style={{paddingLeft: 150, width: '100%'}} pb={4}>
                    <Box>
                        <Typography className={classes.guestCheckText}><FormattedMessage id="guest_number"/><span
                            style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>

                        <input
                            name="Numbers"
                            type="text"
                            id="Numbers"
                            readOnly={true}
                            value={roomStore.roomInfo.guestNum}
                            placeholder={intl.formatMessage({id: "msg.add_or_excel"})}
                            className={classes.inputStyle1}
                        />
                        <Button className={classes.buttonStyle} onClick={() => roomStore.createGuestList()} style={{marginRight:5}}>
                            <FormattedMessage id="add"/>
                        </Button>

                        <label htmlFor="input-file" style={{marginRight:5}}>
                            <Button className={classes.buttonStyle} component="span">
                                <FormattedMessage id="file_selection"/>
                            </Button>
                        </label>
                        <Input type="file" id="input-file" style={{display: 'none'}}
                               accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                               className={classes.inputStyle}
                               onChange={this.parsingExcelToJson}
                               onClick={(event) => event.target.value=''}
                        />
                        <Button className={classes.buttonStyle} onClick={() => roomStore.clearGuestList()}>
                            <FormattedMessage id="delete_all"/>
                        </Button>
                    </Box>
                    <Box>
                        <Button className={classes.btnStyle} onClick={this.exportExcelByDefaultForm}>
                            <FormattedMessage id={"form_download"}/>
                        </Button>
                    </Box>

                    {roomStore.guestList.length > 0 &&
                    <>
                        <Typography style={{margin: '8px 0'}}><span
                            style={{color: '#ff0000'}}>*</span><FormattedMessage
                            id="msg.notice_guest_info"/></Typography>
                        <Typography style={{margin: '8px 0'}}><span
                            style={{color: '#ff0000'}}>*</span><FormattedMessage id="nickname"/> : <FormattedMessage
                            id="msg.validation_nickname"/></Typography>
                        <Typography style={{margin: '8px 0'}}><span
                            style={{color: '#ff0000'}}>*</span><FormattedMessage id="msg.notice_unavailable_nickname"/></Typography>
                    </>
                    }
                    {roomStore.guestList.length > 0 &&
                    <Box className={classes.guestBox}>
                        {roomStore.guestList.map((guest, i) =>

                            <Box key={i}>
                                <Typography style={{margin: 8, display:'inline-block', width:'24px'}}>{i + 1}</Typography>
                                <input
                                    id={`guestNickName${i}`}
                                    name={'nickName'}
                                    type="text"
                                    maxLength="16"
                                    placeholder={intl.formatMessage({id: "nickname"})+`(guest${i + 1})`}
                                    onChange={e => roomStore.changeGuestNickName(e.target.value, i)}
                                    value={guest.nickName}
                                    className={classes.inputStyle}
                                />
                                <input
                                    id={`guestEmail${i}`}
                                    name={'email'}
                                    type="email"
                                    maxLength="50"
                                    placeholder={intl.formatMessage({id: "email"})+"( "+intl.formatMessage({id: "msg.notice_send_email"}) + " )"}
                                    onChange={e => roomStore.changeGuestEmail(e.target.value, i)}
                                    value={guest.email || ""}
                                    className={classes.inputStyle2}
                                />
                                <Button className={classes.buttonStyle} onClick={() => this.deleteGuest(i)}>
                                    <FormattedMessage id="delete"/>
                                </Button>
                            </Box>
                        )}
                    </Box>
                    }
                </Box>
                }
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(GuestParticipation));