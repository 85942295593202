import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {List, ListItem, ListItemText} from "@material-ui/core";
import {inject, MobXProviderContext, observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    gridRoot: {
        "& .MuiGrid-item":{
            textAlign: 'center'
        },
    },
}));

function QuizDetailDialog() {
    const intl = useIntl();
    const classes = useStyles();
    const { statisticsStore } = React.useContext(MobXProviderContext);

    const getQuizTypeString = type => {
        switch (type) {
            case 'SingleChoice':
                return intl.formatMessage({id: "single_choice"});
            case 'MultipleChoice':
                return intl.formatMessage({id: "multiple_choice"});
            case 'ShortAnswer':
                return intl.formatMessage({id: "short_answer"});
            case 'Subjective':
                return intl.formatMessage({id: "subjective"});
            default:
                return "";
        }
    };

    return (
        <Dialog
            open={statisticsStore.isQuizDetailDialogOpen}
            onClose={() => statisticsStore.setQuizDetailDialogOpen(false)}
            scroll={'paper'}
            maxWidth={'lg'}
            fullWidth={true}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title"><FormattedMessage id="quiz_result"/></DialogTitle>
            <DialogContent dividers={true}>
                <Grid container className={classes.gridRoot}>
                    <Grid item xs={1}>
                        <Typography variant={'subtitle1'}> <FormattedMessage id="quiz_result"/> </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant={'subtitle1'}> <FormattedMessage id="question"/> </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant={'subtitle1'}> <FormattedMessage id="answer"/> </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant={'subtitle1'}> <FormattedMessage id="correct_answer"/> </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography variant={'subtitle1'}> <FormattedMessage id="quiz_type"/> </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Box borderBottom={1} borderColor={"grey.500"} style={{width: '100%', marginTop: '10px', marginBottom: '10px'}} />
                    </Grid>
                    {statisticsStore.detailQuizResults.map((quizResult, i) => {
                        const quiz = statisticsStore.quizList.find(q => q.roomId === quizResult.roomId && q.activationId === quizResult.activationId && q.quizId === quizResult.quizId);
                        const quizType = quiz.quizType;
                        const itemAnswerList = (quizType === 'SingleChoice' || quizType === 'MultipleChoice') ? quiz.quizItemList.filter(qi => qi.correct).map(qi => {return qi.itemId}).sort((a, b) => {return a - b}) : [];
                        const userItemAnswerList = quizResult.itemAnswerList.map(uia => {return uia.itemId}).sort((a, b) => {return a - b})
                        const isCorrect = JSON.stringify(itemAnswerList) === JSON.stringify((userItemAnswerList));

                        return (
                            <Grid container key={"quiz-detail-" + quizResult.activationId + "-" + (quizResult.quizId)} style={{marginBottom: "20px"}} direction="row" justify={'center'} alignItems={'center'}>
                                <Grid item xs={1} style={{color: isCorrect ? "#0000ff" : "#ff0000"}}>
                                    <Typography component={'span'} color={"inherit"}>
                                        {(quizType === 'SingleChoice' || quizType === 'MultipleChoice') ? isCorrect ? "O" : "X" : "•"}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography component={'span'}>
                                        {quiz.title}
                                    </Typography>

                                    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                        {quiz.quizImageList.map((quizImage, index) => (
                                            <img key={`quizDetailImagePreview-${index}`} style={{width: '100px'}} src={'data:' + quizImage.type + ';base64,' + quizImage.data} width={"100%"} alt="" onDoubleClick={e => statisticsStore.onImageDoubleClick(quizImage)} />
                                        ))}
                                    </Box>

                                    {(quizType === 'SingleChoice' || quizType === 'MultipleChoice') &&
                                    <Grid item xs={12}>
                                        <List style={{overflow: 'auto'}}>
                                            {quiz.quizItemList.map((option) =>
                                                <ListItem key={`update-quiz-option-item-${option.activationId}-${option.quizId}-${option.itemId}`}>
                                                    <ListItemText primary={
                                                        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
                                                            <Typography variant="body1" style={{fontSize: '1.125rem'}}> {`${option.itemId}. `}</Typography>
                                                            <Typography variant="body1" style={{fontSize: '1.125rem'}}> {option.content}</Typography>
                                                        </Box>
                                                    }
                                                    />
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                    }
                                </Grid>
                                {quizType === 'SingleChoice' || quizType === 'MultipleChoice' ?
                                    <>
                                        <Grid item xs={2}>
                                            <Typography component={'span'}>
                                                {userItemAnswerList.join(",")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography component={'span'}>
                                                {itemAnswerList.join(",")}
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={2}>
                                            <Typography component={'span'}>
                                                {quizResult.shortAnswer}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography component={'span'}>
                                                •
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={1}>
                                    <Typography component={'span'}>
                                        {getQuizTypeString(quizType)}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12}>
                                    <Box borderBottom={1} borderColor={"grey.500"} style={{width: '100%', marginTop: '10px'}} />
                                </Grid>
                            </Grid>
                        )
                    })}

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => statisticsStore.setQuizDetailDialogOpen(false)} color="primary">
                    <FormattedMessage id="ok"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default inject('statisticsStore')(observer(QuizDetailDialog));