class PathUtil {
    getPath(locationPathname) {
        return (locationPathname).split('/')[1];
    };

    getTeamId(locationPathname) {
        const pathArray = (locationPathname).split('/');
        return pathArray[2] !== undefined ? parseInt(pathArray[2]) : 0;
    };

    getOriginURL() {
        const location = window.location;
        return `${location.protocol}//${location.host}`;
    };

    getSurveyId(locationPathname) {
        const pathArray = (locationPathname).split('/');
        return pathArray[3] !== undefined ? parseInt(pathArray[3]) : 0;
    };
}

export const PATH_UTIL = new PathUtil();