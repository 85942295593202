import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Popper,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Typography,
    Box,
    ClickAwayListener,
    TextareaAutosize
} from "@material-ui/core";
import MoreButton from "./NoticeMoreButton";
import {inject, observer} from "mobx-react";
import {AuthorityType} from "../../../stores/UserLoungeStore";
import {DATE_UTIL} from "../../../common/util/date.util";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding:0,
        width: 450,
        overflow: 'auto',
        boxShadow:'0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        // border:'1px solid #cdced0',
        borderRadius:10,
        "& .MuiTypography-h6":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1.125rem',
            color:'#000',
            fontWeight:600
        },
        "& .MuiDialogTitle-root":{
            padding:0,
            // borderBottom:'1px solid #c0c2c3',
            margin:'10px 20px 0',
        },
        "& .MuiDialogContent-root":{
            textAlign:'justify'
        }
    },
    buttonText:{
        textAlign:"left",
        padding:0,
        textTransform:'none',
        width:'100%',
        "&:hover":{
            background:'transparent'
        }
    },
    popper: {
        zIndex: 99999,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    dialogTitleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#000',
        fontWeight: 600,
        marginBottom: 10,
        marginTop:10
    },
    modifyDialogTitleText: {
        color:'#303030',
        borderRadius:5,
        border:'1px solid #D3D7DB',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        fontWeight: 600,
        marginBottom: 5,
        padding:10,
        marginTop:10,
        width: '100%',
        height: '100%',
        resize:'none',
        "&:focus":{
            outline:'none',
        },
    },
    textareaBox:{
        width:'100%',
        maxHeight:200,
        paddingRight:10,
        overflow:'auto',
        marginBottom:14,
        resize:'none',
        "&:focus":{
            outline:'none',
        },
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    modidfyTextareaBox:{
        width:'100%',
        maxHeight:200,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        padding:10,
        overflow:'auto',
        border:'1px solid #D3D7DB',
        borderRadius:5,
        resize:'none',
        "&:focus":{
            outline:'none',
        },
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    paperInBox:{
        width:'100%',
        background:'#f5f5f5',
        borderRadius:16,
        padding:'20px 13px 10px',
        marginTop:10,
        cursor:'pointer',
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#000000',
        fontSize:'1.125rem',
        fontWeight:800
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#b6b6b6',
        fontSize:'1rem',
        margin:'3px 0 4px',
        fontWeight:600
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#a3a8af',
        fontSize:'0.875rem',
        textAlign:'right',
        marginTop:10
    },
    textStyle3:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#303030',
    },
    dialogContentText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        color:'#000',
        fontWeight:600,
        marginBottom:5
    },
});

@inject('authStore', 'userLoungeStore')
@observer
class NoticeContents extends Component {
    state = {
        arrow: true,
        arrowRef: null,
        disablePortal: false,
        flip: true,
        open: false,
        placement: 'right-start',
    };

    handleClickButton = (notice) => {
        const { userLoungeStore } = this.props;
        userLoungeStore.changeSelectedNotice(notice);
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const { classes, authStore, userLoungeStore, notice } = this.props;
        const { open, placement, disablePortal, flip, arrow, arrowRef } = this.state;

        const getNoticeContent = () => {
            return (
                <React.Fragment>
                    <Paper elevation={0} className={classes.paperInBox}>
                        <Typography noWrap className={classes.textStyle} style={{maxWidth: 800}}>{notice.title}</Typography>
                        <Typography noWrap className={classes.textStyle1} style={{maxWidth: 800}}>{notice.content}</Typography>
                        <Typography noWrap className={classes.textStyle2} style={{textAlign:'left',}}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(notice.updatedDatetime), DATE_UTIL.FORMAT.AMPM_FORMAT)}</Typography>
                    </Paper>
                </React.Fragment>
            )
        }

        return (
            <div className={classes.root} >
                <Button
                    buttonRef={node => {this.anchorEl = node;}}
                    onClick={() => this.handleClickButton(notice)}
                    className={classes.buttonText}
                    disableRipple
                >
                    {getNoticeContent()}
                </Button>
                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    placement={placement}
                    disablePortal={disablePortal}
                    className={classes.popper}
                    modifiers={{
                        flip: {
                            enabled: flip,
                        },
                        arrow: {
                            enabled: arrow,
                            element: arrowRef,
                        },
                        preventOverflow: {
                            enabled: false,
                            boundariesElement:'scrollParent',
                        },
                    }}

                >
                    {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={this.handleClose}>
                            <Box>
                            {open ? (
                                <div>
                                    <DialogTitle style={userLoungeStore.isSelectedNoticeModify ?{borderBottom:0}:{borderBottom:'1px solid #c0c2c3'}}>
                                        {
                                            (userLoungeStore.userType === 'Leader' || ((notice.userId === authStore.loginUser.id) && userLoungeStore.authorityList.find(a => a.authority === AuthorityType.CreateNotice))) &&
                                            <Box display='flex' justifyContent='flex-end'>
                                                <MoreButton contentClose={this.handleClose}/>
                                            </Box>
                                        }

                                        {
                                            userLoungeStore.isSelectedNoticeModify ?
                                                <TextareaAutosize
                                                    name="title"
                                                    type="text"
                                                    id="title"
                                                    value={userLoungeStore.noticeTitle}
                                                    placeholder=""
                                                    maxLength="100"
                                                    onChange={(event) => userLoungeStore.changeNoticeTitle(event.target.value)}
                                                    className={classes.modifyDialogTitleText}
                                                />
                                                :
                                                <Typography className={classes.dialogTitleText}>
                                                    {notice.title}
                                                </Typography>
                                        }
                                    </DialogTitle>

                                    <DialogContent style={userLoungeStore.isSelectedNoticeModify ?{padding:'0 20px 10px',}:{padding:'10px 20px 10px',}}>
                                        <DialogContentText component='div'>
                                            {
                                                userLoungeStore.isSelectedNoticeModify ?
                                                    <React.Fragment>
                                                        <Typography className={classes.dialogContentText}><FormattedMessage id="contents"/></Typography>
                                                        <Box>
                                                            <TextareaAutosize
                                                                rows={10}
                                                                rowsMax={10}
                                                                aria-label="maximum height"
                                                                value={userLoungeStore.noticeContent}
                                                                onChange={(event) => userLoungeStore.changeNoticeContent(event.target.value)}
                                                                className={classes.modidfyTextareaBox}
                                                                maxLength={500}
                                                            />
                                                        </Box>
                                                        {/*<Typography noWrap className={classes.textStyle2}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(notice.updatedDatetime), DATE_UTIL.FORMAT.AMPM_FORMAT)}</Typography>*/}
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <Box className={classes.textareaBox}>
                                                            <Typography className={classes.textStyle3}>
                                                                {notice.content}
                                                            </Typography>
                                                        </Box>
                                                        <Typography noWrap className={classes.textStyle2}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(notice.updatedDatetime), DATE_UTIL.FORMAT.AMPM_FORMAT)}</Typography>
                                                    </React.Fragment>
                                            }
                                        </DialogContentText>
                                    </DialogContent>
                                </div>
                            ) : null}
                            </Box>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
        );
    };
}

export default withStyles(style)(NoticeContents);