import React from "react";
import { TablePagination } from '@material-ui/core';

class PatchedPagination extends React.Component {
    render() {
        const {
            ActionsComponent,
            onChangePage,
            onChangeRowsPerPage,
            ...tablePaginationProps
        } = this.props;

        return (
            <TablePagination
                {...tablePaginationProps}
                // @ts-expect-error onChangePage was renamed to onPageChange
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={(subprops) => {
                    const {onPageChange, ...actionsComponentProps} = subprops;
                    return (
                        // @ts-expect-error ActionsComponent is provided by material-table
                        <ActionsComponent
                            {...actionsComponentProps}
                            onChangePage={onPageChange}
                        />
                    );
                }}
            />
        );
    }
};

export default PatchedPagination;