import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import MobileCreateRoom from "./MobileCreateRoom";
import CreateRoom from "./CreateRoom";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";

@inject('roomStore', 'authStore', 'userLoungeStore', 'teamStore', 'userStore', 'progressStore', 'timelineStore', 'topBarStore')
@observer
class CreateRoomView extends Component {
    constructor(props) {
        super(props);

    }

    componentWillUnmount() {
        const {roomStore} = this.props;
        roomStore.initRoom();
    }

    render() {
        const {isMobile, isTablet} = this.props;
        return (
            <React.Fragment>
                {isMobile || isTablet ?  <MobileCreateRoom /> : <CreateRoom />}
            </React.Fragment>
        );
    }
}

export default withRouter(injectIntl(CreateRoomView));