import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Typography
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";

const style = theme => ({
    root: {
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width: '100%',
        marginBottom: 40,
        padding: '30px 20px 25px'
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.375rem',
        color: '#a3a8af',
        fontWeight: 600,
    },
    typoStyle: {
        fontSize: '1.125rem',
        fontWeight: 400,
    },
});

@inject("surveyStore")
@observer
class PreviewParagraphAdd extends Component {
    render() {
        const {classes, surveyQuestion} = this.props;
        return (
            <div className={classes.root}>
                <Typography className={classes.textStyle}
                            style={surveyQuestion.title !== "" ? {color: '#0d0d0d'} : {}}>{surveyQuestion.title !== "" ? surveyQuestion.title :
                    <FormattedMessage id="msg.preview_survey_subject"/>}</Typography>
                <Box style={{marginTop: 50}}>
                    <Typography className={clsx(classes.textStyle, classes.typoStyle)}
                                style={surveyQuestion.contents !== "" ? {color: '#0d0d0d'} : {}}> {surveyQuestion.contents !== "" ? surveyQuestion.contents :
                        <FormattedMessage id="msg.preview_survey_description"/>}</Typography>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(PreviewParagraphAdd));