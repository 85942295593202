import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Typography,
    Box,
    IconButton, Slide, Dialog, Tooltip,
} from "@material-ui/core";
import {ReactComponent as EyeIcon} from "../../../common/images/EyeIcon.svg";
import {ReactComponent as MarkQuestion} from "../../../common/images/MarkQuestion.svg";
import {inject, observer} from "mobx-react";
import {ReactComponent as MobileClose} from "../../../common/images/MobileClose.svg";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonText:{
        textDecoration:'underline',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#0097ff',
        textAlign:'left',
        padding:0,
        minWidth:0,
        "&:hover":{
            background:'transparent',
            textDecoration:'underline'
        }
    },
    dialogTitleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px'
    },
    iconBtn:{
        padding:5
    },
    titleText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    saveBtn:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover":{
            background:'transparent'
        }
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#0d0d0d',
        fontWeight:600,
        width:120
    },
    inputStyle:{
        width:'100%',
        minWidth: 190,
        padding:8,
        borderRadius: 4,
        border: '1px solid #b7bcd6 !important',
        background: '#fff',
        "&::placeholder":{
            fontSize:'1.2rem',
            color:'#aeb3b9'
        },
        "&:focus":{
            outline: 'none !important'
        }
    },
    inputStyle_err:{
        width:'100%',
        padding:8,
        borderRadius: 4,
        border: '1px solid red',
        background: '#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color:'#8d909e',
        "&::placeholder":{
            fontSize:'1.2rem',
            color:'#aeb3b9'
        },
        "&:focus":{
            outline: 'none !important'
        }
    },
    textStyle2:{
        marginLeft:100,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#8d909e',
    },
    textStyle2_err:{
        marginLeft:100,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'red'
    },
    passwordBtn:{
        width:90,
        color:'#57a4ff',
        textDecoration:'underline',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        "&:hover":{
            background:'transparent',
            textDecoration:'underline',
        }
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref}/>
));

@inject('userStore')
@observer
class TabletResetPassword extends Component {
    constructor(props) {
        super(...arguments, props);
        this.state = {
            open: false,
            pwState:"password",
            userPasswordCheckState : true,
            userPasswordCheckMsg : "",
            tooltipState: false,
        };
    }

    componentWillUnmount() {
        this.props.userStore.detailUser.password = "";
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = (event) => {
        this.props.userStore.detailUser.password = "";
        this.setState({
            open: false,
        });
    };
    handleCancel = (e) =>{
        if (e.isComposing || e.keyCode === 229) return;
        if(e.keyCode  === 27) {
            this.handleClose(e);
        }else if(e.keyCode  === 13) {
            if( !this.state.userPasswordCheckState
                || this.props.userStore.detailUser.password === ""
                || !this.props.userStore.detailUser.password) {
                return;
            }
            this.modifyUserPassword();
        }
    }
    creatDetailUserRandomPassword = async (e) => {
        this.handleCheckPasswordState(true, "");
        await this.props.userStore.creatDetailUserRandomPassword();
        await this.copyRandomPassword();
    }
    copyRandomPassword = async () => {
        const pwState = this.state.pwState;
        const password = document.getElementById("password");
        if( pwState === "password"){
            this.setState({pwState : "text"});
        }
        password.select();
        document.execCommand("copy");
        password.setSelectionRange(0, 0);
        if( pwState === "password"){
            this.setState({pwState : "password"});
        }
        this.setState({tooltipState: true});
        setTimeout(() => this.setState({tooltipState: false}), 3000)
    }
    changeDetailUserPassword = (e) => {
        const {intl} = this.props;
        this.props.userStore.changeDetailUserPassword(intl, e, this.handleCheckPasswordState);
    }
    handleCheckPasswordState= (value, msg) =>{
        this.setState({
            userPasswordCheckState : value,
            userPasswordCheckMsg : msg,
        });
    }

    handleShowPassword = () => {
        if(this.state.pwState === "password"){
            this.setState({pwState : "text"});
        }else{
            this.setState({pwState : "password"});
        }
    }


    modifyUserPassword = () =>{
        const {intl} = this.props;
        this.props.userStore.modifyUserPassword(intl, this.handleClose);
    }

    disabledFrom = (e) =>{
        if(e.keyCode === 13){
            e.preventDefault();
            return false;
        }
    }

    render() {
        const { classes, intl } = this.props;
        // const { open, placement, disablePortal, flip, arrow, arrowRef} = this.state;
        const { detailUser } = this.props.userStore;


        return (
            <div className={classes.root} >
                <Button
                    buttonRef={node => {this.anchorEl = node;}}
                    onClick={this.handleClickOpen}
                    className={classes.buttonText}
                >
                    <FormattedMessage id="reset"/>
                </Button>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.dialogTitleBox}>
                        <Box display='flex' alignItems='center'>
                            <IconButton onClick={this.handleClose} aria-label="Close" className={classes.iconBtn}>
                                <MobileClose/>
                            </IconButton>
                            <Typography className={classes.titleText}>
                                <FormattedMessage id="reset_password"/>
                            </Typography>
                        </Box>
                        <Button
                            className={classes.saveBtn}
                            onClick={this.modifyUserPassword}
                            disabled={
                                !this.state.userPasswordCheckState
                                || detailUser.password === ""
                                || !detailUser.password
                            }
                            disableRipple
                        >
                            <FormattedMessage id="save"/>
                        </Button>
                    </Box>
                    <Box style={{padding:'40px 20px'}}>
                        <Box display="flex" alignItems="center">
                            <Typography className={classes.textStyle1}>
                                <FormattedMessage id="new_password"/>
                            </Typography>
                            <Box display='flex' flexDirection='column' style={{width:'100%'}}>
                                <Box display="flex" alignItems="center">
                                    <form onKeyDown={this.disabledFrom}>
                                    <input
                                        value={detailUser.password ? detailUser.password : ""}
                                        name="password"
                                        type={this.state.pwState}
                                        id="password"
                                        placeholder={intl.formatMessage({id: "msg.insert_new_password"})}
                                        className={this.state.userPasswordCheckState ? classes.inputStyle : classes.inputStyle_err }
                                        onChange={this.changeDetailUserPassword}
                                        onKeyDown={this.handleCancel}
                                        autoComplete={"off"}
                                    />
                                    </form>
                                    <IconButton id="passwordBtn"
                                                onClick={this.handleShowPassword}
                                                className={classes.iconBtn}>
                                        <EyeIcon/>
                                    </IconButton>
                                    <Tooltip
                                        open={this.state.tooltipState}
                                        title={intl.formatMessage({id: "msg.copied_password"})}
                                        disableHoverListener
                                        disableTouchListener
                                        arrow={true}
                                        placement={"top-start"}>
                                        <Button className={classes.passwordBtn}
                                                onClick={this.creatDetailUserRandomPassword}>
                                            <FormattedMessage id="auto_generate"/>
                                        </Button>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                        {!this.state.userPasswordCheckState &&
                        <Box display="flex" pt={1}>
                            <Typography
                                className={this.state.userPasswordCheckState ? classes.textStyle2 : classes.textStyle2_err}>{this.state.userPasswordCheckMsg}</Typography>
                        </Box>
                        }
                        <Box display="flex" pt={1}>
                            <Typography className={classes.textStyle2}>
                                <FormattedMessage id="msg.validation_password"/>
                            </Typography>
                            <MarkQuestion style={{marginTop:2,marginLeft:5, width:20, height:20,}}/>
                        </Box>
                        <Box display="flex">
                            <Typography className={classes.textStyle2}>
                                <FormattedMessage id="msg.required_change_password"/>
                            </Typography>
                            <MarkQuestion style={{marginTop:2,marginLeft:5, width:20, height:20,}}/>
                        </Box>
                    </Box>

                </Dialog>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(TabletResetPassword));