import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography, Box, Grid} from "@material-ui/core"
import {inject, observer} from "mobx-react";
import {injectIntl} from "react-intl";
import clsx from "clsx";

const style = theme => ({
    root:{

    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#0d0d0d'
    },
    textStyle1:{
        fontWeight:500,
        marginBottom:30
    },
    textStyle2:{
        fontSize:'1.063rem',
        fontWeight:300
    },
    titleImgBox:{
        width: 241,
        height: 181,
        overflow:'hidden',
        display:'flex',
        alignItems:'center',
    },
    imgBox:{
        width: 192,
        height: 144,
        overflow:'hidden',
        display:'flex',
        alignItems:'center',
    },
});

@inject('statisticsStore')
@observer
class StatisticsSurveyItem extends Component {
    itemImage = (image) => {
        const width = image.width > 241 ? '100%' : image.width;
        const height = image.height > 181 ? '100%' : image.height;

        return (
            <img src={image.image} alt="" style={{width:width, height:height}} />
        )
    };

    optionImage = (image, key) => {
        const width = image.width > 192 ? '100%' : image.width;
        const height = image.height > 144 ? '100%' : image.height;

        return (
            <img key={key} src={image.image} alt="" style={{width:width, height:height}} />
        )
    };

    render() {
        const { classes, intl } = this.props;
        const { selectedSurveyItem } = this.props.statisticsStore;
        const sortedOptions = selectedSurveyItem.options.map(o => ({...o})).sort((a, b) => a.number < b.number ? -1 : a.number > b.number ? 1 : 0);

        return (
            <div className={classes.root}>
                {selectedSurveyItem &&
                <Box>
                    {selectedSurveyItem.title !== "" &&
                        <>
                            {selectedSurveyItem.required ?
                                <Typography className={classes.textStyle} style={{color: '#0d0d0d'}}><span style={{color:'#0047ac', fontWeight:800}}>{selectedSurveyItem.number}) </span>{selectedSurveyItem.title}<span style={{color: '#FF0000'}}>*</span></Typography>
                                :
                                <Typography className={classes.textStyle} style={{color: '#0d0d0d'}}><span style={{color:'#0047ac', fontWeight:800}}>{selectedSurveyItem.number}) </span>{selectedSurveyItem.title}</Typography>
                            }
                        </>
                    }
                    {selectedSurveyItem.contents !== "" &&
                        <>
                            <Typography className={clsx(classes.textStyle, classes.explanationText)} style={{color:'#0d0d0d', marginTop: 10}}>{selectedSurveyItem.contents}</Typography>
                        </>
                    }

                    {/* 이미지가 있는경우*/}
                    {/* 이미지 가로가 241px 보다 클경우 -> img width:'100%' */}
                    {/* 이미지 세로가 181px 보다 클경우 -> img height:'100%' */}
                    {/* 이미지 가로, 세로가 241*181 보다 작을경우 -> 원본사이즈 */}
                    {(selectedSurveyItem.images !== undefined && selectedSurveyItem.images.length > 0) &&
                        selectedSurveyItem.images.map((image, i) => (
                            <Grid key={'itemImage' + i} container>
                                <Grid item xs={6}>
                                    <Box className={classes.titleImgBox} mb={2}>
                                        {this.itemImage(image)}
                                    </Box>
                                </Grid>
                            </Grid>
                        ))
                    }

                    {(selectedSurveyItem.type !== 'ShortAnswer' && selectedSurveyItem.type !== 'Rating') &&
                        <Box mt={3} mb={5}>
                            {/* 객관식 단일형, 다중형 이미지 o */}
                            {/* 이미지 가로가 192px 보다 클경우 -> img width:'100%' */}
                            {/* 이미지 세로가 144px 보다 클경우 -> img height:'100%' */}
                            {/* 이미지 가로, 세로가 241*181 보다 작을경우 -> 원본사이즈 */}
                            <Grid container>
                                {(selectedSurveyItem.options.length > 0) &&
                                sortedOptions.map((option) => (
                                    <Grid key={option.surveyItemOptionId} item xs={6}>
                                        <Box>
                                            <Typography className={classes.textStyle}><span style={{fontWeight:800, marginRight: 16}}>{intl.formatMessage({id: "survey_option_number"}, {"number": option.number})}</span>{option.contents}</Typography>
                                            {(option.images !== undefined && option.images.length > 0) &&
                                            <Box className={classes.imgBox} mt={1} mb={1}>
                                                {option.images.map((image) => {
                                                    return(
                                                        this.optionImage(image, 'optionImage' + option.surveyItemOptionId + image.surveyItemOptionImageId)
                                                    )
                                                })}
                                            </Box>
                                            }
                                        </Box>
                                    </Grid>
                                ))
                                }
                            </Grid>
                        </Box>
                    }
                </Box>
                }
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(StatisticsSurveyItem));