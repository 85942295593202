import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
} from "@material-ui/core";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {DATE_UTIL} from "../../../common/util/date.util";
import {FormattedMessage} from "react-intl";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = theme => ({
    root:{
    },
    endBox:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.0rem',
        color:'#fff',
        background:'#1a5177',
        width:40,
        padding:'2px 0',
        textAlign:'center',
        marginTop:4,
        marginBottom:20
    },
    iconButton:{
        padding:0,
        marginBottom:8,
        "&:hover":{
            background:'transparent'
        }
    },
    timeText:{
        width:80,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:800,
        color:'#a3a8af',
        textAlign:'left'
    },
    liveBox:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background:'#fb4a59',
        width:50,
        padding:'2px 0',
        textAlign:'center',
        marginTop:4
    },
    circle:{
        width:3,
        height:3,
        background:'#fff',
        borderRadius:50,
        marginRight:5
    },
    liveText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.0rem',
        color:'#fff',
    },
});


class MobileTimelineItemOppositeContent extends Component {
    render() {
        const { classes, room } = this.props;
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);

        return (
            <Box style={ room ? {marginTop:13} : {marginTop:0}}>
                {room ?
                    <Typography className={classes.timeText}>{dayjs(room.startDatetime).format('hh:mm A')}</Typography>
                    :
                    <Typography className={classes.timeText}>{dayjs(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT)).format('hh:mm A')}</Typography>
                }

                {/* (지난시간) */}
                {(room && dayjs(room.endDatetime).isBefore(currentDatetime)) &&
                    <Box className={classes.endBox}>
                        <FormattedMessage id="end"/>
                    </Box>
                }
                {/* (현재시간) */}
                {(room && (dayjs(currentDatetime).isBetween(room.startDatetime, room.endDatetime) || dayjs(currentDatetime).isSame(room.startDatetime) || dayjs(currentDatetime).isSame(room.endDatetime))) &&
                    <Box className={classes.liveBox}>
                        <span className={classes.circle}></span> <Typography className={classes.liveText}>LIVE</Typography>
                    </Box>
                }
            </Box>
        );
    }
}

export default withStyles(style)(MobileTimelineItemOppositeContent);
