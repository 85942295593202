import React, {Component} from 'react';
import {ReactComponent as VectorIcon} from "../../../common/images/VectorIcon.svg";
import {Typography, Box} from "@material-ui/core";
import {TimelineOppositeContent, TimelineContent} from "@material-ui/lab";
import {withStyles} from "@material-ui/core/styles";
import {ReactComponent as ArrowLineNoSchedule} from "../../../common/images/ArrowLineNoSchedule.svg";
import dayjs from "dayjs";
import {DATE_UTIL} from "../../../common/util/date.util";
import {observer} from "mobx-react";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{

    },
    timeText:{
        width:80,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:800,
        color:'#a3a8af',
        textAlign:'left',
        letterSpacing:'-0.5px'
    },
    ArrowLineBox:{
        position:'relative',
        marginTop:-10,
        zIndex:20,
        color:'#a3a8af',
    },
    timeLineBox:{
        position:'relative',
        padding:'15px 14px',
        background:'#f5f5f5',
        width:'65%',
        borderRadius:0,
        borderTop:'2px solid #a3a8af',
        marginLeft:20,
        marginTop:-11,
        display:'flex',
        '@media all and (min-width:1181px) and (max-width:1919px)':{
            width:'90%',
        },
        '@media all and (min-width:480px) and (max-width:1180px)':{
            width:'100%',
        },
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:300,
        color:'#3b3b3b',
        marginBottom:95,
        marginLeft:5
    },
    lineContentBox:{
        position:'relative',
        borderTop:'1px dotted #c0c2c3',
    },
});

@observer
class TimeLineNoSchedule extends Component {
    render() {
        const { classes, room } = this.props;

        return (
            <div id={room !== undefined ? room.id : 0}>
                <Box display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} style={{width: '100%', height: '100%'}} mt={2} mb={2}>
                    <TimelineOppositeContent  style={{marginTop:-8}}>
                        <Typography className={classes.timeText}>
                            {dayjs(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT)).format(DATE_UTIL.FORMAT.AMPM_FORMAT)}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineContent className={classes.lineContentBox}>
                        <Box className={classes.ArrowLineBox}>
                            <ArrowLineNoSchedule/>
                        </Box>
                        <div className={classes.timeLineBox}>
                            <VectorIcon/>
                            <Typography className={classes.textStyle}><FormattedMessage id="msg.no_schedule"/></Typography>
                        </div>
                    </TimelineContent>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(TimeLineNoSchedule);