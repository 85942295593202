import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Grid,
} from "@material-ui/core";
import Team from "./containers/Team";
import Survey from "./survey/Survey";
import TimeLineTab from "./containers/TimeLineTab";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import TeamMembersSideBar from "../../components/TeamMembersSideBar";
import {AuthorityType} from "../../stores/UserLoungeStore";
import {PATH_UTIL} from "../../common/util/path.util";

const style = theme => ({
    root:{
        width:'100%',
        paddingTop:90,
        paddingLeft:28,
        marginLeft:90,
        marginRight:30,
        background:'#fff',
        flexGrow: 1,
        height: window.innerHeight - 175
    }
});

@inject('topBarStore', 'userLoungeStore', 'authStore')
@observer
class MyTimeline extends Component {
    componentDidMount() {
        if (window.location.pathname === '/' || window.location.pathname === '/mytimeline') {
            if(this.props.authStore.loginUser.title === 'monitor' || this.props.authStore.loginUser.title === 'assistant') {
                this.props.topBarStore.setIsSearchBarRender(true);
            }else {
                this.props.history.push("/teams/1")
            }
        }
    }

    authority = () => {
        const {userLoungeStore, authStore} = this.props;
        let result = false;
        userLoungeStore.teamList.forEach((team) => {
            const member = team.id !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
            const isLeader = (member !== undefined) && (member.type === 'Leader');
            const isCreateRoom = (member !== undefined) && (member.authorities.find(a => a.authority === AuthorityType.CreateRoom));
            if(isLeader || isCreateRoom || result){
                result = true;
            }
        })
        return result;
    };
    render() {
        const { classes } = this.props;
        const authority = this.authority();
        return (
            <div className={classes.root}>
                <Grid container>
                    {/*<Grid item xs={3} >*/}
                        {/*<Team history={this.props.history} authority={authority}/>*/}
                        {/*{authority &&*/}
                        {/*<Survey history={this.props.history}/>*/}
                        {/*}*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='flex-end' style={{paddingBottom: 5}}>
                            <TeamMembersSideBar
                                members={[]}
                                isTeam={false}
                            />
                        </Box>
                        <TimeLineTab history={this.props.history}/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(MyTimeline));