import React, {Component} from 'react';
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Typography
} from "@material-ui/core";
import {observer} from "mobx-react";

const style = theme => ({
    root:{
        width:'100%',
        marginBottom: 25,
        marginTop: 16
    },
    textStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight: 600,
    },
    typoStyle:{
        fontSize: '1.2rem',
        fontWeight: 400,
    },
});

@observer
class MobileSurveyParagraph extends Component {
    render() {
        const { classes, item } = this.props;

        return (
            <div className={classes.root}>
                {item.title === "" &&
                    <Typography className={classes.textStyle} style={{color:'#0d0d0d'}}>{item.title}</Typography>
                }
                <Box style={{marginTop: 50}}>
                    {item.descriptions === "" &&
                    <Typography className={clsx(classes.textStyle, classes.typoStyle)} style={{color:'#0d0d0d'}}> {item.descriptions}</Typography>
                    }
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(MobileSurveyParagraph);