import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    Select,
    FormControl,
    MenuItem,
    MenuList,
    IconButton,
    FormControlLabel,
    RadioGroup,
    Radio,
    Button, Tooltip, ClickAwayListener, FormGroup, Checkbox,
} from "@material-ui/core";
import { ReactComponent as DeleteIcon} from "../../../common/images/DeleteIcon.svg"
import {ReactComponent as CheckedBox} from "../../../common/images/CheckedBox.svg";
import {ReactComponent as UnCheckedBox} from "../../../common/images/UnCheckedBox.svg";
import {ReactComponent as MarkQuestion} from "../../../common/images/MarkQuestion.svg";
import {ReactComponent as EyeIcon} from "../../../common/images/EyeIcon.svg";
// import {DoneIcon} from '@material-ui/icons';

import CheckIcon from '@material-ui/icons/Check';
import {inject, observer} from "mobx-react";
import { UserType } from "../../../stores/UserStore";
import {TeamUserType, UI} from "../../../stores/UserStore";
import {ReactComponent as WarningIcon} from "../../../common/images/WarningIcon.svg";
import {validateName} from "../../../common/Validation";
import {TeamType} from "../../../stores/TeamStore";
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../../common/util/color.util";

const style = theme => ({
    root:{
        width:'100%',
        background:'#fff',
        padding:'50px 0 0',
        "& .MuiOutlinedInput-input":{
            padding:'8px 12px'
        },
        "& .MuiListItem-root.Mui-selected":{
            background:'#d0d0d0',
        },
        "& :focus":{
            outline:'none',
        },
    },
    gridBox:{
        marginBottom:60,
        "& .MuiButton-root.Mui-disabled":{
            background:'#fff',
            color:'#88889D',
            border:'1px solid #A3A8AF'
        }
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#0d0d0d',
        fontWeight:600,
        marginBottom:20,
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        color:'#0d0d0d',
        fontWeight:600,
        marginBottom:20,
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.0rem',
        color:'#666666'
    },
    textStyle2_err:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.0rem',
        color:'red'
    },
    inputStyle:{
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        width:'80%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid #b7bcd6',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color:'#8d909e',
        // webkitImeMode:"disabled",
        // mozImeMode:"disabled",
        // msImeMode:"disabled",
        // imeMode:"disabled",
    },
    inputStyle_err:{
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        width:'80%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid red',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color:'#8d909e',
        // webkitImeMode:"disabled",
        // mozImeMode:"disabled",
        // msImeMode:"disabled",
        // imeMode:"disabled",
    },
    emailInputStyle:{
        width:'47%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid #b7bcd6',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color:'#8d909e'
    },
    emailInputStyle_err:{
        width:'47%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid red',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color:'#8d909e'
    },
    formControlBox:{
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
            border:'1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus":{
            background:'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            border:'1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    selectBox: {
        width: 240,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
    },
    selectBox_err: {
        width: 240,
        border:'1px solid red',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color:'#303030'
    },
    textareaStyle:{
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        width:"80%",
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#8d909e',
        border:'1px solid #b7bcd6',
        padding:'10px',
        borderRadius:4,
        resize:'none',
        "&::placeholder":{
            color:'#8d909e'
        },
        "&:focus":{
            outline:'none',
        },
    },
    teamBox:{
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        width:"80%",
        height:140,
        border:'1px solid #b7bcd6',
        padding:'0 8px',
        marginBottom:14,
        marginTop:5,
        "& .MuiListItem-button":{
            background:'transparent'
        },
        overflowY:'scroll',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    listStyle:{
        width:'100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color:'#0d0d0d',
        padding:5
    },
    spanStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color:'#7d86a9',
        marginLeft:5
    },
    checkboxStyle:{
        "& .MuiTypography-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.2rem',
            color:'#0d0d0d',
        },
        marginLeft:20,
    },
    checkboxStyle1:{
        "& .MuiTypography-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.2rem',
            color:'#0d0d0d',
            marginRight:50
        },
    },
    btnStyle:{
        width:135,
        padding:'8px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:600,
        // color:'#fff',
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1:{
        width:135,
        padding:'8px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:600,
        color:'#fff',
        marginRight:16,
        "&:hover":{
            background:'#a3a8af'
        }
    },
    passwordBtn:{
        color:'#57a4ff',
        textDecoration:'underline',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        "&:hover":{
            background:'transparent',
            textDecoration:'underline',
        }
    },
    iconBtn:{
        width:25,
        height:25,
        padding:5,
        marginLeft:5
    },
    gridBoxIn:{
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        width:'80%'
    },
    teamDropdown:{
        position: 'relative',
    },
    dropdown: {
        width:'70%',
        position: 'absolute',
        top: 40,
        right: 0,
        left: 0,
        zIndex: 1,
        border:'1px solid #b7bcd6',
        padding:'8px 0',
        background:'#fff'
    },
    dropdownText:{
        fontSize: '1.4rem',
        fontFamily:'NanumSquareRoundOTF',
        color:'#0d0d0d',
        cursor:'pointer',
        padding:'5px 18px',
        "&:hover":{
            background:'#f5f5f5'
        }
    },
    inputStyle1:{
        width:'70%',
        padding:10,
        borderRadius:6,
        background:'#f2f3f7',
        border:0,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color:'#b6b6b6'
    },
});

@inject("userStore", "teamStore", 'authStore', 'progressStore')
@observer
class TabletCreateTeamMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pwState:"password",
            userNameCheckState : true,
            userNameCheckMsg : "",
            userEmailCheckState : true,
            userEmailCheckMsg : "",
            userPasswordCheckState : true,
            userPasswordCheckMsg : "",
            userTypeCheckState : true,
            userTypeCheckMsg : "",

            tooltipState: false,
            teamSearchBar : false,
            teamSelectIndex : 0,
        }
    }

    componentDidMount() {
        const {intl} = this.props;
        const userNameCheckMsg = intl.formatMessage({id: "msg.validation_nickname"});

        this.setState({userNameCheckMsg : userNameCheckMsg});
        if(this.props.authStore.loginUser.type === UserType.Normal){
            this.props.userStore.newUser.type = UserType.Normal;
            const teamUser = {
                userId : 0,
                teamId : this.props.teamStore.selectTeamByLeader.id,
                type : TeamUserType.Member,
            }
            this.props.userStore.newTeamUserList.push(teamUser);
        }else{
            const selectedTeamId = this.props.userStore.memberTreeSelectedTeamId;
            if (selectedTeamId && (selectedTeamId !== "-1") && (selectedTeamId !== "-2")) {
                console.log("there is selected teamId : teamId=", selectedTeamId);
                this.props.teamStore.getSelectedTeam(selectedTeamId, this.callbackSelectedTeam);
            }
        }
    }
    callbackSelectedTeam = (selectedTeamId, selectedTeam) => {
        if (selectedTeam && selectedTeam.type && selectedTeam.type === TeamType.Team) {
            this.props.userStore.addNewTeamUser(selectedTeam);
        }
    }

    componentWillUnmount() {
        this.props.userStore.initSelectedActiveTeam();
        this.props.userStore.cancelAddNewUser();
        this.props.teamStore.initSearchTeamList();
    }
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userStore, progressStore} = this.props;
        progressStore.setIsLoading(userStore.getIsLoading)
    }

    handleCreate = () => {
        const {intl} = this.props;
        const {newUser, setUserConfirmDialog} = this.props.userStore;

        if(newUser.title !== "" && !validateName(newUser.title)){
            const title = intl.formatMessage({id: "insert_new_member"});
            const msg = intl.formatMessage({id: "msg.title_incorrect"});
            setUserConfirmDialog(msg, title);
            return;
        }

        this.props.userStore.createUser(intl);

    }
    handleShowPassword = () => {
        if(this.state.pwState === "password"){
            this.setState({pwState : "text"});
        }else{
            this.setState({pwState : "password"});
        }
    }
    handleCheckName = () =>{
        const {intl} = this.props;
        this.props.userStore.checkUserName(intl, this.handleCheckNameState);
    }
    handleCheckNameKeyDown = (e) =>{
        if (e.isComposing || e.keyCode === 229) return;
        if(e.keyCode  === 13) {
            this.handleCheckName();
        }
    }

    handleChangeNewUserName =(e)=>{
        const {intl} = this.props;
        this.props.userStore.changeNewUserName(intl, e.target.value, this.handleCheckNameState);
    }


    handleCheckEmail = () =>{
        const {intl} = this.props;
        this.props.userStore.checkUserEmail(intl, this.handleCheckEmailState);
    }
    handleCheckEmailKeyDown = (e) =>{
        if (e.isComposing || e.keyCode === 229) return;
        if(e.keyCode  === 13) {
            this.handleCheckEmail();
        }
    }
    handleChangeNewUserEmail =(e)=>{
        const {intl} = this.props;
        this.props.userStore.changeNewUserEmail(intl, e, this.handleCheckEmailState);
    }
    changeNewUserPassword = (e) => {
        const {intl} = this.props;
        this.props.userStore.changeNewUserPassword(intl, e, this.handleCheckPasswordState);
    }
    changeNewUserType = (e) =>{
        const {intl} = this.props;
        this.props.userStore.changeNewUserType(intl, e, this.handleCheckTypeState);
    }
    creatNewUserRandomPassword = async (e) =>{
        this.handleCheckPasswordState(true, "");
        await this.props.userStore.creatNewUserRandomPassword();
        await this.copyRandomPassword();
    }
    copyRandomPassword = async () => {
        const pwState = this.state.pwState;
        const password = document.getElementById("password");
        if( pwState === "password"){
            this.setState({pwState : "text"});
        }
        password.select();
        document.execCommand("copy");
        password.setSelectionRange(0, 0);
        if( pwState === "password"){
            this.setState({pwState : "password"});
        }
        this.setState({tooltipState: true});
        setTimeout(() => this.setState({tooltipState: false}), 3000)
    }

    handleCheckNameState= (value, msg) =>{
        this.setState({
            userNameCheckState : value,
            userNameCheckMsg : msg,
        });
    }
    handleCheckEmailState= (value, msg) =>{
        this.setState({
            userEmailCheckState : value,
            userEmailCheckMsg : msg,
        });
    }
    handleCheckPasswordState= (value, msg) =>{
        this.setState({
            userPasswordCheckState : value,
            userPasswordCheckMsg : msg,
        });
    }
    handleCheckTypeState= (value, msg) =>{
        this.setState({
            userTypeCheckState : value,
            userTypeCheckMsg : msg,
        });
    }

    handleCancelAddNewUser = ()=>{
        const {intl} = this.props;

        if(this.props.authStore.loginUser.type === UserType.Admin){
            this.props.userStore.initUserTablePage()
            this.props.userStore.cancelAddNewUser();
            this.props.userStore.getUserTableListByAll();
        }else if(this.props.authStore.loginUser.type === UserType.Normal ){
            this.props.userStore.initUserTablePage()
            this.props.userStore.cancelAddNewUser();
            this.props.userStore.getUserTableListByTeam(intl, this.props.teamStore.selectTeamByLeader.id);
            this.props.userStore.changeUiState(UI.MemberTable, 0);
        }
    }
    handleAddNewTeamUser=(teamId)=>{
        const team = this.props.teamStore.teamTreeListForTeamMember.find(team=> team.id === teamId);
        if (team) this.props.userStore.addNewTeamUser(team);
    }
    handleAddNewTeamUserByKeyDown = (e, list) =>{
        console.log("start handleAddNewTeamUser keyCode=", e.keyCode);
        if (e.isComposing || e.keyCode === 229) { return; }
        if (e.keyCode  === 13) {
            const teamSelectIndex = this.state.teamSelectIndex;
            this.handleAddNewTeamUser(list[teamSelectIndex].id);
        } else if (e.keyCode  === 38 && this.state.teamSelectIndex > 0) {
            const index = this.state.teamSelectIndex - 1;
            console.log("index : ", index);
            this.setState({teamSelectIndex : index});
        } else if (e.keyCode  === 40 && this.state.teamSelectIndex < list.length-1) {
            const index = this.state.teamSelectIndex + 1;
            console.log("index : ", index);
            this.setState({teamSelectIndex : index});
        } else if (e.keyCode  === 27) {
            this.setState({
                teamSearchBar : false,
            });
        }
    }
    handleClickAway = () => {
        this.setState({teamSearchBar : false});
    };

    handleSearchBar = (event, state) => {
        console.log(" SearchBar selected ");
        this.setState({
            [event.target.name] : state,
            teamSelectIndex : 0
        });
    }
    hoverTeamLabel = (index) => {
        this.setState({teamSelectIndex : index});
    }
    handleTeamKey = (e) => {
        if(e.target.value === UserType.Admin){
            this.props.teamStore.initSearchTeamList();
        }
        this.props.teamStore.searchTeamList(e.target.value);
    }
    disabledFrom = (e) =>{
        if(e.keyCode === 13){
            e.preventDefault();
            return false;
        }
    }

    handleChangeEnabled = (e) => {
        const userType = this.props.authStore.loginUser.type;
        if (userType === UserType.Admin) {
            this.props.userStore.changeNewUserEnabled(e);
        } else if (userType === UserType.Normal) {
            this.props.userStore.changeNewTeamUserEnabled(e);
        } else {
            console.warn("UserType === ", userType);
        }
    }

    selectActiveTeam = (team) => {
        if (this.props.userStore.selectedActiveTeam.teamId === team.teamId) {
            this.props.userStore.initSelectedActiveTeam();
        } else {
            this.props.userStore.changeSelectedActiveTeam(team);
        }
    }

    setMemberTypeBySelectedActiveTeam = () => {
        this.props.userStore.changeMemberType("create");
    }

    render() {
        const { classes, intl } = this.props;
        const { newUser, newTeamUserList, changeNewUserTitle, removeNewTeamUser, selectedActiveTeam} = this.props.userStore;
        const { teamTreeListForTeamMember, selectTeamByLeader, searchKeyword} = this.props.teamStore;
        const { loginUser } = this.props.authStore;

        const notTeamList = teamTreeListForTeamMember.filter( team => newTeamUserList.findIndex(newTeam => newTeam.teamId === team.id) === -1);

            return (
            <Box className={classes.root}>

                {loginUser.type === UserType.Admin &&
                <Typography className={classes.textStyle}>
                    <FormattedMessage id="type"/>
                    <span style={{color: '#ff0000', marginLeft: 5}}>*</span>
                </Typography>
                }
                {loginUser.type === UserType.Admin &&
                <Box className={classes.gridBox}>

                    <FormControl variant={'outlined'} className={classes.formControlBox}>
                        <Select native
                                value={newUser.type}
                                onChange={this.changeNewUserType}
                                className={this.state.userTypeCheckState ? classes.selectBox : classes.selectBox_err}>
                            <option value="" className={classes.optionStyle}>
                                {intl.formatMessage({id: "msg.select_type"})}
                            </option>
                            <option value={UserType.Admin} className={classes.optionStyle}>{intl.formatMessage({id: "management_account"})}</option>
                            <option value={UserType.Normal} className={classes.optionStyle}>{intl.formatMessage({id: "normal_account"})}</option>
                        </Select>
                    </FormControl>

                    {!this.state.userTypeCheckState &&
                    <Box display="flex" alignItems='center' pt={1}>
                        <Typography
                            className={this.state.userTypeCheckState ? classes.textStyle2 : classes.textStyle2_err}>
                            {this.state.userTypeCheckMsg}
                        </Typography>
                        <MarkQuestion style={{marginTop: 2, marginLeft: 5, width:20, height:20}}/>
                    </Box>
                    }
                </Box>
                }

                <Typography className={classes.textStyle}>
                    <FormattedMessage id="email"/>
                    <span style={{color:'#ff0000',marginLeft:5}}>*</span>
                </Typography>
                <Box className={classes.gridBox}>
                    <Box display='flex' alignItems='center' className={classes.gridBoxIn}>
                        <Box display='flex' alignItems='center' style={{width:'100%'}}>
                            <input
                                value={newUser.email.slice(0, newUser.email.indexOf("@"))}
                                name="email"
                                type="text"
                                id="email"
                                style={{height:32}}
                                placeholder={intl.formatMessage({id: "msg.insert_email"})}
                                className={this.state.userEmailCheckState ? classes.emailInputStyle : classes.emailInputStyle_err}
                                onChange={this.handleChangeNewUserEmail}
                            />
                            <Typography
                                style={{width:'5%',textAlign:'center',fontFamily:'NanumSquareRoundOTF',}}>@</Typography>
                            <input
                                value={newUser.email.slice(newUser.email.indexOf("@")+1, newUser.email.length )}
                                name="emailAddress"
                                type="text"
                                id="emailAddress"
                                placeholder={intl.formatMessage({id: "direct_input"})}
                                style={{height:32}}
                                className={this.state.userEmailCheckState ? classes.emailInputStyle : classes.emailInputStyle_err}
                                onChange={this.handleChangeNewUserEmail}
                                onKeyDown={this.handleCheckEmailKeyDown}
                            />
                        </Box>
                        <IconButton onClick={this.handleCheckEmail} className={classes.iconBtn}>
                            <CheckIcon style={{fontSize:'1.5rem'}}/>
                        </IconButton>
                    </Box>
                    <Box display="flex" alignItems='center' pt={1}>
                        {this.state.userEmailCheckMsg !=="" && <WarningIcon style={{width:11,height:11, marginRight:5}}/>}
                        <Typography className={this.state.userEmailCheckState ? classes.textStyle2 : classes.textStyle2_err}>
                            {this.state.userEmailCheckMsg}
                        </Typography>
                    </Box>
                </Box>

                <Typography className={classes.textStyle}>
                    <FormattedMessage id="nickname"/>
                    <span style={{color:'#ff0000',marginLeft:5}}>*</span>
                </Typography>
                <Box className={classes.gridBox}>
                    <Box display='flex' alignItems='center' className={classes.gridBoxIn}>
                        <input
                            value={newUser.name}
                            name="nickName"
                            type="text"
                            id="nickName"
                            placeholder={intl.formatMessage({id: "msg.insert_nickname"})}
                            className={this.state.userNameCheckState ? classes.inputStyle : classes.inputStyle_err}
                            onChange={this.handleChangeNewUserName}
                            onKeyDown={this.handleCheckNameKeyDown}
                        />
                        <IconButton onClick={this.handleCheckName} className={classes.iconBtn}>
                            <CheckIcon style={{fontSize:'1.5rem'}}/>
                        </IconButton>
                    </Box>
                    {!this.state.userNameCheckState &&
                    <Box display="flex" alignItems='center' pt={1}>
                        {this.state.userNameCheckMsg !=="" && <WarningIcon style={{width:11,height:11, marginRight:5}}/>}
                        <Typography className={this.state.userNameCheckState ? classes.textStyle2 : classes.textStyle2_err}>
                            {this.state.userNameCheckMsg}
                        </Typography>
                    </Box>
                    }
                    <Box display="flex" alignItems='center' pt={1}>
                        <Typography className={classes.textStyle2}>
                            <FormattedMessage id="msg.validation_nickname"/>
                        </Typography>
                        <MarkQuestion style={{marginLeft:5, width:20, height:20}}/>
                    </Box>
                    {/*<Box display="flex" alignItems='center'pt={1} >*/}
                    {/*    <Typography className={this.state.userNameCheckState ? classes.textStyle2 : classes.textStyle2_err}>*/}
                    {/*        {this.state.userNameCheckMsg}*/}
                    {/*    </Typography>*/}
                    {/*    <MarkQuestion style={{marginLeft:5}}/>*/}
                    {/*</Box>*/}
                </Box>

                <Typography className={classes.textStyle}>
                    <FormattedMessage id="password"/>
                    <span style={{color:'#ff0000',marginLeft:5}}>*</span>
                </Typography>
                <Box className={classes.gridBox}>
                    <Box display="flex" alignItems='center' className={classes.gridBoxIn} >
                        <form onKeyDown={this.disabledFrom}>
                        <input
                            value={newUser.password}
                            name="password"
                            type={this.state.pwState}
                            id="password"
                            placeholder=""
                            className={this.state.userPasswordCheckState ? classes.inputStyle : classes.inputStyle_err }
                            onChange={this.changeNewUserPassword}
                            style={{height:32, width:'100%'}}
                            autoComplete="off"
                        />
                        </form>
                        <IconButton id="password" className={classes.iconBtn}
                                    onClick={this.handleShowPassword}>
                            <EyeIcon />
                        </IconButton>
                        <Tooltip
                            open={this.state.tooltipState}
                            title={intl.formatMessage({id: "msg.copied_password"})}
                            disableHoverListener
                            disableTouchListener
                            arrow={true}
                            placement={"top-start"}>
                        <Button className={classes.passwordBtn} onClick={this.creatNewUserRandomPassword}>
                            <FormattedMessage id="auto_generate"/>
                        </Button>
                        </Tooltip>
                    </Box>
                        <div>
                            {!this.state.userPasswordCheckState &&
                            <Box display="flex" alignItems='center' pt={1}>
                                {this.state.userPasswordCheckMsg !=="" && <WarningIcon style={{width:11,height:11, marginRight:5}}/>}
                                <Typography
                                    className={this.state.userPasswordCheckState ? classes.textStyle2 : classes.textStyle2_err}>
                                    {this.state.userPasswordCheckMsg}
                                </Typography>
                            </Box>
                            }
                            <Box display="flex" alignItems='center' pt={1}>
                                <Typography className={classes.textStyle2}>
                                    <FormattedMessage id="msg.validation_password"/>
                                </Typography>
                                <MarkQuestion style={{marginLeft:5, width:20, height:20}}/>
                            </Box>
                            <Box display="flex" alignItems='center'>
                                <Typography className={classes.textStyle2}>
                                    <FormattedMessage id="msg.required_change_password"/>
                                </Typography>
                                <MarkQuestion style={{marginLeft:5, width:20, height:20}}/>
                            </Box>
                        </div>
                </Box>

                <Typography className={classes.textStyle}><FormattedMessage id="title"/></Typography>
                <Box className={classes.gridBox}>
                    <Box className={classes.gridBoxIn}>
                        <input
                            value={newUser.title}
                            name="title"
                            type="text"
                            id="title"
                            placeholder={intl.formatMessage({id: "msg.insert_title"})}
                            onChange={changeNewUserTitle}
                            className={classes.inputStyle}
                        />
                    </Box>
                    <Box display="flex" alignItems={'center'} pt={1}>
                        <Typography className={classes.textStyle2}>
                            <FormattedMessage id="msg.validation_nickname"/>
                        </Typography>
                        <MarkQuestion style={{marginTop:2,marginLeft:5, width:20, height:20}}/>
                    </Box>
                </Box>

                <Typography className={classes.textStyle}>
                    <FormattedMessage id="affiliation_team"/>
                </Typography>
                <Box className={classes.gridBox}>
                <Box className={classes.gridBoxIn}>
                    {loginUser.type === UserType.Normal &&
                    <Typography className={classes.textStyle1}>{selectTeamByLeader.name}</Typography>
                    }
                    {loginUser.type === UserType.Admin &&
                    <Box display='flex'>
                        <Box className={classes.teamBox}>
                            <Box display='flex'>
                                <MenuList style={{width: '100%'}}>
                                    {newTeamUserList.map((team, index) => {
                                        return (
                                            <Box key={team.teamId} display='flex' justifyContent='space-between'>
                                                <MenuItem
                                                    key={index.toString()}
                                                    selected={selectedActiveTeam.teamId === team.teamId}
                                                    onClick={() => this.selectActiveTeam(team)}
                                                    className={classes.listStyle}>
                                                    {team.name}
                                                </MenuItem>
                                                <IconButton onClick={() => removeNewTeamUser(team.teamId)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Box>
                                        )
                                    })}
                                </MenuList>
                            </Box>
                        </Box>
                        <Box style={{width: '20%', textAlign: 'center', marginTop: 10}}>
                            <FormGroup className={classes.checkboxStyle}>
                                <FormControlLabel
                                    control={<Checkbox
                                        icon={<UnCheckedBox/>}
                                        checkedIcon={<CheckedBox/>}
                                        checked={selectedActiveTeam.type === TeamUserType.Leader}
                                        onChange={this.setMemberTypeBySelectedActiveTeam}
                                        disabled={ !selectedActiveTeam || selectedActiveTeam.teamId === 0}
                                        name="Leader"
                                    />}
                                    label={<FormattedMessage id={"leader"}/>}
                                />
                            </FormGroup>
                        </Box>
                    </Box>
                    }
                    {loginUser.type === UserType.Admin &&
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                        <Box className={classes.teamDropdown}>
                        <input
                        value={searchKeyword}
                        onChange={this.handleTeamKey}
                        onKeyDown={(event) => this.handleAddNewTeamUserByKeyDown(event, notTeamList)}
                        onSelect={(event) => this.handleSearchBar(event, true)}
                        disabled={newUser.type === UserType.Admin}
                        name="teamSearchBar"
                        type="text"
                        id="teamSearchBar"
                        placeholder={
                            newUser.type === UserType.Normal ?
                                intl.formatMessage({id: "msg.insert_or_select_team_name"})
                                :
                                intl.formatMessage({id: "msg.cannot_join_team"})
                        }
                        className={classes.inputStyle1}
                        />
                        {this.state.teamSearchBar ? (
                            <Box className={classes.dropdown}>
                                {notTeamList.length > 0 ?
                                    notTeamList.map((team, index) => {
                                        if (index === this.state.teamSelectIndex)
                                            return (
                                                <Typography key={index.toString()}
                                                            className={classes.dropdownText}
                                                            style={{background: '#f5f5f5'}}
                                                            onMouseEnter={() => this.hoverTeamLabel(index)}
                                                            onClick={() => this.handleAddNewTeamUser(team.id)}
                                                >
                                                    {team.name}
                                                </Typography>
                                            )
                                        else return (
                                            <Typography key={index.toString()}
                                                        className={classes.dropdownText}
                                                        onMouseEnter={() => this.hoverTeamLabel(index)}
                                                        onClick={() => this.handleAddNewTeamUser(team.id)}
                                            >
                                                {team.name}
                                            </Typography>
                                        )
                                    }) : (
                                        <Typography key={"0"}
                                                    className={classes.dropdownText}>
                                            <FormattedMessage id="msg.no_addable_team"/>
                                        </Typography>
                                    )}
                            </Box>
                        ) : null}
                        </Box>
                        </ClickAwayListener>
                    }
                </Box>
                </Box>

                <Typography className={classes.textStyle}>
                    <FormattedMessage id="state"/>
                    <span style={{color:'#ff0000',marginLeft:5}}>*</span>
                </Typography>
                <Box className={classes.gridBox} style={{marginBottom:0}}>
                    <RadioGroup row className={classes.checkboxStyle1} aria-label="useSelection"
                                name="useSelection1"
                                value={
                                    loginUser.type === UserType.Admin ?
                                        newUser ? newUser.enabled : ""
                                        :
                                        newTeamUserList && newTeamUserList[0] ? newTeamUserList[0].enabled : ""}
                                onChange={this.handleChangeEnabled} >
                        <FormControlLabel
                            control={<Radio
                                icon={<UnCheckedBox />}
                                checkedIcon={<CheckedBox/>}
                            />}
                            value={true}
                            label={intl.formatMessage({id: "enabled"})}
                        />
                        <FormControlLabel
                            control={<Radio
                                icon={<UnCheckedBox />}
                                checkedIcon={<CheckedBox/>}
                            />}
                            value={false}
                            label={intl.formatMessage({id: "disabled"})}
                        />
                    </RadioGroup>

                    <Box display="flex" justifyContent='flex-end' pt={8} style={{width:"100%"}}>
                        <Button className={classes.btnStyle1}
                                onClick={this.handleCancelAddNewUser}>
                            <FormattedMessage id="cancel"/>
                        </Button>
                        <Button className={classes.btnStyle}
                                onClick={this.handleCreate}
                                disabled={
                                    !this.state.userNameCheckState
                                    || !this.state.userEmailCheckState
                                    || !this.state.userPasswordCheckState
                                    || !this.state.userTypeCheckState
                                    || this.props.userStore.newUser.name === ""
                                    || this.props.userStore.newUser.email === ""
                                    || this.props.userStore.newUser.password  === ""
                                    ||  this.props.userStore.newUser.type === ""
                                }
                        >
                            <FormattedMessage id="save"/>
                        </Button>
                    </Box>
                </Box>
            </Box>
        );
    }
}

export default withStyles(style)(injectIntl(TabletCreateTeamMember));