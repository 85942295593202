import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Paper from '@material-ui/core/Paper';
import CheckIcon from '../../../common/images/CheckIcon.svg';
import {CONFIG_SELECTIONS} from "../../../stores/OrgStore";
import {injectIntl} from "react-intl";

const style = theme => ({
    paper: {
        width:160,
        marginTop:10,
        marginBottom:203,
        borderRadius:18,
        background:'linear-gradient(to bottom, #dbdbdb, #dbdbdb), linear-gradient(to top, #3a3a3a, #19191a)',
        padding:'12px 8px 12px 12px',
        "& .MuiToggleButton-root": {
            width: 18,
            height:18,
            cursor:'pointer',
            margin:'4px 6px',
            transition:'all 0.5s ease-out',
            position:'relative',
            borderRadius:5,
            border:0
        },
    },
});


class SimpleColor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainColor : '#004dc9',
            subColor : ''
        }
    }

    componentDidMount() {
        const { mainColor, subColor, type } = this.props;
        if(type === CONFIG_SELECTIONS.Main) this.setState({newColor : mainColor});
        else this.setState({newColor : subColor})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.type !== this.props.type) {
            (this.props.type === CONFIG_SELECTIONS.Main) ? this.setState({newColor : this.props.mainColor})
                : this.setState({newColor : this.props.subColor});
        }
    }

    handleSeasons = (event, newColor) => {
        if(!newColor) {
            return;
        } else {
            const { type, selectMainColor, selectSubColor } = this.props;
            if(type === CONFIG_SELECTIONS.Main ) selectMainColor(newColor);
            if(type === CONFIG_SELECTIONS.Sub) selectSubColor(newColor);
            this.setState({newColor : newColor});
        }
    };

    render() {
        const { classes, intl, defaultColors } = this.props;
        return (
            <div>
                <Paper elevation={0} className={classes.paper}>
                    <ToggleButtonGroup
                        value={this.state.newColor}
                        exclusive
                        onChange={this.handleSeasons}
                        aria-label="text newColor"
                        style={{ display: 'flex',  flexWrap:'wrap',}}
                    >
                        {defaultColors.map(color => (
                            <ToggleButton
                                key={color}
                                style={{background: color}}
                                value={color}
                            >
                                {this.state.newColor === color &&
                                <img src={CheckIcon} alt={intl.formatMessage({id: "color_selection"})}/>
                                }
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Paper>
            </div>
        );
    }
};

export default withStyles(style)(injectIntl(SimpleColor));

