import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    IconButton,
    Popover, MenuItem, MenuList
} from "@material-ui/core";
import {ReactComponent as MoreIcon} from "../../../common/images/MoreIcon.svg";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        fontWeight: 600,
        color: '#0d0d0d',
    },
    popoverBox: {
        "& .MuiPopover-paper": {
            borderRadius: 7,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        }
    },
    menuText: {
        fontSize: '1rem',
        color: '#000',
        fontWeight: 300
    },
    circle: {
        width: 16,
        height: 16,
        borderRadius: 50,
        background: '#dbdbdb',
        textAlign: 'center',
        fontSize: '0.875rem',
        color: '#666',
        marginLeft: 8,
        paddingTop: 1,
        boxSizing: 'border-box'
    },
});

@inject('surveyStore', 'authStore', 'progressStore', 'quizStore')
@observer
class SurveyMoreButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            surveyId: '',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {surveyStore, progressStore} = this.props;
        progressStore.setIsLoading(surveyStore.getIsLoading)
    }

    handleClick = event => {
        event.stopPropagation();
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = event => {
        event.stopPropagation();
        this.setState({
            anchorEl: null,
        });
    };

    handleCopyButton = (e, itemId) => {
        const {authStore, intl, surveyStore, quizStore} = this.props;
        this.handleClose(e);
        if (this.props.item.surveyId) {
            surveyStore.copySurvey(intl, itemId, authStore.loginUser);
        } else {
            quizStore.copyQuiz(intl, itemId, authStore.loginUser);
        }
    };

    handleDelete = (e, itemId) => {
        if (this.props.item.surveyId) {
            this.props.surveyStore.getSurveyHistory(this.props.item.surveyId, () => this.handleDialogOpen(itemId));
        } else {
            this.props.quizStore.getQuizHistory(this.props.item.quizId, () => this.handleDialogOpen(itemId));
        }
        this.handleClose(e);
    };

    handleDialogOpen = (itemId) => {
        if (this.props.item.surveyId) {
            if (this.props.surveyStore.surveyHistory.length > 0) {
                this.props.changeConfirmDialogOpen();
            } else {
                this.props.surveyStore.selectedSurveyId = itemId;
                this.props.changeOpeDeleteDialog();
            }
        } else {
            if (this.props.quizStore.quizHistory.length > 0) {
                this.props.changeConfirmDialogOpen();
            } else {
                this.props.quizStore.selectedQuizId = itemId;
                this.props.changeOpeDeleteDialog();
            }
        }
    }

    render() {
        const {classes} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        return (
            <>
                <IconButton style={{padding: 5}}
                            aria-owns={open ? 'simple-popper' : undefined}
                            onClick={this.handleClick}>
                    <MoreIcon style={{width:24,height:24}}/>
                </IconButton>
                <Popover
                    id={'simple-popper'}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className={classes.popoverBox}
                >
                    <MenuList>
                        <MenuItem className={clsx(classes.textStyle, classes.menuText)} onClick={(e) => {
                            this.handleCopyButton(e, this.props.item.surveyId ? this.props.item.surveyId : this.props.item.quizId)
                        }}>
                            <FormattedMessage id="copy"/>
                        </MenuItem>
                        <MenuItem className={clsx(classes.textStyle, classes.menuText)} onClick={(e) => {
                            this.handleDelete(e, this.props.item.surveyId ? this.props.item.surveyId : this.props.item.quizId)
                        }}>
                            <FormattedMessage id="delete"/>
                        </MenuItem>
                    </MenuList>
                </Popover>

            </>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(SurveyMoreButton)));