import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    Tab,
    Tabs,
} from "@material-ui/core";
import { ReactComponent as TimeLineIcon } from "../../../common/images/TimeLineIcon.svg";
import { ReactComponent as CalendarBlankIcon } from "../../../common/images/CalendarBlankIcon.svg";
import MobileTimeLine from "./MobileTimeLine"
import MobileTimeLineCalendar from "./MobileTimeLineCalendar"
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        padding:'38px 0 30px',

        "& .MuiTab-root":{
            minWidth:0,
            minHeight:0
        },
        "& .Mui-selected":{
            background:'#3B3B3B',
            "& path":{
                fill:'#fff'
            }
        },
        "& .MuiTabs-indicator":{
            background:'transparent'
        }
    },
    tabButton:{
        border:'1px solid #d3d7db',
        borderRadius:'5px 0 0 5px',
        padding:'5px 17px'
    },
    tabButton1:{
        border:'1px solid #d3d7db',
        borderLeft:0,
        borderRadius:'0 5px 5px 0',
        padding:'15px 17px'

    },
    titleStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:800,
        color:'#0d0d0d',
        marginBottom:20,
        textAlign:'center'
    },
});

@inject('authStore', 'userLoungeStore', 'timelineStore')
@observer
class MobileTimeLineTab extends Component {
    handleChange = (event, value) => {
        const {authStore, timelineStore, userLoungeStore} = this.props;
        timelineStore.changeTimelineType(value);

        if (timelineStore.timelineType !== value) {
            if (value === 0) {
                timelineStore.changeScrollTop(0);
                timelineStore.closeAllDateTimelinePanel();
            } else {
                timelineStore.closeAllTimelinePanel();
            }

            if (value === 0) {
                timelineStore.getRoomListGroupByStartTime(userLoungeStore.selectedTeamId, authStore.loginUser.id, 'now', 1);
            }
        }
    };

    render() {
        const { classes, history, isTablet, timelineStore } = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleStyle}><FormattedMessage id="my_timeline"/></Typography>
                <Box display='flex' justifyContent='flex-end' alignItems='center' style={{padding:'0 20px'}}>
                    <Tabs value={timelineStore.timelineType} onChange={this.handleChange}>
                        <Tab
                            label={
                                <TimeLineIcon style={{marginLeft:2,marginRight:2, width:20, height:20}}/>
                            }
                            className={classes.tabButton}
                        />
                        <Tab
                            label={
                                <CalendarBlankIcon style={{width:20, height:20}}/>
                            }
                            className={classes.tabButton1}
                        />
                    </Tabs>
                </Box>
                <Box xs={11}>
                    {timelineStore.timelineType === 0 && <MobileTimeLine history={history} isTablet={isTablet}/>}
                    {timelineStore.timelineType === 1 && <MobileTimeLineCalendar history={history}/>}
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(MobileTimeLineTab));