import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    Button,
    Dialog,
    IconButton,
    Slide, Grid, LinearProgress
} from "@material-ui/core";
import {ReactComponent as MobileTeamArrow} from "../../../common/images/MobileTeamArrow.svg";
import {ReactComponent as MobileClose} from "../../../common/images/MobileClose.svg";
import {ReactComponent as FileArrowDown} from "../../../common/images/FileArrowDown.svg";
import {inject, observer} from "mobx-react";
import {AuthorityType, UploadState} from "../../../stores/UserLoungeStore";
import {ReactComponent as Close} from "../../../common/images/Close.svg";
import {ReactComponent as UploadCheck} from "../../../common/images/UploadCheck.svg";
import MobileTeamRoomFileBody from "./MobileTeamRoomFileBody";
import {FormattedMessage, injectIntl} from "react-intl";


const style = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonBox: {
        width:'100%',
        border:'1px solid #c0c2c3',
        padding:'10px 11px',
        borderRadius:5,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:10
    },
    buttonText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        marginBottom:20
    },
    titleText:{
        width:'100%',
        textAlign:'center',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    iconBtn:{
        padding:5
    },
    fileBtn:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover":{
            background:'transparent'
        }
    },
    fileBox:{
        padding:'16px 8px',
        width:'100%',
        borderBottom:'1px solid #eee',
        "&:hover":{
            background:'#f2f7fc',
            border:'1px solid #dbeaf9',
            borderRadius:4
        },
    },
    fileBox2:{
        padding:'16px 8px',
        width:'100%',
    },
    fileNameText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#4d5a69',
        fontSize:'1.4rem',
        fontWeight:600,
        paddingLeft:8,
        cursor:'default',
        width:'100%',
    },
    fileNameText1:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#cfcfcf',
        fontSize:'1.4rem',
        fontWeight:600,
        paddingLeft:8,
        cursor:'default',
        width:'100%',
    },
    downloadBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'flex-end',
        "&:hover":{
            background:'transparent'
        }
    },
    downloadText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#567fbd',
        fontSize:'1.2rem',
        marginLeft:5,
        textAlign:'right'
    },
    input: {
        display: 'none',
    },
    fileIcon:{
        "& path":{
            fill:'#6b6b6b'
        },
        marginTop:2
    },
    fileIcon1:{
        "& path":{
            fill:'#cfcfcf'
        },
        marginTop:2
    },
    uploadedText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#5fc068',
        fontSize:'1.2rem',
        marginLeft:5,
        textAlign:'right'
    },
    failedText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#f63963',
        fontSize:'1.2rem',
        textAlign:'center'
    },
    deleteIcon:{
        padding:5
    },
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
))

@inject('authStore', 'userLoungeStore')
@observer
class MobileFileSharing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            uploadOpen: false,
        };
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleClickUploadOpen = () => {
        this.setState({ uploadOpen: true });
    };

    handleUploadClose = (event) => {
        event.stopPropagation();
        this.setState({ uploadOpen: false });
    };

    handleMultiple = event => {
        const { authStore, userLoungeStore } = this.props;
        userLoungeStore.addTeamFile(authStore.loginUser.id, {file: event.target.files[0], teamId: userLoungeStore.selectedTeamId, state: UploadState.Pending});
        this.handleClickUploadOpen();
    };

    render() {
        const {classes, intl, userLoungeStore, authStore} = this.props;

        const BorderLinearProgress = withStyles((theme) => ({
            root: {
                height:4,
                borderRadius:10,
            },
            colorPrimary: {
                backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
            },
            bar: {
                borderRadius:10,
                backgroundColor:'#5fc068',
            },
        }))(LinearProgress);

        return (
            <div className={classes.root}>
                <Box onClick={this.handleClickOpen} className={classes.buttonBox}>
                    <Typography className={classes.buttonText}><FormattedMessage id="sharing_file"/></Typography>
                    <MobileTeamArrow />
                </Box>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox}>
                        <Box display='flex' alignItems='center'>
                            <IconButton onClick={this.handleClose} aria-label="Close" className={classes.iconBtn}>
                                <MobileClose/>
                            </IconButton>
                            <Typography className={classes.titleText}><FormattedMessage id="sharing_file"/></Typography>
                        </Box>

                        <input accept="*"
                               className={classes.input}
                               onChange={this.handleMultiple}
                               id="icon-button-file"
                               type="file"
                               disabled={!userLoungeStore.authorityList.find(a => a.authority === AuthorityType.UploadFile) && userLoungeStore.userType !== 'Leader'}
                        />
                        <label htmlFor="icon-button-file">
                            <Button className={classes.fileBtn}
                                    aria-label="upload picture"
                                    component="span"
                                    disableRipple
                                    disabled={!userLoungeStore.authorityList.find(a => a.authority === AuthorityType.UploadFile) && userLoungeStore.userType !== 'Leader'}
                            >
                                <FormattedMessage id="file_selection"/></Button>
                        </label>

                    </Box>
                    <MobileTeamRoomFileBody />
                </Dialog>

                <Dialog
                    fullScreen
                    open={this.state.uploadOpen && userLoungeStore.uploadFileList.length > 0}
                    onClose={this.handleUploadClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox}>
                        <Box display='flex' alignItems='center'>
                            <IconButton onClick={this.handleUploadClose} aria-label="Close" className={classes.iconBtn}>
                                <MobileClose/>
                            </IconButton>
                            <Typography className={classes.titleText}><FormattedMessage id="upload_file"/></Typography>
                        </Box>
                    </Box>
                    <Box style={{padding:'0 20px'}} >
                        {
                            userLoungeStore.uploadFileList.length > 0 &&
                                userLoungeStore.uploadFileList.map((fileInfo, index) => {
                                    return (
                                        <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.fileBox} key={index}>
                                            <Grid item xs={8}>
                                                <Box display='flex' alignItems='center'>
                                                    <Grid item xs={1}>
                                                        <FileArrowDown className={fileInfo.state !== UploadState.Progress ? classes.fileIcon1 : classes.fileIcon}/>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <Typography noWrap className={fileInfo.state !== UploadState.Progress ? classes.fileNameText1 : classes.fileNameText}>{fileInfo.file.name}</Typography>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box className={classes.downloadBtn}>
                                                    {
                                                        (fileInfo.state !== UploadState.Progress) ?
                                                            <React.Fragment>
                                                                <Grid item xs={10}>
                                                                    <Typography className={fileInfo.state === UploadState.Failed || fileInfo.state === UploadState.Canceled ? classes.failedText : classes.uploadedText}>{fileInfo.state === UploadState.Downloaded ? intl.formatMessage({id: "complete"}) : fileInfo.state === UploadState.Pending ? intl.formatMessage({id: "wait"}) : fileInfo.state === UploadState.Canceled ? intl.formatMessage({id: "cancel"}) : intl.formatMessage({id: "fail"})}</Typography>
                                                                </Grid>
                                                                {
                                                                    fileInfo.state === UploadState.Pending ?
                                                                        <Grid item xs={2}>
                                                                            <IconButton className={classes.deleteIcon} onClick={() => userLoungeStore.cancelAddTeamFile(authStore.loginUser.id, fileInfo)}>
                                                                                <Close style={{width:10,height:10}}/>
                                                                            </IconButton>
                                                                        </Grid>
                                                                        :
                                                                        <Grid item xs={2}>
                                                                            <UploadCheck style={{marginLeft:3}}/>
                                                                        </Grid>
                                                                }
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <Grid item xs={10}>
                                                                    <BorderLinearProgress variant="indeterminate" />
                                                                </Grid>
                                                                <Grid item xs={2}>
                                                                    <IconButton className={classes.deleteIcon} onClick={() => userLoungeStore.cancelAddTeamFile(authStore.loginUser.id, fileInfo)}>
                                                                        <Close style={{width:10,height:10}}/>
                                                                    </IconButton>
                                                                </Grid>
                                                            </React.Fragment>
                                                    }
                                                </Box>
                                            </Grid>
                                        </Box>
                                    )
                                })
                        }
                    </Box>
                </Dialog>
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(MobileFileSharing));