import React, {Component} from "react";
import {Box, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import MobileNoticeContents from "./MobileNoticeContents";
import {withStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonBox: {
        width:'100%',
        border:'1px solid #c0c2c3',
        padding:'10px 11px',
        borderRadius:5,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:20
    },
    buttonText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        marginBottom:20
    },
    titleText:{
        width:'100%',
        textAlign:'center',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    iconBtn:{
        padding:5
    },
    teamBox:{
        borderRadius:10,
        padding:'19px 18px',
        background:'#f5f5f5',
        margin:'0 20px 20px'
    },
    teamText1:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#000',
        fontWeight:400,
        textAlign:'justify'
    },
});

@inject('userLoungeStore', 'timelineStore', 'progressStore')
@observer
class MobileTeamRoomNoticeBody extends Component {
    componentDidMount() {
        document.getElementById('noticeList').addEventListener("scroll", () => this.infiniteScroll(), true);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    }

    infiniteScroll = () => {
        const { userLoungeStore, progressStore } = this.props

        let scrollTop = document.getElementById('noticeList').scrollTop;
        let clientHeight = document.getElementById('noticeList').clientHeight;
        let scrollHeight = document.getElementById('noticeList').scrollHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            progressStore.setIsLoading(true);
            userLoungeStore.getTeamNoticeList(userLoungeStore.selectedTeamId);
        }
    };

    render() {
        const {classes} = this.props;
        const { teamNoticeList } = this.props.userLoungeStore;

        return (
            <Box id='noticeList' style={{overflowY:'scroll', height: '80%'}}>
                {
                    teamNoticeList.length > 0 ?
                        teamNoticeList.map((notice, index) => {
                            return (
                                <MobileNoticeContents key={index} notice={notice}/>
                            );
                        })
                        :
                        <Box className={classes.teamBox}>
                            <Typography className={classes.teamText1}><FormattedMessage id="msg.no_notice"/></Typography>
                        </Box>
                }
            </Box>
        );

    };
}

export default withStyles(style)(MobileTeamRoomNoticeBody);