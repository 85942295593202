import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Avatar,
    List,
    ListItem,
} from "@material-ui/core";
import {COLOR_UTIL} from "../../../../common/util/color.util";

const useStyles = makeStyles((theme) => ({
    root:{
        width:35,
        flexShrink: 0,
        background:'#d7d7d7',
        boxShadow:'inset 0 4px 15px 0 rgba(0, 0, 0, 0.1)',
        overflow:'auto',
        "& .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button.Mui-selected" : {
            background: (props) => props.mainColor ? props.mainColor : '#fff',
            borderLeft: (props) =>
                props.subColor
                    ? '3px solid ' + props.subColor
                    : '3px solid #0097ff',
            boxShadow:'inset 0 4px 15px 0 rgba(0, 0, 0, 0.1)',
        },
        "& .MuiListItem-root.Mui-selected:hover":{
            background: (props) => props.mainColor ? props.mainColor : '#fff',
            borderLeft: (props) =>
                props.subColor
                    ? '3px solid ' + props.subColor
                    : '3px solid #0097ff',
        },
        "& .MuiListItem-gutters":{
            paddingLeft:0,
            paddingRight:0
        }
    },
    avatarStyle:{
        fontFamily: 'Montserrat',
        fontSize:10,
        fontWeight:800,
        width:20,
        height:20,
        background:(props) => props.subColor ? props.subColor : '#0097ff',
        border: (props) => {
            let border = COLOR_UTIL.getSwitchBorderColor(props.subColor ? props.subColor : '#0097ff');
            return border;
        },
    },
    avatarStyle1:{
        width:18,
        height:18,
        background:(props) => props.subColor ? props.subColor : '#0097ff',
        border: (props) => {
            let border = COLOR_UTIL.getSwitchBorderColor(props.subColor ? props.subColor : '#0097ff');
            return border;
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

export default function TabletPreviewSideBar(props) {

    const classes = useStyles(props);
    const [selectedIndex] = React.useState(1);

    return (
        <div className={classes.root} >
            <List component="nav" aria-label="main mailbox folders" style={{paddingTop:0}}>
                    <ListItem
                        button
                        selected={selectedIndex === 0}
                        style={{paddingLeft:8, paddingTop:10}}
                    >
                        <Avatar className={classes.avatarStyle} style={{background : props.subColor}}>N</Avatar>
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedIndex === 1}
                        style={{paddingLeft:5, paddingTop:10}}
                    >
                        <Avatar
                            className={classes.avatarStyle1}
                            alt="userImg"
                            style={{background : props.subColor, fontSize:10}}
                        />
                    </ListItem>
            </List>
        </div>
    );
}
