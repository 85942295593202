import React, {useEffect} from 'react';
import clsx from 'clsx';
import {
    Typography,
    Box,
    Select,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {DATE_UTIL} from "../../common/util/date.util";
import dayjs from "dayjs";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 10,
        "& .MuiFormGroup-root": {
            flexDirection: 'row'
        },
        "& .MuiFormControlLabel-root": {
            width: '45%'
        },
        "& .MuiIconButton-root": {
            width: 35
        },
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#1f1f1f',
            marginTop: 8
        },
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: '1.125rem',
        fontWeight: 600,
    },
    formControlBox: {
        width: '100%',
        "& .MuiOutlinedInput-input": {
            padding: '12px 14px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    selectBox: {
        width: "100%",
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        borderRadius: 4,
        "& :focus": {
            outline: 'none',
        },
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        color: '#303030',
    },

    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
    radioBox:{
        fontSize:'1rem',
    }
});

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

export default function StartTime(props) {
    const intl = useIntl();
    const classes = useStyles();
    const localMin = () => {
        let min = parseInt(DATE_UTIL.getTimeZoneDate(dayjs(), 'mm'));
        if (min % 5 > 0) {
            min = min - min % 5
        }
        let stringToMinute = String(min);
        if (stringToMinute.length === 1) {
            stringToMinute = '0'.concat(stringToMinute)
        }
        return stringToMinute
    }
    const [time, setTime] = React.useState(DATE_UTIL.getTimeZoneDate(dayjs(), 'hh'));
    const [minute, setMinute] = React.useState('');
    const [radio, setRadio] = React.useState(DATE_UTIL.getTimeZoneDate(dayjs(), 'HH') >= 12 ? 'PM' : 'AM');

    const changeStartTime = props.onChangeStartTime;

    useEffect(() => {
        setMinute(localMin)
    }, [])

    useEffect(() => {
        changeStartTime(time, minute, radio);
    }, [time, minute, radio, changeStartTime]);

    return (
        <div className={classes.root}>
            <Typography className={classes.titleStyle}><FormattedMessage id="start_time"/></Typography>

            <Box display='flex' alignItems='center' pt={1} pb={1}>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        native
                        value={time}
                        onChange={(event) => {
                            setTime(event.target.value)
                        }}
                        className={classes.selectBox}
                    >
                        <option value="시간" className={classes.optionStyle}>{intl.formatMessage({id: "hour"})}</option>
                        <option value="01" className={classes.optionStyle}>1</option>
                        <option value="02" className={classes.optionStyle}>2</option>
                        <option value="03" className={classes.optionStyle}>3</option>
                        <option value="04" className={classes.optionStyle}>4</option>
                        <option value="05" className={classes.optionStyle}>5</option>
                        <option value="06" className={classes.optionStyle}>6</option>
                        <option value="07" className={classes.optionStyle}>7</option>
                        <option value="08" className={classes.optionStyle}>8</option>
                        <option value="09" className={classes.optionStyle}>9</option>
                        <option value="10" className={classes.optionStyle}>10</option>
                        <option value="11" className={classes.optionStyle}>11</option>
                        <option value="12" className={classes.optionStyle}>12</option>
                    </Select>
                </FormControl>
                <Typography style={{paddingLeft: 10, paddingRight: 10}}> : </Typography>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        native
                        value={minute}
                        onChange={(event) => {
                            setMinute(event.target.value)
                        }}
                        className={classes.selectBox}
                    >
                        <option value="분" className={classes.optionStyle}>{intl.formatMessage({id: "minute"})}</option>
                        <option value="00" className={classes.optionStyle}>00</option>
                        <option value="05" className={classes.optionStyle}>05</option>
                        <option value="10" className={classes.optionStyle}>10</option>
                        <option value="15" className={classes.optionStyle}>15</option>
                        <option value="20" className={classes.optionStyle}>20</option>
                        <option value="25" className={classes.optionStyle}>25</option>
                        <option value="30" className={classes.optionStyle}>30</option>
                        <option value="35" className={classes.optionStyle}>35</option>
                        <option value="40" className={classes.optionStyle}>40</option>
                        <option value="45" className={classes.optionStyle}>45</option>
                        <option value="50" className={classes.optionStyle}>50</option>
                        <option value="55" className={classes.optionStyle}>55</option>
                    </Select>
                </FormControl>
            </Box>
            <FormControl component="div" style={{display: 'flex', alignItems: 'center', marginLeft: 10, width: '100%'}}>
                <RadioGroup value={radio} aria-label="time" name="customized-radios"
                            onChange={(event) => {
                                setRadio(event.target.value)
                            }}
                            style={{width: '100%', display: 'flex', alignItems: 'center',}}
                >
                    <FormControlLabel value="AM" control={<StyledRadio/>} label={intl.formatMessage({id: "am"})} className={classes.radioBox}/>
                    <FormControlLabel value="PM" control={<StyledRadio/>} label={intl.formatMessage({id: "pm"})} className={classes.radioBox}/>
                </RadioGroup>
            </FormControl>

        </div>
    );
}