import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography, Box} from "@material-ui/core";
import { ResponsiveBar } from '@nivo/bar'
import {useTheme} from "@nivo/core";
import {inject, observer} from "mobx-react";
import {ReactComponent as ProhibitIcon} from "../../../common/images/ProhibitIcon.svg";
import {FormattedMessage, injectIntl} from "react-intl";


const style = theme => ({
    root:{
        position:'relative',
        display:'flex',
        width:'100%',
    },
    chartBox:{
        position:'absolute',
        right:0,
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:600,
        color:'#5a6069',
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:300,
        color:'#5a6069',
    },
    circleStyle1:{
        width:8,
        height:8,
        borderRadius:'50%',
        background:'#0024ff',
        content:'',
        marginRight:5
    },
    circleStyle2:{
        width:8,
        height:8,
        borderRadius:'50%',
        background:'#4fb6fe',
        content:'',
        marginRight:5
    },
    imageText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.375rem',
        fontWeight:800,
        color:'#0d0d0d'
    },
    noDataBox:{
        textAlign:'center',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate( -50%, -50% )'
    },
});

@inject('statisticsStore')
@observer
class QuestionsChart extends Component {
    render() {
        const { classes, intl, statisticsStore } = this.props;
        const {userActivityList} = this.props.statisticsStore;
        const chartData = userActivityList.map(ua => {
            return {
                "name": ua.userName,
                "presentation": ua.presentationCount,
                "question": ua.questionCount,
                "presentationColor": "#0047ac",
                "questionColor": "#b7defe",
            };
        });

        const CustomBottomTick = tick => {
            const theme = useTheme();
            const TEXT_MAX_LENGTH = 6;
            const getShortenedText = (length, text) => text.length > length ? text.substr(0, TEXT_MAX_LENGTH) + '...' : text;
            return (
                <g transform={`translate(${tick.x},${tick.y + 22})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: '#7b8288',
                            fontSize: '0.875rem',
                            fontFamily:'NanumSquareRoundOTF',
                            fontWeight:600
                        }}
                    >
                        {getShortenedText(TEXT_MAX_LENGTH, tick.value)}
                    </text>
                </g>
            );
        };

        const CustomLeftTick = tick => {
            const theme = useTheme();
            return (
                <g transform={`translate(${tick.x - 22},${tick.y})`}>
                    <text
                        textAnchor="middle"
                        dominantBaseline="middle"
                        style={{
                            ...theme.axis.ticks.text,
                            fill: '#7b8288',
                            fontSize: '0.875rem',
                            fontFamily:'NanumSquareRoundOTF',
                            fontWeight:600
                        }}
                    >
                        {tick.value}
                    </text>
                </g>
            );
        };

        const TotalLabels = ({ bars, yScale }) => {
            const labelMargin = 20;
            return bars.map(({ data: { data, indexValue }, x, width }, i) => {
                const isPresentation = i < bars.length/2;
                const yScaleStandard = isPresentation ? data.presentation : data.question;
                return (
                    <g
                        transform={`translate(${x}, ${yScale(yScaleStandard ? yScaleStandard : 0) - labelMargin})`}
                        key={`${indexValue}-${i}`}
                    >
                        <text
                            // add any class to the label here
                            className="bar-total-label"
                            x={width / 2}
                            y={labelMargin / 2}
                            textAnchor="middle"
                            alignmentBaseline="central"
                            // add any style to the label here
                            style={{
                                fill: '#0047ac',
                                fontSize: '0.875rem',
                                fontFamily:'NanumSquareRoundOTF',
                                fontWeight:600
                            }}
                        >
                            { isPresentation ? (data.presentation !== undefined ? data.presentation + intl.formatMessage({id: "count"}) : ''): (data.question !== undefined ? data.question + intl.formatMessage({id: "count"}) : '') }
                        </text>
                    </g>
                );
            });
        };

        const MyResponsiveBar = ({data}) => (
            <ResponsiveBar
                data={data}
                keys={[ 'presentation', 'question' ]}
                indexBy="name"
                margin={{ top: 85, right: 40, bottom: 50, left: 80 }}
                padding={0.2}
                innerPadding={6}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                borderRadius={5}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', '0.8' ] ] }}
                colors={({ id, data }) => data[`${id}Color`]}
                label={false}
                isInteractive={false}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                axisBottom={{
                    renderTick: CustomBottomTick,
                }}
                axisLeft={{
                    renderTick: CustomLeftTick,
                }}
                layers={["grid", "axes", "bars", TotalLabels, "markers", "legends"]}
            />
        );

        return (
            <div className={classes.root} style={chartData.length === 0 ? {height: "100%"} : {height: window.innerHeight/1.8}}>
                {chartData.length > 0 && MyResponsiveBar({data: chartData})}
                {chartData.length > 0 &&
                    <Box className={classes.chartBox}>
                        <Box display='flex' alignItems='center' pb={1}>
                            <div className={classes.circleStyle1}/><Typography className={classes.textStyle1}><FormattedMessage id="presentation"/></Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <div className={classes.circleStyle2}/><Typography className={classes.textStyle1}><FormattedMessage id="question"/></Typography>
                        </Box>

                    </Box>
                }
                {(chartData.length === 0 && !statisticsStore.getIsLoading) &&
                    <div style={{position: "relative", width: "100%"}}>
                        <img style={{width: "100%", height: "100%"}} src="/images/NoData_Questions.png" alt="No Data"/>
                        <div className={classes.noDataBox}>
                            <ProhibitIcon />
                            <Typography className={classes.imageText}><FormattedMessage id="msg.no_display_data"/></Typography>
                        </div>
                    </div>
                }

            </div>
        );
    };
}

export default withStyles(style)(injectIntl(QuestionsChart));