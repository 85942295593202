import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Popper,
    Paper,
    IconButton,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList
} from "@material-ui/core";

import {ReactComponent as MoreIcon} from "../../../common/images/MoreIcon.svg";
import {MobXProviderContext} from "mobx-react";
import {AuthorityType} from "../../../stores/UserLoungeStore";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        "& .MuiPaper-root":{
            boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
            border:'solid 0.5px #d4d4d6',
            borderRadius:7,
        },
        "& .MuiMenuItem-root":{
            paddingLeft:10,
            paddingRight:10,
            minHeight:0
        },
    },
    moreIcon:{
        padding:8,
        "&:hover":{
            background:'#ededed'
        }
    },
    listText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#000',
    }
}));

export default function MobileNoticeMoreButton(props) {
    const { authStore, userLoungeStore } = React.useContext(MobXProviderContext);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleRemoveButton = () => {
        userLoungeStore.removeTeamFile(props.file.teamId, props.file.fileId);
        props.contentClose();
        setOpen(false);
    };

    const handleDownloadButton = () => {
        userLoungeStore.downloadTeamFile(props.file.teamId, props.file.fileId, props.file.name)
        props.contentClose();
        setOpen(false);
    };

    useEffect(() => {
        document.getElementById('fileList').addEventListener("scroll", () => setOpen(false), true);
    });

    return (
        <div className={classes.root}>
            <div>
                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.moreIcon}
                >
                    <MoreIcon />
                </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 9999}}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow">
                                        <MenuItem className={classes.listText} onClick={handleDownloadButton}><FormattedMessage id="download"/></MenuItem>
                                        {(userLoungeStore.userType === 'Leader' || ((props.file.userId === authStore.loginUser.id) && userLoungeStore.authorityList.find(a => a.authority === AuthorityType.UploadFile))) &&
                                        <MenuItem className={classes.listText} onClick={handleRemoveButton}><FormattedMessage id="delete"/></MenuItem>
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
};