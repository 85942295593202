import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Grid,
} from "@material-ui/core";
import TeamRoomNotice from "./containers/TeamRoomNotice";
import FileSharing from "./containers/FileSharing";
import TeamMembersSideBar from "../../components/TeamMembersSideBar";
import TimeLineTab from "../MyTimeline/containers/TimeLineTab";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {PATH_UTIL} from "../../common/util/path.util";
import {AuthorityType} from "../../stores/UserLoungeStore";
import Team from "../MyTimeline/containers/Team";
import Survey from "../MyTimeline/survey/Survey";

const style = theme => ({
    root:{
        width:'100%',
        paddingTop:90,
        paddingLeft:28,
        marginLeft:90,
        marginRight:30,
        background:'#fff',
        flexGrow: 1,
        height: window.innerHeight - 175
    }
});

@inject('userLoungeStore', 'topBarStore', 'orgStore', 'authStore')
@observer
class TeamRoom extends Component {
    componentDidMount() {
        const { topBarStore } = this.props;
        if (PATH_UTIL.getPath(this.props.location.pathname) === 'teams') {
            topBarStore.setIsSearchBarRender(true);
        }
    };

    authority = () => {
        const {userLoungeStore, authStore} = this.props;
        let result = false;
        userLoungeStore.teamList.forEach((team) => {
            const member = team.id !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
            const isLeader = (member !== undefined) && (member.type === 'Leader');
            const isCreateRoom = (member !== undefined) && (member.authorities.find(a => a.authority === AuthorityType.CreateRoom));
            if(isLeader || isCreateRoom || result){
                result = true;
            }
        })
        return result;
    };

    render() {
        const { classes, userLoungeStore, orgStore } = this.props
        const authority = this.authority();
        return (
            <div className={classes.root}>
                <Grid container>
                    {/*<Grid item xs={3}>*/}
                    {/*    <Team history={this.props.history} authority={authority}/>*/}
                    {/*    /!*<TeamRoomNotice />*!/*/}
                    {/*    /!*<FileSharing/>*!/*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='flex-end'>
                            <TeamMembersSideBar
                                members={userLoungeStore.selectedTeam !== undefined ? userLoungeStore.selectedTeam.members : []}
                                isTeam={true}
                                configs={orgStore.orgConfigs}
                            />
                        </Box>
                        <TimeLineTab />
                    </Grid>
                </Grid>
            </div>
        );
    };
}

export default withRouter(withStyles(style)(TeamRoom));