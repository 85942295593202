import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Paper,
    MenuList,
    MenuItem,
    Grid,
    Box
} from "@material-ui/core";
import TabletOrganizationInformation from "./OrganizationInformation/TabletOrganizationInformation";
import TabletTeamManagement from "./TeamManagement/TabletTeamManagement";
import TabletTeamMemberManagement from "./TeamMemberManagement/TabletTeamMemberManagement";
import {UI, UserType} from "../../stores/UserStore";
import {inject, observer} from "mobx-react";
import TabletTeamTreeView from "./TeamManagement/TreeView/TabletTeamTreeView";
import TabletTeamMemberTreeView from "./TeamMemberManagement/TreeView/TabletTeamMemberTreeView";
import * as UStore from "../../stores/UserStore";
import {withRouter} from "react-router-dom";
import {ManagementUrlCategoryType} from "../../stores/OrgStore";
import {FormattedMessage, useIntl} from "react-intl";
import TeamManagementByExcel from "./TeamManagementByExcel/TeamManagementByExcel";

const useStyles = makeStyles((theme) => ({
    root:{
        minWidth : 600,
        width:'100%',
        background:'#fff',
        padding:'100px 40px 100px 40px',
        "& .MuiMenuItem-root":{
            minHeight:0
        },
        "& .MuiListItem-root.Mui-selected":{
            background:'#d0d0d0',
            fontWeight:800
        },
        "& :focus":{
            outline:'none',
        },
    },
    gridBox:{
        display:'flex',
        alignItems:'flex-start',
        borderBottom:'2px solid #88889d',
    },
    paper:{
        width:220,
        marginRight:40,
        padding:0,
        borderRadius:0,
        boxShadow:'none',
    },
    menuItemBox:{
        width:220,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:500,
        color:'#0d0d0d',
        "&:hover":{
            background:'#d0d0d0'
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));
// const logPrefix = ' [ OrganizationManagement ] ' ;

function TabletOrganizationManagement(props) {
    const [selectedTeamId, setSelectedTeamId] = useState(0);

    const intl = useIntl();
    const classes = useStyles();

    const handleListItemClick = useCallback((category) => {
        props.progressStore.setIsLoading(false);
        props.history.push(`/management/${category}`);
    },[props.progressStore, props.history]);

    const userType = props.authStore.loginUser.type;
    const selectTeamByLeader = props.teamStore.selectTeamByLeader;

    useEffect(() => {
        if ((userType !== UserType.Admin) && selectTeamByLeader &&selectTeamByLeader.id) {
            handleListItemClick(ManagementUrlCategoryType.Team);
            props.teamStore.getParentTeam(intl, selectTeamByLeader.parentTeamId);
            props.teamStore.getTeamDetail(intl, selectTeamByLeader.id);
            props.teamStore.changeUiState(UI.Information);
        } else if ((userType !== UserType.Admin) && !selectTeamByLeader.id) {
            props.history.push('/');
        }
    },[intl, props.teamStore, handleListItemClick, props.history, selectTeamByLeader, userType]);

    useEffect(()=> {
        if((userType !== UserType.Admin) && (props.match.params.category === ManagementUrlCategoryType.Organization)) props.history.push('/');
    }, [userType, props.match.params.category, props.history]);

    const handleTeamManagement = () => {
        handleListItemClick(ManagementUrlCategoryType.Team);
        if(props.authStore.loginUser.type === UserType.Admin){
            props.teamStore.changeUiState(UI.TeamTable);
            props.teamStore.teamTreeSelectedTeamId = "";
            props.teamStore.initTeamTablePage();
            props.teamStore.getTeamTreeList();
            props.teamStore.getTeamTableList();
            props.teamStore.initTeamTreeExpandedList();
        }else{
            props.teamStore.changeUiState(UI.Information);
            props.teamStore.teamTreeSelectedTeamId = "";
            props.teamStore.getTeamDetail(intl, props.teamStore.selectTeamByLeader.id);
            props.teamStore.getParentTeam(intl, props.teamStore.selectTeamByLeader.parentTeamId);
        }
    };

    const handleTeamMemberManagement = () => {
        handleListItemClick(ManagementUrlCategoryType.Member);
        if(props.authStore.loginUser.type === UserType.Admin){
            props.userStore.changeUiState(UI.MemberTable, 0);
            props.userStore.memberTreeSelectedTeamId = "";
            props.userStore.initUserTablePage();
            props.teamStore.getTeamTreeList();
            props.userStore.getUserTableListByAll();
            props.userStore.initMemberTreeExpandedList();
        }else{
            props.userStore.changeUiState(UI.MemberTable, 0);
            props.userStore.memberTreeSelectedTeamId = "";
            props.userStore.initUserTablePage();
            props.userStore.getUserTableListByTeam(intl, props.teamStore.selectTeamByLeader.id);
            props.teamStore.getTeamTreeList();
        }
    };

    const handleExcelManagement = () => {
        handleListItemClick(ManagementUrlCategoryType.Excel);
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} className={classes.gridBox}>
                    <Paper className={classes.paper}>
                        <MenuList>
                            {props.authStore.loginUser.type === UserType.Admin &&
                            <MenuItem
                                selected={props.match.params.category === ManagementUrlCategoryType.Organization}
                                onClick={() => handleListItemClick(ManagementUrlCategoryType.Organization)}
                                className={classes.menuItemBox}
                            >
                                <FormattedMessage id="organization_setting"/>
                            </MenuItem>
                            }
                            <MenuItem
                                selected={props.match.params.category === ManagementUrlCategoryType.Team}
                                onClick={() => handleTeamManagement()}
                                className={classes.menuItemBox}
                            >
                                <FormattedMessage id="team_management"/>
                            </MenuItem>

                            <MenuItem
                                selected={props.match.params.category === ManagementUrlCategoryType.Member}
                                onClick={() => handleTeamMemberManagement()}
                                className={classes.menuItemBox}>
                                {props.authStore.loginUser.type === UStore.UserType.Admin ?
                                                                <FormattedMessage id="team_member_and_role_management"/> :
                                                                <FormattedMessage id="member_management"/>}
                            </MenuItem>
                            {props.authStore.loginUser.type === UserType.Admin &&
                            <MenuItem
                                selected={props.match.params.category === ManagementUrlCategoryType.Excel}
                                onClick={() => handleExcelManagement()}
                                className={classes.menuItemBox}
                            >
                                <FormattedMessage id={"excel_create_team_and_member"}/>
                            </MenuItem>
                            }
                        </MenuList>
                    </Paper>
                    {props.match.params.category === ManagementUrlCategoryType.Team && props.authStore.loginUser.type === UserType.Admin &&
                        <Box style={{width:180}}>
                            <TabletTeamTreeView isMobile={props.isMobile} isTablet={props.isTablet}/>
                        </Box>

                    }
                    {props.match.params.category === ManagementUrlCategoryType.Member && props.authStore.loginUser.type === UserType.Admin &&
                        <Box style={{width:180}}>
                            <TabletTeamMemberTreeView isMobile={props.isMobile} isTablet={props.isTablet} selectTeam={setSelectedTeamId}/>
                        </Box>
                    }
                </Grid>
                <Grid item xs={12} >
                    {props.match.params.category === ManagementUrlCategoryType.Organization && props.authStore.loginUser.type === UserType.Admin &&
                        <TabletOrganizationInformation {...props}/>
                    }
                    {props.match.params.category === ManagementUrlCategoryType.Team &&
                        <TabletTeamManagement {...props}/>
                    }
                    {props.match.params.category === ManagementUrlCategoryType.Member &&
                        <TabletTeamMemberManagement {...props} selectedTeamId={selectedTeamId}/>
                    }
                    {(props.authStore.loginUser.type === UserType.Admin) && props.match.params.category === ManagementUrlCategoryType.Excel &&
                        <TeamManagementByExcel {...props}/>
                    }
                </Grid>
            </Grid>
        </div>
    );
}
export default withRouter(inject('teamStore', 'userStore', 'authStore', 'orgStore', 'progressStore')(observer(TabletOrganizationManagement)));

