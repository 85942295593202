import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    IconButton,
    InputBase,
    Box,
} from "@material-ui/core";
import { ReactComponent as SearchIcon } from "../../../../common/images/SearchIcon.svg";

const style = theme => ({
    search: {
        height : 20,
        width: '50%',
        display:'flex',
        alignItems:'center',
        background: '#fff',
        border:'1px solid #a3a8af',
        padding:'5px 10px',
        borderRadius:3,
        "& .MuiInputBase-input::placeholder":{
            opacity:1,
            fontFamily: 'Montserrat',
            fontSize:12
        }
    },
    searchIcon: {
        width : 10,
        height : 10,
        cursor:'pointer',
        padding:0,
        "&:hover":{
            background:'transparent'
        },
        marginRight: 2,
    },
    inputRoot: {
        color: '#a3a8af',
        fontFamily: 'Montserrat',
        fontSize:10,
        fontWeight:500,
    },
});

class TabletPreviewTopSearch extends Component {
    render() {
        const { classes } = this.props;

        return (
            <Box className={classes.search}>
                <InputBase
                    className={classes.inputRoot}
                    inputProps={{ 'aria-label': 'search' }}
                />
                <IconButton className={classes.searchIcon} edge={'end'}>
                    <SearchIcon style={{width:10, height:10}}/>
                </IconButton>
            </Box>
        );
    }
}

export default withStyles(style)(TabletPreviewTopSearch);