import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Box,Collapse} from "@material-ui/core";
import MobileTimeLineCalendarShape from "./MobileTimeLineCalendarShape";
import {observer} from "mobx-react";
import { ReactComponent as MobileCalendarArrows } from "../../../common/images/MobileCalendarArrows.svg";
import MobileDataTimeLine from "./MobileDataTimeLine";
import {withRouter} from "react-router-dom";

const style = theme => ({
    root:{
        marginTop:10,
    },
    arrowBox:{
        borderTop:'1px solid #c2c2c2',
        display:'flex',
        justifyContent:'center'
    }
});

@observer
class MobileTimeLineCalendar extends Component {
    state = {
        checked: true,
    };

    handleChange  = () => {
        this.setState(state => ({ checked: !state.checked }));
    };
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Collapse in={!this.state.checked} collapsedHeight={130}>
                    <MobileTimeLineCalendarShape isWeekView={this.state.checked} setWeekView={isWeekView => this.setState({checked: isWeekView})}/>
                </Collapse>

                <Box className={classes.arrowBox} onClick={this.handleChange}>
                    <MobileCalendarArrows/>
                </Box>

                <MobileDataTimeLine history={this.props.history}/>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(MobileTimeLineCalendar));