import {makeAutoObservable} from "mobx";
import axios from "axios";
import _ from 'lodash';

const logPrefix = ' [SurveyStore] ';

export const SurveyItemType = {
    SingleChoice: "SingleChoice",
    MultipleChoice: "MultipleChoice",
    ShortAnswer: "ShortAnswer",
    Rating: "Rating",
    Context: "Context"
};
export const LiveFormType = {
    All: "all",
    Survey: "survey",
    Quiz: "quiz"
}
export const SurveyItemOptionType = {
    Normal: "Normal",
    Etc: "Etc",
};
const ErrorCode = {
    CanNotFoundUser: "msg.error_update_room",
    CanNotFoundSurvey: "msg.error_update_room",
    CanNotInsertSurvey: "msg.error_update_survey",
    DataMakeFail: "msg.error_update_room",
    CanNotInsertData: "msg.error_update_room",
    CanNotDeleteSurvey: "msg.error_update_room",
    CanNotDeleteSurveyItem: "msg.error_update_room",
    BadRequest: "msg.error_input_missing",
    Unknown: "msg.error_unknown"
};

const EmptySurvey = {
    surveyId: '',
    userId: '',
    title: '',
    descriptions: '',
    createdDatetime: '',
    updatedDatetime: '',
    surveyItems: []
};

const EmptySurveyItems = {
    surveyItemId: '',
    userId: '',
    title: '',
    contents: '',
    type: '',
    number: '',
    required: false,
    createdDatetime: '',
    updatedDatetime: '',
    surveyItemImage: [],
    surveyItemOption: []
};

const EmptySurveyItemImage = {
    surveyItemId: '',
    surveyItemImageId: '',
    name: '',
    type: '',
    size: '',
    width: '',
    height: '',
    image: '',
    createdDatetime: '',
    updatedDatetime: '',
};

const EmptySurveyItemOption = {
    surveyItemOptionId: '',
    surveyItemId: '',
    contents: '',
    number: '',
    etc: '',
    createdDatetime: '',
    updatedDatetime: '',
    surveyItemOptionImage: []
};

const EmptySurveyItemOptionImage = {
    surveyItemOptionId: '',
    surveyItemOptionImageId: '',
    name: '',
    type: '',
    size: '',
    width: '',
    height: '',
    image: '',
    createdDatetime: '',
    updatedDatetime: '',
};

export default class SurveyStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    };

    survey = Object.assign({}, EmptySurvey);
    surveyItems = Object.assign({}, EmptySurveyItems);
    surveyItemImage = Object.assign({}, EmptySurveyItemImage);
    surveyItemOption = Object.assign({}, EmptySurveyItemOption);
    surveyItemOptionImage = Object.assign({}, EmptySurveyItemOptionImage);
    surveyList = [];
    surveyItemDataList = [];
    surveyHistory = [];
    selectedSurveyId = '';
    isSurveyHistoriesLoading = false;
    isSurveyLoading = false;
    openConfirmDialog = false;
    openDeleteDialog = false;
    openDeleteItemDialog = false;
    mobileSurveyDialog = false;
    liveFormType = "all";
    errMsg = '';

    initSurvey = (loginUser, surveyId) => {
        // console.log(logPrefix,"InitSurvey loginUser={}, surveyId={}", loginUser, surveyId);
        this.survey = Object.assign({}, EmptySurvey);
        this.surveyItems = Object.assign({}, EmptySurveyItems);
        this.surveyItemImage = Object.assign({}, EmptySurveyItemImage);
        this.surveyItemOption = Object.assign({}, EmptySurveyItemOption);
        this.surveyItemOptionImage = Object.assign({}, EmptySurveyItemOptionImage);
        this.openConfirmDialog = false;
        this.errMsg = '';
        this.surveyItemDataList = [];
        this.surveyHistory = [];
        this.isSurveyLoading = false;
        this.setUserId(loginUser);
        if (surveyId) {
            this.getSurveyHistory(surveyId)
            this.getSurveyDetailBySurveyId(surveyId)
        }
    };

    resetSurveyItems = () => {
        this.surveyItems = Object.assign({}, EmptySurveyItems);
        this.surveyItemOption = Object.assign({}, EmptySurveyItemOption);
        this.surveyItems.surveyItemOption.push(this.surveyItemOption);
    }

    setUserId = (loginUser) => {
        // console.log(logPrefix, "setUserId loginUser={}", loginUser.id);
        this.survey.userId = loginUser.id;
    }

    changeSurveyTitle = (value) => {
        this.survey.title = value;
    }

    changeSurveyDescriptions = (value) => {
        this.survey.descriptions = value;
    }

    get getIsLoading() {
        return this.isSurveyLoading || this.isSurveyHistoriesLoading;
    };

    changeConfirmDialogOpen = () => {
        this.openConfirmDialog = !this.openConfirmDialog;
    };

    changeOpeDeleteDialog = () => {
        this.openDeleteDialog = !this.openDeleteDialog;
    };

    changeOpeDeleteItemDialog = () => {
        this.openDeleteItemDialog = !this.openDeleteItemDialog;
    };

    changeLiveFormType = (value) => {
        this.liveFormType = value;
    };

    isInputValidation = (intl, survey) => {
        let checkInput = true;
        if (survey.title === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_subject"});
        } else if (survey.surveyItems.findIndex(item => item.title === "") !== -1) {
            checkInput = false;
            this.errMsg = "< " + ( this.surveyItemDataList.findIndex(item => item.title === "") + 1 )+ " > " + intl.formatMessage({id: "msg.error_subject"});
        }
        this.openConfirmDialog = !checkInput;
        return checkInput;
    }

    addSurveyItems = (type) => {
        this.resetSurveyItems();
        let newSurveyItem = _.cloneDeep(this.surveyItems);
        if (type === SurveyItemType.Context) {
            newSurveyItem.type = SurveyItemType.Context;
            newSurveyItem.number = this.surveyItemDataList.length + 1;
            newSurveyItem.surveyItemOption = [];
            // console.log(logPrefix, "Add Item type={}", SurveyItemType.Context, this.surveyItemDataList);
        } else {
            newSurveyItem.type = SurveyItemType.SingleChoice;
            newSurveyItem.number = this.surveyItemDataList.length + 1;
            newSurveyItem.surveyItemOption[0].number = 1;
            // console.log(logPrefix, "Add Item type={}", SurveyItemType.SingleChoice, this.surveyItemDataList);
        }
        this.surveyItemDataList.push(newSurveyItem);
    }

    addSurveyItemOptions = (index) => {
        this.surveyItemOption = Object.assign({}, EmptySurveyItemOption);
        let newSurveyItemOption = _.cloneDeep(this.surveyItemOption);
        newSurveyItemOption.number = this.surveyItemDataList[index].surveyItemOption.length + 1;
        this.surveyItemDataList[index].surveyItemOption.push(newSurveyItemOption);
        if (this.surveyItemDataList[index].surveyItemOption.filter(option => option.etc === true).length > 0) {
            this.changeItemOptionIndex(index, this.surveyItemDataList[index].surveyItemOption.findIndex(option => option.etc === true), this.surveyItemDataList[index].surveyItemOption.length)
        }
        // console.log(logPrefix, "Add Item Option", this.surveyItemDataList[index].surveyItemOption);
    }

    addSurveyEtcItemOptions = (index) => {
        this.surveyItemOption = Object.assign({}, EmptySurveyItemOption);
        let newSurveyItemOption = _.cloneDeep(this.surveyItemOption);
        newSurveyItemOption.number = this.surveyItemDataList[index].surveyItemOption.length + 1;
        newSurveyItemOption.etc = true;
        this.surveyItemDataList[index].surveyItemOption.push(newSurveyItemOption);
        // console.log(logPrefix, "Add Etc Item Option", this.surveyItemDataList[index].surveyItemOption);
    }

    copySurveyItem = (index) => {
        const copyItem = _.cloneDeep(this.surveyItemDataList[index]);
        copyItem.number = this.surveyItemDataList.length + 1;
        this.surveyItemDataList.push(copyItem);
    }

    deleteSurveyItems = (index) => {
        this.surveyItemDataList.splice(index, 1);
        this.surveyItemDataList = this.surveyItemDataList.map((item, index) => {
            const number = {...item, number: index + 1};
            return {...number};
        });
        // console.log(logPrefix, "Delete Survey Item index={}, this.surveyItemDataList={}", index, this.surveyItemDataList);
    }

    changeSurveyItemType = (index, value) => {
        this.resetSurveyItems();
        let newSurveyItem = _.cloneDeep(this.surveyItems);
        newSurveyItem.surveyItemOption[0].number = 1;
        this.surveyItemDataList[index] = newSurveyItem;
        if (value === SurveyItemType.Rating) {
            this.surveyItemDataList[index].surveyItemOption = [];
            let newSurveyItemOption = _.cloneDeep(this.surveyItemOption);
            for (let i = 0; i < 2; i++) {
                newSurveyItemOption.number = i * 10;
                this.surveyItemDataList[index].surveyItemOption.push(newSurveyItemOption);
            }
        } else if (value === SurveyItemType.ShortAnswer) {
            this.surveyItemDataList[index].surveyItemOption = [];
        }
        this.surveyItemDataList[index].number = index + 1;
        this.surveyItemDataList[index].type = value;
    }

    changeSurveyItemTitle = (index, value) => {
        this.surveyItemDataList[index].title = value;
    }

    changeItemIndex = (selectedIndex, destination) => {
        const deletedItem = this.surveyItemDataList.splice(selectedIndex, 1);
        this.surveyItemDataList.splice(destination, 0, ...deletedItem);
        this.surveyItemDataList = this.surveyItemDataList.map((item, index) => {
            const number = {...item, number: index + 1};
            return {...number};
        });
    }

    changeItemOptionIndex = (index, selectedIndex, destination) => {
        // console.log(logPrefix, "ChangeItemOptionIndex index={}, selectedIndex={}, destination={}", index, selectedIndex, destination);
        const deletedItemOption = this.surveyItemDataList[index].surveyItemOption.splice(selectedIndex, 1);
        this.surveyItemDataList[index].surveyItemOption.splice(destination, 0, ...deletedItemOption);
        this.surveyItemDataList[index].surveyItemOption = this.surveyItemDataList[index].surveyItemOption.map((option, index) => {
            const number = {...option, number: index + 1};
            return {...number};
        });
    }

    deleteSurveyItemDescription = (index) => {
        // console.log(logPrefix, "DeleteSurveyItemDescription index={}", index);
        this.surveyItemDataList[index].contents = "";
    }

    changeSurveyItemTitleImageSize = (index, width, height) => {
        // console.log(logPrefix, "ChangeSurveyItemTitleImageSize index={}, width={}, height={}", index, width, height);
        if (this.surveyItemDataList[index].surveyItemImage.length > 0) {
            this.surveyItemDataList[index].surveyItemImage[0] = {
                ...this.surveyItemDataList[index].surveyItemImage[0],
                width: width,
                height: height
            };
        }
    }

    changeSurveyItemTitleImage = (index, value, file) => {
        // console.log(logPrefix, "ChangeSurveyItemTitleImage index={}, value={}, file={}", index, value, file);
        if (this.surveyItemDataList[index].surveyItemImage.length > 0) {
            this.surveyItemDataList[index].surveyItemImage[0] = {
                ...this.surveyItemDataList[index].surveyItemImage[0],
                image: value,
                size: file.size,
                name: file.name,
                type: file.type
            };
        } else {
            let newSurveyItemImage = _.cloneDeep(this.surveyItemImage);
            newSurveyItemImage = {
                ...newSurveyItemImage,
                image: value,
                size: file.size,
                name: file.name,
                type: file.type
            };
            this.surveyItemDataList[index].surveyItemImage.push(newSurveyItemImage);
        }
        // console.log(logPrefix, "ChangeSurveyItemTitleImage Success index={}, this.surveyItemDataList[index].surveyItemImage={}", index, this.surveyItemDataList[index].surveyItemImage);
    }

    changeSurveyItemOptionImageSize = (index, optionIndex, width, height) => {
        // console.log(logPrefix, "ChangeSurveyItemTitleImageSize index={}, optionIndex={}, width={}, height={}", index, optionIndex, width, height);
        this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage[0] = {
            ...this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage[0],
            width: width,
            height: height
        };
    }

    changeSurveyItemOptionImage = (index, optionIndex, value, file) => {
        // console.log(logPrefix, "ChangeSurveyItemOptionImage index={}, optionIndex={}, value={}", index, optionIndex, value);
        if (this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage.length > 0) {
            this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage[0] = {
                ...this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage[0],
                image: value,
                size: file.size,
                name: file.name,
                type: file.type
            };
        } else {
            this.surveyItemOptionImage = Object.assign({}, EmptySurveyItemOptionImage);
            let newSurveyItemOptionImage = _.cloneDeep(this.surveyItemOptionImage);
            newSurveyItemOptionImage = {
                ...newSurveyItemOptionImage,
                image: value,
                size: file.size,
                name: file.name,
                type: file.type
            };
            this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage.push(newSurveyItemOptionImage);
        }
        // console.log(logPrefix, "ChangeSurveyItemOptionImage index={}, optionIndex={}, this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage={}", index, optionIndex, this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage);
    }

    deleteSurveyItemTitleImage = (index) => {
        // console.log("this.surveyItemDataList[index]this.surveyItemDataList[index]", this.surveyItemDataList[index].surveyItemImage);
        this.surveyItemDataList[index].surveyItemImage.splice(0, 1);
        // console.log("this.surveyItemDataList[index]this.surveyItemDataList[index]", this.surveyItemDataList[index]);
    }

    changeSurveyItemDescription = (index, value) => {
        this.surveyItemDataList[index].contents = value;
    }

    changeSurveyItemOptionDescription = (index, optionIndex, value) => {
        // console.log(logPrefix, "ChangeSurveyItemOptionDescription index={}, optionIndex={}, value={}", index, optionIndex, value);
        this.surveyItemDataList[index].surveyItemOption[optionIndex].contents = value;
    }

    deleteSurveyItemOptionDescription = (index, optionIndex) => {
        // console.log(logPrefix, "DeleteSurveyItemOptionDescription index={}, optionIndex={}", index, optionIndex);
        this.surveyItemDataList[index].surveyItemOption.splice(optionIndex, 1);
        this.surveyItemDataList[index].surveyItemOption = this.surveyItemDataList[index].surveyItemOption.map((item, i) => {
            const number = {...item, number: i + 1};
            return {...number};
        });
        // console.log(logPrefix, "this.surveyItemDataList[index].surveyItemOption", this.surveyItemDataList[index].surveyItemOption);
    }

    deleteSurveyItemOptionImage = (index, optionIndex) => {
        // console.log(logPrefix, "DeleteSurveyItemOptionImage index={}, optionIndex={}", index, optionIndex);
        this.surveyItemDataList[index].surveyItemOption[optionIndex].surveyItemOptionImage.splice(0, 1);
        // console.log(logPrefix, "this.surveyItemDataList[index].surveyItemOption", this.surveyItemDataList[index].surveyItemOption);
    }

    changeSurveyItemRequired = (index, value) => {
        this.surveyItemDataList[index].required = value;
        // console.log(logPrefix, "ChangeSurveyItemRequired index={}, this.surveyItemDataList[index].required ={}", index, this.surveyItemDataList[index].required);
    }

    changeRatingOptionNumber = (value, index, direction) => {
        // console.log(logPrefix, "ChangeRatingOptionLabel index={}, direction={}, value={}, this.surveyItemDataList[index].surveyItemOption[direction]={}", index, direction, value, this.surveyItemDataList[index].surveyItemOption[direction]);
        this.surveyItemDataList[index].surveyItemOption[direction].number = value;
    }

    mobileSurveyDialogOpen = (loginUser, surveyId) => {
        if(surveyId){
            this.initSurvey(loginUser, surveyId);
        } else {
            this.initSurvey(loginUser);
        }
        this.mobileSurveyDialog = true;
    }

    mobileSurveyDialogClose = () => {
        this.mobileSurveyDialog = false;
    }

    changeRatingOptionLabel = (index, direction, value) => {
        // console.log(logPrefix, "ChangeRatingOptionLabel index={}, direction={}, value={}, this.surveyItemDataList[index].surveyItemOption[direction]={}", index, direction, value, this.surveyItemDataList[index].surveyItemOption[direction]);
        this.surveyItemDataList[index].surveyItemOption[direction].contents = value;
    }

    getSurveyListByUserId = function* getSurveyListByUserId(loginUser) {
        // console.log(logPrefix,"GetSurveyListByUserId Start... userId={}", loginUser.id);
        this.isSurveyLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/surveys/list/${loginUser.id}`);
            // console.log(logPrefix, "GetSurveyListByUserId Success... response={}", response.data);
            this.surveyList = response.data;
        } catch (e) {
            console.log(logPrefix, "GetSurveyListByUserId Fail : >> ", e);
        } finally {
            this.isSurveyLoading = false;
        }
    };

    getSurveyDetailBySurveyId = function* getSurveyDetailBySurveyId(surveyId) {
        // console.log(logPrefix,"GetSurveyDetailBySurveyId Start... surveyId={}", surveyId);
        this.isSurveyLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/surveys/detail/${surveyId}`);
            // console.log(logPrefix, "GetSurveyDetailBySurveyId Success... response={}", response.data);
            this.survey = {...response.data};
            this.surveyItemDataList = response.data.surveyItems;
        } catch (e) {
            console.log(logPrefix, "GetSurveyDetailBySurveyId Fail : >> ", e);
        } finally {
            this.isSurveyLoading = false;
        }
    };

    getSurveyHistory = function* getSurveyHistory(surveyId, callback) {
        // console.log(logPrefix,"GetSurveyHistory Start... surveyId={}", surveyId);
        this.isSurveyHistoriesLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/surveys/histories/${surveyId}`);
            // console.log(logPrefix, "GetSurveyHistory Success... response={}", response.data);
            this.surveyHistory = response.data;
        } catch (e) {
            console.log(logPrefix, "GetSurveyHistory Fail : >> ", e);
        } finally {
            this.isSurveyHistoriesLoading = false;
            callback&& callback();
        }
    };

    insertSurvey = function* insertSurvey(intl, history) {
        // console.log(logPrefix,"InsertSurvey Start...");
        this.isSurveyLoading = true;
        try {
            const param = Object.assign({}, this.survey);
            param.surveyItems = this.surveyItemDataList;
            if (!this.isInputValidation(intl, param)) {
                return false;
            }
            // console.log(logPrefix, "param : >>", param);
            const response = yield axios.post(this.serverContextPath + '/api/v1/surveys', param);
            // console.log(logPrefix, "InsertSurvey Success... response={}", response.data);
            this.surveyList = response.data;
            history.push(`/`);
        } catch (e) {
            console.log(logPrefix, "InsertSurvey Fail : >> ", e);
            // this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;
        } finally {
            // console.log(logPrefix, "InsertSurvey Success");
            this.isSurveyLoading = false;
        }
    };

    updateSurvey = function* updateSurvey(intl, history, surveyId) {
        // console.log(logPrefix,"UpdateSurvey Start...");
        this.isSurveyLoading = true;
        try {
            const param = Object.assign({}, this.survey);
            param.surveyItems = this.surveyItemDataList;
            if (!this.isInputValidation(intl, param)) {
                return false;
            }
            // console.log(logPrefix, "param : >>", param);
            const response = yield axios.put(this.serverContextPath + `/api/v1/surveys/${surveyId}`, param);
            // console.log(logPrefix, "UpdateSurvey Success... response={}", response.data);
            this.surveyList = response.data;
            history.push(`/`);
        } catch (e) {
            console.log(logPrefix, "insertSurveyItem Fail : >> ", e.response);
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;
        } finally {
            // console.log(logPrefix, "UpdateSurvey Success");
            this.isSurveyLoading = false;
        }
    };

    copySurvey = function* copySurvey(intl, surveyId, loginUser) {
        // console.log(logPrefix,"CopySurvey Start... surveyId={}, loginUser", surveyId, loginUser);
        this.isSurveyLoading = true;
        try {
            yield axios.post(this.serverContextPath + `/api/v1/surveys/${surveyId}/copy/${loginUser.id}`);
            this.getSurveyListByUserId(loginUser);
        } catch (e) {
            console.log(logPrefix, "CopySurvey Fail : >> ", e);
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;
        } finally {
            // console.log(logPrefix, "CopySurvey Success");
            this.isSurveyLoading = false;
        }
    };

    deleteSurvey = function* deleteSurvey(intl, loginUser) {
        // console.log(logPrefix,"DeleteSurvey Start... surveyId={}", surveyId);
        this.isSurveyLoading = true;
        try {
            yield axios.delete(this.serverContextPath + `/api/v1/surveys/${this.selectedSurveyId}`);
            this.getSurveyListByUserId(loginUser);
        } catch (e) {
            console.log(logPrefix, "DeleteSurvey Fail : >> ", e);
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;
        } finally {
            // console.log(logPrefix, "DeleteSurvey Success");
            this.isSurveyLoading = false;
        }
    };
}