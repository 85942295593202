import React from 'react';
import {inject, observer} from "mobx-react";
import {Avatar, Box, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";

function SkeletonPreview() {
    return (
        <>
            <Box display={'flex'} alignItems={'center'}>
                <Box margin={1}>
                    <Skeleton variant={'circle'}>
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box width={'100%'}>
                    <Skeleton width={'100%'} >
                        <Typography>.</Typography>
                    </Skeleton>
                </Box>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Box margin={1}>
                    <Skeleton variant={'circle'}>
                        <Avatar />
                    </Skeleton>
                </Box>
                <Box width={'100%'}>
                    <Skeleton width={'100%'} >
                        <Typography>.</Typography>
                    </Skeleton>
                </Box>
            </Box>
            <Skeleton vairant={'rect'} width={'100%'} >
                <div style={{paddingTop : '57%'}} />
            </Skeleton>
        </>
    );
}



export default inject('orgStore')(observer(SkeletonPreview));
