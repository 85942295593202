import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Popper,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Box,
    IconButton,
    Tooltip
} from "@material-ui/core";
import {ReactComponent as EyeIcon} from "../../../common/images/EyeIcon.svg";
import {ReactComponent as MarkQuestion} from "../../../common/images/MarkQuestion.svg";
import {ReactComponent as Close} from "../../../common/images/Close.svg";
import {inject, observer} from "mobx-react";
import {ReactComponent as WarningIcon} from "../../../common/images/WarningIcon.svg";
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../../common/util/color.util";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 10,
        maxWidth: 500,
        overflow: 'auto',
        boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        "& .MuiTypography-h6": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.125rem',
            color: '#000',
            fontWeight: 600
        },
        "& .MuiDialogTitle-root": {
            padding: '0 0 10px',
            borderBottom: '1px solid #c0c2c3',
            marginBottom: 20,
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiButton-root.Mui-disabled": {
            background: '#fff',
            color: '#88889D',
            border: '1px solid #A3A8AF'
        }
    },
    buttonText: {
        textDecoration: 'underline',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0097ff',
        textAlign: 'left',
        padding: 0,
        minWidth: 0,
        "&:hover": {
            background: 'transparent',
            textDecoration: 'underline'
        }
    },
    popper: {
        zIndex: 1,
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    dialogTitleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight: 600,
    },
    textStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight: 600,
        width: 100
    },
    inputStyle: {
        width: 230,
        padding: '6px 10px',
        borderRadius: 4,
        border: '1px solid #b7bcd6',
        background: '#fff',
        fontSize:'1rem',
        "&:focus": {
            outline: 'none !important',
        },
    },
    inputStyle_err: {
        width: 230,
        padding: '6px 10px',
        borderRadius: 4,
        border: '1px solid red',
        background: '#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        color: '#8d909e',
        "&:focus": {
            outline: 'none !important',
        },
    },
    textStyle2: {
        marginLeft: 100,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.873rem',
        color: '#8d909e',
    },
    textStyle2_err: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        color: 'red'
    },
    buttonStyle: {
        width: 130,
        padding: '6px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.063rem',
        // color: '#fff',
        fontWeight: 600,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    passwordBtn: {
        color: '#57a4ff',
        textDecoration: 'underline',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1rem',
        "&:hover": {
            background: 'transparent',
            textDecoration: 'underline',
        }
    },
    iconBtn: {
        padding: 5,
        marginLeft: 5
    }
});

@inject('userStore')
@observer
class ResetPassword extends Component {
    constructor(props) {
        super(...arguments, props);
        this.state = {
            arrow: true,
            arrowRef: null,
            disablePortal: false,
            flip: true,
            open: false,
            placement: 'right',

            pwState: "password",
            userPasswordCheckState: true,
            userPasswordCheckMsg: "",
            tooltipState: false,
        };
    }

    componentWillUnmount() {
        this.props.userStore.detailUser.password = "";
    }

    handleClickButton = () => {
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = (event) => {
        this.props.userStore.detailUser.password = "";
        this.setState({
            open: false,
        });
    };
    handleCancel = (e) => {
        if (e.isComposing || e.keyCode === 229) {
            return;
        }
        if (e.keyCode === 27) {
            this.handleClose(e);
        } else if (e.keyCode === 13) {
            if (!this.state.userPasswordCheckState
                || this.props.userStore.detailUser.password === ""
                || !this.props.userStore.detailUser.password) {
                return;
            }
            this.modifyUserPassword();
        }
    }
    creatDetailUserRandomPassword = async (e) => {
        this.handleCheckPasswordState(true, "");
        await this.props.userStore.creatDetailUserRandomPassword();
        await this.copyRandomPassword();
    }
    copyRandomPassword = async () => {
        const pwState = this.state.pwState;
        const password = document.getElementById("password");
        if( pwState === "password"){
            this.setState({pwState : "text"});
        }
        password.select();
        document.execCommand("copy");
        password.setSelectionRange(0, 0);
        if( pwState === "password"){
            this.setState({pwState : "password"});
        }
        this.setState({tooltipState: true});
        setTimeout(() => this.setState({tooltipState: false}), 3000)
    }
    changeDetailUserPassword = (e) => {
        const {intl} = this.props;
        this.props.userStore.changeDetailUserPassword(intl, e, this.handleCheckPasswordState);
    }
    handleCheckPasswordState = (value, msg) => {
        this.setState({
            userPasswordCheckState: value,
            userPasswordCheckMsg: msg,
        });
    }

    handleShowPassword = () => {
        if (this.state.pwState === "password") {
            this.setState({pwState: "text"});
        } else {
            this.setState({pwState: "password"});
        }
    }

    modifyUserPassword = () => {
        const {intl} = this.props;
        this.props.userStore.modifyUserPassword(intl, this.handleClose);
    }
    disabledFrom = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    }

    render() {
        const {classes, intl} = this.props;
        const {open, placement, disablePortal, flip, arrow, arrowRef} = this.state;
        const {detailUser} = this.props.userStore;


        return (
            <div className={classes.root}>
                <Button
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    onClick={this.handleClickButton}
                    className={classes.buttonText}
                >
                    <FormattedMessage id="reset"/>
                </Button>
                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    placement={placement}
                    disablePortal={disablePortal}
                    className={classes.popper}
                    modifiers={{
                        flip: {
                            enabled: flip,
                        },
                        arrow: {
                            enabled: arrow,
                            element: arrowRef,
                        },
                        preventOverflow: {
                            enabled: false,
                            boundariesElement: 'scrollParent',
                        },
                        hide: {
                            enabled: false,
                            boundariesElement: 'scrollParent',
                        }
                    }}

                >
                    {arrow ? <span className={classes.arrow} ref={this.handleArrowRef}/> : null}
                    <Paper component='div' className={classes.paper}>
                        <DialogTitle>
                            <Box display='flex' justifyContent='space-between' alignItems="center">
                                <Typography className={classes.dialogTitleText}>
                                    <FormattedMessage id="reset_password"/>
                                </Typography>
                                <IconButton onClick={this.handleClose} style={{padding: 5}}>
                                    <Close style={{width: 20, height: 20}}/>
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component='div'>
                                <Box display="flex" alignItems="center">
                                    <Typography className={classes.textStyle1}>
                                        <FormattedMessage id="new_password"/>
                                    </Typography>
                                    <Box display='flex' flexDirection='column'>
                                        <Box display="flex" alignItems="center">
                                            <form onKeyDown={this.disabledFrom}>
                                                <input
                                                    value={detailUser.password ? detailUser.password : ""}
                                                    name="password"
                                                    type={this.state.pwState}
                                                    id="password"
                                                    placeholder={intl.formatMessage({id: "msg.insert_new_password"})}
                                                    className={this.state.userPasswordCheckState ? classes.inputStyle : classes.inputStyle_err}
                                                    onChange={this.changeDetailUserPassword}
                                                    onKeyDown={this.handleCancel}
                                                    autoComplete={"off"}
                                                />
                                            </form>
                                            <IconButton id="passwordBtn"
                                                        onClick={this.handleShowPassword}
                                                        className={classes.iconBtn}>
                                                <EyeIcon/>
                                            </IconButton>
                                            <Tooltip
                                                open={this.state.tooltipState}
                                                title={intl.formatMessage({id: "msg.copied_password"})}
                                                disableHoverListener
                                                disableTouchListener
                                                arrow={true}
                                                placement={"top-start"}>
                                                <Button className={classes.passwordBtn}
                                                        onClick={this.creatDetailUserRandomPassword}>
                                                    <FormattedMessage id="auto_generate"/>
                                                </Button>
                                            </Tooltip>
                                        </Box>
                                    </Box>
                                </Box>
                                {!this.state.userPasswordCheckState &&
                                <Box display="flex" alignItems='center' pt={1}>
                                    {this.state.userPasswordCheckState ? null :
                                        <WarningIcon style={{width: 20, height: 20, marginRight: 5, marginLeft: 100}}/>}
                                    <Typography
                                        className={this.state.userPasswordCheckState ? classes.textStyle2 : classes.textStyle2_err}>
                                        {this.state.userPasswordCheckMsg}
                                    </Typography>

                                </Box>
                                }
                                <Box display="flex" alignItems='center' pt={1}>
                                    <Typography className={classes.textStyle2}>
                                        <FormattedMessage id="msg.validation_password"/>
                                    </Typography>
                                    <MarkQuestion style={{width: 20, height: 20,marginLeft: 5}}/>
                                </Box>
                                <Box display="flex" alignItems='center'>
                                    <Typography className={classes.textStyle2}>
                                        <FormattedMessage id="msg.required_change_password"/>
                                    </Typography>
                                    <MarkQuestion style={{width: 20, height: 20,marginLeft: 5}}/>
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className={classes.buttonStyle}
                                    onClick={this.modifyUserPassword}
                                    disabled={
                                        !this.state.userPasswordCheckState
                                        || detailUser.password === ""
                                        || !detailUser.password
                                    }>
                                <FormattedMessage id="save"/>
                            </Button>
                        </DialogActions>
                    </Paper>
                </Popper>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(ResetPassword));