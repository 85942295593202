import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography, Grid, Box} from "@material-ui/core";
import { ResponsivePie } from '@nivo/pie'
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        display:'flex',
        width: '100%',
        height: window.innerHeight/2
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        fontWeight:600,
        color:'#5a6069',
        marginRight:'1rem',
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:300,
        color:'#5a6069',
    },
    chart: {
        position:'relative',
        display:'flex',
        justifyContent:'space-between',
        "& > div > div > svg > g > g:last-child > g > text":{
            fontSize:'1.5rem !important',
            fontFamily:'Montserrat !important',
            // fill:'#0047ac !important',
            fontWeight:'600 !important',
        }
    },
    chartBox:{
        position:'absolute',
        left:0,
        bottom:0,
    },
    chartBox2:{
        position:'absolute',
        right:0,
    },
    circleStyle1:{
        width:12,
        height:12,
        borderRadius:'50%',
        background:'#0024ff',
        content:'',
        marginRight:5
    },
    circleStyle2:{
        width:12,
        height:12,
        borderRadius:'50%',
        background:'#4fb6fe',
        content:'',
        marginRight:5
    }
});

// const lineGenerator = line()
//     .x(d => d.x)
//     .y(d => d.y)

class MobileAttendanceChart extends Component {
    render() {
        const { classes, intl, data } = this.props;
        const chartData = [
            {
                "id": intl.formatMessage({id: "no_response"}),
                "label": intl.formatMessage({id: "no_response"}),
                "value": data.totalCount - data.responseCount,
                "per": (((data.totalCount - data.responseCount) / data.totalCount) * 100).toFixed(2) + '%',
                "color": "#4fb6fe"
            },
            {

                "id": intl.formatMessage({id: "response"}),
                "label": intl.formatMessage({id: "response"}),
                "value": data.responseCount,
                "per": ((data.responseCount / data.totalCount) * 100).toFixed(2) + '%',
                "color": "#0047ac"
            }
        ]

        const MyResponsivePie = ({chartData}) => (
            <ResponsivePie
                data={chartData}
                margin={{ top: 0, right: 30, bottom: 40, left:30 }}
                startAngle={0}
                innerRadius={0.55}
                // colors={{ scheme: 'pastel2' }}
                borderColor={{ from: 'color', modifiers: [ [ 'darker', '0' ] ] }}
                enableRadialLabels={false}
                radialLabelsLinkOffset={-15}
                radialLabelsLinkDiagonalLength={0}
                radialLabelsLinkHorizontalLength={0}
                radialLabelsLinkStrokeWidth={0}
                radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
                isInteractive={false}
                sliceLabelsSkipAngle={14}
                sliceLabelsTextColor="#ffffff"
                sliceLabel={function(e){
                    return e.data.per;
                }}
                legends={[]}
                colors={{ datum: 'data.color' }}
            />
        )

        return (
            <div className={classes.root}>
                <Grid item xs={12} className={classes.chart}>
                    <Box className={classes.chartBox}>
                        {chartData.map((d, i) => (
                            <Box key={i} display='flex' justifyContent='space-between'>
                                <Typography className={classes.textStyle}>{d.label}</Typography>
                                <Typography className={classes.textStyle1}>{d.value}<FormattedMessage id="person"/></Typography>
                            </Box>
                        ))}
                        <Box display='flex' justifyContent='space-between'>
                            <Typography className={classes.textStyle}><FormattedMessage id="total_number_of_participants"/></Typography>
                            <Typography className={classes.textStyle1}>{chartData.map(d => d.value).reduce((a, b) => a + b)}<FormattedMessage id="person"/></Typography>
                        </Box>
                    </Box>

                    {MyResponsivePie({chartData: chartData})}

                    <Box className={classes.chartBox2}>
                        <Box display='flex' alignItems='center' pb={1}>
                            <div className={classes.circleStyle1}/><Typography className={classes.textStyle1}><FormattedMessage id="response"/></Typography>
                        </Box>
                        <Box display='flex' alignItems='center'>
                            <div className={classes.circleStyle2}/><Typography className={classes.textStyle1}><FormattedMessage id="no_response"/></Typography>
                        </Box>

                    </Box>
                </Grid>
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(MobileAttendanceChart));