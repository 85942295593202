import React from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import MobileMainBg from '../../../common/images/main_bg_samsung.jpg';
import {inject, observer} from "mobx-react";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";
import {injectIntl} from "react-intl";
import MobileSignIn from "./MobileSignIn";

const styles = theme => ({
    root: {},
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    mainBack: {
        position:'relative',
        backgroundImage: `url(${MobileMainBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: '100%',
        height: '35vh',

    },
    buttonStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.5rem',
        color:'#fff',
        background: () => theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor,
        width:229,
        padding:'10px 18px',
        display:'flex',
        justifyContent:'space-between',
        marginTop:25,
        fontWeight:600,
    },
    inputBtnStyle: {
        width: 60,
        height: 27,
        flexGrow : 0,
        borderRadius: 21,
        color: '#dc524f',
        background: '#ffe4e3',
        marginTop : 40,
        marginLeft: 18,
        "&:hover": {
            background: '#ffe4e3',
            color: '#dc524f',
        },
    },
});

@inject('authStore')
@observer
class MobileHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLogoDisplay: true,
        }
    }

    timeout = null;

    componentDidMount() {
        this.timeout = setTimeout(() => this.setState({ isLogoDisplay: false}), 3000);
    }

    componentWillUnmount() {
        this.setState({isLogoDisplay : false});
        clearTimeout(this.timeout);
    }

    handleClickOpen = () => {
        this.props.authStore.setLoginModal();
    };

    handleInputBtn = () => {
        const { classes, intl } = this.props;
        ToastsStore.info(intl.formatMessage({id: "msg.success_url_copy"}), 1500, classes.toasts);
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.mainBack} >
                    <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground />
                </div>
                <MobileSignIn />
            </div>
        );
    }
};

export default withRouter(withStyles(styles)(injectIntl(MobileHome)));