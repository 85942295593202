import React from 'react';
import NewWindow from 'react-new-window';
import {Component} from "react";
import InnerHTML from "dangerously-set-html-content";
import {inject} from "mobx-react";
import {getURLParams} from "../../../common/Params";
import {withRouter} from "react-router-dom";

@inject("timelineStore")
class VideoPlayer extends Component {
    constructor(props) {
        super(props);

        console.log(window.location.hostname);

        if (!props.videoInfo || !props.videoName) {
            const urlParams = getURLParams(window.location.search);
            if (window.location.hostname.startsWith("gbtp")) {
                this.src = "https://gbtp.onthe.live:7443/contents/" + urlParams.roomId + "/" + urlParams.videoName + "/playlist.m3u8";
            } else {
                this.src = "https://slive-vod-dev.onthe.live/contents/" + urlParams.roomId + "/" + urlParams.videoName + "/playlist.m3u8";
            }
        } else {
            if (window.location.hostname.startsWith("gbtp")) {
                this.src = "https://gbtp.onthe.live:7443/contents/" + props.videoInfo.id + "/" + props.videoName + "/playlist.m3u8";
            }else {
                this.src = "https://slive-vod-dev.onthe.live/contents/" + props.videoInfo.id + "/" + props.videoName + "/playlist.m3u8";
            }
        }
        this.html = `
<head>
    <link href="https://vjs.zencdn.net/7.11.4/video-js.css" rel="stylesheet" />
</head>

<body>
<video id="my-video" class="video-js" controls preload="auto" width="1280" height="720" data-setup="{}"> 

    <source title=${props.videoName} src=${this.src} type="application/x-mpegURL"/>

    <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
    </p>
</video>

<script src="https://vjs.zencdn.net/7.11.4/video.min.js"></script>
</body>`
    }

    render() {
        return (
            <NewWindow features={{width: 1280, height: 720}}
                       onUnload={() => this.props.timelineStore.setVideoOpened(false)}>
                <div>
                    <InnerHTML html={this.html}/>
                </div>
            </NewWindow>
        );
    }
}

export default withRouter(VideoPlayer);