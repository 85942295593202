import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Calendar from 'react-calendar';
import '../../../common/css/MobileCalendar.css';
import * as dayjs from "dayjs";
import {DATE_UTIL} from "../../../common/util/date.util";
import {inject, observer} from "mobx-react";

const style = theme => ({
    root: {
        // 달력
        "& .react-calendar":{
            width:260,
            maxWidth:260,
            border:'none',
            margin:"0 auto",
            "& .saturday": {
                color: 'black',
            }
        },
        "& .react-calendar__navigation__label__labelText":{
            fontFamily:'NanumSquareRoundOTF',
            color:'#000',
            fontSize:'1.5rem',
            fontWeight:800,
        } ,
        "& .react-calendar__month-view__weekdays__weekday":{
            "& abbr":{
                fontFamily:'NanumSquareRoundOTF',
                fontSize:'1.4rem',
                color:'#4f5660',
                fontWeight:'normal',
                textDecoration:'none'
            }
        },
        "& .react-calendar__month-view__days__day":{
            padding:10,
            "& abbr":{
                fontFamily:'NanumSquareRoundOTF',
                fontSize:'1.2rem',
                fontWeight:800,
            }
        },
        "& .today": {
            background: '#a3dfff !important',
            color:'#fff !important',
            // borderRadius:"50% !important",
        },
        "& .selectedDay":{
            background:'rgba(163, 223, 255, 0.4)',
            color:'#000',
            "&:enabled:focus":{
                background:'rgba(163, 223, 255, 0.4)',
                color:'#000',
            }
        },
        "& .selectedWeek":{
            background:'rgba(163, 223, 255, 0.4)',
            borderRadius:0
        },
        "& .nonSelectedWeek": {
            display: 'none'
        },

    },
});

@inject('authStore', 'userLoungeStore', 'timelineStore')
@observer
class MobileTimeLineCalendarShape extends Component {
    componentDidMount() {
        const {timelineStore} = this.props;
        if (timelineStore.timelineType === 1) {
            this.onChange();
        }
    };

    onChange = (value, event) => {
        const {authStore, userLoungeStore, timelineStore} = this.props;

        timelineStore.onChangeCalendarDate(userLoungeStore.selectedTeamId, authStore.loginUser.id, value);

        if(this.props.setWeekView) {
            this.props.setWeekView(true);
        }
    };

    render() {
        const { classes, timelineStore, isWeekView } = this.props;
        return (
            <div >
                {
                    timelineStore.getIsLoading === false ?
                        <div className={classes.root}>
                            <Calendar
                                locale={DATE_UTIL.getLanguage()}
                                calendarType="US"
                                onChange={this.onChange}
                                prev2Label={null}
                                next2Label={null}
                                maxDetail={"month"}
                                minDetail={"year"}
                                tileClassName={({ activeStartDate, date, view }) => {
                                    const isSameMonth = dayjs(activeStartDate).month() === dayjs(date).month();
                                    const isSaturday = date.getDay() === 6;
                                    const isSelectedDay = dayjs(date).isSame(timelineStore.calendarSelectedDay, 'day');
                                    const isSelectedWeek = dayjs(date).isSame(timelineStore.calendarWeekOfStartDay, 'day') || dayjs(date).isSame(timelineStore.calendarWeekOfEndDay, 'day') || dayjs(date).isBetween(timelineStore.calendarWeekOfStartDay, timelineStore.calendarWeekOfEndDay, 'day');
                                    const isToday = dayjs(date).format(DATE_UTIL.FORMAT.DATE_FORMAT) === DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT);

                                    if(view === 'month') {
                                        if (isSelectedWeek) {
                                            if (isSaturday && isSameMonth) {
                                                if (isSelectedDay) {
                                                    if(isToday) return 'selectedWeek selectedDay today';
                                                    return 'selectedWeek selectedDay';
                                                }
                                                if(isToday) return 'selectedWeek today';
                                                return 'selectedWeek';
                                            }

                                            if (isSelectedDay) {
                                                if(isToday) return "selectedWeek selectedDay today";
                                                return "selectedWeek selectedDay";
                                            }
                                            if(isToday) return 'selectedWeek today';
                                            return 'selectedWeek';
                                        } else {
                                            if (isSaturday && isSameMonth) {
                                                if(isToday) return isWeekView ? 'today nonSelectedWeek' : 'today';
                                                return isWeekView ? 'nonSelectedWeek' : null;
                                            }
                                            if(isToday) return isWeekView ? 'today nonSelectedWeek' : 'today';
                                            return isWeekView ? 'nonSelectedWeek' : null;
                                        }
                                    }
                                }}
                                formatDay={(locale, date) => dayjs(date).locale(locale).format('D')}
                            />
                        </div>
                        :
                        null
                }
            </div>
        );
    }
}

export default withStyles(style)(MobileTimeLineCalendarShape);