import {makeAutoObservable} from "mobx";
import fileDownload from "js-file-download";
import axios from "axios";

export default class TopBarStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }
    appUploadState = false;
    isSearchBarRender = true;
    setIsSearchBarRender = isSearchBarRender => this.isSearchBarRender = isSearchBarRender;

    *downloadApp() {
        try {
            this.appUploadState = true;
            const response = yield axios.get(`/api/v1/app`,{ responseType: 'blob', headers: {'Content-type' : 'application/octet-stream'}});
            // const fileBuffer = Buffer.from(response.data, 'base64');

            fileDownload(response.data, "onthelive.exe");

            this.appUploadState = false;
        } catch (error) {
            this.appUploadState = false;
        }
    }
}