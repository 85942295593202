import React, {useEffect} from "react";
import {
    Box,
    Paper,
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import {deepPurple} from "@material-ui/core/colors";
import TabletPreviewTopBar from "./Previews/TabletPreviewTopBar";
import TabletPreviewSideBar from "./Previews/TabletPreviewSideBar";
import TabletPreviewTimeline from "./Previews/TabletPreviewTimeline";


const useStyles = makeStyles((theme) => ({
    topBox: {
        '& > span': {
            margin: theme.spacing(2),
        },
        minWidth : '100%',
        maxHeight : 500,
        display :'flex'
    },
    purple: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
}))

function TabletTimelinePreview(props) {
    const classes = useStyles();
    const { setPreviewConfigs, previewConfigs } = props.orgStore;

    useEffect(() => {
        if(Object.values(previewConfigs).length === 0)setPreviewConfigs();
        else return null;
    }, [previewConfigs, setPreviewConfigs])


    return (
        <Paper className={classes.topBox}>
            <TabletPreviewSideBar mainColor={previewConfigs.MainColor} subColor={previewConfigs.SubColor}/>
            <Box style={{width:'100%'}}>
                <TabletPreviewTopBar mainColor={previewConfigs.MainColor} subColor={previewConfigs.SubColor}/>
                <Box>
                    <TabletPreviewTimeline mainColor={previewConfigs.MainColor} subColor={previewConfigs.SubColor}/>
                </Box>
            </Box>
        </Paper>
    )
}

export default inject('orgStore')(observer(TabletTimelinePreview));
