import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box, Button, MenuItem, MenuList, Popover,
    Typography,
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";
import QuizAdd from "./QuizAdd";
import QuizPreview from "./QuizPreview";

const style = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        paddingRight: 55,
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight: 600,
    },
    titleStyle: {
        fontSize: 24,
        fontWeight: 800,
    },
    contentsBox: {
        [theme.breakpoints.down('md')]: {
            paddingRight: 40,
        },
        width: '50%',
        paddingRight: 75
    },
    typoStyle1: {
        fontSize: '1rem',
        color: '#a3a8af'
    },
    btnStyle: {
        color: '#0097ff',
        textDecoration: 'underline',
        fontWeight: 500,
        "&:hover": {
            background: 'transparent',
            textDecoration: 'underline',
        }
    },
    popoverBox: {
        "& .MuiPopover-paper": {
            borderRadius: 7,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        }
    },
    menuText: {
        fontSize: '1rem',
        color: '#000',
        fontWeight: 500,
        "&:hover": {
            background: '#d0d0d0'
        }
    },
    previewBox:{
        [theme.breakpoints.down('md')]: {
            paddingLeft: 40,
        },
        width: '50%',
        paddingLeft: 75
    }
});

@inject('quizStore', 'authStore', 'progressStore')
@observer
class QuizCreateContents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const {classes, quizStore, history} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className={classes.root} style={{minHeight: 'calc(100vh - 192px)'}}>
                <Box className={classes.contentsBox} style={{borderRight: '1px solid #dbdbdb'}}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{margin: '60px 0 50px'}}>
                        <Typography className={clsx(classes.textStyle, classes.titleStyle)}><FormattedMessage
                            id="new_quiz"/></Typography>
                        {quizStore.quizHistory.length > 0 &&
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography className={clsx(classes.textStyle, classes.typoStyle1)}><FormattedMessage
                                id="usage_history"/></Typography>
                            <Button
                                aria-owns={open ? 'simple-popper' : undefined}
                                onClick={this.handleClick}
                                className={clsx(classes.textStyle, classes.btnStyle)}
                            >
                                {quizStore.quizHistory.length}
                            </Button>
                            <Popover
                                id="simple-popper"
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                className={classes.popoverBox}
                            >

                                <MenuList>
                                    {quizStore.quizHistory.map(h => <MenuItem key={h.historyId} className={clsx(classes.textStyle, classes.menuText)}
                                                                              onClick={() => history.push(`/rooms/${h.roomId}/statistics`)}>{h.roomName}</MenuItem>)}
                                </MenuList>
                            </Popover>
                        </Box>
                        }
                    </Box>
                    <Box>
                        <QuizAdd/>
                    </Box>

                </Box>

                {/*미리보기*/}
                <Box className={classes.previewBox}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{margin: '60px 0 50px'}}>
                        <Typography className={clsx(classes.textStyle, classes.titleStyle)}><FormattedMessage id="preview_quiz"/></Typography>
                    </Box>
                    <QuizPreview/>

                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(QuizCreateContents));