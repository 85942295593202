import dayjs from "dayjs";
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import minMax from 'dayjs/plugin/minMax'
import weekday from 'dayjs/plugin/weekday'
import duration from 'dayjs/plugin/duration'
import advancedFormat from 'dayjs/plugin/advancedFormat'
// import {DefaultLocale} from "../../stores/LocaleStore";

dayjs.locale('en');
// dayjs.locale(DefaultLocale);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(minMax)
dayjs.extend(weekday)
dayjs.extend(duration)
dayjs.extend(advancedFormat)

class DateUtil {
    constructor() {
        this.timeZone = 'UTC';
        this.language = 'en';
        // this.language = DefaultLocale;
        this.FORMAT = {
            DEFAULT_FORMAT : 'YYYY-MM-DD HH:mm',
            FULL_FORMAT : 'YYYY-MM-DD HH:mm:ss',
            DATE_FORMAT : 'YYYY-MM-DD',
            AMPM_FORMAT : 'YYYY-MM-DD hh:mm A'
        }
    };

    setTimeZone(zone) {
        this.timeZone = zone;
    };

    setLanguage(languageCode) {
        this.language = languageCode;
    };

    getTimeZone() {
        return this.timeZone;
    };

    getLanguage() {
        return this.language;
    };

    getTimeZoneDate(date, format) {
        return dayjs(date).tz(this.timeZone).locale(this.language).format(format);
    };

    getUTCDate(date) {
        return dayjs(date).tz('UTC');
    };

    getTimeZoneLanguage(date) {
        return dayjs(date).locale(this.language);
    };

    convertTimeZoneToUTC(date) {
        return dayjs(date).tz('UTC', true);
    };

    getWeek(date) {
        const selectedDay = date ? dayjs(date).format("YYYY-MM-DD") : dayjs().tz(this.timeZone).format('YYYY-MM-DD');
        const timeZoneDayDiff = dayjs().diff(dayjs(dayjs().tz(this.timeZone).format('YYYY-MM-DD')), 'day');

        const weeks = 6;
        const currentWeekDay = date ? dayjs(selectedDay).weekday() : dayjs(selectedDay).weekday() + timeZoneDayDiff;
        const startWeekDayDiff = currentWeekDay;
        const endWeekDayDiff = weeks - currentWeekDay;

        const weekOfStartDay = dayjs(date).subtract(startWeekDayDiff, 'day').format("YYYY-MM-DD");
        const weekOfEndDay = dayjs(date).add(endWeekDayDiff, 'day').format("YYYY-MM-DD");

        return {selectedDay: selectedDay, weekOfStartDay: weekOfStartDay, weekOfEndDay: weekOfEndDay}
    };

    getLocalTimeZone() {
        return dayjs.tz.guess();
    }
}

export const DATE_UTIL = new DateUtil();