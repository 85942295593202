import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Drawer, Box, Typography, IconButton} from "@material-ui/core";
import {ReactComponent as Close} from "../../../common/images/Close.svg";
import {ReactComponent as ClockCounterClockwiseIcon} from "../../../common/images/ClockCounterClockwiseIcon.svg";

const useStyles = makeStyles({
    root:{
    },
    drawerBox:{
        zIndex:'9999 !important',
        "& .MuiBackdrop-root": {
            background:'transparent'
        },
        "& .MuiDrawer-paper":{
            marginTop:65,
            boxShadow:'0 4px 7px 0 rgba(0, 0, 0, 0.25)',
        }
    },
    list: {
        width: 264,
        height:'100%',
        background:'#fff',
    },
    titleBox:{
        background:'#f0f1f1',
        padding:'10px 16px'
    },
    fullList: {
        width: 'auto',
        height:'100%',
        background:'#fff'
    },
    titleText:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.188rem',
        fontWeight:800,
        marginLeft:5
    },
    listBox:{
        paddingTop:30
    },
    listText:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1rem',
        borderBottom:'1px dashed #a3a8af',
        paddingBottom:10,
        marginBottom:10,
        textAlign:'center'
    },
    listText1:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        padding:'0 25px 60px',
        textAlign:'center'
    },
    listText2:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:800,
    }
});


export default function MeetingMinutesChange(){
    const classes = useStyles();
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const handleClose = (event) => {
        setState({open: false,});
    };
    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <Box display='flex' alignItems='center' justifyContent='space-between' className={classes.titleBox}>
                <Typography className={classes.titleText}>변경 내역</Typography>
                <IconButton onClick={handleClose} >
                    <Close style={{width:20,height:20}}/>
                </IconButton>
            </Box>

            <Box className={classes.listBox}>
                <Typography className={classes.listText}>2021년 2월 14일 2:30 PM</Typography>
                <Typography className={classes.listText1}>
                    차형민 님이
                    <span className={classes.listText2}>
                        회의 안건, 회의내용,
                        확정내용, 다음 회의 일정
                    </span>
                    을수정하였습니다.
                </Typography>

                <Typography className={classes.listText}>2021년 2월 14일 2:30 PM</Typography>
                <Typography className={classes.listText1}>
                    차형민 님이
                    <span className={classes.listText2}>
                        회의 안건, 회의내용,
                        확정내용, 다음 회의 일정
                    </span>
                    을수정하였습니다.
                </Typography>


            </Box>

        </div>
    );

    return (
        <div className={classes.root}>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Box className={classes.avatarStyle1}>
                        <IconButton onClick={toggleDrawer(anchor, true)}>
                            <ClockCounterClockwiseIcon/>
                        </IconButton>
                    </Box>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} className={classes.drawerBox}>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}