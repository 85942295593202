export function validateEmail(value) {
    // const email = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,4}$/i;
    const email =/^[a-zA-Z0-9]{4,20}$/i;
    return email.test(String(value));
}

export function validateName(name) {
    const regExp = /^(^[ㄱ-ㅎ가-힣a-zA-Z])(([ㄱ-ㅎ가-힣|a-zA-Z|0-9|-]{0,14})([ㄱ-ㅎ가-힣a-zA-Z0-9])){0,1}$/;
    return regExp.test(String(name));
// ^(^[ㄱ-ㅎ가-힣a-zA-Z0-9])([ㄱ-ㅎ가-힣a-zA-Z0-9\-\|\*]{0,})([-]{0,})$
}

export function validateNickName(name) {
    const regExp = /^guest[0-9]{0,}$/gi;
    return regExp.test(String(name));
// ^(^[ㄱ-ㅎ가-힣a-zA-Z0-9])([ㄱ-ㅎ가-힣a-zA-Z0-9\-\|\*]{0,})([-]{0,})$
}

export function validatePassword(pw) {
    const spe = /[~!@#$%^&*=_+()-]/;
    const eng = /[a-z|A-Z]/;
    const num = /[0-9]/;
    const checkPw = /[0-9|a-z|A-Z|~!@#$%^&*=_+()-]/;

    const result = (((eng.test(pw) && num.test(pw))
                    || (eng.test(pw) && spe.test(pw))
                    || (num.test(pw) && spe.test(pw)))
                    && (!checkPw.test(pw)));

    return result;
}

export function validateTitle(title) {
    const regExp = /^[ㄱ-ㅎ가-힣|a-zA-Z]*$/;
    return regExp.test(String(title));
}

export function validateKor(value) {
    const kor = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
    return kor.test(String(value));
}

export function validateNumber(value) {
    const regExp = /[a-z|A-Z|ㄱ-ㅎ|ㅏ-ㅣ|가-힣|~!?@#$%^&*=_+()-.,{\}\[\]`\|\\\'\;\:\>\<\/\"]/g;
    return regExp.test(String(value));
}