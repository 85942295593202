import React from "react";
import Dialog from "@material-ui/core/Dialog/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import Close from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import {inject, observer} from "mobx-react";
import {Button} from "@material-ui/core";

const style = theme => ({
    modalRoot: {
        overflow: "auto",
        alignItems: "unset",
        justifyContent: "unset"
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalHeader: {
        borderBottom: "none",
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "0",
        paddingLeft: "24px",
        minHeight: "16.43px"
    },
    imagePopup: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '900px'
        },
        backgroundColor: 'rgba( 255, 255, 255, 0 )',
            boxShadow: 'none',
            border: 'none',
            marginTop: '100px !important',
            textAlign: 'center'
    },
    imagePopupSize: {
        width: '100%'
    },
});


@inject('statisticsStore')
@observer
class ImagePopupModal extends React.Component {
    render() {
        const { classes, statisticsStore } = this.props;
        return (
            <Dialog
                classes={{root: classes.modalRoot, paper: classes.imagePopup}}
                open={statisticsStore.isShowImagePopupModal}
                onClose={() => statisticsStore.onCloseImagePopupModal()}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button key="close" aria-label="Close" onClick={() => statisticsStore.onCloseImagePopupModal()}>
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                <DialogContent>
                    {statisticsStore.popupTargetImage.data &&
                        <img className={classes.imagePopupSize}
                             src={'data:' + statisticsStore.popupTargetImage.type + ';base64,' + statisticsStore.popupTargetImage.data}
                             title={statisticsStore.popupTargetImage.name}
                             alt={statisticsStore.popupTargetImage.name}/>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(style)(ImagePopupModal);
