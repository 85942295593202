import {makeAutoObservable} from "mobx";
import axios from "axios";

export const _ = require('lodash');

export const NOTICE_STATE = {
    READY : 'READY',
    PENDING : 'PENDING',
    SUCCESS : 'SUCCESS',
    FAILED : 'FAILED',
}

export const ROOM_TYPE = {
    Education : 'education',
    Conference : 'conference',
    Seminar : 'seminar',
}

export const ToolTip_Close_Time = 5000;
export const Request_Notice_IntervalTime = 30000; //30초

const URL = '/api/v1/notices';

const logPrefix = ' [ NoticeStore ] ';

const CancelToken = axios.CancelToken;

export default class NoticeStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    roomNoticeList = [];
    isRoomNoticeLoading = false;

    requestState = NOTICE_STATE.READY;
    requestUpdateState = NOTICE_STATE.READY;

    /** 알림 공지 리스트 비교 **/
    compareNoticeList = (prev, next) => {
        if(!next) {
            return false;
        }
        if(prev.length !== next.length) {
            return false;
        }

        for(let i=0, l=prev.length; i<l; i++) {
            for(const prevProp in prev[i]) {
                if(prev[i][prevProp] !== next[i][prevProp]) {
                    return false;
                }
                else if(typeof prev[i][prevProp] != typeof next[i][prevProp]) {
                    return false;
                }
            };
        };
        return true;
    }

    get getIsRoomNoticeLoading() {
        return this.isRoomNoticeLoading;
    }

    requestGetRoomNotice = function*(userId) {

        // console.log(logPrefix , "Starting requestGetRoomNotice");

        const checkedFirst = (prev, next) => {
            if(!next) {
                return true;
            }
            if(prev.length !== next.length) {
                return false;
            }
        }

        this.isRoomNoticeLoading = true;
        try {
            if(this.cancelTokenSource) {
                // console.log("axios source cancel")
                this.cancelTokenSource.cancel();
            } else {
                // console.log("create axios source")
                this.cancelTokenSource = CancelToken.source();
            }
            const response = yield axios.get(this.serverContextPath +`${URL}/${userId}/rooms`, {
                cancelToken : this.cancelTokenSource.token,
            });

            const compareResult = _.isEqualWith(this.roomNoticeList, response.data, checkedFirst);
            if (compareResult) {
                // console.log(logPrefix, "Notice list equal")
                return;
            } else {
                // console.log(logPrefix, "notice list not equal")
                this.roomNoticeList = response.data;
            }

        } catch(e) {

            this.isRoomNoticeLoading = false;
            if (e.response) {
                console.log(logPrefix ,'Failed RequestGetRoomNotice', e.response.data);
                console.log(" >> ", e.response.status);
                console.log(" >> ", e.response.headers);
            } else if (e.request) {
                console.log(logPrefix ,'Failed RequestGetRoomNotice', e.request);
            } else {
                console.log(logPrefix ,'Failed RequestGetRoomNotice', e.message);
            }

        } finally {
            this.isRoomNoticeLoading = false;
            if (this.cancelTokenSource) {
                this.cancelTokenSource = undefined;
            }
        }

    }

    requestUpdateRoomNotifyState = function*(userId, roomIdList) {

        // console.log(logPrefix, "Starting requestUpdateRoomNotifyState");

        this.isRoomNoticeLoading = true;
        try {
            const response = yield axios.post(this.serverContextPath + `${URL}/${userId}/rooms`, roomIdList);

            this.roomNoticeList = response.data;
            this.isRoomNoticeLoading = false;

        } catch(e) {

            this.isRoomNoticeLoading = false;
            if (e.response) {
                console.log(logPrefix ,'Failed requestUpdateRoomNotifyState', e.response.data);
                console.log(" >> ", e.response.status);
                console.log(" >> ", e.response.headers);
            } else if (e.request) {
                console.log(logPrefix ,'Failed requestUpdateRoomNotifyState', e.request);
            } else {
                console.log(logPrefix ,'Failed requestUpdateRoomNotifyState', e.message);
            }

        } finally {
            this.isRoomNoticeLoading = false;
        }
    }.bind(this);

}