import {makeAutoObservable} from "mobx";
import axios from "axios";
import fileDownload from "js-file-download";

export const AuthorityType = {
    CreateRoom: 'CreateRoom',
    CreateNotice: 'CreateNotice',
    UploadFile: 'UploadFile'
};

export const UploadState = {
    Downloaded: 'Downloaded',
    Pending: 'Pending',
    Progress: 'Progress',
    Failed: 'Failed',
    Canceled: 'Canceled',
};

export default class UserLoungeStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    };

    cancelTokenSource = null;
    isTeamListLoading = false;
    isSimpleTeamListLoading = false;
    isTeamLoading = false;
    isTeamNoticeLoading = false;
    isTeamFileLoading = false;
    selectedTeam = {};
    selectedTeamId = 0;
    userType = '';
    authorityList = [];
    teamList = [];
    simpleTeamList = [];
    teamNoticePage = 1;
    teamNoticeList = [];
    selectedNotice = {};
    isSelectedNoticeModify = false;
    noticeTitle = '';
    noticeContent = '';
    teamFileList = [];
    teamFilePage = 1;
    uploadFileList = [];
    searchKeyword = '';

    init = () => {
        this.isTeamListLoading = false;
        this.isSimpleTeamListLoading = false;
        this.isTeamLoading = false;
        this.isTeamNoticeLoading = false;
        this.isTeamFileLoading = false;
        this.selectedTeam = {};
        this.userType = '';
        this.authorityList = [];
        this.teamList = [];
        this.teamNoticePage = 1;
        this.teamNoticeList = [];
        this.selectedNotice = {};
        this.isSelectedNoticeModify = false;
        this.noticeTitle = '';
        this.noticeContent = '';
        this.teamFileList = [];
        this.teamFilePage = 1;
        this.uploadFileList = [];
        this.searchKeyword = '';
    };

    resetData = () => {
        this.teamNoticePage = 1;
        this.teamNoticeList = [];
        this.selectedNotice = {};
        this.isSelectedNoticeModify = false;
        this.noticeTitle = '';
        this.noticeContent = '';
        this.teamFileList = [];
        this.teamFilePage = 1;
        this.uploadFileList = [];
    };

    get getIsLoading() {
        return this.isTeamListLoading || this.isSimpleTeamListLoading || this.isTeamLoading || this.isTeamNoticeLoading || this.isTeamFileLoading;
    };

    setCancelTokenSource = cancelTokenSource => this.cancelTokenSource = cancelTokenSource;

    requestCancel() {
        if(this.cancelTokenSource !== null) {
            this.cancelTokenSource.cancel();
            this.setCancelTokenSource(null);
        }
    };

    resetPage = () => {
        this.teamNoticePage = 1;
        this.teamFilePage = 1;
    };

    changeSimpleTeamList = (teamList) => {
        this.simpleTeamList = teamList;
    }

    changeSelectedTeamId = (teamId) => {
        this.selectedTeamId = teamId;
    };

    changeSearchKeyword = (keyword) => {
        this.searchKeyword = keyword;
    };

    changeSelectedTeam = (userId, teamId, timelineStore, history) => {
        this.init();
        timelineStore.init();

        this.requestCancel();
        this.setCancelTokenSource(axios.CancelToken.source());

        if (teamId !== 0) {
            this.getTeam(userId, teamId, history);
            this.getTeamNoticeList(teamId, 1);
            this.getTeamFileList(teamId, 1);
        // } else {
            this.getTeamList(userId);
        }
        timelineStore.getRoomListGroupByStartTime(this.selectedTeamId, userId, 'now', 1);
    };

    changeSelectedNotice = (selectedNotice) => {
        this.selectedNotice = selectedNotice
        this.isSelectedNoticeModify = false;
        this.noticeTitle = selectedNotice.title;
        this.noticeContent = selectedNotice.content;
    };

    changeIsSelectedNoticeModify = (isSelectedNoticeModify) => {
        this.noticeTitle = this.selectedNotice.title;
        this.noticeContent = this.selectedNotice.content;
        this.isSelectedNoticeModify = isSelectedNoticeModify;
    };

    changeNoticeTitle = (noticeTitle) => {
        this.noticeTitle = noticeTitle;
    };

    changeNoticeContent = (noticeContent) => {
        this.noticeContent = noticeContent;
    };

    resetNotice = () => {
        this.noticeTitle = '';
        this.noticeContent = '';
    };

    changeAuthorityList = (authorityList) => {
        this.authorityList = authorityList;
    };

    getTeamList = function* getTeamList(userId) {
        // console.log("getTeamList Start userId:", userId);
        this.teamList = [];
        try {
            this.isTeamListLoading = true;

            const response = yield axios({
              method : 'get',
              url : this.serverContextPath + `/api/v1/teams`,
              params : {
                  user_id : userId,
                  type : "detail",
              },
              cancelToken: this.cancelTokenSource.token
            });

            this.teamList = response.data.filter(t => t.type === "Team" && t.enabled);

            // console.log("getTeamList Done");
        } catch(e) {
            console.log("getTeamList Fail : >> ", e);
        } finally {
            this.isTeamListLoading = false;
            this.teamList.forEach(team => {
                this.getTeamMemberAvatarList(team);
            })
        }
    };

    getSimpleTeamList = function* getSimpleTeamList(userId) {
        // console.log("getSimpleTeamList Start userId:", userId);
        this.simpleTeamList = [];
        try {
            this.isSimpleTeamListLoading = true;

            const response = yield axios({
                method : 'get',
                url : this.serverContextPath + `/api/v1/teams`,
                params : {
                    user_id : userId,
                    type : "simple",
                }
            });

            this.simpleTeamList = response.data;
            if(this.simpleTeamList[0].id) {
                this.changeSelectedTeamId(this.simpleTeamList[0].id)
            }

            // console.log("getSimpleTeamList Done");
        } catch(e) {
            console.log("getSimpleTeamList Fail : >> ", e);
        } finally {
            this.isSimpleTeamListLoading = false;
            this.simpleTeamList.forEach(team => {
                this.getTeamAvatar(team);
            });
        }
    };

    getTeam = function* getTeam(userId, teamId, history) {
        // console.log("getTeam Start teamId:", teamId);
        try {
            this.isTeamLoading = true;

            const response = yield axios.get(this.serverContextPath + `/api/v1/teams/${teamId}?userId=${userId}`, {cancelToken: this.cancelTokenSource.token});

            this.selectedTeam = response.data;
            this.authorityList = this.selectedTeam.members.find(m => m.userId === userId).authorities;
            this.userType = this.selectedTeam.members.find(m => m.userId === userId).type;

            // console.log("getTeam Done");
        } catch(e) {
            if (e.response && e.response.data && e.response.data.code) {
                if (e.response.data.code === 'CanNotFoundTeam') {
                    console.log("getTeam Fail : >> ", "Cannot access team");
                    history.push("/mytimeline");
                }
            } else {
                console.log("getTeam Fail : >> ", e);
            }
        } finally {
            this.isTeamLoading = false;
            if (this.selectedTeam.id) {
                this.getTeamAvatar(this.selectedTeam);
                this.getTeamMemberAvatarList(this.selectedTeam);
            }
        }
    };

    getTeamAvatar = function* getTeamAvatar(team) {
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/teams/${team.id}/avatar`);
            team.image = response.data;
        } catch(e) {
            console.log("getTeamAvatar Fail : >> ", e);
        }
    };

    getTeamMemberAvatarList = function* getTeamMemberAvatarList(team) {
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/teams/${team.id}/members/avatars`);

            const newMembers = team.members.map(m => ({...m}));
            for(let i = 0; i < newMembers.length; i++) {
                for (let j = 0; j < response.data.length; j++) {
                    if(newMembers[i].userId === response.data[j].userId) {
                        newMembers[i].image = response.data[j].image;
                        break;
                    }
                }
            }
            team.members = newMembers;
        } catch(e) {
            console.log("getTeamMemberAvatarList Fail : >> ", e);
        }
    };

    addTeamNotice = function* addTeamNotice(userId) {
        // console.log("addTeamNotice Start");
        try {
            this.isTeamNoticeLoading = true;
            const param = {
                teamId : this.selectedTeamId,
                userId : userId,
                title: this.noticeTitle,
                content: this.noticeContent
            };

            yield axios.post(this.serverContextPath + `/api/v1/teams/${this.selectedTeamId}/notices`, param);
            this.getTeamNoticeList(this.selectedTeamId, 1);

            // console.log("addTeamNotice Done");
        } catch(e) {
            console.log("addTeamNotice Fail : >> ", e);
        } finally {
            this.resetNotice();
            this.isTeamNoticeLoading = false;
        }
    };

    modifyTeamNotice = function* modifyTeamNotice() {
        // console.log("modifyTeamNotice Start noticeId:", this.selectedNotice.noticeId);
        try {
            this.isTeamNoticeLoading = true;
            const param = {
                teamId : this.selectedNotice.teamId,
                noticeId : this.selectedNotice.noticeId,
                title: this.noticeTitle,
                content: this.noticeContent
            };

            yield axios.put(this.serverContextPath + `/api/v1/teams/${this.selectedNotice.teamId}/notices/${this.selectedNotice.noticeId}`, param);
            this.getTeamNoticeList(this.selectedTeamId, 1);

            // console.log("modifyTeamNotice Done");
        } catch(e) {
            console.log("modifyTeamNotice Fail : >> ", e);
        } finally {
            this.resetNotice();
            this.isTeamNoticeLoading = false;
        }
    };


    removeTeamNotice = function* removeTeamNotice() {
        // console.log("removeTeamNotice Start noticeId:", this.selectedNotice.noticeId);
        try {
            this.isTeamNoticeLoading = true;

            yield axios.delete(this.serverContextPath + `/api/v1/teams/${this.selectedNotice.teamId}/notices/${this.selectedNotice.noticeId}`);
            this.getTeamNoticeList(this.selectedTeamId, 1);

            // console.log("removeTeamNotice Done");
        } catch(e) {
            console.log("removeTeamNotice Fail : >> ", e);
        } finally {
            this.isTeamNoticeLoading = false;
        }
    };

    getTeamNoticeList = function* getTeamNoticeList(teamId, page) {
        // console.log("getTeamNoticeList Start teamId:", teamId);
        try {
            this.isTeamNoticeLoading = true;
            let url = this.serverContextPath + `/api/v1/teams/${teamId}/notices?`;
            if(page) {
                url = url + `page=${page}`;
            } else {
                this.teamNoticePage = this.teamNoticePage + 1;
                url = url + `page=${this.teamNoticePage}`;
            }
            if (this.searchKeyword !== '') {
                url = url + `&search-keyword=${this.searchKeyword}`;
            }
            const response = yield axios.get(url, {cancelToken: this.cancelTokenSource.token});
            if (page) {
                this.teamNoticeList = response.data;
            } else {
                response.data.forEach(n => this.teamNoticeList.push(n))
            }

            this.teamNoticeList.sort((a, b) => b.updatedDatetime < a.updatedDatetime ? -1 : b.updatedDatetime > a.updatedDatetime ? 1 : 0);

            // console.log("getTeamNoticeList Done");
        } catch(e) {
            console.log("getTeamNoticeList Fail : >> ", e);
        } finally {
            this.isTeamNoticeLoading = false;
        }
    };

    addTeamFile = function* addTeamFile(userId, uploadFile) {
        console.log("addTeamFile Insert list fileName:", uploadFile.file.name.normalize());
        if (uploadFile) {
            if (this.uploadFileList.find(f => f.state === UploadState.Progress)) {
                this.uploadFileList.push(uploadFile);
                return;
            } else {
                uploadFile.state = UploadState.Progress;
                this.uploadFileList.push(uploadFile);
            }
        }

        const targetFile = this.uploadFileList.find(f => f.state === UploadState.Progress);

        if (targetFile) {
            try {
                console.log("addTeamFile Start fileName:", targetFile.file.name.normalize());
                const param = new FormData();
                const fileInfo = new Blob([JSON.stringify({
                    teamId: targetFile.teamId,
                    accessType: 'DB',
                    title: targetFile.file.name.normalize(),
                    name: targetFile.file.name.normalize(),
                    type: targetFile.file.type,
                    size: targetFile.file.size,
                    userId: userId
                })], {
                    type: 'application/json;charset=utf-8'
                });

                param.append('fileInfo', fileInfo);
                param.append('file', targetFile.file);

                const response = yield axios.post(this.serverContextPath + `/api/v1/teams/${targetFile.teamId}/files`, param);
                if (targetFile.state === UploadState.Canceled) {
                    this.removeTeamFile(targetFile.teamId, response.data);
                } else {
                    targetFile.state = UploadState.Downloaded;
                    this.getTeamFileList(this.selectedTeamId, 1)
                }

                console.log("addTeamFile Done");
            } catch(e) {
                console.log("addTeamFile Fail : >> ", e);
                targetFile.state = UploadState.Failed;
            } finally {
                const pendingFile = this.uploadFileList.find(f => f.state === UploadState.Pending);
                if (pendingFile) {
                    pendingFile.state = UploadState.Progress;
                    this.addTeamFile(userId);
                } else {
                    this.uploadFileList = [];
                }
            }
        }
    };

    removeTeamFile = function* removeTeamFile(teamId, fileId) {
        try {
            // console.log("removeTeamFile Start fileId:", fileId);
            yield axios.delete(this.serverContextPath + `/api/v1/teams/${teamId}/files/${fileId}`);
            this.getTeamFileList(this.selectedTeamId, 1)

            // console.log("removeTeamFile Done");
        } catch(e) {
            console.log("removeTeamFile Fail : >> ", e);
        }
    };

    cancelAddTeamFile = (userId, fileInfo) => {
        if (fileInfo.state !== UploadState.Progress) {
            const idx = this.uploadFileList.findIndex(f => f === fileInfo);
            if (idx > -1) {
                this.uploadFileList.splice(idx, 1);
                console.log("cancelAddTeamFile remove list fileName:", fileInfo.file.name.normalize());
            }
        } else {
            console.log("cancelAddTeamFile target file is already in progress. change state. fileName:", fileInfo.file.name.normalize());
            fileInfo.state = UploadState.Canceled;
            if (this.uploadFileList.find(f => f.state === UploadState.Pending)) {
                this.addTeamFile(userId);
            } else {
                this.uploadFileList = [];
            }
        }
    }

    downloadTeamFile = function * downloadTeamFile(teamId, fileId, fileName) {
        try {
            // console.log("downloadTeamFile Start fileName:", fileName);
            const response = yield axios.get(`/api/v1/teams/${teamId}/files/${fileId}/download`, {responseType: 'blob'});
            const file = response.data;

            if(window.flutter_inappwebview !== undefined) {
                window.flutter_inappwebview.callHandler("downloadFile", `/api/v1/teams/${teamId}/files/${fileId}/download`, fileName);
            }

            fileDownload(file, fileName);

            // console.log("downloadTeamFile Done");
        } catch (e) {
            console.log("downloadTeamFile Fail : >> ", e);
        }
    };

    getTeamFileList = function* getTeamFileList(teamId, page) {
        try {
            // console.log("getTeamFileList Start teamId:", teamId);
            this.isTeamFileLoading = true;
            let url = this.serverContextPath + `/api/v1/teams/${teamId}/files?`;
            if(page) {
                url = url + `page=${page}`;
            } else {
                this.teamFilePage = this.teamFilePage + 1;
                url = url + `page=${this.teamFilePage}`;
            }
            if (this.searchKeyword !== '') {
                url = url + `&search-keyword=${this.searchKeyword}`;
            }

            const response = yield axios.get(url, {cancelToken: this.cancelTokenSource.token});
            if (page) {
                this.teamFileList = response.data;
            } else {
                response.data.forEach(f => this.teamFileList.push(f))
            }
            this.teamFileList.sort((a, b) => b.updatedDatetime < a.updatedDatetime ? -1 : b.updatedDatetime > a.updatedDatetime ? 1 : 0);

            // console.log("getTeamFileList Done");
        } catch(e) {
            console.log("getTeamFileList Fail : >> ", e);
        } finally {
            this.isTeamFileLoading = false;
        }
    };
}