import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    FormControlLabel,
    Checkbox,
    Typography, IconButton
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {ReactComponent as WarningIcon} from "../../common/images/WarningIcon.svg"
import {ReactComponent as Close} from "../../common/images/Close.svg"
import {inject, observer} from "mobx-react";
import {State} from "../../stores/AuthStore";
import PasswordChangeDialog from "./PasswordChangeDialog";
import {FormattedMessage, injectIntl} from "react-intl";
import {COLOR_UTIL} from "../../common/util/color.util";

const style = theme => ({
    root: {},
    loginBtn: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.6rem',
        fontWeight: 800,
        color: '#0d0d0d',
    },
    loginBox: {
        "& .MuiDialog-paper": {
            borderRadius: 30,
            padding: '15px 30px'
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialogTitle-root": {
            padding: '0 24px'
        }
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '2.1rem',
        fontWeight: 300,
        color: '#0d0d0d',
        textAlign: 'center',
        marginBottom: 20
    },
    textStyle: {
        width: 330,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        fontWeight: 300,
        padding: '13px 12px',
        borderRadius: 6,
        background: '#fff',
        marginBottom: 13,
        "&::placeholder": {
            color: '#a3a8af',
            fontSize: '1.4rem',
        },
        "&:focus": {
            outline: 'none !important'
        }
    },
    buttonStyle: {
        width: '100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.6rem',
        // color: '#fff',
        fontWeight: 800,
        borderRadius: 6,
        marginTop: 60,
        background: () =>
            theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor)
            return border;
        },
        padding: '10px 0',
        "&:hover": {
            background: () =>
                theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor,
        }
    },
    checkboxText: {
        paddingLeft: 10,
        "& .MuiTypography-root": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.2rem',
            color: '#0d0d0d',
            fontWeight: 300
        },
        "& .MuiIconButton-root": {
            padding: 5
        }
    },
    pwFindText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        textDecoration: 'none'
    },
    errorBox: {
        margin: '0 0 5px 0',
    },
    errorText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        color: '#ff0000',
        fontWeight: 600,
    }
});

@inject('authStore', 'orgStore')
@observer
class TabletSignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userNick: '',
            rememberNickState: '',
        }
    }

    handleClickOpen = () => {
        this.props.authStore.setLoginModal();
    };
    handleClickClose = () => {
        this.alertReset();
        this.props.authStore.changeLoginModal();
    }
    handleKeyPressLogin = (e) => {
        e.preventDefault();
        this.alertReset();
        if (e.keyCode === 13) this.handleLogin();
    }
    alertReset = () => {
        this.props.authStore.loginState = State.Pending;
    }
    handleLogin = () => {
        const {intl} = this.props;
        this.props.authStore.doLogin(intl);
    }

    render() {
        const { classes, authStore, orgStore, intl } = this.props;

        return (
            <div className={classes.root}>
                <Button onClick={this.handleClickOpen} className={classes.loginBtn}>
                    <FormattedMessage id={"sign_in"} />
                </Button>
                <Dialog maxWidth='md' open={authStore.loginModal} onClose={this.handleClickOpen}
                        aria-labelledby="form-dialog-title" className={classes.loginBox}
                        onKeyUp={(e) => this.handleKeyPressLogin(e)}>
                    <Box display='flex' justifyContent='flex-end'>
                        <IconButton onClick={this.handleClickClose} style={{padding: 5}}>
                            <Close style={{width: 21, height: 21}}/>
                        </IconButton>
                    </Box>
                    <DialogTitle id="form-dialog-title" className={classes.titleBox}>
                        {
                            orgStore.orgImages.Logo
                                ? <img
                                    src={orgStore.orgImages.Logo}
                                    alt="LogoImage"
                                    style={{width: 'auto', height: 'auto', maxWidth: 174, maxHeight: 40}}
                                />
                                : <Box><Typography>Organization Logo</Typography></Box>

                        }
                        <Typography className={classes.titleText}>
                            <FormattedMessage id={"sign_in"} />
                        </Typography>
                    </DialogTitle>
                    <DialogContent style={{display: 'flex', flexDirection: 'column'}}>
                        <input
                            name="id"
                            type="email"
                            id="id"
                            autoFocus={true}
                            placeholder={intl.formatMessage({id : "msg.insert_email"})}
                            className={classes.textStyle}
                            style={authStore.loginState !== State.Failed ? {border: '1px solid #c0c2c3'} : {border: '1px solid red'}}
                            autoComplete={"off"}
                            value={authStore.login.email}
                            onChange={(event) => authStore.changeLoginEmail(event.target.value)}
                        />
                        <input
                            name="password"
                            type="password"
                            id="password"
                            placeholder={intl.formatMessage({id : "msg.validation_password"})}
                            style={authStore.loginState !== State.Failed ? {
                                border: '1px solid #c0c2c3',
                                marginBottom: 5
                            } : {border: '1px solid red', marginBottom: 5}}
                            className={classes.textStyle}
                            autoComplete={"off"}
                            onChange={(event) => authStore.changeLoginPassword(event.target.value)}
                        />

                        {/* 사용자 정보가 없을경우 */}
                        {authStore.loginState === State.Failed &&
                        <Box display="flex" alignItems="center" className={classes.errorBox}>
                            <WarningIcon style={{width: 13, height: 13}}/>
                            <Typography className={classes.errorText}>{authStore.errorMsg}</Typography>
                        </Box>
                        }

                        <Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon style={{fontSize: '1.2rem'}}/>}
                                        checkedIcon={<CheckBoxIcon style={{fontSize: '1.2rem'}}/>}
                                        name="checkedId"
                                        color="primary"
                                        checked={authStore.isRememberNickState}
                                    />
                                }
                                label={intl.formatMessage({id : "save_email"})}
                                className={classes.checkboxText}
                                onChange={() => authStore.changeRememberNickState()}
                            />
                        </Box>
                        <Button
                            disabled={authStore.login.email === '' && authStore.login.password === ''}
                            className={classes.buttonStyle}
                            onClick={this.handleLogin}
                        >
                            <FormattedMessage id={"start"} />
                        </Button>
                    </DialogContent>
                </Dialog>
                {authStore.loginState === State.Progress &&
                <PasswordChangeDialog/>
                }
            </div>
        );
    }
};

export default withStyles(style)(injectIntl(TabletSignIn));
