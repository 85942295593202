import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {
    Avatar,
    Box,
    Typography,
    Fab
} from "@material-ui/core";
import MobileTeamRoomNotice from "./containers/MobileTeamRoomNotice";
import MobileFileSharing from "./containers/MobileFileSharing";
import MobileTimeLineTab from "../MyTimeline/containers/MobileTimeLineTab";
import AddIcon from '@material-ui/icons/Add';
import {inject, observer} from "mobx-react";
import {UTIL} from "../../common/util/common.util";
import {AuthorityType} from "../../stores/UserLoungeStore";
import {PATH_UTIL} from "../../common/util/path.util";
import {COLOR_UTIL} from "../../common/util/color.util";

const style = theme => ({
    root:{
        width:'100%',
        padding:'90px 0 0',
    },
    avatarStyle:{
        fontFamily: 'Montserrat',
        fontSize:'2.5rem',
        fontWeight:800,
        width:65,
        height:65,
        background:'#0097ff'
    },
    userText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1.25rem',
        color:'#0d0d0d',
        fontWeight:800,
        marginTop:8
    },
    createRoomBtn:{
        background: () => theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor,
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        // color:'#fff',
        boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.45)',
        "&:hover":{
            background:'#0097ff',
            boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.45)',
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
});

@inject('authStore', 'avatarStore', 'userStore', 'userLoungeStore', 'topBarStore')
@observer
class MobileTeamRoom extends Component {
    componentDidMount() {
        const {topBarStore } = this.props;
        if (PATH_UTIL.getPath(this.props.location.pathname) === 'teams') {
            topBarStore.setIsSearchBarRender(true);
        }
    };

    render() {
        const { classes, authStore, avatarStore, userLoungeStore } = this.props;
        const { userInfo } = this.props.userStore;

        const team = userLoungeStore.selectedTeam;
        const member = team.id !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');
        const isCreateRoom = (member !== undefined) && (member.authorities.find(a => a.authority === AuthorityType.CreateRoom));

        return (
            <div className={classes.root}>
                <Box style={{padding:'0 20px'}}>
                    <Box display='flex' flexDirection="column" alignItems='center'>
                        {
                            avatarStore.loadAvatarImage ?
                                <Avatar className={classes.avatarStyle}>
                                    <img id="userAvatar" style={{width: '100%', height: '100%'}} src={avatarStore.loadAvatarImage} alt={userInfo.name}/>
                                </Avatar>
                                :
                                <Avatar className={classes.avatarStyle} title={"Home"}>
                                    {UTIL.getFirstCapitalLetter(userInfo.name)}
                                </Avatar>
                        }
                        <Typography className={classes.userText}>{userInfo.name}</Typography>
                    </Box>
                    <MobileTeamRoomNotice />
                    <MobileFileSharing/>
                </Box>

                <MobileTimeLineTab history={this.props.history}/>
                {(isLeader || isCreateRoom) &&
                    <Box style={{position:'fixed', right:20, bottom:15, zIndex:100}}>
                        <Link to="/createroom" className={classes.link}>
                            <Fab size="medium"  aria-label="add" className={classes.createRoomBtn}>
                                <AddIcon />
                            </Fab>
                        </Link>
                    </Box>
                }
            </div>
        );
    }
}

export default withRouter(withStyles(style)(MobileTeamRoom));