import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Paper,
    Typography,
    Box,
    IconButton, Dialog, Slide, LinearProgress, Button, Popover, MenuList, MenuItem
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as MobileTeamArrow} from "../../../../common/images/MobileTeamArrow.svg";
import {ReactComponent as MobileClose} from "../../../../common/images/MobileClose.svg";
import MobileSurveyCreate from "./MobileSurveyCreate";
import MobileQuizCreate from "../quiz/MobileQuizCreate";
import {DATE_UTIL} from "../../../../common/util/date.util";
import MobileConfirmDialog from "../../../../common/MobileConfirmDialog";
import MobileDeleteDialog from "../../../../common/MobileDeleteDialog";
import MobileSurveyMoreButton from "./MobileSurveyMoreButton";
import {ReactComponent as NotePencilIcon} from "../../../../common/images/NotePencil.svg";
import {ReactComponent as Quiz} from "../../../../common/images/Quiz.svg";


const style = () => ({
    root: {
        flexGrow: 1,
    },
    buttonBox: {
        width:'100%',
        border:'1px solid #c0c2c3',
        padding:'10px 11px',
        borderRadius:5,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:20
    },
    buttonText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.125rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        fontWeight:600,
        color:'#0d0d0d',
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        marginBottom:20
    },
    titleText:{
        width:'100%',
        textAlign:'center',
        fontWeight:600
    },
    iconBtn:{
        padding:5
    },
    paperInBox:{
        background:'#f5f5f5',
        borderRadius:16,
        marginBottom: 20,
        padding:'30px 20px 10px',
        margin:'20px',
        "& .MuiPopover-paper":{
            borderRadius:7,
            boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        }
    },
    textStyle1:{
        fontWeight:800,
        color:'#000',
        maxWidth: 800
    },
    textStyle2:{
        fontSize:'1.3rem',
        color:'#b6b6b6',
        maxWidth: 800,
        marginTop:3
    },
    textStyle3:{
        fontSize:'1.1rem',
        fontWeight:500,
        color:'#a3a8af',
    },
    popoverBox:{
        "& .MuiPopover-paper":{
            borderRadius:7,
            boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        },
        "& .MuiMenuItem-root":{
            minHeight:0
        }
    },
    menuText:{
        fontSize: '1.2rem',
        color:'#000',
        fontWeight:300,
    },
    circle:{
        width:16,
        height:16,
        borderRadius:50,
        background:'#dbdbdb',
        textAlign:'center',
        fontSize:'0.875rem',
        color:'#666',
        marginLeft:8,
        paddingTop:1,
        boxSizing:'border-box'
    },
    imageText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.063rem',
        fontWeight: 700,
        color: '#fff',
        padding: '2px 4px',
        height: 26,
    },
    imageTextBox: {
        position: 'absolute',
        alignItems: 'center',
        padding: '2px 5px',
        top: '-14px',
        left: 12,
        display: 'flex',
        border: '2px solid #fff',
        borderRadius: 8,
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
))

@inject('surveyStore', 'authStore', 'quizStore')
@observer
class MobileSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            surveyCreateOpen: false,
        };
    }

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClickOpen = () => {
        this.setState({ surveyCreateOpen: true });
    };

    handleClickClose = () => {
        this.setState({ surveyCreateOpen: false });
    };

    surveyDialogOpen = () => {
        this.props.surveyStore.mobileSurveyDialogOpen(this.props.authStore.loginUser);
        this.handleClose();
    };

    quizDialogOpen = () => {
        this.props.quizStore.mobileQuizDialogOpen(this.props.authStore.loginUser);
        this.handleClose();
    };

    handleRemoveSurveyButton = () => {
        const { authStore, intl, surveyStore } = this.props;
        surveyStore.deleteSurvey(intl, authStore.loginUser);
    };

    handleRemoveQuizButton = () => {
        const {authStore, intl, quizStore} = this.props;
        quizStore.deleteQuiz(intl, authStore.loginUser);
    };

    handleItemClick = (item) => {
        const { authStore, surveyStore, quizStore } = this.props;
        if (item.surveyId) {
            surveyStore.mobileSurveyDialogOpen(authStore.loginUser, item.surveyId);
        } else {
            quizStore.mobileQuizDialogOpen(authStore.loginUser, item.quizId);
        }
    };

    render() {
        const { classes, surveyStore, intl, history, quizStore} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        const liveFormList = surveyStore.surveyList.concat(quizStore.quizList);
        const sortedLiveFormList = liveFormList.sort((a, b) => b.updatedDatetime.localeCompare(a.updatedDatetime));
        const { isLiveFormLoading } = surveyStore.isSurveyLoading || quizStore.isQuizLoading;
        return (
            <div className={classes.root}>
                <Box onClick={this.handleClickOpen} className={classes.buttonBox}>
                    <Typography className={classes.buttonText}><FormattedMessage id="live_form"/></Typography>
                    <MobileTeamArrow />
                </Box>
                <Dialog
                    fullScreen
                    open={this.state.surveyCreateOpen}
                    onClose={this.handleClickClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox}>
                        <Box display='flex' alignItems='center'>
                            <IconButton onClick={this.handleClickClose} aria-label="Close" className={classes.iconBtn}>
                                <MobileClose/>
                            </IconButton>
                            <Typography className={clsx(classes.textStyle, classes.titleText)}><FormattedMessage id="live_form"/></Typography>
                        </Box>
                        <MobileSurveyCreate history={history}/>
                        <MobileQuizCreate history={history}/>
                            <Button className={clsx(classes.textStyle, classes.buttonText)} onClick={this.handleClick}>
                                <FormattedMessage id="registration"/>
                            </Button>

                            <Popover
                                id={'simple-popper'}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                                className={classes.popoverBox}
                            >
                                <MenuList>
                                    <MenuItem className={clsx(classes.textStyle, classes.menuText)} onClick={()=> this.surveyDialogOpen()}>
                                        <FormattedMessage id="survey"/>
                                    </MenuItem>
                                    <MenuItem className={clsx(classes.textStyle, classes.menuText)} onClick={()=> this.quizDialogOpen()}>
                                        <FormattedMessage id="quiz"/>
                                    </MenuItem>
                                </MenuList>
                            </Popover>
                    </Box>
                    {isLiveFormLoading && <LinearProgress />}
                    {sortedLiveFormList.length > 0 ?
                        sortedLiveFormList.map((item) => {
                            return (
                                item.surveyId !== undefined ?
                                <Paper key={item.surveyId} elevation={0} className={classes.paperInBox} style={{cursor: 'pointer', position: 'relative'}} onClick={() => {this.handleItemClick(item)}}>
                                    <div className={classes.imageTextBox} style={{background: '#7d86a9'}}>
                                        <NotePencilIcon/>
                                        <Typography className={classes.imageText}><FormattedMessage id="survey"/></Typography>
                                    </div>
                                    <Typography noWrap className={clsx(classes.textStyle, classes.textStyle1)}>{item.title}</Typography>
                                    <Typography noWrap className={clsx(classes.textStyle, classes.textStyle2)}>{item.descriptions}</Typography>
                                    <Box display='flex' justifyContent='space-between' alignItems='center' mt={1}>
                                        <Typography noWrap
                                                    className={clsx(classes.textStyle, classes.textStyle3)}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(item.updatedDatetime), DATE_UTIL.FORMAT.AMPM_FORMAT)}</Typography>
                                        <MobileSurveyMoreButton item={item} changeOpeDeleteDialog={surveyStore.changeOpeDeleteDialog } changeConfirmDialogOpen={surveyStore.changeConfirmDialogOpen}/>
                                    </Box>

                                </Paper>

                                :

                                <Paper key={item.quizId} elevation={0} className={classes.paperInBox} style={{cursor: 'pointer', position: 'relative'}} onClick={() => {this.handleItemClick(item)}}>
                                    <div className={classes.imageTextBox} style={{background: '#7d86a9'}}>
                                        <Quiz/>
                                        <Typography className={classes.imageText}><FormattedMessage id="quiz"/></Typography>
                                    </div>
                                    <Typography noWrap className={clsx(classes.textStyle, classes.textStyle1)}>{item.title}</Typography>
                                    <Box display='flex' justifyContent='space-between' alignItems='center' mt={1}>
                                        <Typography noWrap className={clsx(classes.textStyle, classes.textStyle3)}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(item.updatedDatetime), DATE_UTIL.FORMAT.AMPM_FORMAT)}</Typography>
                                            <MobileSurveyMoreButton item={item} changeOpeDeleteDialog={quizStore.changeOpeDeleteDialog} changeConfirmDialogOpen={surveyStore.changeConfirmDialogOpen}/>
                                    </Box>
                                </Paper>
                            )
                        })
                        :
                        <Paper elevation={0} className={classes.paperInBox} style={{paddingBottom: 50}}>
                            <Typography className={clsx(classes.textStyle, classes.textStyle1)}><FormattedMessage id="msg.no_survey"/></Typography>
                        </Paper>
                    }
                </Dialog>
                <MobileConfirmDialog open={surveyStore.openConfirmDialog}
                                     title={intl.formatMessage({id: "contents"})}
                                     msg={surveyStore.errMsg}
                                     changeDialogOpen={surveyStore.changeConfirmDialogOpen}
                />
                <MobileDeleteDialog open={surveyStore.openDeleteDialog}
                                    title={intl.formatMessage({id: "delete"})}
                                    msg={intl.formatMessage({id: "msg.check_delete_survey"})}
                                    onClose={surveyStore.changeOpeDeleteDialog}
                                    submit={() => this.handleRemoveSurveyButton()}
                />
                <MobileDeleteDialog open={quizStore.openDeleteDialog}
                              title={intl.formatMessage({id: "delete"})}
                              msg={intl.formatMessage({id: "msg.check_delete_survey"})}
                              onClose={quizStore.changeOpeDeleteDialog}
                              submit={() => this.handleRemoveQuizButton()}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(MobileSurvey)));