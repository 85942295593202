import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    // IconButton,
    Button,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import {ReactComponent as MobileClose} from "../common/images/MobileClose.svg"
import {ReactComponent as WarningIcon} from "../../../common/images/WarningIcon.svg";
import {inject, observer} from "mobx-react";
import {State} from "../../../stores/AuthStore";
import { withRouter } from "react-router-dom";
import * as Params from "../../../common/Params";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {FormattedMessage, injectIntl} from "react-intl";
import LogoSamsung from "../../../common/images/logo_samsung.png";
import LogoSamsungSub from "../../../common/images/logo_samsung_sub.png";
import GoogleBnr from "../../../common/images/banner_googleDw.png";

const style = theme => ({
    root:{
        height: '65vh',
        padding:'10px 20px',
        "& .MuiButton-root.Mui-disabled":{
            background:'#bababa',
            color:'#fff'
        }
    },
    logoBox:{
        "& img":{
            width:'60%',
            maxWidth:'220px',
            display: 'block',
            margin:'0 auto',
        },
        "& img:first-child":{
            margin: '2rem auto 2.5rem auto',
        },
    },
    iconBtnBox:{
        display:'flex',
        justifyContent:'flex-end',
        marginBottom:70
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.7rem',
        fontWeight:500,
        color:'#0d0d0d',
        margin:'1rem 0'
    },
    inputBoxWrap:{
        width:'100%',
        "& div input":{
            width:'100%'
        },
        "& > div:first-child input":{
            borderRadius:'5px 5px 0 0',
            borderBottomWidth:'0!important',
        },
        "& > div:last-child input":{
            borderRadius:'0 0 5px 5px',
        }
    },
    textStyle:{
        width:'100%',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:500,
        padding:'14px 9px',
        borderRadius: 5,
        background: '#e8edfa',
        color: '#2d3e6f',
        border:'1px solid #c0c2c3',
        "&::placeholder":{
            color: '#475c98',
            textAlign: 'center',
            fontWeight: 400,
        },
        "&:focus":{
            outline: 'none !important'
        }
    },
    checkboxText:{
        "& .MuiCheckbox-root":{
            paddingRight:3
        },
        "& .MuiCheckbox-colorSecondary.Mui-checked":{
            color:'#0e4da4',
        },
        "& .MuiTypography-root":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1.0rem',
            color:'#475c98',
            fontWeight:300
        },
    },
    buttonStyle:{
        width:'100%',
        padding:10,
        borderRadius:5,
        background:'#5e2eba',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:600,
        color:'#fff',
        marginTop:20,
        "&.Mui-disabled":{
            background:'#5e2eba !important',
            color:'#fff'
        },
        "&:focus, &:hover": {
            background: '#5e2eba'
        }
    },
    errorBox:{
        marginTop:9,
    },
    errorText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.9rem',
        color:'#ff0000',
        fontWeight:600,
        marginLeft:3
    },
    imgBanner:{
        display:'block',
        margin:'2rem auto 1rem',
        width:'50%',
        maxWidth:'240px',
    }
});

@inject('authStore')
@observer
class MobileSignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rememberNickState: '',
        }
    };

    componentDidMount() {
        this.props.authStore.setLoginModal();
    };

    handleLogin = () => {
        const {intl} = this.props;
        const isMobile = Params.isMobileMode(600);
        this.props.authStore.doLogin(intl, isMobile, ()=> {
            if (this.props.authStore.loginState === State.Progress) {
                this.moveTo('/mobilepasswordchange');
            } else {
                this.moveTo('/');
            }
        });
    };

    handleKeyPressLogin = (e) => {
        e.preventDefault();
        this.alertReset();
        if (e.keyCode === 13) this.handleLogin();
    };

    moveTo = (url) => {
        this.props.history.push(url);
    };

    handleBack = () => {
        this.props.history.go(-1);
    };

    alertReset = () => {
        this.props.authStore.loginState = State.Pending;
    };

    render() {
        const { classes, intl, authStore} = this.props;
        const { confirmDialog, changeConfirmDialogOpen } = this.props.authStore;
        return (
            <div className={classes.root}>
                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Box className={classes.logoBox}>
                        <img src={LogoSamsung} alt={''}/>
                        <img src={LogoSamsungSub} alt={''}/>
                    </Box>

                    <Typography className={classes.titleText}>
                        <FormattedMessage id={"sign_in"} />
                    </Typography>
                </Box>
                <Box className={classes.inputBoxWrap}>
                    <Box>
                        <input
                            name="id"
                            type="email"
                            id="id"
                            autoFocus={true}
                            tabIndex={1}
                            placeholder={intl.formatMessage({id: "msg.insert_email"})}
                            value={authStore.login.email}
                            style={authStore.loginState !== State.Failed ? {border:'1px solid #c0c2c3'} : {border: '1px solid #ff000'}}
                            onKeyUp={(e) => this.handleKeyPressLogin(e)}
                            onChange={(event) => {
                                authStore.changeLoginEmail(event.target.value)
                                this.alertReset();
                            }
                            }
                            className={classes.textStyle}
                        />
                    </Box>
                    <Box>
                        <input
                            name="password"
                            type="password"
                            id="password"
                            tabIndex={2}
                            placeholder={intl.formatMessage({id: "msg.validation_password"})}
                            style={authStore.loginState !== State.Failed ? {border:'1px solid #c0c2c3'} : {border: '1px solid #ff000'}}
                            className={classes.textStyle}
                            onKeyUp={(e) => this.handleKeyPressLogin(e)}
                            onChange={(event) => {
                                authStore.changeLoginPassword(event.target.value)
                                this.alertReset();
                            }}
                        />
                    </Box>
                </Box>

                {/* 사용자 정보가 없을경우 */}
                {authStore.loginState === State.Failed &&
                <Box display="flex" alignItems="center" className={classes.errorBox}>
                    <WarningIcon style={{width:11, height:11}}/>
                    <Typography className={classes.errorText}>{authStore.errorMsg}</Typography>
                </Box>
                }
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon style={{fontSize:'1.5rem', color:'#c4c4c4'}}/>}
                                checkedIcon={<CheckBoxIcon style={{fontSize:'1.5rem'}}/>}
                                name="checkedId"
                                tabIndex={3}
                                checked={authStore.isRememberNickState}
                            />
                        }
                        label={intl.formatMessage({id: "save_email"})}
                        className={classes.checkboxText}
                        onChange={() => authStore.changeRememberNickState()}
                    />
                </Box>

                <Button className={classes.buttonStyle} onClick={this.handleLogin} disabled={authStore.login.email === '' && authStore.login.password === ''} tabIndex={4}>
                    <FormattedMessage id={"start"} />
                </Button>
                <ConfirmDialog open={confirmDialog.open}
                               title={confirmDialog.title}
                               msg={confirmDialog.msg}
                               changeDialogOpen={(value)=>changeConfirmDialogOpen(value)}/>
                <img src={GoogleBnr} className={classes.imgBanner} alt={''}/>
            </div>
        );
    }
};

export default withRouter(withStyles(style)(injectIntl(MobileSignIn)));