import React, {Component} from 'react';
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";
import MobileModifyRoom from "./MobileModifyRoom";
import ModifyRoom from "./ModifyRoom";

@inject('roomStore', 'authStore', 'userLoungeStore', 'teamStore', 'userStore', 'progressStore', 'timelineStore', 'topBarStore')
@observer
class ModifyRoomView extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {isMobile, isTablet} = this.props;
        return (
            <React.Fragment>
                {isMobile || isTablet ?  <MobileModifyRoom /> : <ModifyRoom />}
            </React.Fragment>
        );
    }
}

export default withRouter(injectIntl(ModifyRoomView));