import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import TimeLineCalendarShape from "./TimeLineCalendarShape";
import DataTimeLine from "./DataTimeLine";
import {observer} from "mobx-react";
import {COLOR_UTIL} from "../../../common/util/color.util";


const style = theme => ({
    root:{
        marginTop:50,
        paddingLeft:40,
    },
    calendarBox:{
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-start',
        minWidth:340,
        marginBottom:100,
        paddingLeft:40,
        paddingRight:100,
        boxSizing:'border-box',
        '@media all and (min-width:1200px) and (max-width:2010px)':{
            // alignItems:'center',
            minWidth:'100%',
        },
    },
    dataTimeLineBox:{
        width:'calc(100% - 460px)',
        boxSizing:'border-box',
        // '@media all and (min-width:1200px) and (max-width:2200px)':{
        //     paddingLeft:40,
        // },
        '@media all and (min-width:1200px) and (max-width:2010px)':{
            alignItems:'center',
            minWidth:'100%',
            paddingLeft:40,
        },
    },
    btnStyle:{
        padding:'10px 33px',
        background:() => theme.configs.MainColor ? theme.configs.MainColor : '#0097ff',
        color: () => {
            let color = COLOR_UTIL.getSwitchFontColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor);
            return color;
        },
        border: () => {
            let border = COLOR_UTIL.getSwitchBorderColor(theme.configs.MainBtnColor ? theme.configs.MainBtnColor : theme.configs.MainColor)
            return border;
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.125rem',
        fontWeight:600,
        // color:'#fff',
        marginTop:27,
        "&:hover":{
            background:() => theme.configs.MainColor ? theme.configs.MainColor : '#0097ff',
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
});

@observer
class TimeLineCalendar extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item className={classes.calendarBox}>
                        <TimeLineCalendarShape/>
                    </Grid>
                    <Grid item className={classes.dataTimeLineBox}>
                        <DataTimeLine/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(style)(TimeLineCalendar);