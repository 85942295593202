import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Box, Typography} from "@material-ui/core";
import Swiper from 'react-id-swiper';
import 'swiper/swiper-bundle.css';
import MobileTimelineItemOppositeContent from "./MobileTimelineItemOppositeContent";
import MobileTimeLineItemContent from "./MobileTimelineItemContent";
import MobileTimeLineNoSchedule from "./MobileTimeLineNoSchedule";
import {DATE_UTIL} from "../../../common/util/date.util";
import {inject, observer} from "mobx-react";
import * as dayjs from "dayjs";
import {withRouter} from "react-router-dom";

const style = theme => ({
    root:{
        width:'100%',
        "& .swiper-slide":{
            width:275
        },
        "& .swiper-container": {
            marginRight: 0,
            marginLeft: 0,
            width: '100%'
        }
    },
    dateStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:600,
        color:'#0097ff',
        paddingLeft:20,
        marginBottom:10
    },
});

@inject('authStore', 'userLoungeStore', 'timelineStore', 'progressStore')
@observer
class MobileTimeLine extends Component {
    constructor(props){
        super(props);
        this.state = {
            scrollTop: 0
        };
    }

    componentDidMount() {
        const { authStore, userLoungeStore} = this.props;

        document.getElementById('timelineList').addEventListener("scroll", () => this.changeTimelineDate(), true);
        document.getElementById('timelineList').addEventListener("scroll", () => this.infiniteScroll(userLoungeStore.selectedTeamId, authStore.loginUser.id), true);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {timelineStore} = this.props;

        if (timelineStore.topRoomId !== undefined) {
            const el = document.getElementById(timelineStore.topRoomId);
            if (el) {
                el.scrollIntoView({block: "start", behavior : 'auto'});
            }
            timelineStore.changeTopRoomId(undefined);
        }
    }

    changeTimelineDate = () => {
        const {timelineStore} = this.props;
        const targetElement = document.getElementById('timelineList');
        const scrollTop = targetElement ? parseInt(targetElement.scrollTop.toString()) : 0;
        timelineStore.closeAllTimelinePanel();
        timelineStore.changeScrollTop(scrollTop);

        for(let i = 0; i < timelineStore.roomListGroupByStartTime.length; i++) {
            if (document.getElementById(timelineStore.roomListGroupByStartTime[i].roomList[0].id) !== null) {
                let top = document.getElementById(timelineStore.roomListGroupByStartTime[i].roomList[0].id).getBoundingClientRect().top;
                let bottom = document.getElementById(timelineStore.roomListGroupByStartTime[i].roomList[0].id).getBoundingClientRect().bottom;

                if (this.state.scrollTop < scrollTop) {
                    if (top - 85 < scrollTop < top - 75) {
                        timelineStore.changeTimelineDate(timelineStore.roomListGroupByStartTime[i].roomList[0].startDatetime);
                        this.setState({
                            scrollTop: scrollTop
                        });
                        break;
                    }
                } else {
                    if (bottom - 370 < scrollTop < bottom - 360) {
                        timelineStore.changeTimelineDate(timelineStore.roomListGroupByStartTime[i].roomList[0].startDatetime);
                        this.setState({
                            scrollTop: scrollTop
                        });
                        break;
                    }
                }
            }
        }
    }

    infiniteScroll = (teamId,userId) => {
        const {timelineStore, progressStore} = this.props;
        const targetElement = document.getElementById('timelineList');
        let scrollTop = targetElement ? targetElement.scrollTop : 0;
        let clientHeight = targetElement ? targetElement.clientHeight : 0;
        let scrollHeight = targetElement ? targetElement.scrollHeight : 0;

        if (timelineStore.roomListGroupByStartTime.length > 0) {
            if (scrollTop + clientHeight >= scrollHeight) {
                progressStore.setIsLoading(true)
                timelineStore.getRoomListGroupByStartTime(teamId, userId, 'future', 0)
            }
            if (scrollTop === 0) {
                if (timelineStore.roomListGroupByStartTime[0].roomList.length > 0) {
                    progressStore.setIsLoading(true)
                    const topRoomId = timelineStore.roomListGroupByStartTime[0].roomList[0].id;
                    timelineStore.getRoomListGroupByStartTime(teamId, userId, 'past', 0, topRoomId);
                }
            }
        }
    };

    render() {
        const { classes, history, timelineStore } = this.props;
        const date = DATE_UTIL.getTimeZoneLanguage(timelineStore.timelineDate).format('YYYY.MM.DD(ddd)') !== 'Invalid Date' ? DATE_UTIL.getTimeZoneLanguage(timelineStore.timelineDate).format('YYYY.MM.DD(ddd)') : DATE_UTIL.getTimeZoneLanguage(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT)).format('YYYY.MM.DD(ddd)');
        const params = {
            slidesPerView: 1,
            allowSlideNext: false,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
        }

        const multiParams = {
            slidesPerView: this.props.isTablet ? 2 : 1,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            spaceBetween: 10
        }

        return (
            <React.Fragment>
                <Typography className={classes.dateStyle}>{date}</Typography>
                <div id='timelineList' className={classes.root} style={{overflowY: 'auto', height: window.innerHeight - 350}}>
                    {
                        timelineStore.roomListGroupByStartTime.length > 0 ?
                            timelineStore.roomListGroupByStartTime.map((roomData, index) => {
                                return (
                                    <Box mb={3} key={`${index}-${roomData.roomList[0].id}`} style={roomData.isLast ? {paddingBottom: (window.innerHeight - 350) - 210} : {paddingBottom: 0}}>
                                        {roomData.roomList.length > 1 ?
                                            <div id={roomData.roomList[0].id}>
                                                <Box display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} mb={2} mt={2}>
                                                    <Swiper {...multiParams}>
                                                    {
                                                        roomData.roomList.map((room, index) => {
                                                            return (
                                                                <Box key={`${room.id}-${index}`} display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} mb={2}>
                                                                    {index === 0 &&
                                                                        <div style={window.innerWidth > 350 ? {width:60, paddingLeft:20} : {width:60, paddingLeft:10}}>
                                                                            <MobileTimelineItemOppositeContent room={room}/>
                                                                        </div>
                                                                    }
                                                                    <div>
                                                                        <MobileTimeLineItemContent
                                                                            history={history}
                                                                            room={room}
                                                                            isMulti={false}
                                                                        />
                                                                    </div>
                                                                </Box>
                                                            )
                                                        })
                                                    }
                                                    </Swiper>
                                                    {/* do not delete this */}
                                                    <div style={{width:20, height:20}}/>
                                                </Box>
                                            </div>
                                            :
                                            <div id={roomData.roomList[0].id}>
                                                <Box display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} mb={2} mt={2}>
                                                    <Swiper {...params}>
                                                        {roomData.roomList[0].id !== 0 ?
                                                            <Box display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} mb={2}>
                                                                <div style={window.innerWidth > 350 ? {width:60, paddingLeft:20} : {width:60, paddingLeft:10}}>
                                                                    <MobileTimelineItemOppositeContent room={roomData.roomList[0]}/>
                                                                </div>
                                                                <div>
                                                                    <MobileTimeLineItemContent
                                                                        history={history}
                                                                        room={roomData.roomList[0]}
                                                                        isMulti={false}
                                                                    />
                                                                </div>
                                                            </Box>
                                                            :
                                                            <MobileTimeLineNoSchedule/>
                                                        }
                                                        {/* do not delete this */}
                                                        <div style={{width:20, height:20}}/>
                                                    </Swiper>
                                                </Box>
                                            </div>
                                        }
                                    </Box>
                                )
                            })
                            :
                            <Box mb={3}>
                                <Swiper {...params}>
                                    <MobileTimeLineNoSchedule/>
                                </Swiper>
                            </Box>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(style)(MobileTimeLine));
