import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, Typography,Box} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles({
    dialogBox:{
        "& .MuiDialog-paper":{
            borderRadius:10,
            boxShadow:'none'
        },
        "& .MuiDialogContent-root":{
            padding:0
        },
        "& .MuiDialogActions-root":{
            padding:0
        },
        "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.2)"
        },
    },
    dialogTitleBox:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    titleStyle: {
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#0d0d0d',
        fontWeight:800,
    },
    textStyle:{
        borderBottom:'1px solid #c4c4c4',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#000',
        padding:'0 0 15px',
        textAlign:'center',
        wordBreak: 'keep-all',
    },
    btnStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#0082db',
        width:'100%',
        borderRadius:0,
    }
});

export default function MobileConfirmDialog(props) {
    const classes = useStyles();
    const { open, title, msg, changeDialogOpen } = props;

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xs'} className={classes.dialogBox} >
            <DialogTitle>
                <Box className={classes.dialogTitleBox}>
                    <Typography className={classes.titleStyle}>{title}</Typography>
                </Box>

            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" component="h2" className={classes.textStyle}>
                    {msg}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={()=>{changeDialogOpen(false)}}
                    className={classes.btnStyle}
                >
                    <FormattedMessage id="ok"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}