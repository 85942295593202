import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Button,
    Typography,
    Box,
    TextareaAutosize, IconButton, Dialog, Slide
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import { AuthorityType } from "../../../stores/UserLoungeStore"
import {ReactComponent as MobileClose} from "../../../common/images/MobileClose.svg";
import {FormattedMessage, injectIntl} from "react-intl";


const style = theme => ({
    root: {
    },
    saveBtn:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover":{
            background:'transparent'
        }
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        "& .MuiMenuItem-root":{
            minHeight:0
        }
    },
    titleText:{
        width:'100%',
        textAlign:'center',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    iconBtn:{
        padding:5
    },
    buttonStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover":{
            background:'transparent'
        }
    },
    noticeBox:{
        padding:20,
    },
    modifyDialogTitleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#000',
        fontWeight: 500,
        textAlign:'justify',
        width: '100%',
        resize:'none',
        padding:'10px 8px',
        border:'1px solid #c0c2c3',
        borderRadius:5,
        "&::placeholder":{
            color:'#a3a8af',
            fontWeight: 500,
        },
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
        "&:focus":{
            outline:'none',
        }
    },
    modifyText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#000000',
        fontWeight:600,
        margin:'25px 0 10px'

    }

});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref}/>
));

@inject('authStore', 'userLoungeStore')
@observer
class MobileAnnouncement extends Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        const { userLoungeStore } = this.props;
        userLoungeStore.resetNotice();
        this.setState({ open: false });
    };

    handleToggle = () => {
        this.setState(state => ({ moreOpen: !state.moreOpen }));
    };

    handleSave = (event) => {
        const { authStore, userLoungeStore } = this.props;
        userLoungeStore.addTeamNotice(authStore.loginUser.id);
        this.setState({
            open: false,
        });
    };

    render() {
        const { classes, intl, userLoungeStore } = this.props;

        return (
            <div className={classes.root} >
                <Button
                    onClick={this.handleClickOpen}
                    className={classes.saveBtn}
                    disableRipple
                    disabled={!userLoungeStore.authorityList.find(a => a.authority === AuthorityType.CreateNotice) && userLoungeStore.userType !== 'Leader'}
                >
                    <FormattedMessage id="registration"/>
                </Button>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox}>
                        <Box display='flex' alignItems='center'>
                            <IconButton onClick={this.handleClose} aria-label="Close" className={classes.iconBtn}>
                                <MobileClose/>
                            </IconButton>
                            <Typography className={classes.titleText}><FormattedMessage id="notice"/></Typography>
                        </Box>
                        <Button className={classes.buttonStyle} onClick={this.handleSave}><FormattedMessage id="complete"/></Button>
                    </Box>

                    <Box className={classes.noticeBox}>
                        <TextareaAutosize
                            aria-label="maximum height"
                            rows={8}
                            rowsMax={8}
                            maxLength="100"
                            placeholder={intl.formatMessage({id: "msg.insert_subject"})}
                            onChange={(event) => userLoungeStore.changeNoticeTitle(event.target.value)}
                            className={classes.modifyDialogTitleText}
                        />

                        <Typography className={classes.modifyText}><FormattedMessage id="descriptions"/></Typography>
                        <TextareaAutosize
                            aria-label="maximum height"
                            rows={14}
                            rowsMax={14}
                            maxLength="500"
                            placeholder={intl.formatMessage({id: "msg.insert_contents"})}
                            onChange={(event) => userLoungeStore.changeNoticeContent(event.target.value)}
                            maxLength={500}
                            className={classes.modifyDialogTitleText}
                        />
                    </Box>
                </Dialog>
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(MobileAnnouncement));